<!-- 产品管理编辑 -->
<template>
    <div class="product-edit">
        <div class="product-basic">

            <div class="product-edit-title">
                <span>基本信息</span>
                <div class="product-up">
                    <i class="el-icon-arrow-down"></i>
                </div>
            </div>

            <div class="product-form">
                <el-form :model="ruleForm" :rules="rules" ref="ruleProductForm" label-width="100px"
                    class="product-ruleForm">
                    <el-form-item label="产品标题" prop="title">
                        <el-input v-model="ruleForm.title" placeholder="请填写产品标题"></el-input>
                    </el-form-item>
                    <el-form-item label="产品副标题" prop="titleSub">
                        <el-input v-model="ruleForm.titleSub" placeholder="请填写产品副标题"></el-input>
                    </el-form-item>
                    <el-form-item label="国家-领区" prop="areaId">
                        <div class="product-select">
                            <el-select v-model="ruleForm.countryId" placeholder="请选择" @change="selectCountry">

                                <el-option v-for="item in country" :key="item.id" :label="item.zhName" :value="item.id">
                                </el-option>
                            </el-select>
                            <span>-</span>
                            <el-select v-model="ruleForm.areaId" placeholder="请选择">
                                <el-option v-for="item in area" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </div>
                    </el-form-item>

                    <el-form-item label="签证-类型" prop="visaSubTypeId">
                        <div class="product-select">
                            <el-select v-model="ruleForm.visaTypeId" placeholder="请选择" @change="selectVisa">
                                <el-option v-for="item in visaType" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                            <span>-</span>
                            <el-select v-model="ruleForm.visaSubTypeId" placeholder="请选择">
                                <el-option v-for="item in visaSubList" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </div>

                    </el-form-item>
                    <el-form-item label="服务" prop="serviceTypeName">
                        <!-- <el-radio-group v-model="ruleForm.serviceType">
                            <el-radio :label="item.value" v-for="(item, index) in servicetype" :key="index">{{ item.label
                            }}</el-radio>
                        </el-radio-group> -->
                        <el-checkbox-group v-model="ruleForm.serviceTypeName">
                            <el-checkbox :label="item.label" v-for="(item, index) in servicetype" :key="index">{{item.label}}</el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item label="办理类型" prop="method">
                        <el-radio-group v-model="ruleForm.method">
                            <el-radio :label="item.value" v-for="(item, index) in visaMethods" :key="index">{{ item.label
                            }}</el-radio>
                        </el-radio-group>

                    </el-form-item>
                    <el-form-item label="停留期" prop="durationOfStay">
                        <el-input v-model="ruleForm.durationOfStay" placeholder="请填写停留期"></el-input>
                    </el-form-item>
                    <el-form-item label="停留期描述" prop="durationOfStayDesc">
                        <el-input v-model="ruleForm.durationOfStayDesc" placeholder="请填写停留期描述"></el-input>
                    </el-form-item>
                    <el-form-item label="有效期" prop="validityPeriod">
                        <el-input v-model="ruleForm.validityPeriod" placeholder="请填写有效期"></el-input>
                    </el-form-item>
                    <el-form-item label="有效期描述" prop="validityPeriodDesc">
                        <el-input v-model="ruleForm.validityPeriodDesc" placeholder="请填写有效期"></el-input>
                    </el-form-item>
                    <el-form-item label="预计办理天数" prop="estimatedDays">
                        <el-input v-model="ruleForm.estimatedDays" placeholder="请填写预计办理天数"></el-input>
                    </el-form-item>
                    <el-form-item label="预计办理天数描述" prop="spendTime">
                        <el-input v-model="ruleForm.spendTime" placeholder="请填写预计办理天数描述"></el-input>
                    </el-form-item>
                    <el-form-item label="入境次数" prop="entyCount">
                        <el-input v-model="ruleForm.entyCount" placeholder="请填写入境次数"></el-input>
                    </el-form-item>
                    <el-form-item label="入境次数描述" prop="entyCountDesc">
                        <el-input v-model="ruleForm.entyCountDesc" placeholder="请填写入境次数描述"></el-input>
                    </el-form-item>
                    <el-form-item label="资料时长描述" prop="dataSpendTime">
                        <el-input v-model="ruleForm.dataSpendTime" placeholder="请填写资料时长描述"></el-input>
                    </el-form-item>
                    <el-form-item label="使馆时长描述" prop="embassySpendTime">
                        <el-input v-model="ruleForm.embassySpendTime" placeholder="请填写使馆时长描述"></el-input>
                    </el-form-item>



                    <!-- <el-form-item label="价格1" prop="price1">
                        <el-input v-model="ruleForm.price1" type="Number" placeholder="请填写价格1"></el-input>
                    </el-form-item> -->
                    <el-form-item label="成本价" prop="price2">
                        <el-input v-model="ruleForm.price2" type="Number" placeholder="请填写成本价"></el-input>
                    </el-form-item>
                    <el-form-item label="商户价格" prop="price3">
                        <el-input v-model="ruleForm.price3" type="Number" placeholder="请填写商户价格"></el-input>
                    </el-form-item>
                    <el-form-item label="零售价" prop="price4">
                        <el-input v-model="ruleForm.price4" type="Number" placeholder="请填写零售价"></el-input>
                    </el-form-item>

                    <el-form-item label="出签率" prop="visaRate">
                        <el-input v-model="ruleForm.visaRate" type="Number" placeholder="请填写出签率"></el-input>
                    </el-form-item>
                    <el-form-item label="排序" prop="sort">
                        <el-input v-model="ruleForm.sort" type="Number" placeholder="请填写描述"></el-input>
                    </el-form-item>
                </el-form>
            </div>
        </div>

        <div class="product-basic">

            <div class="product-edit-title">
                <span>详细描述</span>
                <div class="product-up">
                    <i class="el-icon-arrow-down"></i>
                </div>
            </div>
            <div class="product-form">
                <el-form :model="ruleForm" :rules="rules" ref="ruleProductForm2" label-width="100px"
                    class="product-ruleForm">
                    <el-form-item label="受理范围" prop="rangeDesc">
                        <el-input type="textarea" :rows="4" placeholder="请输入受理范围" v-model="ruleForm.rangeDesc">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="服务包含" prop="serviceDesc">
                        <el-input type="textarea" :rows="4" placeholder="请输入服务包含" v-model="ruleForm.serviceDesc">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="温馨提示" prop="promptDesc">
                        <el-input type="textarea" :rows="4" placeholder="请输入温馨提示" v-model="ruleForm.promptDesc">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="办理须知" prop="instructions">
                        <el-input type="textarea" :rows="4" placeholder="请输入办理须知" v-model="ruleForm.instructions">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="签证图例" prop="visaLegend">
                        <upload v-model="ruleForm.visaLegend" :auto-upload="true" :image-url="ruleForm.visaLegend"
                            :drag="'drag'" thisType="other">
                        </upload>
                    </el-form-item>

                    <el-form-item label="" prop="auditRequired">

                        <div class="product-switch" :style="{ marginTop: isSystemVisaId ? '120px' : '175px'  }">

                            <el-switch v-model="isSystemVisaId" inactive-text="是否绑定在线填表（支持一键提交到官网）" style="margin-right: 25px;">
                            </el-switch>

             
                            <el-select v-if="isSystemVisaId" v-model="ruleForm.systemVisaId" style="width: 320px;margin-top: 20px;" placeholder="请选择" @change="selectSystemVisaId">
                                <el-option v-for="item in sysTem" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </div>
                    </el-form-item>




                </el-form>

            </div>
        </div>
        <div class="product-basic">

            <div class="product-edit-title">
                <span>流程明细</span>
                <div class="product-up">
                    <i class="el-icon-arrow-down"></i>
                </div>
            </div>
            <div class="box-table">
                <el-table :data="dataList"
                    :header-cell-style="{ backgroundColor: '#F2F4F7', color: '#333', fontWeight: 'bold', textAlign: 'center' }"
                    style="width: 100%" v-loading="loading" :cell-style="{ 'text-align': 'center' }" @row-click="toInput">
                    <el-table-column prop="title" label="流程名称" width="300px" >o
                        <template slot-scope="scope">
                            <span style="cursor: pointer;"  v-if="!scope.row.inputShow">{{
                                scope.row.title }}</span>
                            <el-select v-else v-model="scope.row.title" placeholder="请选择" @input="selectLc($event, scope.$index)">
                                <el-option v-for="item in procesSeselect" :key="item.code" :label="item.title"
                                    :value="item.code">
                                </el-option>
                            </el-select>
                        </template>
                    </el-table-column>
                    <el-table-column prop="content" label="流程明细" width="380px">
                        <template slot-scope="scope">
                            <span style="cursor: pointer;"  v-if="!scope.row.inputShow">{{
                                scope.row.content }}</span>
                            <el-input v-else v-model="scope.row.content" placeholder="请输入内容"></el-input>
                        </template>

                    </el-table-column>
                    <el-table-column prop="sort" label="排序">
                        <template slot-scope="scope">
                            <span style="cursor: pointer;"  v-if="!scope.row.inputShow">{{
                                scope.row.index }}</span>
                            <el-input v-else v-model="scope.row.index" placeholder="请输入内容"></el-input>
                        </template>
                    </el-table-column>

                    <el-table-column label="操作" header-align="center">
                        <template slot-scope="scope">
                            <div>
                                <span class="member-edit" @click="editMember(scope.row, scope.$index)">删除</span>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="box-table-btn">
                <el-button class="close btn-back" @click="addLc">添加</el-button>
            </div>

        </div>

        <div class="product-btn">
            <el-button class="default btn-submit" @click="submit('ruleProductForm')">保存</el-button>
            <el-button class="close btn-back" @click="back">返回</el-button>

        </div>

    </div>
</template>

<script>
import { countrylist, arealist, visatypelist, bindsystemvisa , updateProduct, servicetypeselect, visamethodselect, orderprocesseselect, add, editProduct, querysystemvisa } from '@/api/personal.js'
import upload from '@/components/upload.vue'
export default {
    components: {
        upload
    },
    data() {
        return {
            rules: {
                title: [
                    { required: true, message: '请填写产品标题', trigger: ['click', 'change', 'blur'] }
                ],
                titleSub: [
                    { required: true, message: '产品副标题', trigger: ['click', 'change', 'blur'] },
                ],
                countryId: [
                    { required: true, message: '请选择国家-领区', trigger: ['click', 'change', 'blur'] }
                ],
                areaId: [
                    { required: true, message: '请选择国家-领区', trigger: ['click', 'change', 'blur'] }
                ],
                visaTypeId: [
                    { required: true, message: '请选择签证-类型', trigger: ['click', 'change', 'blur'] },
                ],
                visaSubTypeId: [
                    { required: true, message: '请选择签证-类型', trigger: ['click', 'change', 'blur'] },
                ],
                // serviceType: [
                //     { required: true, message: '请选择服务', trigger: ['click', 'change', 'blur'] },
                // ],
                method: [
                    { required: true, message: '请选择办理类型', trigger: ['click', 'change', 'blur'] },
                ],
                durationOfStay: [
                    { required: true, message: '请填写停留期', trigger: ['click', 'change', 'blur'] },
                ],
                entyCount: [
                    { required: true, message: '请填写入境次数', trigger: ['click', 'change', 'blur'] },
                ],
                validityPeriod: [
                    { required: true, message: '请填写有效期', trigger: ['click', 'change', 'blur'] },
                ],
                estimatedDays: [
                    { required: true, message: '请填写预计办理天数', trigger: ['click', 'change', 'blur'] },
                ],
                visaRate: [
                    { required: true, message: '请填写出签率', trigger: ['click', 'change', 'blur'] },
                ],
                dataSpendTime: [
                    { required: true, message: '请填写资料时长描述', trigger: ['click', 'change', 'blur'] },
                ],
                embassySpendTime: [
                    { required: true, message: '请填写资料时长描述', trigger: ['click', 'change', 'blur'] },
                ],

                price1: [
                    { required: true, message: '请填写价格1', trigger: ['click', 'change', 'blur'] },
                ],
                price2: [
                    { required: true, message: '请填写成本价', trigger: ['click', 'change', 'blur'] },
                ],
                price3: [
                    { required: true, message: '请填写商户价格', trigger: ['click', 'change', 'blur'] },
                ],
                price4: [
                    { required: true, message: '请填写零售价', trigger: ['click', 'change', 'blur'] },
                ],
                sort: [
                    { required: true, message: '请填写排序', trigger: ['click', 'change', 'blur'] },
                ],


            },
            ruleForm: {
                id: '',
                orgId: '',
                title: '',
                titleSub: '',
                countryId: '',
                areaId: '',
                visaTypeId: '',
                visaSubTypeId: '',
                serviceTypeName: [],
                method: '',
                durationOfStayDesc: '',
                durationOfStay: '',
                validityPeriodDesc: '',
                estimatedDays: '',
                validityPeriod: '',
                entyCountDesc: '',
                entyCount: '',
                price1: '',
                price2: '',
                price3: '',
                price4: '',
                rangeDesc: '',
                serviceDesc: '',
                promptDesc: '',
                spendTime: '',
                instructions: '',
                visaLegend: '',
                auditRequired: false,
                enable: false,
                visaRate: '',
                dataSpendTime: '',
                embassySpendTime: '',
                sort: '',
                systemVisaId: '',
            },
            loading: false,
            isSystemVisaId: false,
            country: [],
            area: [],
            visaType: [],
            visaSubList: [],
            servicetype: [],
            visaMethods: [],
            procesSeselect: [],
            dataList: [],
            sysTem: [],
        }
    },
    props: {
        id: {
            type: [Number, String],
            default: ''
        }
    },
    watch: {
        'ruleForm.countryId' (val) {
            this.getArealist();
        },
        'ruleForm.visaTypeId' (val, oldVal) {
            console.log(val, oldVal)
            this.selectVisa(val);

            if (val != oldVal && oldVal){
                this.ruleForm.visaSubTypeId = '';
            }
        }
    },
    mounted() {
        console.log(this.id);

        this.getOrderprocesseselect();
        this.getVisamethodselect();
        this.getServicetypeselect();
        this.getCountrylist();
        // this.getArealist();
        this.getVisatypelist();
        this.querysystemvisaFn();

        if (this.id) {
            this.getEditProduct();
        } else {
            this.isSystemVisaId = false;
        }


    },
    methods: {
        getCountrylist() {
            countrylist({
                 
            }).then(res => {
                console.log(res);
                this.country = res.data
            })
        },

        getEditProduct () {
            editProduct({
                id: this.id
            }).then(res => {

                let data = res.data

                data.processDesc = JSON.parse(data.processDesc);
                console.log(res);
                this.dataList = data.processDesc.process

                this.dataList.forEach(item => {
                    item.inputShow = false;
                })


                
                if (!data.systemVisaId) {
                    data.systemVisaId = ''
                    this.isSystemVisaId = false;
                } else {
                    this.isSystemVisaId = true;
                }

                // this.dataList
                this.ruleForm = {
                    ...data
                }

            })
        },
        getArealist() {
            arealist({
                countryId: this.ruleForm.countryId
            }).then(res => {
                console.log(res);
                this.area = res.data;
            })
        },

        // 签证大类
        async getVisatypelist() {
            let res = await visatypelist({
                 
            })

            this.visaType = res.data;
        },

        getServicetypeselect() {
            servicetypeselect({
                 
            }).then(res => {
                console.log(res);
                this.servicetype = res.data;
            })
        },

        getVisamethodselect() {
            visamethodselect({
                 
            }).then(res => {
                console.log(res);

                this.visaMethods = res.data;
            })
        },

        querysystemvisaFn () {
            querysystemvisa({
            }).then(res => {
                console.log(res);

                this.sysTem = res.data.rows;
            })
        },

        toInput (e) {
            console.log(e);
            this.setInput(e);
        },

        getOrderprocesseselect() {
            this.loading = true;

            orderprocesseselect({
                 
            }).then(res => {
                console.log(res);
                this.loading = false;

                this.procesSeselect = res.data;


            })
        },

  

        selectCountry(e) {
            console.log(e);

            this.ruleForm.areaId = '';
            this.getArealist();
        },
        async selectVisa(e) {
            this.getVisatypelist();


            let res = await visatypelist({
                 
            })

            this.visaType = res.data;
                
            console.log(e); 
  
            console.log(this.visaType);
            this.visaType.forEach(item => {
                if (item.id == e) {

                    this.visaSubList = item.subTypes

                }
            })

        },

        setInput(item) {

            this.dataList.forEach(item => {
                item.inputShow = false;
            })
            item.inputShow = !item.inputShow


            this.$forceUpdate();
        },


        setSort(item) {
            item.sortShow = !item.sortShow
            this.$forceUpdate();
        },

        editMember(item, index) {
            this.dataList.splice(index, 1)
        },

        addLc() {

            this.dataList.forEach(item => {
                item.inputShow = false;
            })
            this.dataList.push({
                title: '',
                content: '',
                sort: '',
                isEdit: true,
                index: '',
                code: '',
                inputShow: true,
            })
        },
        selectLc(e, index) {
            console.log(e, index);
            this.procesSeselect.forEach(item => {
                if (item.code == e) {
                    console.log(1);
                    this.dataList[index] = {
                        title: item.title,
                        content: item.desc,
                        sort: index,
                        index: index +1,
                        code: e,
                        inputShow: true,
                        isEdit: true,
                    }
                }
            })
            this.dataList = JSON.parse(JSON.stringify(this.dataList))
            this.$forceUpdate();
        },

        selectSystemVisaId (e) {
            console.log(e);

            if (this.ruleForm.id) {

                bindsystemvisa({
                    visaId: this.ruleForm.id,
                    systemVisaId: e
                }).then(res => {
    
                }) 
            }

                
            
        },


        back() {
            this.$emit('open')
        },
        submit(formName) {
            let vm = this;

            this.$refs[formName].validate((valid) => {
                if (valid) {

                    var processDesc = JSON.stringify({process: this.dataList})
                    console.log(this.id)

                    if (!this.id) {
                        add({
                            token: vm.$store.state.token,
                            countryId: vm.ruleForm.countryId,
                            areaId: vm.ruleForm.areaId,
                            visaTypeId: vm.ruleForm.visaTypeId,
                            visaSubTypeId: vm.ruleForm.visaSubTypeId,
                            title: vm.ruleForm.title,
                            titleSub: vm.ruleForm.titleSub,
                            validityPeriod: vm.ruleForm.validityPeriod,
                            durationOfStay: vm.ruleForm.durationOfStay,
                            serviceTypeName: vm.ruleForm.serviceTypeName,
                            estimatedDays: vm.ruleForm.estimatedDays,
                            spendTime: vm.ruleForm.spendTime,
                            method: vm.ruleForm.method,
                            rangeDesc: vm.ruleForm.rangeDesc,
                            instructions: vm.ruleForm.instructions,
                            promptDesc: vm.ruleForm.promptDesc,
                            serviceDesc: vm.ruleForm.serviceDesc,
                            visaLegend: vm.ruleForm.visaLegend,
                            price1: vm.ruleForm.price1,
                            price2: vm.ruleForm.price2,
                            price3: vm.ruleForm.price3,
                            price4: vm.ruleForm.price4,
                            enable: vm.ruleForm.enable,
                            entyCountDesc: vm.ruleForm.entyCountDesc,
                            visaRate: vm.ruleForm.visaRate,
                            auditRequired: vm.ruleForm.auditRequired,
                            dataSpendTime: vm.ruleForm.dataSpendTime,
                            embassySpendTime: vm.ruleForm.embassySpendTime,
                            entyCount: vm.ruleForm.entyCount,
                            validityPeriodDesc: vm.ruleForm.validityPeriodDesc,
                            durationOfStayDesc: vm.ruleForm.durationOfStayDesc,
                            systemVisaId: vm.isSystemVisaId && vm.ruleForm.systemVisaId ? vm.ruleForm.systemVisaId : 0,
                            sort: vm.ruleForm.sort,
                            processDesc: processDesc
                        }).then(res => {


                            if (res.code == 1) {

                                this.$message({
                                    message: '添加成功！',
                                    type: 'success'
                                });
                                this.dataList.forEach(item => {
                                    item.inputShow = false;
                                })
                                // this.$emit('open')
                            } else {

                                this.$message({
                                    message: res.msg,
                                    type: 'warning'
                                });
                            }
                        })
                    } else {
                        updateProduct({
                            id: vm.ruleForm.id,
                            orgId: vm.ruleForm.orgId,
                            token: vm.$store.state.token,
                            countryId: vm.ruleForm.countryId,
                            areaId: vm.ruleForm.areaId,
                            visaTypeId: vm.ruleForm.visaTypeId,
                            visaSubTypeId: vm.ruleForm.visaSubTypeId,
                            title: vm.ruleForm.title,
                            titleSub: vm.ruleForm.titleSub,
                            validityPeriod: vm.ruleForm.validityPeriod,
                            durationOfStay: vm.ruleForm.durationOfStay,
                            serviceTypeName: vm.ruleForm.serviceTypeName,
                            estimatedDays: vm.ruleForm.estimatedDays,
                            spendTime: vm.ruleForm.spendTime,
                            method: vm.ruleForm.method,
                            rangeDesc: vm.ruleForm.rangeDesc,
                            instructions: vm.ruleForm.instructions,
                            promptDesc: vm.ruleForm.promptDesc,
                            serviceDesc: vm.ruleForm.serviceDesc,
                            visaLegend: vm.ruleForm.visaLegend,
                            price1: vm.ruleForm.price1,
                            price2: vm.ruleForm.price2,
                            price3: vm.ruleForm.price3,
                            price4: vm.ruleForm.price4,
                            enable: vm.ruleForm.enable,
                            entyCountDesc: vm.ruleForm.entyCountDesc,
                            visaRate: vm.ruleForm.visaRate,
                            auditRequired: vm.ruleForm.auditRequired,
                            dataSpendTime: vm.ruleForm.dataSpendTime,
                            embassySpendTime: vm.ruleForm.embassySpendTime,
                            entyCount: vm.ruleForm.entyCount,
                            validityPeriodDesc: vm.ruleForm.validityPeriodDesc,
                            durationOfStayDesc: vm.ruleForm.durationOfStayDesc,
                            sort: vm.ruleForm.sort,
                            systemVisaId:  vm.isSystemVisaId && vm.ruleForm.systemVisaId ? vm.ruleForm.systemVisaId : 0,
                            processDesc: processDesc
                        }).then(res => {


                            if (res.code == 1) {

                                this.$message({
                                    message: '修改成功！',
                                    type: 'success'
                                });
                                this.dataList.forEach(item => {
                                    item.inputShow = false;
                                })
                                // this.$emit('open')
                            } else {

                                this.$message({
                                    message: res.msg,
                                    type: 'warning'
                                });
                            }
                        })
                    }


                } else {



                    return false;
                }
            });


        }
    }

}
</script>

<style lang="scss">
.product-ruleForm {
    margin-top: 4px;

    .el-form-item {
        display: flex;
        flex-direction: column;
        margin-bottom: 6px;
        width: 434px;

        .el-form-item__label {
            width: 50px !important;
            white-space: nowrap;
            height: 30px;
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 8px;
        }

        .el-form-item__content {
            margin-left: 0px !important;
        }

        .el-input {
            input {
                width: 323px;
                border: none;
                background: #F7F8FA;
                border-radius: 4px;
            }


        }

        .el-form-item__content {
            .el-textarea {
                width: 323px;


                textarea {
                    border: none;
                    background: #F7F8FA;
                    border-radius: 4px;
                }
            }

        }

        .product-select {
            .el-input__inner {
                width: 150px;
            }


        }
    }
}
</style>

<style lang="scss" scoped>
.product-edit {
    padding: 18px;

    .product-basic {
        width: 100%;
        width: 977px;
        margin-bottom: 15px;

        .product-edit-title {
            background: #EAF3FF;
            border-left: 4px solid #3888E9;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 44px;
            padding: 0 17px;

            span {
                font-size: 20px;
                font-weight: bold;
            }
        }

        .box-table {
            margin-top: 16px;
        }
    }
}

.box-table-btn {
    text-align: center;
    margin-top: 15px;
}

.product-btn {
    width: 100%;
    text-align: right;
    margin: 20px 0;
    margin-top: 40px;
}

.product-ruleForm {
    display: flex;
    
    flex-wrap: wrap;

    .product-switch {
        // margin-top: 170px;
        display: flex;
        flex-direction: column;


    }
}

.product-select {}

.member-edit {
    color: #E41313;
    cursor: pointer;
    font-size: 12px;
}
</style>