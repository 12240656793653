<template>
    <div class="page order-detail-child">
        <div class="order-wrap">
            <div class="page-path">
                <span @click="toPath(1)">快马签证</span> > <span @click="toPath(2)">我的订单</span> > <span
                    @click="toPath(3)">资料上传</span> > <span @click="toPath(4)">申请表详情</span>

            </div>

            <div class="page-order-detaul-bom">
                <div class="page-order-detail-left">
                    <div class="page-order-item-info">
                        <div class="page-order-item-t">
                            <span>订单号：</span>
                            <p id="copy">{{ orderDetail.order && orderDetail.order.orderNo }}
                                <!-- <i i style="color: #3888E9;" @click="copyOrder"
                                    class="el-icon-document-copy"></i> -->
                            </p>
                        </div>
                        <div class="page-order-item-t">
                            <span>国家-类型：</span>
                            <p>{{ orderDetail.visaDetail && orderDetail.visaDetail.visa &&
                                orderDetail.visaDetail.visa.countryName }}-{{ orderDetail.visaDetail &&
        orderDetail.visaDetail.visa && orderDetail.visaDetail.visa.typeName }}</p>
                        </div>
                        <div class="page-order-item-t">
                            <span>下单时间：</span>
                            <p>{{ orderDetail.order && orderDetail.order.createdTime }}</p>
                        </div>
                    </div>

                    <div class="page-order-item-table">
                        <div class="page-order-detail-btn">
                            <el-button class="default" @click="addUser">添加申请人</el-button>
                            <el-button class="default">批量下载</el-button>
                        </div>


                        <!-- :height="$store.state.product.screen ? '820px' : '470px'" -->
                        <div class="page-order-table-box">
                            <!-- @row-click="setColl" -->
                            <el-table :data="userList" ref="tableOrder"
                                :header-cell-style="{ backgroundColor: 'none', color: '#333', fontWeight: 'bold', textAlign: 'center', fontSize: '10px' }"
                                height="470px" @row-click="setColl" :cell-style="{ 'text-align': 'center' }">
                                <!--  -->

                                <el-table-column prop="applicantName" label="姓名">
                                </el-table-column>
                                <el-table-column prop="identityTypeName" label="身份类型">
                                </el-table-column>
                                <el-table-column prop="statusName" label="申请状态">
                                </el-table-column>

                                <el-table-column label="操作" header-align="center">
                                    <template slot-scope="scope">
                                        <span class="lan" v-if="scope.row.status == 5 && scope.row.visaPage"
                                            @click="downPackage(scope.row)">下载</span>
                                        <span class="lan" v-if="scope.row.status == 0"
                                            @click="editPackage(scope.row)">变更身份类型</span>
                                        <!-- <span class="lan"
                                            v-if="scope.row.status == 0 && orderDetail.visaDetail.visaDocs.length > 1"
                                            @click="editPackage(scope.row)">变更身份类型</span> -->
                                        <!-- <span class="lan" v-if="[0].indexOf(scope.row.status) != -1"
                                            @click="selectStatus(1,scope.row)">审核</span> -->
                                        <!-- <span class="del" @click="delProduct(scope.row.id)">删除</span> -->
                                    </template>
                                </el-table-column>
                            </el-table>
                            <div class="table-pagination">

                                <el-pagination @current-change="addressCurrentChange" :current-page="pageIndex"
                                    :page-size="pageSize" layout="total, prev, pager, next, jumper" :total="total">
                                </el-pagination>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="page-order-detail-bottom">
                    <!-- <div class="page-order-detail-bottom-title">免责声明:平台作为连接移民局官网提交签证的便捷工具，仅提供提交签证申请及下载签证页的功能，签证申请提交
                        后，超过3天状态未变更，请及时联系客服处理，平台不对任何签证结果负责。</div> -->
                    <div class="page-order-detail-bottom-content" style="height:calc( 100% - 70px);">
                        <iframe class="page-card-iframe" ref="iframe" v-if="isShow" style="height: 100%"
                            :src="applicationForm.applicationForm + '&terminal=PC'" frameborder="0"
                            scrolling="auto"></iframe>
                    </div>
                    <div class="page-rder-detail-bottom-btn">
                        <div>
                            <span style="font-size: 16px;color: #0094E8;margin-right: 32px;cursor: pointer;"
                                @click="reload">刷新列表</span>
                            <el-button class="default" @click="iframeSubmit">保存</el-button>
                            <el-button class="text2" style="margin-right: 6px;" @click="delUser">删除</el-button>
                        </div>


                    </div>
                </div>
            </div>


        </div>
        <!-- <div class="order-content">
                <div class="page-card-top">
                    <div class="page-card-top-left">
                        <span class="page-top-back" @click="toPath(3)"><i class="el-icon-arrow-left"></i>返回</span>
                        <p class="page-top-name">{{ name }}</p>
                        <p class="page-top-identity">{{ userType }}</p>
                    </div>
                    <div class="page-card-right">
                        <el-button class="default">提交资料表</el-button>
                    </div>
                </div>
                <div class="page-card-conetent">
                    <iframe  class="page-card-iframe" :src="applicationForm" frameborder="0" scrolling="auto"></iframe>
                </div>
            </div> -->

        <el-dialog title="修改申请人身份" :close-on-click-modal="false" center :visible.sync="isVisible" width="360px"
            class="dialog-wrap">
            <div class="sf-list">
                <p class="sf-label">不同身份类型，签证资料可能会有区别</p>
                <el-radio v-model="sfObj.identityType" size="medium" class="sf-radio" :label="item.identityTypeId"
                    v-for="(item, index) in orderDetail.visaDetail.visaDocs" :key="index">{{ item.name }}</el-radio>
                <div class="sf-btn">
                    <el-button @click="isVisible = false;">取消</el-button>
                    <el-button class="default" @click="editSf">确认</el-button>
                </div>
            </div>
        </el-dialog>
        <addUserInfo :config="newAddUserShow" @addUserFn="addUserFn" @close="newAddUserShow = false" isAdd="true"
            :countryId="orderDetail.order.countryId" :visaDocs="orderDetail.visaDetail.visaDocs"
            :visa="orderDetail.visaDetail.visa" v-if="newAddUserShow"></addUserInfo>
    </div>
</template>

<script>
import { orderdetail, upadateorderapplicants, queryorderapplicants, orderapplicantmodifystatus } from '@/api/order.js'
import addUserInfo from '@/components/addUserInfo.vue'
export default {
    data() {
        return {
            name: '',
            userType: '',
            applicationForm: {},
            id: '',
            identityType: '',
            sfObj: {},
            userList: [],
            isVisible: false,
            newAddUserShow: false,
            orderDetail: {
                order: {},
                orderApplicants: [],
                visaDetail: {
                    basicInfo: [],
                    offlineVisaDocs: [],
                    visa: {},
                    visaDocs: [],
                },
            },
            dateInfo: {
                date: ''
            },
            isShow: true,
            tableActive: 0,
            pageIndex: 1,
            pageSize: 10,
            total: 0,
        }
    },
    components: {
        addUserInfo
    },
    mounted() {
        // applicationForm
        console.log(this.$router.history.current, 99999999)
        // this.applicationForm = this.$router.history.current.params.id.split('_')[0]
        this.name = this.$router.history.current.params.id.split('_')[0]
        this.userType = this.$router.history.current.params.id.split('_')[1]
        this.id = this.$router.history.current.params.id.split('_')[2]
        this.orderdetailFn();
        this.queryorderapplicantsFn();
    },
    methods: {
        handleClose() {

        },
        addressCurrentChange(val) {
            this.pageIndex = valu
            this.queryorderapplicantsFn()
        },
        queryorderapplicantsFn() {
            let vm = this;

            queryorderapplicants({
                OrderId: this.id,
                'Page.Size': this.pageSize,
                'Page.Index': this.pageIndex,
            }).then(res => {
                console.log(res);
                this.total = res.data.total;
                this.userList = res.data.rows;

                if (this.userId > 0) {
                    vm.userList.forEach(item => {
                        if (item.id == this.userId) {
                            vm.applicationForm = item
                            this.$nextTick(() => {


                                this.$refs.tableOrder.setCurrentRow(item)
                            })
                        }
                    })
                } else {
                    vm.applicationForm = vm.userList[0]
                    this.$nextTick(() => {


                        this.$refs.tableOrder.setCurrentRow(this.userList[0])
                    })
                }
            })
        },
        delUser() {

            console.log(this.applicationForm)
            var index = 0
            this.orderDetail.orderApplicants.forEach((item, idx) => {
                if (item.id == this.applicationForm.id) {
                    index = idx
                }
            })

            this.$confirm(`是否要要删除该申请人`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {

                this.orderDetail.orderApplicants.splice(index, 1)
                let oldArr = []
                this.orderDetail.orderApplicants.forEach(item => {
                    oldArr.push({
                        applicantName: item.applicantName,
                        identityTypeId: item.identityType,
                        id: item.id
                    })
                })

                let arr = [...oldArr]

                upadateorderapplicants({
                    orderId: this.id,
                    DepartureTime: this.dateInfo.date,
                    applicants: arr,

                }).then(res => {
                    console.log(res);
                    if (res.code == 1) {
                        this.$message({
                            message: '删除成功！',
                            type: 'success'
                        });
                        this.queryorderapplicantsFn();
                        this.orderdetailFn();
                    }

                })
            }).catch(res => {

            })

        },
        orderdetailFn() {
            let vm = this;

            orderdetail({
                token: vm.$store.state.token,
                id: vm.id
            }).then(res => {

                vm.orderDetail = res.data;
                vm.applicationForm = vm.orderDetail.orderApplicants[0]
                vm.dateInfo.date = vm.orderDetail.order.departureTime.split(' ')[0]

                this.$nextTick(() => {
                    this.$refs.tableOrder.setCurrentRow(this.orderDetail.orderApplicants[0])
                })
            })
        },
        selectStatus(e, item) {
            orderapplicantmodifystatus({
                orderApplicantId: item.id,
                status: e,
            }).then(res => {
                this.$message({
                    message: '修改成功！',
                    type: 'success'
                });
                this.queryorderapplicantsFn();
            })
        },
        toPath(num) {
            if (num == 1) {
                // this.$router.push({ name: 'index' })
                this.$router.push({ name: 'home' })

            }

            if (num == 2) {
                this.$router.push({ name: 'order' })
            }


            if (num == 3) {
                // this.$router.push({ name: 'order-id', params: { id: this.id } })
                this.$router.go(-1)
            }

        },
        reload() {
            // this.$refs.iframe.contentWindow.location.reload(true);

            this.isShow = false;

            setTimeout(() => {
                this.isShow = true;
            }, 15)
        },
        iframeSubmit() {
            console.log("执行提交")
            this.$refs.iframe.contentWindow.postMessage(this.passengerId, '*');
        },
        editPackage(item) {
            console.log(item);
            this.isVisible = true;

            this.sfObj = item

        },
        addUserFn(e = []) {
            console.log('111', e);
            let oldArr = []
            // 修复bug只是新增一个申请人，前端传递了所有申请人数据，导致重复
            this.orderDetail.orderApplicants.forEach(item => {
                oldArr.push({
                    applicantName: item.applicantName,
                    identityTypeId: item.identityType,
                    id: item.id
                })
            })

            let arr = [...e, ...oldArr]

            upadateorderapplicants({
                orderId: this.id,
                DepartureTime: this.dateInfo.date,
                applicants: arr,

            }).then(res => {
                console.log(res);
                if (res.code == 1) {
                    this.$message({
                        message: '添加成功！',
                        type: 'success'
                    });
                    this.queryorderapplicantsFn();
                    this.orderdetailFn();
                    this.newAddUserShow = false;
                }

            })

        },
        addUser() {
            this.newAddUserShow = true;
        },
        downPackage(item) {
            window.open(item.visaPage)
        },
        setColl(row) {
            console.log(row)

            let vm = this;

            vm.orderDetail.orderApplicants.forEach(item => {

                if (row.id == item.id) {

                    vm.applicationForm = item
                }
            })


        },
        copyOrder() {
            // var url = document.getElementById('copy')
            // url.select();

        },
        editSf() {
            let vm = this;
            let oldArr = []
            vm.orderDetail.orderApplicants.forEach(item => {
                if (item.id == vm.sfObj.id) {
                    item = vm.sfObj
                }
            })

            vm.orderDetail.orderApplicants.forEach(item => {
                oldArr.push({
                    applicantName: item.applicantName,
                    identityTypeId: item.identityType,
                    id: item.id
                })
            })

            let arr = [...oldArr]
            upadateorderapplicants({
                orderId: this.id,
                applicants: arr,

            }).then(res => {
                console.log(res);
                if (res.code == 1) {
                    this.$message({
                        message: '修改成功！',
                        type: 'success'
                    });
                    this.orderdetailFn();
                    this.isVisible = false;
                }

            })

        },
    }
}
</script>
<style lang="scss" >
.order-detail-child {


    .current-row {
        td {
            background-color: rgba(222, 239, 255, 0.726) !important;
        }
    }

    .form-item-btn {
        text-align: right;


    }

    .demo-ruleForm {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .demo-ruleForm-radio {
            text-align: left;
        }

        .el-form-item {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 6px;
            width: 323px;

            &.form-item-btn {
                width: 100%;
            }

            .el-date-editor {
                width: 100%;
            }

            &:nth-child(1) {
                margin-right: 130px;
            }

            .el-form-item__label {
                width: 50px !important;
                white-space: nowrap;
                height: 30px;
            }

            .el-form-item__content {
                margin-left: 0px !important;
            }
        }

        .el-form-item__content {
            text-align: right;

            .el-button {
                margin-top: 20px;
            }
        }
    }
}
</style>
<style lang="scss" scoped>
.order-detail-child {
    background: #F7F8FA;
    padding-bottom: 100px;

}

.sf-list {
    display: flex;
    flex-direction: column;
    margin-top: -20px;

    .sf-label {
        margin-bottom: 15px;
        color: #999;
    }

    .sf-radio {
        margin-bottom: 15px;
    }

    .sf-btn {
        text-align: right;
    }
}

.page-order-detaul-bom {
    width: 1200px;
    display: flex;
    // height: calc( 100vh - 130px);

    .page-order-detail-left {
        width: 398px;
        // height: 500px;
        display: flex;
        flex-direction: column;

        .page-order-item-info {
            width: 100%;
            padding: 20px;
            box-sizing: border-box;
            margin-bottom: 20px;
            background: #FFF;

            .page-order-item-t {
                margin-bottom: 20px;
                display: flex;
                align-items: center;

                span {
                    width: 120px;
                }
            }
        }


        .page-order-item-table {
            width: 398px;
            min-height: 600px;
            background: #FFF;

            .page-order-detail-btn {
                padding: 20px;
                padding-bottom: 0px;
                text-align: center;
            }

            .page-order-table-box {
                padding: 0 20px;
            }
        }
    }


    .page-order-detail-bottom {
        width: 784px;
        // height: 1107px;
        // background: #FFF;
        margin-left: 20px;

        .page-order-detail-bottom-title {
            width: 100%;
            height: 64px;
            background: #FFF8EE;
            font-size: 14px;
            color: #FF9D3D;
            padding: 12px;
            box-sizing: border-box;
        }

        .page-order-detail-bottom-content {
            // height: 980px;

            // background: rgb(138, 115, 115);
            width: 100%;

            iframe {
                flex: 1;
                width: 784px;
                height: 700px;

                .countryContainer {
                    background: #fff !important;
                }
            }
        }

        .page-rder-detail-bottom-btn {
            box-shadow: 0px -3px 12px 1px rgba(102, 102, 102, 0.1);
            flex: 1;
            height: 61px;
            line-height: 61px;
            text-align: right;
            display: flex;
            justify-content: space-between;
            padding: 0 10px;
            background: #FFF;

        }

    }

    // background: #FFF;
}

.order-wrap {
    width: 1200px;
    margin: 0 auto;

    .order-content {
        width: 1200px;
        // height: 831px;
        background: #FFF;
        box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.16);
    }

    .page-path {
        margin: 16px 0;
        font-size: 12px;
        // color: #CCCCCC;
        color: #a19f9f;

        span {
            cursor: pointer;

        }
    }

    .page-card-top {
        padding: 20px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 60px;
        border-bottom: 3px solid #F5F5F5;

        .page-card-top-left {
            display: flex;
            align-items: center;
        }

        .page-card-right {}

        .page-top-back {
            font-size: 16px;
            color: #3888E9;
            cursor: pointer;

            i {
                color: #3888E9;
                font-weight: bold;
            }

        }

        .page-top-name {
            margin-left: 32px;
            font-size: 18px;
            font-weight: bold;

        }

        .page-top-identity {
            margin-left: 20px;
            font-size: 16px;
            font-weight: bold;
            color: #666666;

        }

        .page-card-top-left {}

    }

    .lan {
        color: #3888E9;
        cursor: pointer;
    }

    .page-card-conetent {
        height: 668px;
        width: 100%;
        flex: 1;

        iframe {
            flex: 1;
            width: 1200px;
            height: 668px;
        }
    }

    .page-card-bom {
        height: 97px;
        border-top: 3px solid #F5F5F5;
        display: flex;
        align-items: center;
        justify-content: center;

        .page-card-bom-btn {
            margin: auto;
            width: 138px;
            height: 45px;
        }
    }

    .page-card {
        width: 1200px;
        background: #FFFFFF;
        box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.16);


        .page-require-box {
            width: 100%;

            .page-title {
                height: 42px;
                padding-left: 24px;
                font-size: 16px;
                color: #3888E9;
                display: flex;
                align-items: center;
                background: #F2F8FF;
            }

            .page-item {
                padding: 20px 24px;
                background: #FFF;
                border-bottom: 5px solid #F7F8FA;

                .page-item-avatar {
                    .page-item-name {
                        display: block;
                        margin-bottom: 12px;
                    }

                    .page-item-flex {
                        display: flex;
                        //  align-items: center;

                        .page-item-no-passport {
                            display: flex;
                            align-items: center;
                            margin-left: 77px;
                            // margin-top: 38px;
                        }

                        .page-input {
                            width: 734px;
                            margin-left: 26px;

                            .page-item-title {
                                width: 100%;
                                height: 38px;
                                background: #F2F8FF;
                                color: #3888E9;
                                font-size: 16px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }

                            .page-item-waning {
                                color: #FF5967;

                                .el-icon-warning-outline {
                                    margin-right: 8px;
                                }
                            }

                            .page-item-wrap {
                                background: #F7F8FA;
                                min-height: 400px;
                                width: 100%;
                                padding: 12px 28px;
                                box-sizing: border-box;

                                .page-item-top {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    font-size: 14px;

                                    .page-edit {
                                        color: #5F9FED;
                                        cursor: pointer;
                                    }
                                }

                                .demo-list {
                                    display: flex;
                                    flex-wrap: wrap;
                                    margin-top: 16px;

                                    li {

                                        width: 330px;
                                        display: flex;
                                        margin-bottom: 24px;

                                        span {
                                            width: 110px;
                                            color: #666;
                                            font-size: 14px;
                                        }

                                        p {
                                            font-size: 16px;
                                            font-weight: bold;
                                        }
                                    }
                                }
                            }
                        }

                        .page-item-legend {
                            width: 222px;
                            height: 151px;
                            margin-left: 16px;
                        }

                        .page-item-desc {
                            max-width: 336px;
                            flex: flex;
                            flex-direction: column;
                            margin-left: 20px;

                            span {
                                font-size: 16px;

                            }

                            p {
                                font-size: 14px;
                                color: #999999;
                                margin-top: 8px;
                            }
                        }


                    }
                }
            }
        }

    }
}</style>