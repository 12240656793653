<template>
  <div class="wrap">
    <div class="box-input">
      <div class="box-input-left">
        <el-select clearable v-model="applicationForm.type" @change="getApplicationData" placeholder="应用类型">
          <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-input v-model="applicationForm.name" style="width: 460px;margin-right: 12px;" placeholder="请输入名称"></el-input>
      </div>
      <div class="box-input-btn">
        <el-button class="default" @click="getApplicationData">搜索</el-button>
        <el-button class="box-input-btn-cz" @click="resetSearchForm">重置</el-button>
        <el-button class="box-input-btn-add1" @click="() => { dialogVisible = true }">新增应用</el-button>
      </div>
    </div>
    <div class="table-box">
      <div class="flex-center table-header">
        <div style="width: 350px;padding-left: 10px;">应用信息</div>
        <div style="width: 180px">应用类型</div>
        <div style="width: 300px;padding-left: 10px;">轮播图配置</div>
        <div style="width: 143px;text-align: center;">操作</div>
      </div>
      <div class="table-box-item" v-show="applicationList.length">
        <div class="flex-center table-item" v-for="(item, index) in applicationList" :key="index">
          <div class="item item-info">
            <div class="item-info-detail">
              <span class="icon-box">
                <i class="iconfont icon-chrome"></i>
              </span>
              <span class="item-text">{{ item.name }}</span>
            </div>
            <div class="item-info-detail">
              <span class="icon-box">
                <i class="iconfont icon-appM"></i>
              </span>
              <span class="item-text">应用Id:{{ item.outAppId }}</span>
            </div>
            <div class="item-info-detail">
              <span class="icon-box">
                <i class="iconfont icon-lock"></i>
              </span>
              <span class="item-text elli">应用密钥:{{ item.outAppSecert }}</span>
              <i class="iconfont "  @click="Coppy(item)">复制</i>
            </div>
          </div>
          <div class="item item-type">
            <span class="item-text">{{ item.typeName }}</span>
          </div>
          <div class="item item-img">
            <div class="demo-image__preview">
              <el-image :src="item.banners[0]" :preview-src-list="item.banners">
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline">未配置</i>
                </div>
              </el-image>
              <div class="bt" @click="showBannerDialog(item.id)">配置轮播图</div>
            </div>
          </div>
          <div class="item item-operate">
            <p @click="openPayConfig(item.id)">支付配置</p>
            <p @click="openEdit(item)">编辑</p>
            <p @click="delApp(item.id)" style="color: red;">删除</p>
          </div>
        </div>
      </div>
    </div>
    <div class="no-data" v-show="!applicationList.length">暂无数据</div>
    <div class="table-pagination" v-show="applicationList.length">
      <el-pagination @current-change="currentChange" :current-page="pageIndex" :page-size="5"
        layout="total, prev, pager, next, jumper" :total="pageTotal">
      </el-pagination>
    </div>
    <!-- 新增/编辑应用 -->
    <el-dialog :visible.sync="dialogVisible" width="470px" class="common-dialog-class" :close-on-click-modal="false"
      @close="resetForm('applicationDialogForm')">
      <div class="dialog-member-wrap">
        <div class="dialog-member-title">
          <span class="dialog-member-name">{{ applicationDialogForm.id ? '编辑应用' : '新增应用' }} </span>
          <div class="dialog-close" @click="dialogVisible = false">
            <i class="el-icon-close"></i>
          </div>
        </div>
        <div class="dialog-form-wrap">
          <el-form :model="applicationDialogForm" :rules="rules" ref="applicationDialogForm" label-width="80px"
            class="demo-form">
            <el-form-item label="名称" prop="name">
              <el-input v-model="applicationDialogForm.name" placeholder="请输入应用名称"></el-input>
            </el-form-item>
            <el-form-item label="类型" prop="type">
              <el-select clearable v-model="applicationDialogForm.type" placeholder="应用类型">
                <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="应用Id" prop="outAppId">
              <el-input v-model="applicationDialogForm.outAppId" placeholder="请输入应用Id"></el-input>
            </el-form-item>
            <el-form-item label="应用密钥" prop="outAppSecert">
              <el-input v-model="applicationDialogForm.outAppSecert" type="textarea" resize="none" row="2"
                placeholder="请输入应用密钥"></el-input>
            </el-form-item>
          </el-form>
          <div class="common-dialog-foot-class" style="margin-top: 0">
            <el-button class="common-btn" @click="dialogVisible = false">取消</el-button>
            <el-button class="common-btn" type="primary" @click="submitForm('applicationDialogForm')">确认</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 支付配置 -->
    <el-dialog :visible.sync="payDialogVisible" width="470px" class="common-dialog-class" :close-on-click-modal="false">
      <div class="dialog-member-wrap">
        <div class="dialog-member-title">
          <span class="dialog-member-name">支付配置</span>
          <div class="dialog-close" @click="resetForm('payDialogForm')">
            <i class="el-icon-close"></i>
          </div>
        </div>
        <div class="dialog-form-wrap">
          <el-form :model="payDialogForm" :rules="rulesPay" ref="payDialogForm" label-width="80px" class="demo-form">
            <el-form-item label="商户ID" prop="mchAppId">
              <el-input v-model="payDialogForm.mchAppId" placeholder="请输入商户ID"></el-input>
            </el-form-item>
            <el-form-item label="商户密钥" prop="appKey">
              <el-input v-model="payDialogForm.appKey" type="textarea" resize="none" row="2"
                placeholder="请输入商户密钥"></el-input>
            </el-form-item>
            <el-form-item label="appId" prop="appId">
              <el-input v-model="payDialogForm.appId" type="textarea" resize="none" row="2"
                placeholder="请输入appId"></el-input>
            </el-form-item>
          </el-form>
          <div class="common-dialog-foot-class" style="margin-top: 0">
            <el-button class="common-btn" @click="resetForm('payDialogForm')">取消</el-button>
            <el-button class="common-btn" type="primary" @click="submitPayForm('payDialogForm')">确认</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 轮播图配置 -->
    <el-dialog :visible.sync="bannerDialog" width="1000px" class="common-dialog-class" :close-on-click-modal="false">
      <div class="dialog-member-wrap">
        <div class="dialog-member-title">
          <span class="dialog-member-name">轮播图配置</span>
          <div class="dialog-close" @click="bannerDialog = false">
            <i class="el-icon-close"></i>
          </div>
        </div>
        <div class="dialog-form-wrap">
          <div>
            <el-table :data="bannerTableData"
              :header-cell-style="{ backgroundColor: '#F2F4F7', color: '#333', fontWeight: 'bold', textAlign: 'center', fontSize: '12px' }"
              ref="refBannerTable" style="width: 100%" height='600' :cell-style="{ 'text-align': 'center' }">
              <el-table-column prop="imgUrl" label="图片" width="250">
                <template slot-scope="scope">
                  <upload accept="img" :auto-upload="true" :image-url="scope.row.imgUrl" :drag="'drag'" thisType="custom"
                    @input="toImg($event, scope.row)" :customSty="{ width: '240px', height: '100px' }">
                  </upload>
                </template>
              </el-table-column>
              <el-table-column prop="path" label="跳转路径" width="400">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.path" type="textarea" :rows="3" resize="none"
                    placeholder="配置跳转路径"></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="enable" label="是否启用">
                <template slot-scope="scope">
                  <el-switch v-model="scope.row.enable" active-color="#3888E9" inactive-color="#CCCCCC" />
                </template>
              </el-table-column>
              <el-table-column prop="email" label="排序" width="60">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.sort"></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="" label="操作">
                <template slot-scope="scope">
                  <span style="color:#E41313 ;cursor: pointer;" @click="deleteBanner(scope.$index)">删除</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="common-dialog-foot-class" style="margin-top: 20px">
            <el-button class="common-btn box-input-btn-add1" @click="addBanner">新增轮播图</el-button>
            <el-button class="common-btn" type="primary" @click="saveBanner">保存</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  apptypeselect, appinfoquery, addappinfo, updateappinfo, delappinfo, getpayconstants, updatepayconstants,
  getbanners,savebanners
} from '@/api/settings.js'
export default {
  name: 'applicationManage',
  data() {
    return {
      applicationForm: {
        type: '',
        name: ''
      },
      orgInfo: {},
      dialogShow: false,
      yzmCode: '',
      seconds: 60,
      codeText: '发送验证码',
      showCodeBtn: true,
      timer: null,
      showEdit: true,
      typeOptions: [],
      applicationList: [],
      pageIndex: 1,
      pageTotal: 0,
      dialogVisible: false,
      payDialogVisible: false,
      applicationDialogForm: {
        name: '',
        type: '',
        outAppId: '',
        outAppSecert: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入应用名称', trigger: ['click', 'change', 'blur'] },
        ],
        type: [
          { required: true, message: '请输入应用类型', trigger: ['click', 'change', 'blur'] },
        ],
        outAppId: [
          { required: true, message: '请输入appId', trigger: ['click', 'change', 'blur'] },
        ],
        outAppSecert: [
          { required: true, message: '请输入secret', trigger: ['click', 'change', 'blur'] },
        ],
      },
      payDialogForm: {
        mchAppId: '',
        appKey: '',
        appId: ''
      },
      rulesPay: {
        mchAppId: [
          { required: true, message: '请输入商户ID', trigger: ['click', 'change', 'blur'] },
        ],
        appKey: [
          { required: true, message: '请输入appKey', trigger: ['click', 'change', 'blur'] },
        ],
        appId: [
          { required: true, message: '请输入appId', trigger: ['click', 'change', 'blur'] },
        ],
      },
      bannerTableData: [],
      bannerDialog: false,
      curAppInfoId:''
    }
  },
  mounted() {
    this.getAppType()
    this.getApplicationData()
  },
  methods: {
    getApplicationData() {
      this.applicationForm['Page.Index'] = this.pageIndex
      this.applicationForm['Page.Size'] = 5
      appinfoquery(this.applicationForm).then(res => {
        this.applicationList = res.data.rows.map(v=>({canSee:false,outAppSecertForShow:'***************',...v}))
        this.pageTotal = res.data.total
      })
    },
    getAppType() {
      apptypeselect().then(res => {
        this.typeOptions = res.data
      })
    },
    resetSearchForm() {
      this.applicationForm = {
        type: '',
        name: ''
      },
        this.getApplicationData()
    },
    resetForm(formName) {
      this.dialogVisible = false
      this.payDialogVisible = false
      this.$refs[formName].resetFields();
      this[formName] = {}
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (!this.applicationDialogForm.id) {//添加账号
            addappinfo(this.applicationDialogForm).then(res => {
              if (res.code == 1) {
                this.$message.success('添加成功！')
                this.dialogVisible = false
                this.getApplicationData()
              } else {
                this.$message.error(res.msg)
              }
            })
          } else {//编辑账号
            updateappinfo(this.applicationDialogForm).then(res => {
              if (res.code == 1) {
                this.$message.success('编辑成功！')
                this.dialogVisible = false
                this.getApplicationData()
              } else {
                this.$message.error(res.msg)
              }
            })
          }
        } else {
          return false
        }
      })
    },
    delApp(id) {
      this.$confirm(`是否要删除该应用`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delappinfo({ id }).then(res => {
          this.$message.success('删除成功');
          this.getApplicationData();
        }).catch(res => {
        })
      }).catch(res => {
      })
    },
    openEdit(item) {
      this.applicationDialogForm = JSON.parse(JSON.stringify(item))
      this.dialogVisible = true
    },
    openPayConfig(id) {
      this.curAppInfoId = id
      getpayconstants({ id }).then(res => {
        this.payDialogForm = res.data
        this.payDialogVisible = true
      })
    },
    submitPayForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          updatepayconstants({appInfoId:this.curAppInfoId,payConstant:this.payDialogForm}).then(res => {
            if (res.code == 1) {
              this.$message.success('修改成功！')
              this.payDialogVisible = false
            } else {
              this.$message.error(res.msg)
            }
          })
        } else {
          return false
        }
      })
    },
    currentChange(val) {
      this.pageIndex = val;
      this.getApplicationData()
    },
    showBannerDialog(appInfoId) {
      this.curAppInfoId = appInfoId
      getbanners({ appInfoId }).then(res => {
        this.bannerTableData = res.data
        this.bannerDialog = true
      })
    },
    addBanner() {
      this.bannerTableData.push({ imgUrl: "", path: "", enable: false, sort: this.bannerTableData.length + 1 })
    },
    saveBanner() {
      savebanners({appInfoId:this.curAppInfoId,data:this.bannerTableData}).then(res=>{
        this.$message.success('配置成功！')
        this.bannerDialog = false
        this.getApplicationData()
      })
    },
    deleteBanner(index) {
      this.$confirm(`是否要删除该轮播图`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.bannerTableData.splice(index, 1)
        this.$message.success('删除成功');
      }).catch(res => {
      })
    },
    toImg(url, row) {
      row.imgUrl = url
    }
  },
}
</script>

<style lang="scss" scoped>
.wrap {
  width: 970px;

  ::v-deep .el-table__row {
    .cell {
      font-size: 12px;
    }
  }


  .tab {
    height: calc(100% - 100px);
  }

  .box-table {
    height: calc(100% - 100px);
  }

  .box-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;

    ::v-deep .el-input__inner {
      background: #F2F4F7;
      border: none;
    }

    ::v-deep .el-select,
    .el-input {
      margin-right: 8px;
    }

    .box-input-btn {
      text-align: right;

      .box-input-btn-cz {
        background: #EAF3FF;
        color: #3888E9;
        border: none;
      }

      .box-input-btn-add {
        background: #D8F5E4;
        color: #54D8A5;
        border: none;
      }
    }
  }

  .box-input-btn-add1 {
    background: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #3888E9;
    color: #3888E9;
  }

  .table-box {
    width: 970px;
    border-radius: 8px;
    padding: 10px;
    box-sizing: border-box;
    background: #F2F4F7;

    .table-header {
      height: 30px;

      div {
        font-size: 12px;
        color: #333;
        font-weight: bold;
      }
    }

    .table-box-item {
      width: 100%;
      height: 600px;
      overflow-y: auto;
      margin-top: 15px;
    }

    .table-item {
      height: 102px;
      background: #FFFFFF;
      border-radius: 8px;
      margin-bottom: 18px;
      font-family: PingFang SC-Bold, PingFang SC;

      .item {
        height: 100%;
        padding: 5px;
        box-sizing: border-box;

        .iconfont {
          color: #3888E9;
        }

        .item-text {
          color: #333333;
          font-size: 16px;
          font-weight: bold;
        }

        .elli {
          display: inline-block;
          width: 200px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }

      .item-info {
        width: 350px;

        .item-info-detail {
          .icon-box {
            display: inline-block;
            width: 25px;
            height: 25px;
            background: #EBEEF2;
            border-radius: 50%;
            text-align: center;
            line-height: 25px;
            margin-right: 8px;
            margin-bottom: 5px;
          }

          .icon-open,
          .icon-close {
            cursor: pointer;
            font-size: 11px;
            margin-left: 10px;
          }
        }
      }

      .item-type {
        width: 180px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }

      .item-img {
        width: 300px;

        .demo-image__preview {
          width: 230px;
          height: 90px;
          border-radius: 8px;
          position: relative;

          ::v-deep .el-image {
            width: 100%;
            height: 100%;
            border-radius: 8px;

            .image-slot {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }


          .bt {
            position: absolute;
            bottom: 0px;
            width: 100%;
            height: 25px;
            background: rgba($color: #000000, $alpha: 0.45);
            border-radius: 0px 0px 8px 8px;
            text-align: center;
            line-height: 25px;
            color: #fff;
            cursor: pointer;
          }
        }
      }

      .item-operate {
        width: 140px;

        p {
          font-weight: bold;
          font-size: 16px;
          color: #3888E9;
          cursor: pointer;
          text-align: center;
          margin-bottom: 3px;
        }
      }
    }
  }

  .no-data {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #666;
    height: 350px;
  }

  .table-pagination {
    text-align: center;
    margin-top: 16px;
  }
}
</style>