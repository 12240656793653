<template>
    <div class="page order-detail-child">
        <div class="page-card-top-fixd" v-if="topShow">
            <div class="page-card-top">
                <div class="page-card-top-flex">
                    <span class="page-top-back" @click="toPath(3)"><i class="el-icon-arrow-left"></i>返回</span>
                    <p class="page-top-name">{{ applicantdocsObj.orderApplicant.applicantName }}</p>
                    <p class="page-top-identity">{{ applicantdocsObj.orderApplicant.identityTypeName }}</p>
                </div>
                <el-button class="default" @click="toPath(3)">确认</el-button>
            </div>
        </div>
        <div class="order-wrap">
            <div class="page-path">
                <span @click="toPath(1)">快马签证</span> > <span @click="toPath(2)">我的订单</span> > <span
                    @click="toPath(3)">资料上传</span> > <span @click="toPath(4)">资料详情</span>

            </div>
            <div class="page-card">
                <div class="page-card-top">
                    <div class="page-card-top-flex">
                        <span class="page-top-back" @click="toPath(3)"><i class="el-icon-arrow-left"></i>返回</span>
                        <p class="page-top-name">{{ applicantdocsObj.orderApplicant.applicantName }}</p>
                        <p class="page-top-identity">{{ applicantdocsObj.orderApplicant.identityTypeName }}</p>
                    </div>
                    <div>
                        <span v-if="applicantdocsObj.orderApplicant.visaPage">
                            <span class="fine-name">
                                {{ applicantdocsObj.orderApplicant.visaPageName }}
                            </span>
                            <i class="iconfont icon-search" @click="openFile"></i>
                        </span>
                        <el-button round type="warning" @click="downDoc">下载资料包 <i
                                class="iconfont icon-xiazai"></i></el-button>
                    </div>
                </div>
                <div class="page-require-box">
                    <div class="page-title">必备资料</div>
                    <div class="page-item" v-for="(item, index) in requiredList" :key="index + item.id">
                        <template v-if="[10, 2, 1, 8].includes(item.docId)">
                            <!-- 头像 -->
                            <div class="page-item-avatar" v-if="item.docId == 10">
                                <span class="page-item-name">{{ item.name }}</span>
                                <div class="page-item-flex">
                                    <upload @uploading="uploadHandle" :auto-upload="true" :accept="'img'"
                                        :image-url="item.uploadedData[0].path" :drag="'drag'" thisType="avater"
                                        @input="toImg($event, item)">
                                    </upload>

                                    <el-image class="page-item-legend" :src="item.legend" alt=""
                                        :preview-src-list="[item.legend]"></el-image>

                                    <div class="page-item-desc">
                                        <span>资料要求</span>
                                        <p style="white-space: pre-line;">{{ item.desc }}</p>
                                    </div>
                                </div>
                            </div>

                            <!-- 护照 -->
                            <div class="page-item-avatar" v-if="item.docId == 2">
                                <span class="page-item-name">{{ item.name }}</span>
                                <div class="page-item-flex">
                                    <upload v-model="item.uploadedData[0].path" @uploading="uploadHandle" :accept="'img'"
                                        :auto-upload="true" :image-url="item.uploadedData[0].path" :drag="'drag'"
                                        thisType="passport" @input="toImg($event, item)">
                                    </upload>
                                    <div v-if="!item.uploadedData[0].path">

                                        <div class="page-item-no-passport">

                                            <el-image class="page-item-legend" :src="item.legend" alt=""
                                                :preview-src-list="[item.legend]"> </el-image>

                                            <div class="page-item-desc" style="margin-left: 40px">
                                                <span>资料要求</span>
                                                <p style="white-space: pre-line;">{{ item.desc }}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- 编辑护照信息 -->
                                    <div class="page-item-input page-input" v-else>
                                        <div class="model-upload" v-if="loading">
                                            <div class="model-upload-flex">
                                                <span style="font-size: 20px;color: #FFF;">识别中</span>
                                                <i class="el-icon-loading" style="font-size: 30px;color: #FFF;"></i>
                                            </div>

                                        </div>
                                        <div class="page-item-title">护照信息读取</div>
                                        <div class="page-item-wrap">
                                            <div class="page-item-top">
                                                <p class="page-item-waning"><i
                                                        class="el-icon-warning-outline"></i>证件信息错误可能导致拒签，请仔细核对</p>
                                                <p class="page-edit" @click="inputShow = true;"><i
                                                        class="iconfont icon-bianji"></i>修改资料</p>
                                            </div>
                                            <div class="page-input-form" v-if="inputShow">


                                                <div class="dialog-form-wrap">
                                                    <el-form :model="ruleForm" :rules="rules" ref="ruleForm"
                                                        label-width="110px" class="demo-ruleForm1">
                                                        <el-form-item label="护照号码" prop="customer_PassportNumber">
                                                            <el-input v-model="ruleForm.customer_PassportNumber"
                                                                placeholder="请填写护照号码"></el-input>
                                                        </el-form-item>
                                                        <el-row :gutter="24">
                                                            <el-col :span="12">
                                                                <el-form-item label="姓(中文)" prop="customer_SurName">
                                                                    <el-input v-model="ruleForm.customer_SurName"
                                                                        placeholder="请填写姓(中文)"></el-input>
                                                                </el-form-item>

                                                                <el-form-item label="名(中文)" prop="customer_Name">
                                                                    <el-input v-model="ruleForm.customer_Name"
                                                                        placeholder="请填写名(中文)"></el-input>
                                                                </el-form-item>
                                                                <el-form-item label="性别" prop="customer_Gender">
                                                                    <div class="demo-ruleForm-radio">
                                                                        <el-radio v-model="ruleForm.customer_Gender"
                                                                            label="M">男</el-radio>
                                                                        <el-radio v-model="ruleForm.customer_Gender"
                                                                            label="F">女</el-radio>
                                                                    </div>
                                                                </el-form-item>
                                                                <el-form-item label="签发日期" prop="customer_PassportIssue">
                                                                    <el-date-picker
                                                                        v-model="ruleForm.customer_PassportIssue"
                                                                        type="date" placeholder="选择签发日期"
                                                                        value-format="yyyy-MM-dd">
                                                                    </el-date-picker>
                                                                </el-form-item>
                                                                <el-form-item label="出生地点"
                                                                    prop="customer_PassportProvinceName">
                                                                    <el-input
                                                                        v-model="ruleForm.customer_PassportProvinceName"
                                                                        placeholder="请填写出生地点"></el-input>
                                                                </el-form-item>
                                                                <el-form-item label="签发地点" prop="customer_ProvinceName">
                                                                    <el-input v-model="ruleForm.customer_ProvinceName"
                                                                        placeholder="请填写签发地点"></el-input>
                                                                </el-form-item>
                                                                <el-form-item label="签发机关"
                                                                    prop="customer_PassportAuthority">
                                                                    <el-input v-model="ruleForm.customer_PassportAuthority"
                                                                        placeholder="请填写签发机关"></el-input>
                                                                </el-form-item>
                                                            </el-col>
                                                            <el-col :span="12">
                                                                <el-form-item label="姓(拼音)" prop="customer_SurnameEn">
                                                                    <el-input v-model="ruleForm.customer_SurnameEn"
                                                                        placeholder="请填写姓(拼音)"></el-input>
                                                                </el-form-item>
                                                                <el-form-item label="名(拼音)" prop="customer_NameEn">
                                                                    <el-input v-model="ruleForm.customer_NameEn"
                                                                        placeholder="请填写名(拼音)"></el-input>
                                                                </el-form-item>
                                                                <el-form-item label="出生日期" prop="customer_BirthDay">
                                                                    <el-date-picker v-model="ruleForm.customer_BirthDay"
                                                                        type="date" placeholder="请选择出生日期"
                                                                        value-format="yyyy-MM-dd">
                                                                    </el-date-picker>
                                                                </el-form-item>
                                                                <el-form-item label="有效期至" prop="customer_PassportValidity">
                                                                    <el-date-picker
                                                                        v-model="ruleForm.customer_PassportValidity"
                                                                        type="date" placeholder="选择日期有效期"
                                                                        value-format="yyyy-MM-dd">
                                                                    </el-date-picker>
                                                                </el-form-item>
                                                                <el-form-item label="出生地点英文"
                                                                    prop="customer_PassportProvinceEn">
                                                                    <el-input v-model="ruleForm.customer_PassportProvinceEn"
                                                                        placeholder="请填写出生地点英文"></el-input>
                                                                </el-form-item>
                                                                <el-form-item label="签发地点英文" prop="customer_ProvinceNameEn">
                                                                    <el-input v-model="ruleForm.customer_ProvinceNameEn"
                                                                        placeholder="请填写签发地点英文"></el-input>
                                                                </el-form-item>
                                                                <el-form-item label="签发机关英文"
                                                                    prop="customer_PassportAuthorityEn">
                                                                    <el-input
                                                                        v-model="ruleForm.customer_PassportAuthorityEn"
                                                                        placeholder="请填写签发机关英文"></el-input>
                                                                </el-form-item>
                                                            </el-col>
                                                        </el-row>
                                                        <!-- <el-form-item label="护照号码" prop="customer_PassportNumber">
                                                            <el-input v-model="ruleForm.customer_PassportNumber"
                                                                placeholder="请填写护照号码"></el-input>
                                                        </el-form-item>
                                                        <el-form-item label="姓 (中文)" prop="customer_SurName">
                                                            <el-input v-model="ruleForm.customer_SurName"
                                                                placeholder="请填写姓（中文）"></el-input>
                                                        </el-form-item>


                                                        <el-form-item label="姓（拼音）" prop="customer_SurnameEn">
                                                            <el-input v-model="ruleForm.customer_SurnameEn"
                                                                placeholder="请填写姓（拼音）"></el-input>
                                                        </el-form-item>

                                                        <el-form-item label="名（中文）" prop="customer_Name">
                                                            <el-input v-model="ruleForm.customer_Name"
                                                                placeholder="请填写名（中文）"></el-input>
                                                        </el-form-item>

                                                        <el-form-item label="名（拼音）" prop="customer_NameEn">
                                                            <el-input v-model="ruleForm.customer_NameEn"
                                                                placeholder="请填写名（拼音）"></el-input>
                                                        </el-form-item>

                                                        <el-form-item label="性别" prop="customer_Gender">
                                                            <div class="demo-ruleForm-radio">

                                                                <el-radio v-model="ruleForm.customer_Gender"
                                                                    label="M">男</el-radio>
                                                                <el-radio v-model="ruleForm.customer_Gender"
                                                                    label="F">女</el-radio>
                                                            </div>
                                                        </el-form-item>

                                                        <el-form-item label="出生日期" prop="customer_BirthDay">
                                                            <el-date-picker v-model="ruleForm.customer_BirthDay" type="date"
                                                                placeholder="请选择出生日期" value-format="yyyy-MM-dd">
                                                            </el-date-picker>
                                                        </el-form-item>

                                                        <el-form-item label="签发日期" prop="customer_PassportIssue">
                                                            <el-date-picker v-model="ruleForm.customer_PassportIssue"
                                                                type="date" placeholder="选择签发日期" value-format="yyyy-MM-dd">
                                                            </el-date-picker>

                                                        </el-form-item>

                                                        <el-form-item label="有效期至" prop="customer_PassportValidity">
                                                            <el-date-picker v-model="ruleForm.customer_PassportValidity"
                                                                type="date" placeholder="选择日期有效期" value-format="yyyy-MM-dd">
                                                            </el-date-picker>
                                                        </el-form-item>


                                                        <el-form-item label="出生地点" prop="customer_PassportProvinceName">
                                                            <el-input v-model="ruleForm.customer_PassportProvinceName"
                                                                placeholder="请填写出生地点"></el-input>
                                                        </el-form-item>

                                                        <el-form-item label="出生地点英文" prop="customer_PassportProvinceEn">
                                                            <el-input v-model="ruleForm.customer_PassportProvinceEn"
                                                                placeholder="请填写出生地点英文"></el-input>
                                                        </el-form-item>

                                                        <el-form-item label="签发地点" prop="customer_ProvinceName">
                                                            <el-input v-model="ruleForm.customer_ProvinceName"
                                                                placeholder="请填写签发地点"></el-input>
                                                        </el-form-item>

                                                        <el-form-item label="签发地点英文" prop="customer_ProvinceNameEn">
                                                            <el-input v-model="ruleForm.customer_ProvinceNameEn"
                                                                placeholder="请填写签发地点英文"></el-input>
                                                        </el-form-item>

                                                        <el-form-item label="签发机关" prop="customer_PassportAuthority">
                                                            <el-input v-model="ruleForm.customer_PassportAuthority"
                                                                placeholder="请填写签发机关"></el-input>
                                                        </el-form-item>

                                                        <el-form-item label="签发机关英文" prop="customer_PassportAuthorityEn">
                                                            <el-input v-model="ruleForm.customer_PassportAuthorityEn"
                                                                placeholder="请填写签发机关英文"></el-input>
                                                        </el-form-item> -->

                                                        <el-form-item class="form-item-btn">

                                                            <el-button type="primary" class="default"
                                                                @click="submitForm('ruleForm')">保存</el-button>
                                                        </el-form-item>
                                                    </el-form>
                                                </div>

                                            </div>

                                            <!-- 护照信息展示 -->
                                            <div class="page-input-form" v-else>
                                                <div class="dialog-form-wrap">
                                                    <ul class="demo-list">
                                                        <li>
                                                            <span>护照号码</span>
                                                            <p>{{ ruleForm.customer_PassportNumber }}</p>
                                                        </li>
                                                        <li>
                                                            <span>姓 (中文)</span>
                                                            <p>{{ ruleForm.customer_SurName }}</p>
                                                        </li>

                                                        <li>
                                                            <span>姓（拼音）</span>
                                                            <p>{{ ruleForm.customer_SurnameEn }}</p>
                                                        </li>

                                                        <li>
                                                            <span>名（中文）</span>
                                                            <p>{{ ruleForm.customer_Name }}</p>
                                                        </li>

                                                        <li>
                                                            <span>名（拼音）</span>
                                                            <p>{{ ruleForm.customer_NameEn }}</p>
                                                        </li>

                                                        <li>
                                                            <span>性别</span>
                                                            <p>{{ ruleForm.customer_Gender == '男' ||
                                                                ruleForm.customer_Gender ==
                                                                '女' ? ruleForm.customer_Gender : ruleForm.customer_Gender ==
                                                                    'M'
                                                                ? '男' : '女' }}</p>
                                                        </li>

                                                        <li>
                                                            <span>出生日期</span>
                                                            <p>{{ ruleForm.customer_BirthDay }}</p>
                                                        </li>

                                                        <li>
                                                            <span>签发日期</span>
                                                            <p>{{ ruleForm.customer_PassportIssue }}</p>

                                                        </li>

                                                        <li>
                                                            <span>有效期至</span>
                                                            <p>{{ ruleForm.customer_PassportValidity }}</p>
                                                        </li>


                                                        <li>
                                                            <span>出生地点</span>
                                                            <p>{{ ruleForm.customer_PassportProvinceName }}</p>
                                                        </li>

                                                        <li>
                                                            <span>出生地点英文</span>
                                                            <p>{{ ruleForm.customer_PassportProvinceEn }}</p>
                                                        </li>

                                                        <li>
                                                            <span>签发地点</span>
                                                            <p>{{ ruleForm.customer_ProvinceName }}</p>
                                                        </li>

                                                        <li>
                                                            <span>签发地点英文</span>
                                                            <p>{{ ruleForm.customer_ProvinceNameEn }}</p>
                                                        </li>

                                                        <li>
                                                            <span>签发机关</span>
                                                            <p>{{ ruleForm.customer_PassportAuthority }}</p>
                                                        </li>

                                                        <li>
                                                            <span>签发机关英文</span>
                                                            <p>{{ ruleForm.customer_PassportAuthorityEn }}</p>
                                                        </li>


                                                    </ul>
                                                </div>


                                            </div>
                                        </div>



                                    </div>

                                </div>

                            </div>

                            <!-- 身份证正反面照 -->
                            <div class="page-item-avatar" v-if="item.docId == 1 || item.docId == 8">
                                <span class="page-item-name">{{ item.name }}</span>
                                <div class="page-item-flex">
                                    <upload @uploading="uploadHandle" :auto-upload="true" :accept="'img'"
                                        :image-url="item.uploadedData[0].path" :drag="'drag'" thisType="other"
                                        @input="toImg($event, item)">
                                    </upload>

                                    <el-image class="page-item-legend" style="margin-left: 36px" :src="item.legend" alt=""
                                        :preview-src-list="[item.legend]"> </el-image>

                                    <div class="page-item-desc" v-if="item.desc" style="margin-left: 36px">
                                        <span>资料要求</span>
                                        <p style="white-space: pre-line;">{{ item.desc }}</p>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <!-- 必备资料 - 其他资料 - 单文件上传 -->
                            <div class="page-item-avatar" v-if="!item.isMultiple">
                                <span class="page-item-name">{{ item.name }}</span>
                                <div class="page-item-flex">
                                    <upload @uploading="uploadHandle" :auto-upload="true" :accept="'img'"
                                        :image-url="item.uploadedData[0].path" :drag="'drag'" thisType="other"
                                        @input="toImg($event, item)" @deleteDoc="deleteDoc(item,0)">
                                    </upload>

                                    <el-image class="page-item-legend" style="margin-left: 36px" v-if="item.legend"
                                        :src="item.legend" alt="" :preview-src-list="[item.legend]"></el-image>

                                    <div class="page-item-desc" style="margin-left: 36px">
                                        <span>资料要求</span>
                                        <p style="white-space: pre-line;">{{ item.desc }}</p>
                                    </div>
                                </div>
                            </div>
                            <!-- 必备资料 - 其他资料 - 多文件上传 -->
                            <div v-if="item.isMultiple">
                                <span class="page-item-name" style="margin-bottom: 12px">{{ item.name }}</span>
                                <div class="page-item-content">
                                    <div v-for="(elem, idx) in item.uploadedData" :key="idx" class="page-item-content-list">
                                        <upload @uploading="uploadHandle" :auto-upload="true" class="page-item-content-item"
                                            :image-url="elem.path" :drag="'drag'" @deleteDoc="deleteDoc(item, idx)"
                                            :is-delete="true" thisType="other" @input="toImg($event, item, idx)" :accept="'img'">
                                        </upload>
                                    </div>
                                </div>

                                <div class="page-item-desc-2">
                                    <span>资料要求</span>
                                    <p style="white-space: pre-line;">{{ item.desc }}</p>
                                </div>
                            </div>
                        </template>
                    </div>
                    <div class="page-title">其他资料</div>
                    <div class="page-item" v-for="(item, index) in addNewArr" :key="index + item.docId">
                        <!-- 其他资料 - 单文件上传 -->
                        <div class="page-item-avatar" v-if="!item.isMultiple">
                            <span class="page-item-name">{{ item.name }}</span>
                            <div class="page-item-flex">
                                <upload @uploading="uploadHandle" :auto-upload="true" :image-url="item.uploadedData[0].path" :accept="'img'"
                                    :drag="'drag'" thisType="other" @input="toImg($event, item)" @deleteDoc="deleteDoc(item,0)">
                                </upload>

                                <el-image class="page-item-legend" style="margin-left: 36px" v-if="item.legend"
                                    :src="item.legend" alt="" :preview-src-list="[item.legend]"></el-image>

                                <div class="page-item-desc" style="margin-left: 36px">
                                    <span>资料要求</span>
                                    <p style="white-space: pre-line;">{{ item.desc }}</p>
                                </div>
                            </div>
                        </div>
                        <!-- 其他资料 - 多文件上传 -->
                        <div v-if="item.isMultiple">
                            <span class="page-item-name" style="margin-bottom: 12px">{{ item.name }}</span>
                            <div class="page-item-content">
                                <div v-for="(elem, idx) in item.uploadedData" :key="idx" class="page-item-content-list">
                                    <upload @uploading="uploadHandle" :auto-upload="true" class="page-item-content-item"
                                    :accept="'img'"  :image-url="elem.path" :drag="'drag'" @deleteDoc="deleteDoc(item, idx)"
                                        :is-delete="true" thisType="other" @input="toImg($event, item, idx)">
                                    </upload>
                                </div>
                            </div>

                            <div class="page-item-desc-2">
                                <span>资料要求</span>
                                <p style="white-space: pre-line;">{{ item.desc }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="page-btn">
                    
                </div> -->
            </div>

        </div>

    </div>
</template>

<script>
import { applicantdocs, postuploadpassport, updatedocimg, savepassportinfo, savedocimg, savedocimgnodel, deletedocimgtwo ,downloadapplicantdata } from '@/api/order.js'
import upload from '@/components/upload.vue'
export default {
    data() {
        return {
            uploading: true,
            inputShow: false,
            topShow: false,
            id: '',
            loading: false,
            detailId: '',
            applicantdocsObj: {
                orderApplicant: {
                    identityTypeName: '',
                    applicantName: ''
                }
            },
            bbList: [],
            avatar: '',
            requiredList: [],   //  常见资料
            other: [],  //  其他资料
            addNewArr: [],
            ruleForm: {
                customer_PassportNumber: "",
                customer_SurName: '',
                customer_SurnameEn: '',
                customer_Name: '',
                customer_NameEn: '',
                customer_Gender: 'M',
                customer_GenderName: '男',
                customer_PassportAuthority: '',
                customer_PassportAuthorityEn: '',
                customer_BirthDay: '',  //  出生日期
                customer_PassportIssue: '', //  签发日期
                customer_PassportValidity: '',  //  有效日期
                customer_SavePassportInfo: false,
            },

            rules: {
                customer_PassportNumber: [
                    { required: true, message: '请填写护照号码', trigger: ['click', 'change', 'blur'] }
                ],
                customer_SurName: [
                    { required: true, message: '请填写姓（中文）', trigger: ['click', 'change', 'blur'] },
                ],
                customer_SurnameEn: [
                    { required: true, message: '请填写姓（拼音）', trigger: ['click', 'change', 'blur'] }
                ],
                customer_Name: [
                    { required: true, message: '请填写名（中文）', trigger: ['click', 'change', 'blur'] },
                ],

                customer_NameEn: [
                    { required: true, message: '请填写名（拼音）', trigger: ['click', 'change', 'blur'] },
                ],

                customer_Gender: [
                    { required: true, message: '请选择性别', trigger: ['click', 'change', 'blur'] },
                ],
                customer_BirthDay: [
                    { required: true, message: '请选择出生日期', trigger: ['click', 'change', 'blur'] },
                ],
                customer_PassportIssue: [
                    { required: true, message: '选择签发日期', trigger: ['click', 'change', 'blur'] },
                ],
                customer_PassportValidity: [
                    { required: true, message: '选择日期有效期', trigger: ['click', 'change', 'blur'] },
                ],
                customer_PassportProvinceName: [
                    { required: true, message: '请填写出生地点', trigger: ['click', 'change', 'blur'] },
                ],
                customer_PassportProvinceEn: [
                    { required: true, message: '请填写出生地点英文', trigger: ['click', 'change', 'blur'] },
                ],
                customer_ProvinceName: [
                    { required: true, message: '请填写签发地点', trigger: ['click', 'change', 'blur'] },
                ],
                customer_ProvinceNameEn: [
                    { required: true, message: '请填写签发地点英文', trigger: ['click', 'change', 'blur'] },
                ],
                customer_PassportAuthority: [
                    { required: true, message: '请填写签发机关', trigger: ['click', 'change', 'blur'] },
                ],

                customer_PassportAuthorityEn: [
                    { required: true, message: '请填写签发机关英文', trigger: ['click', 'change', 'blur'] },
                ],


            },

        }
    },

    mounted() {
        this.id = this.$router.history.current.params.id.split('_')[0]
        this.detailId = this.$router.history.current.params.id.split('_')[1]
        this.getApplicantdocs();
        // 滚动条的获取
        window.addEventListener('scroll', this.handleScrollx, true)
    },
    components: {
        upload
    },
    methods: {
        toPath(num) {
            if (num == 1) {
                // this.$router.push({ name: 'index' })
                this.$router.push({ name: 'home' })
            }

            if (num == 2) {
                this.$router.push({ name: 'order' })
            }

            if (num == 3) {
                // this.$router.push({ name: 'orderDetail', params: { id: this.id } })
                this.$router.go(-1)
            }

        },

        uploadHandle(val) {
            var vm = this;

            console.log(val);
            vm.uploading = val;
        },
        handleScrollx() {
            // console.log(window.pageYOffset)

            if (window.pageYOffset > 200) {
                this.topShow = true;
            } else {
                this.topShow = false;
            }
        },
        toImg(e, item, idx) {

            let vm = this;
            console.log(e)
            console.log(item)
            console.log(idx)
            if (item.docId == 2) {
                this.loading = true;
                postuploadpassport({
                    orderApplicantId: vm.detailId,
                    docId: item.docId,
                    url: e,
                    token: vm.$store.state.token
                }).then(res => {
                    console.log(res);
                    this.inputShow = true;
                    this.loading = false;
                    this.ruleForm = res.data;

                })

            } else {


                if (item.isMultiple) {
                    savedocimgnodel({
                        orderApplicantId: vm.detailId,
                        docId: item.docId,
                        url: e,
                        docImgId: item.uploadedData[idx].id ? item.uploadedData[idx].id : 0,
                        token: vm.$store.state.token
                    }).then(res => {
                        console.log(res);
                        // vm.$message({
                        //     message: '上传成功!',
                        //     type: 'success'
                        //  });


                        vm.getApplicantdocs();
                    })


                } else {

                    savedocimgnodel({
                        orderApplicantId: vm.detailId,
                        docId: item.docId,
                        url: e,
                        docImgId: item.uploadedData[0].id ? item.uploadedData[0].id : 0,
                        token: vm.$store.state.token
                    }).then(res => {
                        console.log(res);
                        // vm.$message({
                        //     message: '上传成功!',
                        //     type: 'success'
                        //  });


                        vm.getApplicantdocs();
                    })
                }


            }






            return;
            if (item.docId == 10 || item.docId == 1 || item.docId == 8) {
                savedocimgnodel({
                    orderApplicantId: vm.detailId,
                    docId: item.docId,
                    url: e,
                    docImgId: item.id,
                    token: vm.$store.state.token
                }).then(res => {
                    console.log(res);
                    // vm.$message({
                    //     message: '上传成功!',
                    //     type: 'success'
                    //  });


                    vm.getApplicantdocs();
                })
            } else if (item.docId == 2) {
                postuploadpassport({
                    orderApplicantId: vm.detailId,
                    docId: item.docId,
                    url: e,
                    token: vm.$store.state.token
                }).then(res => {
                    console.log(res);
                    this.inputShow = true;
                    this.ruleForm = res.data;

                })

            } else {

                //  其他材料多张上传
                if (item.isMultiple) {

                    // if (item.path) {

                    // } else {

                    // }
                    console.log(item)

                    if (elem) {
                        updatedocimg({
                            docImgId: item.pathLId[idx],
                            url: e,
                            token: vm.$store.state.token
                        }).then(res => {
                            this.getApplicantdocs();
                        })

                    } else {
                        savedocimgnodel({
                            orderApplicantId: vm.detailId,
                            docId: item.docId,
                            url: e,
                            token: vm.$store.state.token
                        }).then(res => {
                            this.getApplicantdocs();

                        })
                        item.pathList.push('')
                        this.$forceUpdate();
                    }


                    // 单张上传图片
                } else {


                    if (item.path) {
                        console.log(item)
                        updatedocimg({
                            docImgId: item.id,
                            url: e,
                            token: vm.$store.state.token
                        }).then(res => {
                            this.getApplicantdocs();
                        })
                    } else {
                        savedocimg({
                            orderApplicantId: vm.detailId,
                            docId: item.docId,
                            url: e,
                            token: vm.$store.state.token
                        }).then(res => {
                            this.getApplicantdocs();
                        })
                    }


                }


            }
        },

        deleteDoc(item, idx) {
            let vm = this;
            deletedocimgtwo({
                docImgId: item.uploadedData[idx].id,
                token: vm.$store.state.token
            }).then(res => {
                this.getApplicantdocs();
            })
        },


        /** 图片上传成功回调 */
        uploadImgBack(val) {
            console.log(val)
            this.avatar = val
        },
        getApplicantdocs() {
            applicantdocs({
                ApplicantId: this.detailId,

            }).then(res => {

                this.applicantdocsObj = res.data

                let required = []
                let other = []
                if (this.applicantdocsObj.orderApplicant.visaPage) {
                    let arr = this.applicantdocsObj.orderApplicant.visaPage.split('/'),
                        fineName = arr[arr.length - 1];
                    this.applicantdocsObj.orderApplicant.visaPageName = fineName
                }

                this.applicantdocsObj.orderApplicant.electronicData.forEach(item => {
                    if (item.isNeed) {

                        // if (item.docId != 2) {
                        if (item.uploadedData.length == 0) {

                            item.uploadedData = [{
                                id: '',
                                docId: '',
                                path: '',
                            }]
                        }

                        // }
                        required.push(item)
                    } else {
                        other.push(item);
                    }
                })

                this.ruleForm = this.applicantdocsObj.passPortInfo
                this.inputShow = !this.ruleForm.customer_SavePassportInfo
                let arr = []
                let newArr = []
                let addNewArr = []
                other.forEach(item => {
                    arr.push(item.docId)
                })

                newArr = Array.from(new Set(arr));


                newArr.forEach(item => {
                    addNewArr.push({
                        docId: item,
                        pathList: [],
                        pathLId: [],
                        uploadedData: [],
                        path: '',
                        isMultiple: false,
                        desc: '',
                        name: '',
                        id: ''
                    })
                })

                other.forEach(item => {
                    addNewArr.forEach(elem => {
                        if (item.docId == elem.docId) {
                            elem.path = item.path
                            elem.isMultiple = item.isMultiple
                            elem.desc = item.desc
                            elem.name = item.name
                            elem.id = item.id
                            if (item.uploadedData.length == 0) {
                                elem.uploadedData = [{
                                    id: '',
                                    docId: '',
                                    path: '',
                                }]
                            } else {
                                elem.uploadedData = item.uploadedData
                                if (item.isMultiple) {
                                    elem.uploadedData.push({
                                        id: '',
                                        docId: '',
                                        path: '',
                                    })
                                }
                            }
                            if (item.isMultiple) {
                                if (item.path) {
                                    elem.pathList.push(item.path)
                                    elem.pathLId.push(item.id)
                                }
                            }
                        }
                    })
                })
                addNewArr.forEach(item => {
                    if (item.isMultiple) {
                        item.pathList.push('')
                    }
                })
                this.addNewArr = addNewArr;
                this.requiredList = required;
                this.other = other;
                console.log(this.requiredList)
                console.log(this.other)
                // this.submitForm('passportForm');
            })
        },
        isChong(array) {
            array.sort();  //数组排序
            var reNum = 0;  //返回结果
            //遍历整个数组对象
            for (var i = 0; i < array.length; i++) {
                //跳过最后一个元素的比较
                if (i + 1 == array.length) {
                    continue;
                }
                //判断相邻的元素是否相同
                if (array[i] == array[i + 1]) {
                    reNum += 1;
                }
            }
            return reNum;
        },
        submitForm(formName) {

            console.log();
            let vm = this;
            // console.log(this.$refs[formName][0].validate(()));

            // return;
            vm.$refs[formName][0].validate((valid) => {
                console.log(valid)
                if (valid) {
                    this.inputShow = true;
                    savepassportinfo({
                        ...vm.ruleForm,
                        customer_SavePassportInfo: true,
                        token: vm.$store.state.token
                    }).then(res => {
                        // this.$message({
                        //     message: '保存成功',
                        //     type: 'success'
                        // });
                        this.inputShow = false;
                    })

                } else {
                    // console.log('error submit!!');
                    // this.inputShow = false;
                    return false;
                }
            });
        },
        openFile() {
            window.open(this.applicantdocsObj.orderApplicant.visaPage)
        },
        downDoc() {
            downloadapplicantdata({
                orderApplicantIds:[this.applicantdocsObj.orderApplicant.id]
            }).then(res => {
                if (res.data == null || res.data == "") {
                    this.$message({
                        message: '无可下载资料',
                        type: 'warning'
                    });
                } else {
                    window.open(res.data)
                }
            })
        }
    }
}
</script>

<style lang="scss">
.order-detail-child {

    .form-item-btn {
        text-align: right;


    }

    .demo-ruleForm {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .demo-ruleForm-radio {
            text-align: left;
        }

        .el-form-item {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 6px;
            width: 323px;

            &.form-item-btn {
                width: 100%;
            }

            .el-date-editor {
                width: 100%;
            }

            &:nth-child(1) {
                margin-right: 130px;
            }

            .el-form-item__label {
                width: 50px !important;
                white-space: nowrap;
                height: 30px;
            }

            .el-form-item__content {
                margin-left: 0px !important;
            }
        }

        .el-form-item__content {
            text-align: right;

            .el-button {
                margin-top: 20px;
            }
        }
    }
}
</style>

<style lang="scss" scoped>
.page-btn {
    height: 100px;
    text-align: right;
    margin-top: 34px;
    margin-right: 24px;
    box-sizing: border-box;

    .default {
        width: 132px;
        height: 49px;
        font-size: 24px;
    }
}

.order-detail-child {
    min-height: 800px;
    background: #F7F8FA;
    padding-bottom: 100px;

    .page-card-top-fixd {
        position: fixed;
        top: 0;
        left: 50%;
        width: 1240px;
        height: 76px;

        z-index: 11;
        background: #FFF;
        transform: translateX(-50%);
        // padding-top: 10px;
        box-sizing: border-box;

    }
}

.page-card-top {
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .page-card-top-flex {
        display: flex;
        align-items: center;
    }

    .page-top-back {
        font-size: 16px;
        color: #3888E9;
        cursor: pointer;

        i {
            color: #3888E9;
            font-weight: bold;
        }

    }

    .page-top-name {
        margin-left: 32px;
        font-size: 18px;
        font-weight: bold;

    }

    .page-top-identity {
        margin-left: 20px;
        font-size: 16px;
        font-weight: bold;
        color: #666666;

    }

    .fine-name {
        color: #3888E9;
        margin-right: 5px;
    }

    i {
        cursor: pointer;
        display: inline-block;
    }

    .icon-search {
        color: #3888E9;
        margin-right: 10px;
    }
}

.order-wrap {
    width: 1200px;
    margin: 0 auto;

    .page-path {
        margin: 16px 0;
        font-size: 12px;
        // color: #CCCCCC;
        color: #a19f9f;

        span {
            cursor: pointer;

        }
    }

    .page-card {
        width: 1200px;
        background: #FFFFFF;
        box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.16);



        .page-require-box {
            width: 100%;

            .page-title {
                height: 42px;
                padding-left: 24px;
                font-size: 16px;
                color: #3888E9;
                display: flex;
                align-items: center;
                background: #F2F8FF;
            }

            .page-item {
                padding: 20px 24px;
                background: #FFF;
                border-bottom: 5px solid #F7F8FA;
                min-height: 250px;

                .page-item-desc-2 {
                    margin-top: 20px;

                    span {
                        font-size: 16px;

                    }

                    p {
                        font-size: 14px;
                        color: #999999;
                        margin-top: 8px;
                    }
                }

                .page-item-content {
                    margin-top: 15px;
                    width: 1200px;
                    display: flex;
                    flex-wrap: wrap;

                    .page-item-content-list {
                        margin-right: 38px;
                        margin-bottom: 20px;
                    }
                }

                .page-item-avatar {
                    .page-item-name {
                        display: block;
                        margin-bottom: 12px;
                    }

                    .page-item-flex {
                        display: flex;
                        //  align-items: center;

                        .page-item-no-passport {
                            display: flex;
                            align-items: center;
                            margin-left: 77px;
                            // margin-top: 38px;
                        }

                        .page-input {
                            width: 734px;
                            margin-left: 26px;
                            position: relative;



                            .model-upload {
                                position: absolute;
                                top: 0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                background: #000000;
                                opacity: .45;
                                z-index: 2;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                .model-upload-flex {
                                    display: flex;
                                    align-items: center;
                                }
                            }

                            .page-item-title {
                                width: 100%;
                                height: 38px;
                                background: #F2F8FF;
                                color: #3888E9;
                                font-size: 16px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }

                            .page-item-waning {
                                color: #FF5967;

                                .el-icon-warning-outline {
                                    margin-right: 8px;
                                }
                            }

                            .page-item-wrap {
                                background: #F7F8FA;
                                min-height: 400px;
                                width: 100%;
                                padding: 12px 28px;
                                box-sizing: border-box;

                                .page-item-top {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    font-size: 14px;

                                    .page-edit {
                                        color: #5F9FED;
                                        cursor: pointer;
                                    }
                                }




                                .demo-list {
                                    display: flex;
                                    flex-wrap: wrap;
                                    margin-top: 16px;

                                    li {

                                        width: 330px;
                                        display: flex;
                                        margin-bottom: 24px;

                                        span {
                                            width: 110px;
                                            color: #666;
                                            font-size: 14px;
                                        }

                                        p {
                                            font-size: 16px;
                                            font-weight: bold;
                                        }
                                    }
                                }
                            }
                        }

                        .page-item-legend {
                            width: 222px;
                            height: 151px;
                            margin-left: 16px;
                        }

                        .page-item-desc {
                            max-width: 336px;
                            flex: flex;
                            flex-direction: column;
                            margin-left: 20px;

                            span {
                                font-size: 16px;

                            }

                            p {
                                font-size: 14px;
                                color: #999999;
                                margin-top: 8px;
                            }
                        }


                    }
                }
            }
        }

    }
}

.footer-wrap {
    display: none !important;
}
</style>