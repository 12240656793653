import request from '@/utils/request';
//立即处理
export function orgorder(params) {
  return request('/org_api/v1/orgfinance/orgorder', {
    method: 'post',
    data: { ...params }
  })
}

//工具收藏或者取消
export function toolcollect(params){
  return request('/org_api/v1/org/toolcollect', {
    method: 'post',
    data: { ...params }//get请求使用params,直接拼接在请求url上
  })
}
//机构端查询工具列表
export function getordertools(params){
  return request('/org_api/v1/org/getordertools', {
    method: 'get',
    params: { ...params }
  })
}
// 添加充值套餐
export function add(params) {
  return request('/org_api/v1/recharge/add', {
    method: 'post',
    data: { ...params }//get请求使用params,直接拼接在请求url上
  })
}

// 查询充值套餐
export function query(params) {
  return request('/org_api/v1/recharge/query', {
    method: 'get',
    params: { ...params }
  })
}

// 修改充值套餐
export function update(params) {
  return request('/org_api/v1/recharge/update', {
    method: 'post',
    data: { ...params }
  })
}

// 所有券数据
export function couponlist(params) {
  return request('/org_api/v1/recharge/couponlist', {
    method: 'get',
    params: { ...params }
  })
}

// 查询优惠券
export function couponquery(params) {
  return request('/org_api/v1/recharge/couponquery', {
    method: 'get',
    params: { ...params }
  })
}

// 券类型
export function coupontypeselect(params) {
  return request('/org_api/v1/recharge/coupontypeselect', {
    method: 'get',
    params: { ...params }
  })
}

// 添加优惠券
export function addcoupon(params) {
  return request('/org_api/v1/recharge/addcoupon', {
    method: 'post',
    data: { ...params }
  })
}

// 修改优惠券
export function updatecoupon(params) {
  return request('/org_api/v1/recharge/updatecoupon', {
    method: 'post',
    data: { ...params }
  })
}


// 增加券数量
export function addcouponcount(params) {
  return request('/org_api/v1/recharge/addcouponcount', {
    method: 'post',
    data: { ...params }
  })
}

// 删除优惠券
export function deletecoupon(params) {
  return request('/org_api/v1/recharge/deletecoupon', {
    method: 'get',
    params: { ...params }
  })
}

// 修改充值套餐优惠券设置
export function packagecouponmodify(params) {
  return request('/org_api/v1/recharge/packagecouponmodify', {
    method: 'post',
    data: { ...params }
  })
}

// 套餐是否上架
export function rechargepackageenable(params) {
  return request('/org_api/v1/recharge/rechargepackageenable', {
    method: 'post',
    data: { ...params }
  })
}

// 查询商户列表
export function merchant(params) {
  return request('/org_api/v1/merchant/query', {
    method: 'get',
    params: { ...params }
  })
}

// 获取分销商来源数据
export function mercahtsouceselect(params) {
  return request('/org_api/v1/merchant/mercahtsouceselect', {
    method: 'get',
    params: { ...params }
  })
}

// 获取申请状态数据
export function applystatusselect(params) {
  return request('/org_api/v1/merchant/applystatusselect', {
    method: 'get',
    params: { ...params }
  })
}

// 查询客服
export function querycustomerservice(params) {
  return request('/org_api/v1/merchant/querycustomerservice', {
    method: 'get',
    params: { ...params }
  })
}

// 添加客服
export function addcustomerservice(params) {
  return request('/org_api/v1/merchant/addcustomerservice', {
    method: 'post',
    data: { ...params }
  })
}

// 编辑客服
export function updatecustomerservice(params) {
  return request('/org_api/v1/merchant/updatecustomerservice', {
    method: 'post',
    data: { ...params }
  })
}

// 删除客服
export function deletecustomerservice(params) {
  return request('/org_api/v1/merchant/deletecustomerservice', {
    method: 'get',
    params: { ...params }
  })
}

// 绑定客服
export function bindcustomservice(params) {
  return request('/org_api/v1/merchant/bindcustomservice', {
    method: 'post',
    data: { ...params }
  })
}

// 删除充值套餐
export function deletePackage(params) {
  return request('/org_api/v1/recharge/delete', {
    method: 'get',
    params: { ...params }
  })
}

// 关闭或者启用优惠券
export function modifycouponenable(params) {
  return request('/org_api/v1/recharge/modifycouponenable', {
    method: 'post',
    data: { ...params }
  })
}

// 修改店铺信息
export function modifymerchatinfo(params) {
  return request('/app_api/v1/merchat/modifymerchatinfo', {
    method: 'post',
    data: { ...params }
  })
}
// 删除店铺
export function deletedmerchat(params) {
  return request('/app_api/v1/merchat/deletedmerchat', {
    method: 'get',
    params: { ...params }
  })
}
// 查询机构下的权限数据
export function queryorgroles(params) {
  return request('/org_api/v1/org/queryorgroles', {
    method: 'get',
    params: { ...params }
  })
}
// 查询当前登录平台账号信息,只展示子账号
export function queryorgaccounts(params) {
  return request('/org_api/v1/org/queryorgaccounts', {
    method: 'get',
    params: { ...params }
  })
}
//添加子账号信息
export function addorgaccount(params) {
  return request('/org_api/v1/org/addorgaccount', {
    method: 'post',
    data: { ...params }
  })
}

//编辑账号信息
export function updateorgaccount(params) {
  return request('/org_api/v1/org/updateorgaccount', {
    method: 'post',
    data: { ...params }
  })
}
// 删除账号
export function delorgaccount(params) {
  return request('/org_api/v1/org/delorgaccount', {
    method: 'get',
    params: { ...params }
  })
}
// 查询机构下拥有的菜单和按钮数据
export function seachorgmenus(params) {
  return request('/org_api/v1/org/seachorgmenus', {
    method: 'get',
    params: { ...params }
  })
}

// 查询当前平台相关信息
export function getorganizationinfo(params) {
  return request('/org_api/v1/org/getorganizationinfo', {
    method: 'get',
    params: { ...params }
  })
}
// 发送刷新密钥短信
export function sendsms(params) {
  return request('/org_api/v1/org/sendsms', {
    method: 'get',
    params: { ...params }
  })
}
//机构刷新SecertKey
export function reflashsecertkey(params) {
  return request('/org_api/v1/org/reflashsecertkey', {
    method: 'post',
    data: { ...params }
  })
}
//更新回调地址
export function updatenotifyurl(params) {
  return request('/org_api/v1/org/updatenotifyurl', {
    method: 'post',
    data: { ...params }
  })
}
// 获取角色详情信息
export function queryorgrolesbyroleid(params) {
  return request('/org_api/v1/org/queryorgrolesbyroleid', {
    method: 'get',
    params: { ...params }
  })
}

//添加Or更新机构下的角色信息
export function addorgroles(params) {
  return request('/org_api/v1/org/addorgroles', {
    method: 'post',
    data: { ...params }
  })
}
// 删除机构角色
export function delorgrole(params) {
  return request('/org_api/v1/org/delorgrole', {
    method: 'get',
    params: { ...params }
  })
}

// 应用类型
export function apptypeselect(params) {
  return request('/org_api/v1/org/apptypeselect', {
    method: 'get',
    params: { ...params }
  })
}
// 查询应用列表
export function appinfoquery(params) {
  return request('/org_api/v1/org/appinfoquery', {
    method: 'get',
    params: { ...params }
  })
}
//添加应用信息
export function addappinfo(params) {
  return request('/org_api/v1/org/addappinfo', {
    method: 'post',
    data: { ...params }
  })
}
//修改应用信息
export function updateappinfo(params) {
  return request('/org_api/v1/org/updateappinfo', {
    method: 'post',
    data: { ...params }
  })
}
//删除应用信息
export function delappinfo(params) {
  return request('/org_api/v1/org/delappinfo', {
    method: 'get',
    params: { ...params }
  })
}
//获取应用支付配置
export function getpayconstants(params) {
  return request('/org_api/v1/org/getpayconstants', {
    method: 'get',
    params: { ...params }
  })
}
//修改应用支付配置
export function updatepayconstants(params) {
  return request('/org_api/v1/org/updatepayconstants', {
    method: 'post',
    data: { ...params }
  })
}
//获取指定应用轮播图配置
export function getbanners(params) {
  return request('/org_api/v1/org/getbanners', {
    method: 'get',
    params: { ...params }
  })
}
//保存轮播图
export function savebanners(params) {
  return request('/org_api/v1/org/savebanners', {
    method: 'post',
    data: { ...params }
  })
}

