<template>
  <div class="wrap">
    <div class="dock-item">
      <p>开发者信息</p>
      <div class="dock-item-content">
        密钥：{{ orgInfo.key }} <i class="iconfont icon-fuzhi" v-clipboard:copy="orgInfo.key"
          v-clipboard:success="copySuccess" v-clipboard:error="copyError"></i> <span
          @click="dialogShow = true">重置密钥</span>
      </div>
    </div>
    <div class="dock-item">
      <p>回调相关参数配置</p>
      <div class="dock-item-content">
        状态异步回传地址：
        <div class="flex-center" v-show="showEdit">{{ orgInfo.notifyUrl }} <span @click="showEdit = false">修改配置</span>
        </div>
        <div class="flex-center" v-show="!showEdit">
          <el-input placeholder="请输入内容" v-model="orgInfo.notifyUrl" clearable>
          </el-input>
          <span style="width: 50px;" @click="handleUpdate">保存</span>
        </div>
      </div>
    </div>
    <!-- 重置密钥 -->
    <el-dialog :visible.sync="dialogShow" width="370px" class="common-dialog-class" :close-on-click-modal="false"
      @close="resetForm">
      <div class="dialog-member-wrap">
        <div class="dialog-member-title">
          <span class="dialog-member-name">重置密钥</span>
          <div class="dialog-close" @click="resetForm">
            <i class="el-icon-close"></i>
          </div>
        </div>
        <div class="dialog-form-wrap">
          <div class="form-item">
            账号：{{ orgInfo.phone }}
          </div>
          <div class="form-item">
            <span style="width: 80px;">验证码：</span>
            <el-input placeholder="请输验证码" v-model="yzmCode">
              <template slot="append">
                <div class="code-btn" @click="sendCode" v-show="showCodeBtn">{{ codeText }}</div>
                <span v-show="!showCodeBtn">{{ seconds }}</span>
              </template>
            </el-input>
          </div>
          <div class="common-dialog-foot-class" style="margin-top: 0">
            <el-button class="common-btn" type="primary" @click="handleReset">重置密钥</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getorganizationinfo, sendsms, reflashsecertkey, updatenotifyurl } from '@/api/settings.js'
export default {
  data() {
    return {
      orgInfo: {},
      dialogShow: false,
      yzmCode: '',
      seconds: 60,
      codeText: '发送验证码',
      showCodeBtn: true,
      timer: null,
      showEdit: true,
    }
  },
  mounted() {
    this.getOrgInfo();
  },
  methods: {
    getOrgInfo() {
      getorganizationinfo().then(res => {
        this.orgInfo = res.data
      })
    },
    copySuccess() {
      this.$message({
        message: '复制成功！',
        type: 'success'
      });
    },
    copyError() {
      this.$message({
        message: '复制失败，请手动复制！',
        type: 'error'
      });
    },
    resetForm() {
      this.dialogShow = false
      this.yzmCode = ''
      this.seconds = 60
      this.showCodeBtn = true
    },
    handleReset() {
      if (!this.yzmCode) {
        this.$message.warning('验证码不能为空！')
        return
      }
      reflashsecertkey({ code: this.yzmCode }).then(res => {
        this.$message.success('重置成功')
        this.dialogShow = false
        this.getOrgInfo()
      })
    },
    sendCode() {
      sendsms({ phone: this.orgInfo.phone }).then(res => {
        this.$message.success('发送成功！');
        this.showCodeBtn = false
        this.timer = setInterval(() => {
          this.seconds--
          if (this.seconds == 0) {
            this.showCodeBtn = true
            this.seconds = 60
            this.codeText = '再次获取'
            clearInterval(this.timer)
          }
        }, 1000);
      })
    },
    handleUpdate() {
      updatenotifyurl({ notifyUrl: this.orgInfo.notifyUrl }).then(res => {
        this.$message.success('修改成功')
        this.showEdit = true
        this.getOrgInfo()
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.dock-item {
  margin-bottom: 40px;

  p {
    font-size: 18px;
    color: #333;
    font-weight: bold;
    line-height: 20px;
    margin-bottom: 20px;
  }

  .dock-item-content {
    color: #777777;
    font-size: 16px;
    display: flex;
    align-items: center;
    height: 30px;

    i {
      color: #3888E9;
      margin: 0 8px;
      cursor: pointer;
    }

    span {
      cursor: pointer;
      color: #3888E9;
      font-weight: bold;
      font-family: PingFang SC-Bold, PingFang SC;
      margin: 0 8px;
    }
  }
}

.form-item {
  display: flex;
  margin-bottom: 25px;
  font-weight: bold;
  font-size: 16px;

  .code-btn {
    background: #E9F0FF;
    color: #3888E9;
    font-size: 12px;
    cursor: pointer;
  }

  ::v-deep .el-input-group__append {
    width: 50px;
    text-align: center;
  }
}
</style>