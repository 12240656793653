<template>
  <div class="page order-detail">
    <div class="order-wrap">
      <div class="page-path">
        <span @click="toPath(1)">快马签证</span> >
        <span @click="toPath(2)">我的订单</span> >
        <span @click="toPath(3)">{{ menuList[active].name }}</span>
      </div>

      <div class="page-card">
        <div class="order-top">
          <div class="steps">
            <div class="steps-x"></div>

            <!-- steps-active -->
            <div
              class="steps-item"
              :class="[
                active == index ? 'steps-active' : '',
                active == 1 && index == 0 ? 'steps-active-2' : '',
              ]"
              v-for="(item, index) in menuList"
              :key="index"
              @click="toStup(index)"
            >
              <p>{{ item.value }}</p>
              <span>{{ item.name }}</span>
            </div>
          </div>
        </div>
        <div class="order-x"></div>
        <div class="order-info">
          <div class="order-input">
            <span
              >商品名称：【{{ orderDetail.visaDetail.visa.areaName }}】
              {{ orderDetail.visaDetail.visa.title }}</span
            >
          </div>
          <div class="order-date">
            <span>预计出行日期</span>

            <div
              :class="[inputShow ? 'date-input-active' : '']"
              @click.stop="openCheck"
              class="order-date-input"
            >
              {{ dateInfo.str ? dateInfo.str : dateInfo.date }}
              <dateSelect
                ref="dateSelect"
                :processingDay="orderDetail.visaDetail.visa.processingDay"
                @setDate="setDate"
                :dateInfo="dateInfo"
                v-model="inputShow"
                :countryId="orderDetail.order.countryId"
              ></dateSelect>
              <i class="el-icon-date"></i>
            </div>
          </div>
        </div>

        <!-- 资料上传 -->
        <div class="order-user" v-if="active == 0">
          <div class="order-user-top">
            <div class="order-t-left">
              <p class="order-t-num">申请人<span>（共{{ orderDetail.orderApplicants.length }}人）</span></p>
              <span class="order-t-add" @click="addUser"
                ><i class="el-icon-circle-plus-outline"></i>添加</span
              >
            </div>

            <div class="order-t-right" @click="openAppUpload">
              <div class="iconfont icon-a-zu140"></div>
              <span>小程序上传</span>
            </div>
          </div>

          <div class="order-user-list">
            <div
              class="order-user-item"
              v-for="(item, index) in orderDetail.orderApplicants"
              :key="index"
            >
              <div class="order-user-left">
                <img
                  src="https://file.sport-syy.com/visa/admin/images/pc/foot.png"
                  alt=""
                />
                <div class="user-info">
                  <span>{{ item.applicantName }}</span>
                  <span>{{ item.identityTypeName }}</span>
                </div>
              </div>
              <div class="order-zl-info">
                <div class="order-item" v-if="!item.applicationForm">
                  <span>电子资料</span>
                  <span>{{ `还有${item.picCount}项${item.statusName}` }}</span>
                </div>
                <div class="order-item" v-if="item.applicationForm">
                  <span>申请表</span>
                  <span
                    :class="[item.applicationFormStatus ? 'no-order-item' : '']"
                    >{{
                      item.taskTable==100 ? "已完成" : "未完成"
                    }}</span
                  >
                </div>
              </div>

              <div class="order-btn-wrap">
                <el-button class="order-btn" @click="toDetail(item)" v-if="!item.applicationForm">去上传</el-button>
                <el-button
                  class="order-btn order-btn2"
                  v-if="item.applicationForm"
                  @click="openRlectron(item)"
                  >{{ item.taskTable < 100 ? "去填写" : "去查看" }}</el-button
                >
              </div>
              <div class="order-btn-icon">
                <div
                  class="iconfont icon-a-bianji_editor1"
                  v-if="visaDocs.length != 1"
                  @click="toIdentityType(item)"
                ></div>
                <div
                  class="iconfont icon-shanchuyonghu_people-delete"
                  @click="delUser(index)"
                  style="margin-left: 40px"
                  v-if="orderDetail.orderApplicants.length != 1"
                ></div>
              </div>

              <div class="order-bom"></div>
              <div
                class="order-bom2"
                :style="{ width: parseInt((item.taskTable/100)*1120)  + 'px' }"
              >
                <span class="order-bom2-num" v-if="item.taskTable != 0"
                  >{{ parseInt(item.taskTable) }}%</span
                >
              </div>
            </div>
          </div>

          <div class="order-btn">
            <el-button type="primary" class="default" @click="stepsFn"
              >下一步</el-button
            >
          </div>
        </div>

        <div class="order-user" v-if="active == 1 && isFg">
          <p class="order-date-title">
            <i class="el-icon-warning-outline"></i
            >请选择3个方便前往使馆的时间；预约成功后修改赴馆时间需补缴<span>每人每次50元</span>重新进行预约操作
          </p>

          <div class="order-date-info">
            <div class="order-date-item" v-if="optionsCenter.length > 1">
              <div class="order-date-lable">选择前往签证中心</div>
              <div class="order-date-right">
                <el-select
                  v-model="embassyDateList.centerIds"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in optionsCenter"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>

            <div class="radio-class">
              <div class="radio-class-item">
                <input
                  type="radio"
                  class="radio-class-item-input"
                  id="wuhan"
                  value="指定最佳赴馆时间前往"
                  v-model="radio"
                />
                <label class="radio-class-item-label" for="wuhan"
                  >指定最佳赴馆时间前往</label
                >

                <div
                  class="checkbox-wrap"
                  v-if="radio == '指定最佳赴馆时间前往'"
                >
                  <el-checkbox-group
                    v-model="checkboxValue"
                    @change="checkboxGroupChange"
                  >
                    <el-checkbox
                      v-for="(date, dateIndex) in dates"
                      :label="date"
                      :key="dateIndex"
                      >{{ getDateAndWeek(date) }}</el-checkbox
                    >
                  </el-checkbox-group>
                </div>
                <!-- getDateAndWeek(date) -->

                <!-- <div class="order-date-item order-date-item-left" v-for="(item, index) in fgDate" :key="index">

                                    <div class="order-date-lable">{{ item.name }}</div>
                                    <div :class="[item.isShow ? 'date-input-active' : '', !item.date ? 'date-input-no' : '']"
                                        @click.stop="openCneterCheck(item)" class="order-date-input"
                                        style="margin-left: 0px">{{
                                            item.date ? item.str ? item.str :
                                            item.date : '请选择赴馆日期' }}
                                        <dateSelect ref="dateSelect" @setDate="setCenterDate($event, item)"
                                            :dateInfo="{ date: item.date }" v-model="item.isShow"
                                            :countryId="orderDetail.order.countryId"
                                            :processingDay="orderDetail.visaDetail && orderDetail.visaDetail.visa && orderDetail.visaDetail.visa.processingDay"></dateSelect>
                                        <i class="el-icon-date"></i>
                                    </div>


                                </div> -->
              </div>
              <div class="radio-class-item">
                <input
                  type="radio"
                  class="radio-class-item-input"
                  id="xian"
                  value="近30天任意时间都方便"
                  v-model="radio"
                />
                <label for="xian" class="radio-class-item-label"
                  >近30天任意时间都方便</label
                >
              </div>
              <div class="radio-class-item">
                <input
                  type="radio"
                  class="radio-class-item-input"
                  id="hangzhou"
                  value="近30天内均无法前往"
                  v-model="radio"
                />
                <label for="hangzhou" class="radio-class-item-label"
                  >近30天内均无法前往</label
                ><br />

                <el-input
                  v-if="'近30天内均无法前往' == radio"
                  class="textarea-input"
                  type="textarea"
                  :rows="4"
                  placeholder="请说明其他方便赴馆的具体时间"
                  v-model="textareaInput"
                >
                </el-input>
              </div>
            </div>
          </div>
          <div class="order-btn">
            <el-button
              type="primary"
              style="margin: 0 55px"
              class="close2"
              @click="dateUpFn"
              >上一步</el-button
            >
            <el-button
              type="primary"
              style="margin: 0 55px"
              class="default"
              @click="dateNextFn"
              >下一步</el-button
            >
          </div>
        </div>
      </div>
    </div>

    <!-- <addUserInfo
      :config="newAddUserShow"
      @addUserFn="addUserFn"
      @close="newAddUserShow = false"
      isAdd="true"
      :countryId="orderDetail.order.countryId"
      :visaDocs="visaDocs"
      :visa="orderDetail.visaDetail.visa"
      v-if="newAddUserShow"
    ></addUserInfo> -->

    <addUserInfoTwo
      :config="newAddUserShow"
      @addUserFn="addUserFn"
      @close="newAddUserShow = false"
      :visaDocsInfo="visaDocs"
      v-if="newAddUserShow"
    >
    </addUserInfoTwo>

    <el-dialog
      title="修改申请人身份"
      :close-on-click-modal="false"
      center
      :visible.sync="isVisible"
      width="360px"
      class="dialog-wrap"
    >
      <div class="sf-list">
        <p class="sf-label">不同身份类型，签证资料可能会有区别</p>
        <el-radio
          v-model="sfObj.identityType"
          size="medium"
          class="sf-radio"
          :label="item.identityTypeId"
          v-for="(item, index) in visaDocs"
          :key="index"
          >{{ item.name }}</el-radio
        >

        <div class="sf-btn">
          <el-button @click="isVisible = false">取消</el-button>
          <el-button class="default" @click="editSf">确认</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="appShow"
      width="507px"
      class="app-class"
      :close-on-click-modal="true"
    >
      <div class="fx-wrap-icon-close" @click="appShow = false">
        <i class="el-icon-close"></i>
      </div>
      <div class="app-bg">
        <div class="app-bg-left">
          <p>打开微信</p>
          <span>扫描右侧二维码</span>
        </div>
        <div class="app-bg-right">
          <div class="app-bg-nei">
            <img :src="wmnImg" v-if="wmnImg" alt="" />
            <div
              class="recharge-bom-left-loading"
              v-if="loading || !wmnImg"
            ></div>
            <div class="recharge-bom-left-loading-p" v-if="loading || !wmnImg">
              <i
                class="el-icon-loading"
                style="color: #ffffff; font-size: 30px"
              ></i>
            </div>
          </div>
        </div>
      </div>
      <div class="app-bom">
        <div class="app-bom-left">
          <p>选择申请人</p>
          <span>点击转发快速上传资料</span>
        </div>
        <div class="app-bom-right">
          <img
            src="https://file.sport-syy.com/visa/admin/images/pc/app-bg2.png"
            alt=""
          />
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  orderdetail,
  upadateorderapplicants,
  getvisacenters,
  upadateorderembassydate,
} from "@/api/order.js";
import { queryembassy } from "@/api/product.js";
import dateSelect from "@/components/date.vue";
import addUserInfo from "@/components/addUserInfo.vue";
import addUserInfoTwo from "@/components/addUserInfoTwo.vue";
import { wechatshareqrcode, getorginfo } from "@/api/login.js";
import { timeFormat, getNowDate } from "@/utils/dateUtils.js";
export default {
  components: {
    dateSelect,
    addUserInfo,
    addUserInfoTwo
  },
  data() {
    return {
      id: "",
      active: 0, //  步骤
      radio: "指定最佳赴馆时间前往",
      inputShow: false,
      isFg: false,
      dateShow: false,
      isVisible: false,
      appShow: false,
      textareaInput: "",
      sfObj: {},
      orginfo: {},
      optionsCenter: [],
      visaDocs: [],
      holidayDate: [],
      checkboxValue: [],
      loading: false,
      orderDetail: {
        order: {},
        orderApplicants: [],
        visaDetail: {
          basicInfo: [],
          offlineVisaDocs: [],
          visa: {},
          visaDocs: [],
        },
      },
      fgDate: [
        {
          name: "赴馆日期1",
          isShow: false,
          date: "",
          dateStr: "",
        },
        {
          name: "赴馆日期2",
          isShow: false,
          date: "",
          dateStr: "",
        },
        {
          name: "赴馆日期3",
          isShow: false,
          date: "",
          dateStr: "",
        },
      ],
      menuList: [
        {
          value: 1,
          name: "资料上传",
        },
        {
          value: 2,
          name: "赴馆日期",
        },
        {
          value: 3,
          name: "确认订单",
        },
      ],
      dateInfo: {
        date: "",
      },
      embassyDateList: {
        orderId: "",
        centerIds: "",
        embassyDate: "",
        str: "",
        DepartureTime: "",
      },
      wmnImg: "",
      newAddUserShow: false,
    };
  },

  async mounted() {
    this.id = this.$router.history.current.params.id;
    if (this.$router.history.current.query.stup) {
      this.active = JSON.parse(this.$router.history.current.query.stup);
    }
    // this.active = 2
    await this.getOrderdetail();
    await this.getvisacentersFn();
    this.orginfo = (await getorginfo({})).data;
    await this.appUpload();
  },
  computed: {
    dates() {
      let holidays = [];
      this.holidayDate.map((h) => {
        holidays.push(timeFormat(h.date, "yyyy-mm-dd"));
      });

      const today = new Date();
      const futureDate = new Date(today.getTime() + 30 * 24 * 60 * 60 * 1000);

      const dateArray = [];

      for (let i = 0; i < 30; i++) {
        const currentDate = new Date(today.getTime() + i * 24 * 60 * 60 * 1000);
        const day = timeFormat(currentDate, "yyyy-mm-dd");

        if (holidays.includes(day)) continue;

        dateArray.push(day);
      }
      return dateArray;
    },
    getDateAndWeek() {
      return function (time) {
        const daysOfWeek = [
          "星期日",
          "星期一",
          "星期二",
          "星期三",
          "星期四",
          "星期五",
          "星期六",
        ];
        const date = new Date(time);
        const dayIndex = date.getDay();
        return timeFormat(date, "mm月dd日") + " " + daysOfWeek[dayIndex];
      };
    },
  },
  methods: {
    getOrderdetail() {
      let vm = this;

      orderdetail({
        Id: vm.id,
        token: vm.$store.state.token,
      }).then((res) => {
        vm.orderDetail = res.data;

        vm.isFg = vm.orderDetail.visaDetail.visa.needReservation;

        if (vm.isFg) {
          vm.menuList = [
            {
              value: 1,
              name: "资料上传",
            },
            {
              value: 2,
              name: "赴馆日期",
            },
            {
              value: 3,
              name: "确认订单",
            },
          ];

          if (this.orderDetail.order.embassyDate) {
            let date = JSON.parse(this.orderDetail.order.embassyDate);
            // this.fgDate.forEach((item, index) => {

            //     item.date = date[index]
            //     item.str = this.getNowDate(date[index])
            // })

            this.radio = date.label;
            if (date.label == "指定最佳赴馆时间前往") {
              this.checkboxValue = date.value;
            }

            if (date.label == "近30天内均无法前往") {
              this.textareaInput = date.value[0];
            }

            // departureTime.value = [this.textareaInput]

            // this.embassyDateList.str = this.getNowDate(this.orderDetail.order.embassyDate)

            // this.embassyDateList.str =
          } else {
            this.radio = "指定最佳赴馆时间前往";
          }

          if (this.orderDetail.order.embassys && this.orderDetail.order.embassys!='null') {
            this.embassyDateList.centerIds = JSON.parse(
              this.orderDetail.order.embassys
            )[0];
          } else {
            if (this.optionsCenter.length > 1) {
            } else {
              this.embassyDateList.centerIds = this.optionsCenter[0].id;
            }
          }
        } else {
          vm.menuList = [
            {
              value: 1,
              name: "资料上传",
            },

            {
              value: 2,
              name: "确认订单",
            },
          ];
        }

        // this.orderDetail.orderApplicants[0].taskTable =  this.orderDetail.orderApplicants[0].taskTable * 0.01;
        vm.visaDocs = vm.orderDetail.visaDetail.visaDocs;
        vm.orderDetail.orderApplicants.forEach((item) => {
          // item.taskTable = 20
          item.taskTables = 1120 * (item.taskTable * 0.01);
          // item.taskTable = 1120 * (item.taskTable * 0.01);
        });

        vm.dateInfo.date = vm.orderDetail.order.departureTime.split(" ")[0];
        vm.dateInfo.str = getNowDate(vm.dateInfo.date);
        this.queryembassyFn();
      });
    },

    getvisacentersFn() {
      let vm = this;
      getvisacenters({
        OrderId: vm.id,
        token: vm.$store.state.token,
      }).then((res) => {
        vm.optionsCenter = res.data;
      });
    },
    toIdentityType(item) {
      let vm = this;

      vm.isVisible = true;
      vm.sfObj = item;
    },

    queryembassyFn() {
      queryembassy({
        countryId: this.orderDetail.order.countryId,
      }).then((res) => {
        this.holidayDate = res.data;
      });
    },
    appUpload() {
      this.loading = true;
      wechatshareqrcode({
        appId: this.orginfo.wechatApp.appId,
        path: `/pages/visa/data_uploading/index?id=${this.id}`,
      }).then((res) => {
        this.loading = false;

        this.wmnImg = res.data;
      });
    },

    openAppUpload() {
      this.appShow = true;
      this.appUpload();
    },

    editSf() {
      let vm = this;
      let oldArr = [];
      vm.orderDetail.orderApplicants.forEach((item) => {
        if (item.id == vm.sfObj.id) {
          item = vm.sfObj;
        }
      });

      this.orderDetail.orderApplicants.forEach((item) => {
        oldArr.push({
          applicantName: item.applicantName,
          identityTypeId: item.identityType,
          id: item.id,
        });
      });

      let arr = [...oldArr];

      upadateorderapplicants({
        orderId: this.id,
        DepartureTime: this.dateInfo.date,
        applicants: arr,
      }).then((res) => {
        if (res.code == 1) {
          this.$message({
            message: "修改成功！",
            type: "success",
          });
          this.getOrderdetail();
          this.isVisible = false;
        }
      });
    },

    setDate(e) {
      this.dateInfo = e;
    },
    setCenterDate(e, item) {
      let flag = true;
      this.fgDate.forEach((item) => {
        if (item.date == e.date) {
          this.$message({
            message: "不能选择相同的赴馆时间！",
            type: "warning",
          });
          flag = false;
        }
      });

      if (!flag) return;

      item.date = e.date;
      item.str = e.str;
    },
    openCheck() {
      this.inputShow = !this.inputShow;
    },

    openCneterCheck(item) {
      item.isShow = !item.isShow;
    },

    addUser() {
      this.newAddUserShow = true;
    },

    stepsFn() {
      let vm = this;
      vm.active += 1;
      if(!this.isFg){
        this.$router.push({ name: "place", params: { place: this.id } });
      }
      // if (vm.isFg) {
      //   vm.active += 1;
      // } else {
      //   vm.$router.push({ name: "order-id-place", params: { place: vm.id } });
      // }
    },

    dateUpFn() {
      this.active = 0;
    },
    dateNextFn() {
      let vm = this;

      // embassyDateList: {
      //     orderId: '',
      //     centerIds: '',
      //     embassyDate: '',
      //     DepartureTime: '',
      // },
      if (!vm.embassyDateList.centerIds) {
        this.$message({
          message: "前往签证中心不能为空！",
          type: "warning",
        });

        return;
      }

      // let isFgDate = this.fgDate.every(item => {
      //     return item.date
      // })
      // if (!isFgDate) {
      //     this.$message({
      //         message: '赴馆时间未填写完善',
      //         type: 'warning'
      //     });

      //     return;
      // }

      let date = [];
      this.fgDate.forEach((item) => {
        date.push(item.date);
      });

      // {"label":"近30天内任何时间都方便","value":["2023-09-14","2023-09-05","2023-09-06"]}

      let departureTime = {
        label: this.radio,
        // value:
        value: "",
      };

      if (this.radio == "指定最佳赴馆时间前往") {
        if (this.checkboxValue.length == 0) {
          this.$message({
            message: "请选择赴馆日期!",
            type: "warning",
          });
          return;
        } else {
          departureTime.value = this.checkboxValue;
        }
      }

      if (this.radio == "近30天任意时间都方便") {
        departureTime.value = "";
      }

      if (this.radio == "近30天内均无法前往") {
        if (!this.textareaInput) {
          this.$message({
            message: "请说明其它方便赴馆的具体时间!",
            type: "warning",
          });
          return;
        } else {
          departureTime.value = [this.textareaInput];
        }
      }

      // if () {

      // }

      let obj = {
        orderId: vm.id,
        centerIds: [vm.embassyDateList.centerIds],
        embassyDate: JSON.stringify(departureTime),
        DepartureTime: vm.dateInfo.date,
        token: vm.$store.state.token,
      };

      // return;
      upadateorderembassydate({
        orderId: vm.id,
        centerIds: [vm.embassyDateList.centerIds],
        embassyDate: JSON.stringify(departureTime),
        DepartureTime: vm.dateInfo.date,
      }).then((res) => {
        this.active += 1;

        this.$router.push({ name: "place", params: { place: this.id } });
      });
    },
    toPath(num) {
      if (num == 1) {
        // this.$router.push({ name: 'index' })
        this.$router.push({ name: "home" });
        return;
      }

      if (num == 2) {
        this.$router.push({ name: "order" });
        return;
      }
    },

    toStup(index) {
      if (this.menuList.length == 2) {
        if (index == 0) {
          return;
        } else {
          this.stepsFn();
        }
      } else {
        if (index == 2) {
          this.dateNextFn();
          return;
        }
      }

      this.active = index;
    },
    toDetail(item) {
      let vm = this;
      // vm.$router.push({
      //   name: "orderCenterElectron",
      //   params: {
      //     id: `${item.applicantName}_${item.identityTypeName}_${item.orderId}_`,
      //   },
      // });
      vm.$router.push({ name: 'information', params: { id: `${this.id}_${item.id}` } })

    },
    openRlectron(item) {
      let vm = this;
      vm.$router.push({
        name: "electron",
        params: {
          id: `${item.applicantName}_${item.identityTypeName}_${vm.id}`,
        },
      });
    },
    checkboxGroupChange(e) {
      if(e.length>3){
        this.$message.warning('最多选择三个日期！');
        this.checkboxValue = e.slice(e.length - 3);
      }else {
        this.checkboxValue = e;
      }
    },
    addUserFn(e=[]) {
      let oldArr = [];
      this.orderDetail.orderApplicants.forEach((item) => {
        oldArr.push({
          applicantName: item.applicantName,
          identityTypeId: item.identityType,
          id: item.id,
        });
      });

      let arr = [...e, ...oldArr];

      upadateorderapplicants({
        orderId: this.id,
        DepartureTime: this.dateInfo.date,
        applicants: arr,
      }).then((res) => {
        if (res.code == 1) {
          this.$message({
            message: "添加成功！",
            type: "success",
          });
          this.getOrderdetail();
          this.newAddUserShow = false;
        }
      });
    },

    delUser(index) {
      this.$confirm(`是否要要删除该申请人`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.orderDetail.orderApplicants.splice(index, 1);

          let oldArr = [];
          this.orderDetail.orderApplicants.forEach((item) => {
            oldArr.push({
              applicantName: item.applicantName,
              identityTypeId: item.identityType,
              id: item.id,
            });
          });

          let arr = [...oldArr];

          upadateorderapplicants({
            orderId: this.id,
            DepartureTime: this.dateInfo.date,
            applicants: arr,
          }).then((res) => {
            if (res.code == 1) {
              this.$message({
                message: "删除成功！",
                type: "success",
              });
              this.getOrderdetail();
              this.newAddUserShow = false;
            }
          });
        })
        .catch((res) => {});
    },
  },
};
</script>

<style lang="scss">
.app-class {
  background: none !important;
  border-radius: 12px;
  z-index: 3;

  .el-dialog {
    background: none;
  }

  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    width: 507px;
    height: 580px;
    background: #fff;
    // border-radius: 12px;
    // background: url('https://file.sport-syy.com/visa/admin/images/pc/app-bg1.png') no-repeat;

    background-size: 100%;
    background-position: 100%;
    box-sizing: border-box;
    overflow: hidden;
    padding: 0;
    border-radius: 8px;
  }
}

.order-detail {
  .el-radio-group {
    display: flex;
    flex-direction: column;
  }
}
</style>

<style lang="scss" scoped>
.app-class {
  // position: relative;

  .fx-wrap-icon-close {
    position: absolute;
    right: 15px;
    top: 15px;

    .el-icon-close {
      font-size: 30px;
      color: #fff;
      cursor: pointer;
    }
  }

  .radio-flex {
  }

  .app-bg {
    background: url("https://file.sport-syy.com/visa/admin/images/pc/app-bg1.png")
      no-repeat;
    background-size: 507px 290px;
    background-position: 100%;
    width: 507px;
    height: 290px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .app-bg-left {
      margin-left: 50px;

      p {
        font-size: 28px;
        color: #fff;
        margin-bottom: 8px;
        font-weight: bold;
      }

      span {
        font-size: 16px;
        color: #fff;
      }
    }

    .app-bg-right {
      margin-right: 50px;
      width: 204px;
      height: 204px;
      border-radius: 8px;
      background: linear-gradient(90deg, #578af6 0%, #8aadf7 100%);
      display: flex;
      justify-content: center;
      align-items: center;

      .app-bg-nei {
        width: 174px;
        height: 174px;
        background: #fff;
        border-radius: 8px;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          border-radius: 8px;
        }

        .recharge-bom-left-loading {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          background: #000000;
          opacity: 0.45;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 2;
          border-radius: 8px;
        }

        .recharge-bom-left-loading-p {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 3;
        }
      }
    }
  }

  .app-bom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 290px;

    .app-bom-left {
      margin-left: 50px;

      p {
        font-size: 28px;
        font-weight: bold;
        margin-bottom: 8px;
      }

      span {
        font-size: 16px;
      }
    }

    .app-bom-right {
      margin-right: 17px;

      width: 230px;
      height: 230px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.order-detail {
  min-height: 800px;
  background: #f7f8fa;

  padding-bottom: 100px;
}

.order-wrap {
  width: 1200px;
  margin: 0 auto;

  .page-path {
    margin: 16px 0;
    font-size: 12px;
    // color: #CCCCCC;
    color: #a19f9f;

    span {
      cursor: pointer;
    }
  }

  .page-card {
    width: 1200px;
    background: #ffffff;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.16);

    .order-top {
      height: 104px;
      margin: auto 200px;
      padding-top: 20px;
      box-sizing: border-box;

      .steps {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        .steps-x {
          position: absolute;
          border: 1px dashed #b7d6fc;
          width: 720px;
          margin-left: 40px;
          overflow: hidden;
          top: 15px;
          z-index: 0;
        }

        .steps-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          z-index: 1;
          cursor: pointer;

          &.steps-active {
            p {
              background: #3888e9;
              color: #fff;
              border: 1px solid #3888e9;
            }

            span {
              color: #3888e9;
            }
          }

          &.steps-active-2 {
            p {
              background: #fff;
              color: #3888e9;
              border: 1px solid #3888e9;
            }

            span {
              color: #3888e9;
            }
          }

          p {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            font-size: 16px;
            text-align: center;
            line-height: 30px;
            background: #fff;
            border: 1px solid #333;
            color: #333;
          }

          span {
            margin-top: 12px;
            font-size: 16px;
            color: #333;
          }
        }
      }
    }

    .order-x {
      height: 8px;
      background: #f7f8fa;
      width: 100%;
    }

    .order-info {
      padding: 30px 40px;
      border-bottom: 1px solid #e7e7e7;
    }

    .order-user {
      width: 1120px;
      margin: 0 auto;
      margin-top: 32px;
      padding-bottom: 40px;

      .order-date-title {
        i,
        span {
          color: #ff5967;
        }

        font-size: 14px;
        color: #999999;
      }

      .order-date-info {
        display: flex;
        flex-direction: column;
        margin-top: 20px;

        .order-date-item {
          display: flex;
          align-items: center;
          margin-bottom: 20px;

          &.order-date-item-left {
            margin-left: 100px;
          }
        }

        .radio-class {
          display: flex;
          flex-direction: column;

          .radio-class-item {
            margin-bottom: 20px;

            .radio-class-item-input {
              cursor: pointer;
            }

            .radio-class-item-label {
              cursor: pointer;
              margin-left: 8px;
            }

            .textarea-input {
              margin-top: 10px;
              margin-bottom: 40px;
            }

            .checkbox-wrap {
              border: 1px solid #ddd;
              padding: 10px;
              border-radius: 10px;
              margin-top: 10px;
            }
          }
        }

        .order-date-lable {
          width: 130px;
          margin-right: 16px;
        }
      }

      .order-user-top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .order-t-left {
          display: flex;
          align-items: center;

          .order-t-num {
            font-size: 20px;
            font-weight: bold;

            span {
              font-size: 12px;
              color: #999999;
            }
          }

          .order-t-add {
            font-size: 16px;
            color: #3888e9;
            margin-left: 16px;
            font-weight: bold;
            cursor: pointer;
          }
        }

        .order-t-right {
          display: flex;
          align-items: center;
          cursor: pointer;

          .icon-a-zu140 {
            color: #3888e9;
            margin-right: 4px;
          }

          span {
            font-size: 16px;
            color: #3888e9;
          }
        }
      }

      .order-btn {
        display: flex;
        justify-content: center;
      }

      .order-user-list {
        margin-top: 20px;
        padding-bottom: 100px;

        .order-user-item {
          margin-bottom: 24px;
          width: 1120px;
          height: 117px;
          background: #ffffff;
          box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.16);
          display: flex;
          align-items: center;
          position: relative;

          .order-bom {
            position: absolute;
            bottom: 0;
            width: 1120px;
            height: 9px;
            background: #d6e9ff;
            z-index: 1;
          }

          .order-bom2 {
            position: absolute;
            bottom: 0;
            height: 9px;
            background: #3888e9;
            z-index: 2;

            .order-bom2-num {
              position: absolute;
              top: -17px;
              right: -5px;
              font-size: 12px;
              color: #3888e9;
            }
          }

          .order-user-left {
            padding: 0 20px;
            display: flex;
            height: 72px;
            align-items: center;
            width: 200px;
            border-right: 1px solid #ebebeb;

            img {
              width: 60px;
              height: 60px;
              border-radius: 50%;
            }

            .user-info {
              display: flex;
              flex-direction: column;
              margin-left: 28px;

              span {
                margin: 8px 0;

                &:nth-child(1) {
                  font-weight: bold;
                  font-size: 16px;
                }

                &:nth-child(2) {
                  font-weight: bold;
                  font-size: 14px;
                  color: #999999;
                }
              }
            }
          }

          .order-zl-info {
            margin-left: 66px;

            .order-item {
              margin: 8px 0;
              display: flex;
              align-items: center;

              span {
                &:nth-child(1) {
                  font-size: 16px;
                  width: 100px;
                }

                &:nth-child(2) {
                  font-size: 16px;
                  width: 100px;
                  color: #3888e9;
                  white-space: nowrap;

                  &.no-order-item {
                    color: #999999;
                  }
                }
              }
            }
          }

          .order-btn-wrap {
            margin-left: 100px;
            display: flex;
            flex-direction: column;

            .order-btn {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 74px;
              height: 30px;
              color: #fff;
              background: #3888e9;
              border: 1px solid #3888e9;
              margin: 0;
              font-size: 14px;
              margin: 4px 0;
              border-radius: 4px;

              &.order-btn2 {
                border: 1px solid #cccccc;
                background: #fff;
                color: #777777;
              }
            }
          }

          .order-btn-icon {
            flex: 1;
            margin-right: 40px;
            display: flex;
            justify-content: flex-end;

            .iconfont {
              cursor: pointer;

              &:hover {
                color: #3888e9;
              }
            }
          }
        }
      }
    }

    .order-date {
      margin-top: 24px;
      display: flex;
      align-items: center;
      position: relative;
    }
  }
}

.sf-list {
  display: flex;
  flex-direction: column;
  margin-top: -20px;

  .sf-label {
    margin-bottom: 15px;
    color: #999;
  }

  .sf-radio {
    margin-bottom: 15px;
  }

  .sf-btn {
    text-align: right;
  }
}

.order-date-input {
  width: 212px;
  height: 36px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #cccccc;
  margin-left: 12px;
  line-height: 36px;
  padding-left: 12px;
  box-sizing: border-box;
  position: relative;
  font-size: 14px;
  cursor: pointer;

  &.date-input-active {
    border: 1px solid #5e9be1;
  }

  &.date-input-no {
    color: #c0c4cc;
  }

  .el-icon-date {
    position: absolute;
    right: 10px;
    top: 10px;
    color: rgb(192, 196, 204);
  }
}
</style>
