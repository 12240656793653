<template>
    <div class="page order-detail-child order-detail-zdh">
        <div class="order-wrap">
            <div class="page-path">
                <span @click="toPath(1)">快马签证</span> > <span @click="toPath(2)">订单中心</span> > <span
                    @click="toPath(3)">资料上传</span>

            </div>

            <div class="page-order-detaul-bom">
                <div class="page-order-detail-left">
                    <div class="page-order-item-info" style="overflow: hidden;"
                        :style="{ height: isUp ? 'auto' : '210px' }">
                        <div class="page-order-item-t">
                            <span>订单号：</span>
                            <p id="copy">{{ orderDetail.order.orderNo }}
                            </p>
                            <el-button type="text" style="font-size: 16px;padding: 0;padding-left: 5px;"
                                class="el-icon-document-copy" v-clipboard:copy="orderDetail.order.orderNo"
                                v-clipboard:success="copySuccess" v-clipboard:error="copyError">
                            </el-button>
                            <!-- setStatus -->
                            <div class="page-order-status-name" :class="[setStatus(orderDetail.order.status)]">
                                {{ orderDetail.order.statusName }}
                            </div>
                        </div>
                        <div class="page-order-item-t">
                            <span>产品名称：</span>
                            <p id="copy">{{ orderDetail.order.visaTitle }}
                                <!-- <i i style="color: #3888E9;" @click="copyOrder"
                                    class="el-icon-document-copy"></i> -->
                            </p>
                        </div>
                        <div class="page-order-item-t">
                            <span>国家-类型：</span>
                            <p>{{ orderDetail.visaDetail && orderDetail.visaDetail.visa && orderDetail.visaDetail.visa.countryName }}-{{ orderDetail.visaDetail && orderDetail.visaDetail.visa && orderDetail.visaDetail.visa.typeName }}</p>
                        </div>
                        <div class="page-order-item-t">
                            <span>下单时间：</span>
                            <p>{{ orderDetail.order.createdTime && orderDetail.order.createdTime.split(' ')[0] }}</p>
                        </div>
                        <div class="page-order-item-t">
                            <span>预计出行：</span>
                            <p>{{ orderDetail.order.departureTime && orderDetail.order.departureTime.split(' ')[0] }}</p>
                        </div>
                        <div class="page-order-item-t" v-if="orderDetail.order.embassyDate">
                            <span>预约信息：</span>
                            <p>{{ orderDetail.order.embassyDate.str }}</p>
                        </div>
                        <div class="page-order-up" @click="isUp = !isUp">
                            <span v-if="!isUp">展开<i class="el-icon-arrow-down"></i></span>
                            <span v-else>收起<i class="el-icon-arrow-up"></i></span>

                        </div>
                    </div>

                    <div class="page-order-item-table">
                        <div class="page-order-detail-btn">
                            <el-button class="default" style="margin-right: 10px;" @click="addUser">添加申请人</el-button>
                            <el-dropdown @command="handleCommand">
                                <el-button class="default">批量处理<i class="el-icon-arrow-down el-icon--right"></i></el-button>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item command="a">批量下载</el-dropdown-item>
                                    <el-dropdown-item command="b"
                                        v-if="orderDetail.order.autocommit">批量提交</el-dropdown-item>
                                    <el-dropdown-item command="c">批量刷新</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>


                        <!-- :height="$store.state.product.screen ? '820px' : '470px'" -->
                        <div class="page-order-table-box"  v-loading="applicationLoading">
                            <!-- @row-click="setColl" :height="$store.state.product.screen ? '550px' : '420px'" -->
                            <el-table :data="userList" ref="tableOrder"
                                :header-cell-style="{ backgroundColor: 'none', color: '#333', fontWeight: 'bold', textAlign: 'center', fontSize: '10px' }"
                                 height="calc( 100% - 150px)"
                                 @row-click="setColl" :cell-style="{ 'text-align': 'center' }"
                                 :row-class-name="rowClassFun" 
                                 highlight-current-row>
                                <!--  -->

                                <el-table-column prop="applicantName" label="姓名" width="70px">
                                    <template slot-scope="scope">
                                        <div class="flex-app">
                                            <span>{{ scope.row.applicantName }}</span>
                                            <el-progress v-if="scope.row.taskTable" :show-text="false" define-back-color="#EAF3FF"
                                                :percentage="scope.row.taskTable" :width="25" :height="35" :stroke-width="6"
                                                :color="colors"></el-progress>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="identityTypeName" label="身份类型" width="100px">
                                    <template slot-scope="scope">
                                        <div class="select-input">
                                            <el-select v-model="scope.row.identityType" placeholder="请选择"
                                                @change="selectIdentit($event, scope.row)">
                                                <el-option v-for="item in orderDetail.visaDetail.visaDocs"
                                                    :key="item.identityTypeId" :label="item.name"
                                                    :value="item.identityTypeId">
                                                </el-option>
                                            </el-select>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="statusName" label="申请状态" width="100px">
                                    <!-- <template slot-scope="scope">
                                        <div class="select-input">
                                            <el-select v-model="scope.row.status" placeholder="请选择"
                                                @change="selectStatus($event, scope.row)" :class="setTopClass(scope.row.status)">
                                                <el-option v-for="item in statusSelect" :key="item.value"
                                                    :label="item.label" :value="item.value">
                                                </el-option>
                                            </el-select>
                                        </div>
                                    </template> -->
                                    <template slot-scope="scope">
                                        <div class="flex-app">
                                            <div :style="setTopClass(scope.row.status)">{{ scope.row.statusName }}</div>
                                        </div>
                                    </template>
                                </el-table-column>

                                <el-table-column label="操作" header-align="center" width="80px">
                                    <template slot-scope="scope">
                                        <span class="lan" v-if="[0,4].indexOf(scope.row.status) != -1"
                                            @click="selectStatus(2,scope.row)">审核</span>
                                        <span class="lan" v-if="[2].indexOf(scope.row.status) != -1"
                                            @click="selectStatus(4,scope.row)">资料不完善</span>    
                                        <span class="lan" v-if="[16].indexOf(scope.row.status) != -1"
                                            @click="setHandleCommand('b',scope.row)">下载</span>   
                                        <span class="lan" v-if="[12].indexOf(scope.row.status) != -1"
                                            @click="openPay(scope.row)">支付</span>
                                        <!-- <span class="lan" v-if="[6].indexOf(scope.row.status) != -1"
                                            @click="selectStatus(1,scope.row)">重新审核</span> -->
                                       <!--  <span class="lan" v-if="[0].indexOf(scope.row.status) != -1"
                                            @click="openStatusFn(scope.row)">查看</span>
                                        <span class="lan" v-if="[1, 4,3,5].indexOf(scope.row.status) != -1"
                                            @click="toPayDetail(scope.row)">查看</span>
                                        <span class="lan" v-if="[2, 6, 7, 8].indexOf(scope.row.status) != -1"
                                            @click="openBeizhu(scope.row)">备注</span>
                                        <span class="lan" v-if="[10].indexOf(scope.row.status) != -1"
                                            @click="openPay(scope.row)">支付</span>
                                        <el-dropdown v-if="[5].indexOf(scope.row.status) !== -1" trigger="hover"
                                            @command="setHandleCommand($event, scope.row)">
                                            <span class="lan" style="cursor: pointer;font-size: 12px;">展开<i
                                                    class="el-icon-arrow-down el-icon--right"></i></span>
                                            <el-dropdown-menu slot="dropdown">
                                                <el-dropdown-item command="a">
                                                    上传
                                                    <uploadTwo
                                                        style="width: 30px;height: 30px;overflow: hidden;margin-top: -30px;opacity: 0;"
                                                        v-if="isShow" @uploading="uploadHandle" @input="toImg"
                                                        @change="change" v-model="imageUrl" :limit="10" :showBtn="true"
                                                        :drag="false" :multiple="false" :auto-upload="true" :image-url="[]"
                                                        thisType="passport">
                                                    </uploadTwo>
                                                </el-dropdown-item>
                                                <el-dropdown-item command="b">下载</el-dropdown-item>
                                                <el-dropdown-item command="c">
                                                    查看
                                                </el-dropdown-item>

                                            </el-dropdown-menu>
                                        </el-dropdown> -->
                                        <!-- <span class="lan"
                                            v-if="scope.row.status == 0 && orderDetail.visaDetail.visaDocs.length > 1"
                                            @click="editPackage(scope.row)">变更身份类型</span> -->

                                        <!-- <span class="del" @click="delProduct(scope.row.id)">删除</span> -->
                                        <i class="el-icon-loading" v-if="scope.row.status == submitIngStatus"></i>
                                    </template>
                                
                                </el-table-column>
                            </el-table>
                            <div class="table-pagination">

                                <el-pagination @current-change="addressCurrentChange" :current-page="pageIndex" :pager-count="5" small
                                    :page-size="pageSize" layout="total, prev, pager, next, jumper" :total="total">
                                </el-pagination>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="page-order-detail-bottom">
                    <div class="page-order-detail-bottom-title">免责声明:平台作为连接移民局官网提交签证的便捷工具，仅提供提交签证申请及下载签证页的功能，签证申请提交
                        后，超过3天状态未变更，请及时联系客服处理，平台不对任何签证结果负责。</div>
                        <!-- calc( 100% - 150px) :style="{height:$store.state.product.screen ? '850px' : '629px'}"-->
                    <div class="page-order-detail-bottom-content" :style="{height: 'calc( 100% - 150px)'}">
                        <template >
                            <!-- <iframe class="page-card-iframe" ref="iframeRef" v-if="isShow" :src="applicationForm.applicationForm +'&terminal=PC'"
                                frameborder="0" scrolling="auto" :style="{height: '100%'}"></iframe> -->
                                <!-- .replace('https://visa-application-form.sport-syy.com', 'http://localhost:8081')  -->
                                <iframe class="page-card-iframe" ref="iframeRef" frameborder="0" scrolling="auto" :style="{height: '100%'}"></iframe> 
                                <!-- v-if="isShow" :src="applicationForm.applicationForm+'&terminal=PC&'+ applicationForm.userId" -->
                        </template>
                        <div v-if="!applicationForm.applicationForm" class="no-iframe">
                            未配置表单地址
                        </div>
                    </div>
                    <div class="page-rder-detail-bottom-btn">
                        <div>
                            <el-button class="default" v-if="orderDetail.order.autocommit" :disabled="submitLoading || applicationForm.status == 10"
                            :loading="submitLoading || applicationForm.status == 10"  @click="clickSubmit">
                                <span v-if="applicationForm.status == 10">提交中</span>
                                <span v-else-if="applicationForm.status == 20">重新提交</span>
                                <span v-else>提交到大使馆网站</span>
                            </el-button>
                            
                            <el-tooltip v-if=" applicationForm.status == 20"  :content="applicationForm.latestStatus">
                                <span style="color: red"> {{ applicationForm.latestStatus }}</span>
                            </el-tooltip>
                            
                        </div>
                        <div>
                            <span style="font-size: 16px;color: #0094E8;margin-right: 32px;cursor: pointer;"
                                @click="reload">刷新</span>
                            <el-button :disabled="[8, 10, 14, 16, 20].indexOf(applicationForm.status) > -1" class="default" @click="iframeSubmit">保存</el-button>
                            <el-button :disabled="[8, 10, 14, 16, 20].indexOf(applicationForm.status) > -1" class="text2" style="margin-right: 6px;" @click="delUser">删除</el-button>
                        </div>


                    </div>
                </div>
            </div>


        </div>
        <!-- <div class="order-content">
                <div class="page-card-top">
                    <div class="page-card-top-left">
                        <span class="page-top-back" @click="toPath(3)"><i class="el-icon-arrow-left"></i>返回</span>
                        <p class="page-top-name">{{ name }}</p>
                        <p class="page-top-identity">{{ userType }}</p>
                    </div>
                    <div class="page-card-right">
                        <el-button class="default">提交资料表</el-button>
                    </div>
                </div>
                <div class="page-card-conetent">
                    <iframe  class="page-card-iframe" :src="applicationForm" frameborder="0" scrolling="auto"></iframe>
                </div>
            </div> -->

        <el-dialog title="修改申请人身份" :close-on-click-modal="false" center :visible.sync="isVisible" width="360px"
            :before-close="handleClose" class="dialog-wrap">

            <div class="sf-list">

                <p class="sf-label">不同身份类型，签证资料可能会有区别</p>
                <el-radio v-model="sfObj.identityType" size="medium" class="sf-radio" :label="item.identityTypeId"
                    v-for="(item, index) in orderDetail.visaDetail.visaDocs" :key="index">{{ item.name }}</el-radio>

                <div class="sf-btn">
                    <el-button @click="isVisible = false;">取消</el-button>
                    <el-button class="default" @click="editSf">确认</el-button>

                </div>
            </div>
        </el-dialog>
        <el-dialog title="备注信息" :close-on-click-modal="false" center :visible.sync="beizhuShow" width="419px"
            :before-close="handleClose" class="dialog-order-detail-wrap">
            <div class="dialog-member-title">
                <span class="dialog-member-name">备注信息</span>
                <div class="dialog-close" @click="beizhuShow = false">
                    <i class="el-icon-close"></i>
                </div>
            </div>
            <div class="sf-list-zb" style="margin-top: 15px;">
                <el-input type="textarea" :rows="5" placeholder="请输入内容" v-model="textarea">
                </el-input>


                <div class="packge-btn-add" style="margin-top: 15px;">

                    <el-button class="pageck-btn" @click="beizhuShow = false">取消</el-button>
                    <el-button class="pageck-btn default" type="primary" @click="submitBz('ruleForm')">确认</el-button>
                </div>
            </div>
        </el-dialog>
        <el-dialog title="批量下载" :close-on-click-modal="false" center :visible.sync="dowmShow" width="761px"
            class="dialog-wrap-dowm-nom">
            <div class="dialog-member-title">
                <span class="dialog-member-name">批量下载</span>
                <div class="dialog-close" @click="dowmShow = false">
                    <i class="el-icon-close"></i>
                </div>
            </div>

            <div class="dialog-nom-list">
                <div class="dialog-nom-list-left">
                    <el-table :data="downList.data" ref="multipleTable"
                        :header-cell-style="{ backgroundColor: 'none', color: '#333', fontWeight: 'bold', textAlign: 'center', fontSize: '14px' }"
                        height="470px" style="width: 390px;" :cell-style="{ 'text-align': 'center' }" :row-key="getRowKeys"
                        @selection-change="getUser">
                        <el-table-column type="selection" width="55">
                        </el-table-column>
                        <el-table-column prop="applicantName" label="姓名">
                        </el-table-column>
                        <el-table-column prop="statusName" label="申请状态">
                        </el-table-column>

                    </el-table>

                    <div class="table-pagination">

                        <el-pagination @current-change="addressCurrentChange" :current-page="downList.pageIndex"
                            :page-size="downList.pageSize" layout="total, prev, pager, next, jumper" :pager-count="5" small
                            :total="downList.total">
                        </el-pagination>
                    </div>
                </div>
                <div class="dialog-nom-list-right">
                    <div class="dialog-nom-list-right-wrap">

                        <div class="dialog-nom-list-right-title">
                            <p>选择下载内容</p>
                            <p>共选择<span>{{userListAllTable.length}}本</span>签证</p>
                        </div>
                        <div class="dialog-nom-list-right-yello">
                            <i class="el-icon-warning"></i>
                            <span style="margin-left: 5px;">若选择的申请人无相关资料，下载内容为空</span>
                        </div>
                        <div class="dialog-nom-select-up">
                            <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll"
                                @change="handleCheckAllChange">全选</el-checkbox>
                            <div style="margin: 15px 0;"></div>
                            <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
                                <el-checkbox v-for="city in cities" :label="city" :key="city">{{ city }}</el-checkbox>
                            </el-checkbox-group>

                        </div>
                    </div>
                </div>
            </div>

            <div class="packge-btn-add" style="margin-top: 15px;">

                <el-button class="pageck-btn" @click="dowmShow = false">取消</el-button>
                <el-button  v-preventReClick class="pageck-btn default" type="primary" @click="submitForm('ruleForm')">确认</el-button>
            </div>
        </el-dialog>
        <el-dialog title="批量提交" :close-on-click-modal="false" center :visible.sync="tjShow" width="425px"
            class="dialog-wrap-dowm-nom">
            <div class="dialog-member-title">
                <div class="dialog-member-name">
                    批量提交
                    <span style="font-size: 12px"> 已选择<span style="color: #0094E8">{{ userListAllTable.length }}</span>本</span>
                </div>
                <div class="dialog-close" @click="tjShow = false">
                    <i class="el-icon-close"></i>
                </div>
            </div>

            <div class="dialog-nom-list">
                <div class="dialog-nom-list-left">
                    <el-table :data="downList.data" ref="multipleTable"
                        :header-cell-style="{ backgroundColor: 'none', color: '#333', fontWeight: 'bold', textAlign: 'center', fontSize: '14px' }"
                        height="470px" style="width: 390px;" :cell-style="{ 'text-align': 'center' }" :row-key="getRowKeys"
                        @selection-change="getUser">
                        <el-table-column type="selection" width="55">
                        </el-table-column>
                        <el-table-column prop="applicantName" label="姓名">
                        </el-table-column>
                        <el-table-column prop="statusName" label="申请状态">
                            <template slot-scope="scope">
                                <div class="flex-app">
                                    <div :style="setTopClass(scope.row.status)">{{ scope.row.statusName }}</div>
                                </div>
                            </template>
                        </el-table-column>

                    </el-table>

                    <!-- <div class="table-pagination">

                        <el-pagination @current-change="addressCurrentChange" :current-page="downList.pageIndex"
                            :page-size="downList.pageSize" layout="total, prev, pager, next, jumper" :pager-count="5" small
                            :total="downList.total">
                        </el-pagination>
                    </div> -->
                </div>
                <!-- <div class="dialog-nom-list-right">
                    <div class="dialog-nom-list-right-wrap">

                        <div class="dialog-nom-list-right-title">
                            <p>选择下载内容</p>
                            <p>共选择<span>15本</span>签证</p>
                        </div>
                        <div class="dialog-nom-list-right-yello">
                            <i class="el-icon-warning"></i>
                            <span style="margin-left: 5px;">若选择的申请人无相关资料，下载内容为空</span>
                        </div>
                        <div class="dialog-nom-select-up">
                            <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll"
                                @change="handleCheckAllChange">全选</el-checkbox>
                            <div style="margin: 15px 0;"></div>
                            <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
                                <el-checkbox v-for="city in cities" :label="city" :key="city">{{ city }}</el-checkbox>
                            </el-checkbox-group>

                        </div>
                    </div>
                </div> -->
            </div>

            <div class="packge-btn-add" style="margin-top: 15px;">

                <el-button class="pageck-btn" @click="tjShow = false">取消</el-button>
                <el-button  v-preventReClick class="pageck-btn default" type="primary" @click="tjSubmitForm('ruleForm')">确认</el-button>
            </div>
        </el-dialog>
        <addUserInfoTwo :config="newAddUserShow" :isAddOrder="false" :visaDocsInfo="orderDetail.visaDetail.visaDocs"
            :order="orderDetail.order" v-if="newAddUserShow" @addUserFn="addUserFn" @close="newAddUserShow = false">
        </addUserInfoTwo>
        <submitApplication :visible="submitApplicationShow" :orderId="id" @confirm="inpuSubmit" @close="submitApplicationShow = false"></submitApplication>
    </div>
</template>

<script>
import { orderdetail, upadateorderapplicants, addorderapplicants, orderapplcantstatusselect, queryorderapplicants,
    orderapplicantmodifystatus, orderapplicantmodifyidentity, downloadapplicantsdata, orderapplicantremarks, 
    uploadvisapage, batchsubmitapplicant,paycallback, applicantstatus } from '@/api/order.js'
import addUserInfoTwo from '@/components/addUserInfoTwo.vue'
import uploadTwo from '@/components/uploadTwo.vue';
import submitApplication from './components/submitApplication.vue';
const cityOptions = ['收据页', '签证文件', '拒签信'];
export default {
    data() {
        return {
            name: '',
            userType: '',
            applicationForm: {},
            id: '',
            identityType: '',
            textarea: '',
            sfObj: {},
            checkedCities: ['收据页', '签证文件', '拒签信'],
            statusSelect: [],
            userList: [],
            userListAllTable: [],
            isVisible: false,
            isUp: false,
            cities: cityOptions,
            newAddUserShow: false,
            pageIndex: 1,
            pageSize: 10,
            checkAll: true,
            isIndeterminate: false,
            total: 0,
            downList: {
                pageIndex: 1,
                pageSize: 10,
                total: 0,
                data: []
            },
            orderDetail: {
                order: {},
                orderApplicants: [],
                visaDetail: {
                    basicInfo: [],
                    offlineVisaDocs: [],
                    visa: {},
                    visaDocs: [],
                },
            },
            dateInfo: {
                date: ''
            },
            colors: [
                { color: '#E41313', percentage: 50 },
                { color: '#EEBB17', percentage: 99 },
                { color: '#1C9C1C', percentage: 100 }
            ],
            isShow: true,
            tableActive: 0,
            userId: '',
            dowmShow: false,
            beizhuShow: false,
            tjShow: false,
            beizhuId: '',
            submitLoading: false, // 是否点击提交
            submitIngStatus: 10, // 提交中状态
            applicantstatusTimer: null,
            applicationLoading: false,
            submitApplicationShow: false
        }
    },
    components: {
        addUserInfoTwo,
        uploadTwo,
        submitApplication
    },
    watch: {
        applicationForm: {
            deep: true,
            immediate: true,
            handler(val, oldVal) {
                // if(val && oldVal && val.id != oldVal.id ) {
                //     if(this.applicantstatusTimer) {
                //         clearInterval(this.applicantstatusTimer);
                //         this.applicantstatusTimer = null;
                //     }
                // }
                if(val && val.applicationForm  && val.applicationForm != (oldVal && oldVal.applicationForm)) {
                    this.$refs.iframeRef.contentWindow.location.replace( val.applicationForm +'&terminal=PC&'+ this.userId )  
                }
            }
        }
    },
    mounted() {
        this.name = this.$router.history.current.params.id.split('_')[0]
        this.userType = this.$router.history.current.params.id.split('_')[1]
        this.id = this.$router.history.current.params.id.split('_')[2]
        this.userId = this.$router.history.current.params.id.split('_')[3]
        this.orderdetailFn();
        this.queryorderapplicantsFn();

        var that = this
		window.addEventListener('message',function(event){
			// console.log('addEventListener', event.data)
            if(event.data == '刷新进度') {
				that.queryorderapplicantsFn()
			}
		})
    },
    computed: {
        setStatus() {
            return function (status) {
                if (status == -4 || status == -2 || status == -3 || status == 2) {
                    return 'sty-red'
                } else if (status == 0 || status == 1 || status == 3) {
                    return 'sty-blue'
                } else {
                    return 'sty-yello'
                }
            }
        },
        setTopClass () {
            return function (identityType) {
                if ([0, 2, 14, 6,11,18].indexOf(identityType) >= 0 ) {
                    return 'color:#eebb17'//黄色
                } else if([ 8,10,12,16].indexOf(identityType) >= 0) {
                    return 'color:#1c9c1c'//绿色
                } else {
                    return 'color:#e41313'//红色
                }
            }
        }
    },
    methods: {
        handleClose() {

        },

        submitForm() {
            // this.userListAllTable = e;
            if (this.checkedCities.length == 0) {
                this.$message({
                    message: '未选择下载内容！',
                    type: 'warning'
                });
                return;
            }


            if (this.userListAllTable.length == 0) {
                this.$message({
                    message: '未选择申请人！',
                    type: 'warning'
                });
                return;
            }

            let obj = {
                visaPage: false,
                rejectLetter: false,
                bill: false,
                orderApplicantIds: []
            }

            this.checkedCities.forEach(item => {
                if (item == '收据页') {
                    obj.bill = true;
                }

                if (item == '签证文件') {
                    obj.visaPage = true;
                }

                if (item == '拒签信') {
                    obj.rejectLetter = true;
                }
            })


            this.userListAllTable.forEach(item => {
                obj.orderApplicantIds.push(item.id);
            })

            downloadapplicantsdata({
                ...obj
            }).then(res => {
                if(res.data==null||res.data==""){
                    this.$message({
                    message: '无可下载资料',
                    type: 'warning'
                });
                }else{
                    window.open(res.data)
                }
            })
        },

        tjSubmitForm() {
            if (this.userListAllTable.length == 0) {
                this.$message({
                    message: '未选择申请人！',
                    type: 'warning'
                });
                return;
            }

            let arr = []

            this.userListAllTable.forEach(item => {
                arr.push(item.id);

            })
            batchsubmitapplicant({
                orderApplicantIds: arr,
            }).then(res => {
                this.tjShow = false;
                if (res.data[0].successful) {
                    this.$message({
                        message: '提交！',
                        type: 'success'
                    });
                } else {
                    this.$message({
                        message: res.data[0].msg,
                        type: 'warning'
                    });
                }
                // this.orderdetailFn();
                setTimeout(() => {
                    this.queryorderapplicantsFn();
                }, 3800);
                let findIndex = this.userList.findIndex(item => { return item.id == arr[0]});
                if(findIndex > -1) {
                    this.userList[findIndex].status = this.submitIngStatus;
                    this.userList[findIndex] = {...this.userList[findIndex]}
                    this.userList = [...this.userList]
                }
                if(this.applicationForm.id == arr[0]) {
                    this.applicationForm.status = this.submitIngStatus;
                    // 提交中状态轮询查询订单状态
                    this.clickApplicantStatus(this.applicationForm)
                }
                
                // location.reload();
            })
        },

        getUser(e) {
            this.userListAllTable = e;
        },
        getRowKeys(val) {
            return val.id||''
        },
        clickSubmit() {
            // autoSubmitStatus-1时就是重新提交不需要再次支付，直接提交
            if(this.applicationForm.status == 20 && this.applicationForm.autoSubmitStatus == -1) {
                // 重新提交
                this.inpuSubmit()
            } else {
                // 选择支付方式提交大使馆
                this.submitApplicationShow = true
            }
        },
        inpuSubmit(payType) {
            if(this.submitLoading) {
                return
            }
            this.submitLoading = true
            batchsubmitapplicant({
                payType,
                orderApplicantIds: [this.applicationForm.id],
            }).then(res => {
                console.log(res);
                this.submitLoading = false;

                if (res.data[0].successful) {

                    this.$message({
                        message: '提交成功！',
                        type: 'success'
                    });
                } else {
                    this.$message({
                        message: res.data[0].msg,
                        type: 'warning'
                    });
                }
                this.orderdetailFn();
                setTimeout(() => {
                    this.queryorderapplicantsFn();
                }, 3800);
                let findIndex = this.userList.findIndex(item => { return item.id == this.applicationForm.id});
                if(findIndex > -1) {
                    this.userList[findIndex].status = this.submitIngStatus;
                    this.userList[findIndex] = {...this.userList[findIndex]}
                    this.userList = [...this.userList]
                }
                this.applicationForm.status = this.submitIngStatus;
                // 提交中状态轮询查询订单状态
                this.clickApplicantStatus(this.applicationForm)
                // location.reload();
            }).catch(() => {
                this.submitLoading = false;
            })
        },

        openStatusFn(item) {
            let vm = this;

            vm.$router.push({ name: 'information', params: { information: item.id, id: `${this.id}_${item.id}` } })
        },

        copySuccess() {
            this.$message({
                message: '复制成功！',
                type: 'success'
            });
        },
        copyError() {
            this.$message({
                message: '复制失败，请手动复制！',
                type: 'error'
            });
        },

        delUser() {
            var index = 0
            this.orderDetail.orderApplicants.forEach((item, idx) => {
                if (item.id == this.applicationForm.id) {
                    index = idx
                }
            })

            this.$confirm(`是否要要删除该申请人`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.orderDetail.orderApplicants.splice(index, 1)
                let oldArr = []
                this.orderDetail.orderApplicants.forEach(item => {
                    oldArr.push({
                        applicantName: item.applicantName,
                        identityTypeId: item.identityType,
                        id: item.id
                    })
                })

                let arr = [...oldArr]
                upadateorderapplicants({
                    orderId: this.id,
                    DepartureTime: this.dateInfo.date,
                    applicants: arr,

                }).then(res => {
                    if (res.code == 1) {
                        this.$message({
                            message: '删除成功！',
                            type: 'success'
                        });
                        this.orderdetailFn();
                        setTimeout(() => {
                            this.queryorderapplicantsFn();
                        }, 1800);
                        
                    }

                })
            }).catch(res => {
            })

        },
        orderdetailFn() {
            let vm = this;

            orderdetail({
                id: vm.id
            }).then(res => {
                vm.orderDetail = res.data;
                vm.dateInfo.date = vm.orderDetail.order && vm.orderDetail.order.departureTime && vm.orderDetail.order.departureTime.split(' ')[0]

                
                if (vm.orderDetail.order && vm.orderDetail.order.embassyDate) {
                    vm.orderDetail.order.embassyDate = JSON.parse(vm.orderDetail.order.embassyDate)

                    if (vm.orderDetail.order.embassyDate.label == '近30天内均无法前往') {
                        vm.orderDetail.order.embassyDate.str = vm.orderDetail.order.embassyDate.value[0];
                    } else if (vm.orderDetail.order.embassyDate.label == '指定最佳赴馆时间前往') {
                        vm.orderDetail.order.embassyDate.str = vm.orderDetail.order.embassyDate.value.join('、');
                    } else {
                        vm.orderDetail.order.embassyDate.str = vm.orderDetail.order.embassyDate.label;
                    }
                }



            })
        },

        toImg(e) {
            uploadvisapage({
                orderApplicantId: this.beizhuId,
                visaPage: e,
            }).then(res => {
                this.$message({
                    message: '上传成功！',
                    type: 'success'
                });
            })
        },

        orderapplcantstatusselectFn() {
            orderapplcantstatusselect({
            }).then(res => {
                this.statusSelect = res.data;
            })
        },

        selectIdentit(e, item) {
            // this.editSf(e, item)
            orderapplicantmodifyidentity({
                orderApplicantId: item.id,
                status: item.status,
                identityTypeId: e,
            }).then(res => {
                this.$message({
                    message: '修改成功！',
                    type: 'success'
                });
            })
        },

        loadReast() {
            this.queryorderapplicantsFn();

        },

        submitBz() {
            orderapplicantremarks({
                orderApplicantId: this.beizhuId,
                remarks: this.textarea
            }).then(res => {
                this.$message({
                    message: '备注成功！',
                    type: 'success'
                });
                this.beizhuShow = false;
            })
        },

        handleCheckAllChange(val) {
            this.checkedCities = val ? cityOptions : [];
            this.isIndeterminate = false;
        },
        handleCheckedCitiesChange(value) {
            let checkedCount = value.length;
            this.checkAll = checkedCount === this.cities.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
        },

        handleCommand(e) {
            if (e == 'a') { // 批量下载
                this.dowmShow = true;
                
                this.getQueryorderapplicants(this.downList);
            } else if (e == 'b') { // 批量刷新
                this.tjShow = true;
                this.getQueryorderapplicants({
                    pageIndex: 1,
                    pageSize: this.total,
                    e
                });
            } else {
                // this.loadReast();
                location.reload();
            }
        },
        setHandleCommand(e, item) {
            if (e == 'a') {
                this.beizhuId = item.id;

            } else if (e == 'b') {
                // downloadapplicantsdata({
                //     orderApplicantIds: [item.id],
                //     visaPage: true,
                //     rejectLetter: true,
                //     bill: true,
                // }).then(res => {
                //     if(res.code==0){
                //         this.$message({
                //             message: res.msg,
                //             type: 'warning'
                //         });
                //     }else{
                //         window.open(res.data)
                //         this.urlDownload(res.data);
                //     }
                // })
                window.open(item.visaPage)

            } else {
                this.openStatusFn(item)
                // this.loadReast();
            }
        },

        urlDownload(url, fileName = '下载文件') {
            // 创建隐藏的可下载链接
            let eleLink = document.createElement('a')
            eleLink.download = fileName
            eleLink.style.display = 'none'
            eleLink.href = url
            // 触发点击
            document.body.appendChild(eleLink)
            eleLink.click()
            // 然后移除
            document.body.removeChild(eleLink)
        },


        queryorderapplicantsFn(isTableLoading) {
            if(isTableLoading) {
                this.applicationLoading = true;
            }
            queryorderapplicants({
                OrderId: this.id,
                'Page.Size': this.pageSize,
                'Page.Index': this.pageIndex,
            }).then(res => {
                this.total = res.data.total;
                this.userList = res.data.rows;this
                if (this.userId > 0) {
                    this.userList.forEach(item => {
                        if (item.id == this.userId) {
                            this.applicationForm = item
                            this.userId = item.id
                            this.$nextTick(() => {
                                this.$refs.tableOrder&&this.$refs.tableOrder.setCurrentRow(item)
                            })
                            // 提交中状态轮询查询订单状态
                            this.clickApplicantStatus(vm.applicationForm)
                        }
                    })
                } else {
                    this.applicationForm = vm.userList[0]
                    this.userId = vm.userList[0].id
                    this.$nextTick(() => {
                        this.$refs.tableOrder&&this.$refs.tableOrder.setCurrentRow(this.userList[0])
                    })
                    // 提交中状态轮询查询订单状态
                    vm.clickApplicantStatus(vm.applicationForm)
                }
                this.applicationLoading = false;
            }).catch(() => {
                this.applicationLoading = false;
            })
        },

        // 批量下载内表格
        getQueryorderapplicants(downList) {
            let vm = this;

            queryorderapplicants({
                OrderId: this.id,
                'Page.Size': downList.pageSize,
                'Page.Index': downList.pageIndex,
            }).then(res => {
                if(downList && downList.e == 'b') {
                    this.downList.data = res.data.rows.filter(item => { return item.autoSubmitStatus == -1 || item.autoSubmitStatus == 0});
                    this.downList.total = this.downList.data.length;
                }
                else {
                    this.downList.data = res.data.rows;
                    this.downList.total = res.data.total;
                }
                // downList: { 
                //     pageIndex: 1,
                //     pageSize: 10,
                //     total
                // },
            })
        },
        uploadHandle(val) {
            var vm = this;

            vm.uploading = val;
        },

        toPath(num) {
            if (num == 1) {
                // this.$router.push({ name: 'index' })
                this.$router.push({ name: 'home' })

            }

            if (num == 2) {
                this.$router.push({ name: 'orderCenter' })
            }


            if (num == 3) {
                // this.$router.push({ name: 'order-id', params: { id: this.id } })
                // this.$router.go(-1)
            }

        },
        selectStatus(e, item) {
            orderapplicantmodifystatus({
                orderApplicantId: item.id,
                status: e,
            }).then(res => {
                this.$message({
                    message: '修改成功！',
                    type: 'success'
                });
                this.queryorderapplicantsFn();
            })
        },
        reload() {
            // this.$refs.iframeRef.contentWindow.location.reload(true);

            this.isShow = false;

            setTimeout(() => {
                this.isShow = true;
            }, 15)
        },
        iframeSubmit() {
            this.$refs.iframeRef.contentWindow.postMessage(this.userId, '*');
        },
        addressCurrentChange(e) {
            this.pageIndex = e;

            this.queryorderapplicantsFn();
        },
        editPackage(item) {
            this.isVisible = true;
            this.sfObj = item
        },

        toPayDetail(item) {
            this.$router.push({ name: 'orderInfoDetails', params: { details: this.id } })
        },

        change(e) {

        },

        openBeizhu(item) {
            this.beizhuId = item.id;

            this.beizhuShow = true;
        },
        openPay(item) {
            if(item.payUrl==null||item.payUrl=="")
            {
                this.$message({
                 message: '未获取到支付连接',
                 type: 'warning'
                });
            }else{
                window.open(item.payUrl)
                paycallback({
                    orderApplicantId: item.id,
                }).then(res => {

                })
            }
        },
        addUserFn(e=[]) {
            if(this.applicationLoading) {
                return
            }
            let oldArr = []
            // 修复bug只是新增一个申请人，前端传递了所有申请人数据，导致重复
            // this.orderDetail.orderApplicants.forEach(item => {
            //     oldArr.push({
            //         applicantName: item.applicantName,
            //         identityTypeId: item.identityType,
            //         id: item.id
            //     })
            // })

            let arr = [...e, ...oldArr]
            this.applicationLoading = true;
            addorderapplicants({
                orderId: this.id,
                DepartureTime: this.dateInfo.date,
                applicants: arr,

            }).then(res => {
                if (res.code == 1) {
                    this.$message({
                        message: '添加成功！',
                        type: 'success'
                    });
                    this.orderdetailFn();
                    setTimeout(() => {
                        this.queryorderapplicantsFn(true);
                    }, 1800);
                    this.newAddUserShow = false;
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'warning'
                    });
                    this.applicationLoading = false;
                }
            }).catch(() => {
                this.applicationLoading = false;
            })

        },
        addUser() {
            this.newAddUserShow = true;

        },
        downPackage(item) {
            window.open(item.visaPage)
        },
        setColl(row) {
            let vm = this;
            vm.userId = row.id;

            vm.orderDetail.orderApplicants.forEach(item => {

                if (row.id == item.id) {

                    vm.applicationForm = item;
                    // 提交中状态轮询查询订单状态
                    vm.clickApplicantStatus(vm.applicationForm);
                }
            })
            this.reload();

        },
        rowClassFun(item) {
            // console.log('itemitem', item);
           if(item.row.status == this.submitIngStatus) {
                return 'submitting_calss';
           } else {
            return ''
           }
        },
        copyOrder() {
            // var url = document.getElementById('copy')
            // url.select();

        },
        editSf(e, elem) {
            let vm = this;
            let oldArr = []


            vm.orderDetail.orderApplicants.forEach(item => {
                if (item.id == elem.id) {

                    item.identityType = e
                }
            })

            vm.orderDetail.orderApplicants.forEach(item => {
                oldArr.push({
                    applicantName: item.applicantName,
                    identityTypeId: item.identityType,
                    id: item.id
                })
            })
            let arr = [...oldArr]
            upadateorderapplicants({
                orderId: this.id,
                applicants: arr,

            }).then(res => {
                if (res.code == 1) {
                    this.$message({
                        message: '修改成功！',
                        type: 'success'
                    });
                    this.queryorderapplicantsFn();
                    this.isVisible = false;
                }

            })

        },
        // 提交中状态轮询查询订单状态
        clickApplicantStatus() {
            let vm = this;
            if(this.applicantstatusTimer) {
                clearInterval(this.applicantstatusTimer);
                this.applicantstatusTimer = null;
            }
            if(this.applicationForm.status == this.submitIngStatus) {
                vm.applicantstatusTimer = setInterval(() => {
                    vm.applicantstatusFun(vm.applicationForm) 
                }, 1000);
            }
        },
        // 查询申请人状态
        applicantstatusFun(applicationForm) {
            applicantstatus({
                orderApplicantId: applicationForm.id
            }).then(res => {
                if (res.code == 1 && res.data && res.data != this.submitIngStatus ) {

                    // 状态更新刷新列表
                    // this.orderdetailFn();
                    this.queryorderapplicantsFn();

                    if(this.applicantstatusTimer) {
                        clearInterval(this.applicantstatusTimer);
                        this.applicantstatusTimer = null;
                    }
                }

            })
        }
    },
    destroyed() {
        if(this.applicantstatusTimer) {
            clearInterval(this.applicantstatusTimer);
            this.applicantstatusTimer = null;
        }
    },
    beforeDestroy() {
        if(this.applicantstatusTimer) {
            clearInterval(this.applicantstatusTimer);
            this.applicantstatusTimer = null;
        }
        
    }
}
</script>
<style lang="scss" >


.order-detail-zdh {

    .yellow-cls {
        input {
            color: #EEBB17;
        }
    }

    .green-cls {
        input {
            color: #1C9C1C;
        }
    }

    .red-cls {
        input {

            color: #E41313;
        }
    }
}

.order-detail-child {

    
    iframe {
        .countryContainer {
            background: #fff !important;
        }
    }

    .dialog-wrap-dowm-nom {
        background: none !important;
        border-radius: 12px;

        .el-dialog {
            background: none;
        }

        .el-dialog__header {
            display: none;
        }

        .el-dialog__body {
            // width: 761px;

            // height: 285px;
            background: linear-gradient(180deg, #E7ECFB 0%, #F6F8FE 24%, #FFFFFF 100%);
            border-radius: 12px;
            box-sizing: border-box;
            overflow: hidden;
        }
    }

    .dialog-order-detail-wrap {
        background: none !important;
        border-radius: 12px;

        .el-dialog {
            background: none;
        }

        .el-dialog__header {
            display: none;
        }

        .el-dialog__body {
            width: 419px;

            // height: 285px;
            background: linear-gradient(180deg, #E7ECFB 0%, #F6F8FE 24%, #FFFFFF 100%);
            border-radius: 12px;
            box-sizing: border-box;
            overflow: hidden;
        }
    }

    .select-input {
        input {
            width: 89px;
            height: 28px;
            line-height: 28px;
            padding: 0 5px;
            font-size: 12px;
            background: #F5F8FD;
        }
    }

    .dialog-wrap-dowm {}

    .page-order-table-box {

        .el-table__body-wrapper {

            font-size: 12px;
        }
        
        .el-table .submitting_calss {
            opacity: 1;
            .el-icon-loading {
                color: #409EFF;
            }
        }
    }

    .el-select__caret {
        display: block;
        line-height: 28px !important;
    }


    .current-row {
        td {
            background-color: rgba(222, 239, 255, 0.726) !important;
        }
    }

    .form-item-btn {
        text-align: right;
    }

    .dialog-nom-list {
        margin-top: 10px;

        .el-table {
            background: none;

            .el-table__row {
                background: none;

                .el-table__cell {
                    padding: 6px 0;
                }
            }

            .el-table__header-wrapper {
                height: 36px;
                border-radius: 4px;

                .cell {
                    line-height: 36px;
                    height: 36px;
                    margin-top: -10px;
                }
            }


        }
    }

    .demo-ruleForm {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .demo-ruleForm-radio {
            text-align: left;
        }

        .el-form-item {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 6px;
            width: 323px;

            &.form-item-btn {
                width: 100%;
            }

            .el-date-editor {
                width: 100%;
            }

            &:nth-child(1) {
                margin-right: 130px;
            }

            .el-form-item__label {
                width: 50px !important;
                white-space: nowrap;
                height: 30px;
            }

            .el-form-item__content {
                margin-left: 0px !important;
            }
        }

        .el-form-item__content {
            text-align: right;

            .el-button {
                margin-top: 20px;
            }
        }
    }
}
</style>
<style lang="scss" scoped>
.order-detail-child {
    background: #F7F8FA;
    // padding-bottom: 100px;

}

.packge-btn-add {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;

    .pageck-btn {
        width: 145px;
        margin: 0 16px;
    }
}

.dialog-nom-list {
    display: flex;
    justify-content: space-between;

    .dialog-nom-list-left {
        width: 390px;
    }

    .dialog-nom-list-right {

        .dialog-nom-list-right-wrap {


            width: 295px;

            .dialog-nom-list-right-title {
                box-sizing: border-box;
                background: #FFF;
                border-radius: 4px;
                height: 36px;
                display: flex;
                align-items: center;
                padding: 0 12px;
                justify-content: space-between;

                p {
                    font-size: 12px;

                    span {
                        color: #0094E8;

                    }
                }
            }

            .dialog-nom-list-right-yello {
                background: #FDF6EC;
                //  height: 28px;
                padding: 6px 12px;
                color: #F9C26F;
                box-sizing: border-box;
                font-size: 12px;
                margin-top: 8px;
            }

            .dialog-nom-select-up {
                margin-top: 15px;
            }
        }
    }
}


.sf-list {
    display: flex;
    flex-direction: column;
    margin-top: -20px;

    .sf-label {
        margin-bottom: 15px;
        color: #999;
    }

    .sf-radio {
        margin-bottom: 15px;
    }

    .sf-btn {
        text-align: right;
    }
}

.page-order-detaul-bom {
    width: 1200px;
    display: flex;
    height: calc( 100vh - 130px);

    .page-order-detail-left {
        width: 398px;
        height: 100%;
        display: flex;
        flex-direction: column;
        // padding-bottom: 30px;

        .page-order-item-info {
            width: 100%;
            padding: 20px;
            box-sizing: border-box;
            margin-bottom: 20px;
            background: #FFF;
            padding-bottom: 60px;
            position: relative;

            .page-order-up {
                text-align: center;
                color: #0094E8;
                position: absolute;
                left: 0px;
                bottom: 0px;
                width: 398px;
                text-align: center;
                border-top: 1px solid #eeeeee;
                padding-top: 15px;
                padding-bottom: 15px;
                z-index: 2;
                background: #FFF;
                cursor: pointer;

            }

            .page-order-item-t {
                margin-bottom: 10px;
                display: flex;
                align-items: center;

                .page-order-status-name {
                    padding: 4px 14px;
                    background: #DDF0DD;
                    color: #1C9C1C;
                    border-radius: 4px;
                    margin-left: 18px;
                    font-weight: bold;

                    &.sty-red {
                        background: #FBDBDB;
                        color: #E41313;
                    }

                    &.sty-yello {
                        background: #FDF5DC;
                        color: #EEBB17;
                    }
                }

                span {
                    width: 120px;
                }
            }
        }

        .page-order-item-table {
            width: 398px;
            background: #FFF;
            // min-height: 550px;
            height: calc(100% - 255px);

            .page-order-detail-btn {
                padding: 20px;
                padding-bottom: 0px;
            }

            .page-order-table-box {
                padding: 0 20px;
                height: 100%;
            }
        }
    }

    .page-order-detail-bottom {
        width: 784px;
        // height: 1107px;
        // background: #FFF;
        margin-left: 20px;

        .page-order-detail-bottom-title {
            width: 100%;
            height: 64px;
            background: #FFF8EE;
            font-size: 14px;
            color: #FF9D3D;
            padding: 12px;
            box-sizing: border-box;
        }

        .page-order-detail-bottom-content {
            position: relative;
            // height: 980px;

            // background: rgb(138, 115, 115);
            width: 100%;

            iframe {
                flex: 1;
                width: 784px;
                height: 628px;

                .countryContainer {
                    background: #fff !important;
                }
            }
            .no-iframe {
                position: absolute;
                top:64px;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #6666;
                font-size: 18px;
                border-radius: 12px;
                background-color: #f5f5f5;
            }
        }

        .page-rder-detail-bottom-btn {
            box-shadow: 0px -3px 12px 1px rgba(102, 102, 102, 0.1);
            flex: 1;
            height: 61px;
            line-height: 61px;
            text-align: right;
            display: flex;
            justify-content: space-between;
            padding: 0 10px;
            background: #FFF;

        }

    }

    // background: #FFF;
}

.table-pagination {
    text-align: center;
    margin-top: 16px;
}

.order-wrap {
    width: 1200px;
    margin: 0 auto;

    .order-content {
        width: 1200px;
        // height: 831px;
        background: #FFF;
        box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.16);
    }

    .page-path {
        margin: 16px 0;
        font-size: 12px;
        // color: #CCCCCC;
        color: #a19f9f;

        span {
            cursor: pointer;

        }
    }

    .page-card-top {
        padding: 20px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 60px;
        border-bottom: 3px solid #F5F5F5;

        .page-card-top-left {
            display: flex;
            align-items: center;
        }

        .page-card-right {}

        .page-top-back {
            font-size: 16px;
            color: #3888E9;
            cursor: pointer;

            i {
                color: #3888E9;
                font-weight: bold;
            }

        }

        .page-top-name {
            margin-left: 32px;
            font-size: 18px;
            font-weight: bold;

        }

        .page-top-identity {
            margin-left: 20px;
            font-size: 16px;
            font-weight: bold;
            color: #666666;

        }

        .page-card-top-left {}

    }

    .lan {
        color: #3888E9;
        cursor: pointer;
    }

    .page-card-conetent {
        height: 668px;
        width: 100%;
        flex: 1;

        iframe {
            flex: 1;
            width: 1200px;
            height: 668px;
        }
    }

    .flex-app {
        display: flex;
        flex-direction: column;
    }

    .page-card-bom {
        height: 97px;
        border-top: 3px solid #F5F5F5;
        display: flex;
        align-items: center;
        justify-content: center;

        .page-card-bom-btn {
            margin: auto;
            width: 138px;
            height: 45px;
        }
    }

    .page-card {
        width: 1200px;
        background: #FFFFFF;
        box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.16);


        .page-require-box {
            width: 100%;

            .page-title {
                height: 42px;
                padding-left: 24px;
                font-size: 16px;
                color: #3888E9;
                display: flex;
                align-items: center;
                background: #F2F8FF;
            }

            .page-item {
                padding: 20px 24px;
                background: #FFF;
                border-bottom: 5px solid #F7F8FA;

                .page-item-avatar {
                    .page-item-name {
                        display: block;
                        margin-bottom: 12px;
                    }

                    .page-item-flex {
                        display: flex;
                        //  align-items: center;

                        .page-item-no-passport {
                            display: flex;
                            align-items: center;
                            margin-left: 77px;
                            // margin-top: 38px;
                        }

                        .page-input {
                            width: 734px;
                            margin-left: 26px;

                            .page-item-title {
                                width: 100%;
                                height: 38px;
                                background: #F2F8FF;
                                color: #3888E9;
                                font-size: 16px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }

                            .page-item-waning {
                                color: #FF5967;

                                .el-icon-warning-outline {
                                    margin-right: 8px;
                                }
                            }

                            .page-item-wrap {
                                background: #F7F8FA;
                                min-height: 400px;
                                width: 100%;
                                padding: 12px 28px;
                                box-sizing: border-box;

                                .page-item-top {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    font-size: 14px;

                                    .page-edit {
                                        color: #5F9FED;
                                        cursor: pointer;
                                    }
                                }

                                .demo-list {
                                    display: flex;
                                    flex-wrap: wrap;
                                    margin-top: 16px;

                                    li {

                                        width: 330px;
                                        display: flex;
                                        margin-bottom: 24px;

                                        span {
                                            width: 110px;
                                            color: #666;
                                            font-size: 14px;
                                        }

                                        p {
                                            font-size: 16px;
                                            font-weight: bold;
                                        }
                                    }
                                }
                            }
                        }

                        .page-item-legend {
                            width: 222px;
                            height: 151px;
                            margin-left: 16px;
                        }

                        .page-item-desc {
                            max-width: 336px;
                            flex: flex;
                            flex-direction: column;
                            margin-left: 20px;

                            span {
                                font-size: 16px;

                            }

                            p {
                                font-size: 14px;
                                color: #999999;
                                margin-top: 8px;
                            }
                        }


                    }
                }
            }
        }

    }
}
</style>