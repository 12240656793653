<template>
    <div class="page">

        <div class="page-box-agreement">

            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="用户协议" name="first">
                    <div v-html="text"  class="agreement-content">
                    </div>
                
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
import { getagreementbykey } from '@/api/common.js'

export default {
    data() {
        return {
            activeName: 'first',
            text: ''
        }
    },
    mounted () {
        this.getagreementbykeyFn();
    },
    methods: {
        handleClick () {

        },
        getagreementbykeyFn () {

            console.log(1111);
            getagreementbykey({
                key: 'UserAgreement'
            }).then(res => {
                console.log(222);
                console.log(res);

                this.text = res.data
            })
        }
    }
}
</script>

<style lang="scss">
    .el-tabs__nav-scroll {
        height: 60px;
        line-height: 60px;
        .el-tabs__nav {
            height: 60px;
            margin-left: 24px;

            .el-tabs__item {
                font-weight: bold;
            }
        }
    }
</style>

<style lang="scss" scoped>

.page-box-agreement {
    width: 1200px;
    margin: 30px auto;
    background: #FFF;
    min-height: 700px;

}


.page {
    background: #f7f8fa;
    padding-bottom: 30px;
}
</style>