import router from './router'
import { getToken } from '@/utils/auth'
import store from '@/store'

const permissionList = ['/order', '/orderCenter', '/system', '/settings', '/personal']

router.beforeEach(async (to, from, next) => {
    // const hasToken = getToken();
    // if(!hasToken && permissionList.find(t=> to.path.indexOf(t) !== -1 )) {
    //     next('/');
    // } else {
    //     next();
    // }
    const hasToken = getToken()//store.state.user.token//getToken();
    if (hasToken) {
        next()
    } else {
        store.dispatch('user/logout')
        if (permissionList.find(t => to.path.indexOf(t) !== -1)) {
            next('/');
            // store.dispatch('user/logout').then(res => {
            //     // window.location.reload()
            // })
        } else {
            next()
        }
    }

});

router.afterEach((to, from, next) => {
    window.scrollTo(0, 0);
    // 或
    // window.scroll(0, 0);
});