<template>
  <div class="page">

    <div class="page-top-fixed" :style="{ opacity: obtainShow ? 1 : 0 }">
      <div class="page-top-fixed-wrap">

        <div class="page-top-fixed-left">
          <div class="page-top-fixed-item" @click="backTop(item)"
            :class="[item.isShow ? 'page-top-fixed-item-active' : '']" v-for="(item, index) in topFixed" :key="index">{{
              item.name }}</div>
        </div>
        <div class="page-top-fixed-right">
          <p class="page-product-p"><span>￥</span>{{ visa.price2 }}</p>
          <el-button class="page-productbtn" @click="toHandle()">立即办理</el-button>
          <div class="el-icon-close" style="font-size: 26px;" @click="$router.go(-1);"></div>
        </div>
      </div>
    </div>

    <div class="page-content" ref="visaInfo">
      <div class="page-visa">
        <div class="el-icon-close" @click="$router.go(-1);"></div>
        <div class="page-title">
          <p>【{{ visa.areaName }}】{{ visa.title }}</p>
        </div>
        <div class="page-label">
          <span class="page-type">{{ visa.typeName }}</span>
          <span class="page-no">商品编号：{{ visa.visaNo }}</span>
        </div>
        <div class="page-info">
          <div class="page-info-left">
            <div class="page-info-item">
              <p>入境次数</p>
              <span>{{ visa.entyCountDesc }}</span>
            </div>
            <div class="page-info-y"></div>
            <div class="page-info-item">
              <p>有效期</p>
              <span>{{ visa.validityPeriod }}</span>
            </div>
            <div class="page-info-y"></div>
            <div class="page-info-item">
              <p>停留时长</p>
              <span>{{ visa.durationOfStay }}</span>
            </div>
            <div class="page-info-y"></div>
            <div class="page-info-item">
              <p>是否需要预约</p>
              <span>{{ visa.needReservation ? '是' : '否' }}</span>
            </div>
            <div class="page-info-y"></div>
            <div class="page-info-item">
              <p>资料审核(工)</p>
              <span>{{ visa.dataSpendTime }}</span>
            </div>
            <div class="page-info-y"></div>
            <div class="page-info-item">
              <p>使馆审核(工)</p>
              <div>
                <span>{{ visa.embassySpendTime }}</span>

                <el-tooltip placement="top">
                  <div slot="content" class="page-info-item-tooltip">
                    预计办理时长是签证专家根据经验作出的时间预估，如果<br />遇到政策干预、领馆假期等特殊情况，有可能延迟出<br />签!出签后如需邮寄还需1-2日快递时间）</div>
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </div>

            </div>
          </div>
          <!-- <div class="page-info-btn">
            <el-button class="child-btn3">分享</el-button>
            <el-button type="primary" @click="toHandle(el)">立即办理</el-button>
          </div> -->
        </div>
        <div class="page-product" ref="obtain">
          <div class="page-product-left">
            <p class="page-product-p"><span>￥</span>{{ visa.price2 }}</p>
            <p class="page-product-p2">建议零售价{{ visa.price4 }}元</p>
          </div>
          <div class="page-product-right">
            <el-button class="page-product-fx" @click="toFx">分享给顾客</el-button>
            <el-button class="page-productbtn" @click="toHandle()">立即办理</el-button>
          </div>
        </div>
        <!-- <div>
          12
        </div> -->
        <div class="page-bom">
          <div class="page-bom-item">
            <p class="page-bom-title">
              <span>受理范围</span>
              <span class="page-bom-title-l" @click="fwShow = !fwShow">{{ !fwShow ? '展开▼' : '收起▲' }}</span>
            </p>
            <p class="page-bom-info" style="white-space: pre-line;" @click="fwShow = !fwShow"
              :style="{ height: fwShow ? '100%' : '120px' }">
              {{ visa.rangeDesc }}</p>
          </div>
          <div class="page-bom-item page-bom-item2">
            <p class="page-bom-title">
              <span>温馨提示</span>
              <span class="page-bom-title-l" @click="wxShow = !wxShow">{{ !wxShow ? '展开▼' : '收起▲' }}</span>
            </p>
            <p class="page-bom-info" style="white-space: pre-line;" @click="wxShow = !wxShow"
              :style="{ height: wxShow ? '100%' : '120px' }">
              {{ visa.promptDesc }}</p>
          </div>
        </div>
   
      </div>
      <div class="page-visa-docs"  ref="dropdownRef">
        <div class="page-title page-title2">
          <div class="page-title-left">

            <p>所需材料</p>
            <el-dropdown class="dropdown-btn" @command="selectCom" trigger="click">
              <span class="el-dropdown-link">
                {{ visaObj.name }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="(item, index) in visaMehodList" :key="index" :command="item">{{ item.name
                }}</el-dropdown-item>

              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="page-title-right">
            <el-button class="child-btn3" size="small" @click="openEmailD()">发送邮件</el-button>
            <el-button class="child-btn3" size="small" @click="openMb()">下载全部模板</el-button>
            <el-button size="small" type="primary" @click="openQd()">资料清单</el-button>
          </div>
        </div>
        <div class="page-visa-menu">
          <!-- 一级 -->
          <el-tabs v-model="activeName" @tab-click="handleClickInfo">
            <el-tab-pane :label="item.name" :name="item.value" v-for="(item, index) in visaObj.data" :key="index">
              <div class="page-visa-m-t">
                <span class="page-visa-m-text1">需邮寄资料({{ item.docs.length }})</span>
                <p class="page-visa-m-text2"><span>*</span> 为必备资料</p>
              </div>

              <!-- <div class="visa-children" v-if="docs.length > 0">

                <div   v-for="(elem, idx) in docs" :key="idx">
                  <div class="visa-flex" >
                    <span>{{elem.categoryName}}</span>
                    <div class="visa-children-item" v-for="(el, i) in elem.children" :key="i">
                      <p class="child-left">{{ el.name }} <span v-if="el.isNeed">*</span></p>
                      <div class="child-right">
                        <span>{{ el.desc }}</span>
                        <el-button class="child-btn" size="medium" type="primary" round v-if="el.type == 1 && el.path"
                          @click="openImg(el)">下载模板</el-button>
                        <el-button class="child-btn child-btn2" size="medium" round v-if="el.type == 0 && el.path"
                          @click="openImg(el)">
                          <el-image class="child-img" :src="el.path" :preview-src-list="[el.path]">
                          </el-image>
                          查看样例</el-button>

                      </div>
                    </div>
                  </div>
                </div>
              </div> -->

              <div class="visa-children-2">
                <div class="visa-children-2-item" v-for="(elem, idx) in docs" :key="idx">
                  <div class="visa-children-left">
                    {{ elem.categoryName }}
                  </div>
                  <div class="visa-children-right">
                    <div class="visa-children-right-item" v-for="(el, i) in elem.children" :key="i">
                      <p class="child-left">{{ el.name }}<span v-if="el.isNeed">*</span></p>
                      <div class="child-right">
                        <span style="white-space: pre-wrap;">{{ el.desc }}</span>
                        <el-button class="child-btn" size="medium" type="primary" round v-if="el.type == 1 && el.legend"
                          @click="openImg(el)">下载模板</el-button>
                        <el-button class="child-btn child-btn2" size="medium" round v-if="el.type == 0 && el.legend"
                          @click="openImg(el)">
                          <el-image class="child-img" :src="el.legend" :preview-src-list="[el.legend]">
                          </el-image>
                          查看样例</el-button>

                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </el-tab-pane>
          </el-tabs>
        </div>

      </div>

      <!-- 办理须知 -->
      <div class="basic-info" ref="handleRef">
        <el-tabs v-model="basicActice" @tab-click="handleClick">
          <el-tab-pane :label="item.title" :name="index+''" v-for="(item, index) in basicInfo" :key="index">
            <p class="basic-info-text" style="white-space: pre-line;">{{ item.content }}</p>
          </el-tab-pane>
        </el-tabs>
      </div>

      <!-- 办签流程 -->
      <!-- 签证图例 -->
      <div class="visa-legend" ref="lcRef">
        <div class="page-title">
          <p>办理流程</p>
        </div>
        <div class="page-timeline">
          <!-- {{visa.process.process}} -->
          <el-timeline>
            <el-timeline-item v-for="(item, index) in visa.process.process" size="large" color="#1890FF"
              icon="el-icon-check" :key="index">
              <p class="time-line-top">{{ item.title }}</p>
              <span class="time-line-bom">{{ item.content }}</span>
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>


      <!-- 签证图例 -->
      <div class="visa-legend visa-img-tp" v-if="visa.visaLegend" ref="tlRef">
        <div class="page-title">
          <p>签证图例</p>
        </div>
        <div class="visa-img">
          <!-- <img :src="visa.visaLegend" alt=""> -->
          <el-image fit="cover" :src="visa.visaLegend" :preview-src-list="[visa.visaLegend]">
          </el-image>
        </div>
      </div>
    </div>
    <handle></handle>

    <!-- <addUserInfoTwo :config="userShow" :visa="visa" :visaDocs="visaDocs" v-if="userShow" @close="userShow = false"
      @refresh="handleUserRefresh" :countryId="visa.countryId" :provinceId="provinceId" :showSubmissionMethod="true">
    </addUserInfoTwo> -->

    <addUserInfo :config="userShow" :visa="visa" :visaDocs="visaDocs" v-if="userShow" @close="userShow = false"
      @refresh="handleUserRefresh" :countryId="visa.countryId" :provinceId="provinceId">
    </addUserInfo>
    <!-- 发送资料至邮箱 -->
    <el-dialog :visible.sync="openEmail" width="525px" class="email-class" :close-on-click-modal="false">
      <div class="dialog-member-wrap">
        <div class="dialog-member-title">
          <span class="dialog-member-name">发送资料至邮箱</span>
          <div class="dialog-close" @click="openEmail = false;">
            <i class="el-icon-close"></i>
          </div>
        </div>

        <div class="zl-input">
          <div class="zl-item">
            <div class="zl-label">资料递交的方式</div>
            <div class="zl-form">
              <el-radio v-model="zlEmail.method" :label="1">在线上传资料</el-radio>
              <el-radio v-model="zlEmail.method" :label="2">线下邮寄资料</el-radio>
            </div>
          </div>

          <div class="zl-item">
            <div class="zl-label">顾客身份</div>
            <div class="zl-form">
              <div class="zl-item-kk"
                :class="[item.isActice  ? 'zl-item-kk-active' : '']"
                @click="infoKK(item)" v-for="(item, index) in selectKK" :key="index">
                {{ item.name }}</div>
            </div>
          </div>


          <div class="zl-item">
            <div class="zl-label">邮箱地址</div>
            <div class="zl-form">
              <el-input v-model="zlEmail.email" style="margin-left: -50px;width: 360px;"></el-input>
            </div>
          </div>
          <div class="zl-text">
            请留意来自xxxxxx@email的邮件
          </div>
          <div class="zl-btn">
            <el-button class="default" style="width: 145px;height: 38px" @click="emailSubmit">发送</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="fxShow" width="690px" class="fx-class" :close-on-click-modal="true">
      <div class="fx-wrap">

        <div class="fx-wrap-icon-close" @click="fxShow = false">
          <i class="el-icon-close"></i>
        </div>
        <div class="fx-left">
          <img src="https://file.sport-syy.com/visa/admin/images/pc/phone-bg.png" alt="">
        </div>
        <div class="fx-right">
          <div class="fx-r-top">
            <div class="fx-r-item" v-for="(item, index) in fxIcon" :key="index">
              <div class="fx-r-item-icon">
                <i class="iconfont" :class="[item.icon]"></i>

              </div>
              <span class="fx-r-item-text">{{item.name}}</span>
            </div>
          </div>
          <di class="fx-r-bom">
            <div class="fx-r-bom-left">
              <p>微信扫描右侧二维码</p>
              <span>微信小程序快速办理</span>
            </div>
            <div class="fx-r-bom-wmn">
              <img :src="wmnImg" v-if="wmnImg" alt="">
              <div class="recharge-bom-left-loading" v-if="loading || !wmnImg">
              </div>
              <div class="recharge-bom-left-loading-p"  v-if="loading || !wmnImg">

                  <i class="el-icon-loading" style="color: #ffffff;font-size: 30px;"></i>
              </div>
            </div>
          </di>
        </div>
      </div>
    </el-dialog>


  </div>
</template>

<script>
import handle from '@/components/handle.vue';
import addUserInfoTwo from '@/components/addUserInfoTwo.vue'
import addUserInfo from '@/components/addUserInfo.vue'
import { getvisadetail, downloadvisadata, getvisadatalist, sendemailvisa } from '@/api/product.js';
import { wechatshareqrcode, getorginfo } from '@/api/login.js'

export default {
  data() {
    return {
      userShow: false,
      openEmail: false,
      id: '',
      wmnImg: '',
      visa: {
        process: {
          process: []
        }
      },
      fxIcon: [
        {
          name: '免费拍摄签证照',
          icon: 'icon-renlianshibie'
        },
        {
          name: '资料扫描自动填表',
          icon: 'icon-checklist'
        },
        {
          name: '护照顺丰包邮',
          icon: 'icon-qianfeijian'
        },
        {
          name: '一次提供永久使用',
          icon: 'icon-ecsyunfuwuqiECS'
        },
      ],
      topFixed: [
        {
          name: '基本信息',
          isShow: false,
          ref: 'visaInfo',
          class: '.page-visa',
        },
        {
          name: '所需资料',
          isShow: false,
          ref: 'dropdownRef',
          class: '.page-visa-docs',
        },
        {
          name: '办理须知',
          isShow: false,
          ref: 'handleRef',
          class: '.basic-info',
        },
        {
          name: '办理流程',
          isShow: false,
          ref: 'lcRef',
          class: '.visa-legend',
        },
        {
          name: '签证图例',
          isShow: false,
          ref: 'tlRef',
          class: '.visa-img-tp'
        },
      ],
      offlineVisaDocs: [],
      basicInfo: [],
      visaDocs: [],
      visaMehodList: [],
      selectKK: [],
      docs: [],
      orginfo: {},
      fwShow: false,
      wxShow: false,
      obtainShow: false,
      visaObj: {},
      activeName: 0,
      basicActice: 0,
      provinceId: 0,
      activeChildrenName: 0,
      zlEmail: {
        method: 1,
        email: '',
        IdentityTypeIds: [],

      },
      config: {
        dialogVisible: false,
      },
      activities: [{
        content: '活动按期开始',
        timestamp: '2018-04-15'
      }, {
        content: '通过审核',
        timestamp: '2018-04-13'
      }, {
        content: '创建成功',
        timestamp: '2018-04-11'
      }],
      isBntShow: false,
      fxShow: false,
      loading:false
    }
  },
  components: {
    handle,
    addUserInfo,
    addUserInfoTwo
  },
  beforeDestroy  () {
    window.removeEventListener('scroll', this.handleScrollx, true)
  },  
  mounted() {

    console.log(this.$router.history.current.params, 886);
    if (this.$router.history.current.params.id) {
      this.$store.commit('SET_COUNTRY_ID', this.$router.history.current.params.id);
      this.id = this.$router.history.current.params.id
      this.provinceId = this.$router.history.current.params.provinceId
    } else {
      this.id = this.$store.state.countryId;
    }
    // 滚动条的获取
    window.addEventListener('scroll', this.handleScrollx, true)
    this.getvisadetailFn();
    this.getorginfoFn();
  },

  methods: {

    getvisadetailFn() {
      getvisadetail({
        id: this.id
      }).then(res => {
        console.log(res);
        this.visa = res.data.visa;
        this.visa.rangeDesc = this.visa.rangeDesc.replace(/^\s*/, "")
        this.visaDocs = res.data.visaDocs;
        this.offlineVisaDocs = res.data.offlineVisaDocs;
        this.basicInfo = res.data.basicInfo;


        if (this.visa.method == 0) {
          this.visaMehodList = [{ name: '线上所需', value: '1', data: this.visaDocs }, { name: '线下所需', value: '2', data: this.offlineVisaDocs }]
        } else if (this.visa.method == 1) {
          this.visaMehodList = [{ name: '线上所需', value: '1', data: this.visaDocs }]
        } else {
          this.visaMehodList = [{ name: '线下所需', value: '2', data: this.offlineVisaDocs }]
        }

        this.visaObj = this.visaMehodList[0]

        this.initHandleClick(0);
      })
    },
    handleUserRefresh() {
      this.userShow = false
    },

    backTop(item) {


      const scrollTop = document.querySelector(item.class)
      console.log(scrollTop.offsetTop)
      // scrollTop
      scrollTop.scrollIntoView({
          block: 'start',
          behavior: 'smooth' 
      });

      window.scroll(0, scrollTop.offsetTop - (76))

      // document.querySelector('.uul').scrollIntoView({

      //   })
      // this.scrollTo(30, 1000)
    },
    toHandle() {

      this.userShow = true;
    },

    toFx () {
        this.fxShow = true;

        this.loading = true;

        wechatshareqrcode({
          appId: this.orginfo.wechatApp.appId,
          path: `/pages/visa/index/index?id=${this.id}&ProvinceId=${this.provinceId}&VisaTypeId=${this.visa.visaTypeId}`
        }).then(res => {
            console.log(res);
            this.wmnImg = res.data
            this.loading = false;
        })
    },  

    getorginfoFn() {
        getorginfo({
        }).then(res => {
            console.log(res);
            this.orginfo = res.data
        })
    },

    emailSubmit () {
      // zlEmail: {
      //   method: 1,
      //   email: '',
      //   IdentityTypeIds: '',

      // },

      if (this.isBntShow) {
        this.$message({
            message: '请选择顾客身份！',
            type: 'warning'
        });
        return;
      }

      
      if (!this.zlEmail.email) {
        this.$message({
            message: '请填写邮箱地址',
            type: 'warning'
        });
        return;
      }

      let reg = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;		//	手机


      if (!reg.test(this.zlEmail.email)) {
        this.$message({
            message: '请输入正确的邮箱地址格式',
            type: 'warning'
        });
        return;
      }

      let arr = []
      this.selectKK.forEach(item => {
        if (item.isActice) {
          arr.push(item.identityTypeId)
        }
      })
      console.log(arr);

      sendemailvisa({
        VisaId: this.visa.id,
        IdentityTypeId: arr.join(','),
        Method: this.zlEmail.method,
        EmailAddress: this.zlEmail.email,
      }).then(res => {
        console.log(res);

        if (res.code == 1) {
          this.$message({
              message: res.data,
              type: 'success'
          });

          this.openEmail = false;
        }

      })
    },
    infoKK (elem) {
        console.log(elem)
        this.selectKK.forEach(item => {
          if (item.identityTypeId == elem.identityTypeId) {
            item.isActice = !item.isActice;
          }
        })
        let num = 0
        this.selectKK.forEach(item => {
          if (item.isActice) {
            num = num + 1;
          }
        })

        if (num == 0) {
          this.isBntShow = true
        } else {
          this.isBntShow = false
        }


        this.$forceUpdate();
    },
    openEmailD() {
      this.openEmail = true;


      this.zlEmail.method = this.visaObj.value
      this.zlEmail.identityTypeId = [this.visaObj.data[this.activeName].identityTypeId]


      console.log(this.zlEmail.identityTypeId);
      this.selectKK = this.visaDocs;

      this.selectKK.forEach(item => {
        item.isActice = false;

        this.zlEmail.identityTypeId.forEach(elem => {
          if (item.identityTypeId == elem) {
            item.isActice = true;
          }
        })
      })
    },

    handleScrollx() {
      console.log('滚动高度', window.pageYOffset)
      // console.log('距离顶部高度', this.$refs.obtain.getBoundingClientRect().top)


      // console.log(this.$refs.tlRef.getBoundingClientRect().top);

      // if (this.$refs.dropdownRef.getBoundingClientRect().top > 0) {

      //   this.topFixed.forEach(item => {
      //     item.isShow = false;
      //   })
      //   this.topFixed[0].isShow = true

      // } else {
      //   this.topFixed[0].isShow = false
      // }

      // if (this.$refs.handleRef.getBoundingClientRect().top > 0) {

      //   this.topFixed.forEach(item => {
      //     item.isShow = false;
      //   })
      //   this.topFixed[1].isShow = true

      // } else {
      //   this.topFixed[1].isShow = false
      // }
      // console.log(this.$refs.handleRef.getBoundingClientRect(), 666)
      this.$nextTick(() => {
        this.topFixed.forEach(item => {
          item.isShow = false;
        })
        console.log(this.$refs.tlRef.getBoundingClientRect().top)
        console.log(this.$refs[this.topFixed[this.topFixed.length - 1].ref]);
        this.topFixed.some((item, index) => {

          if (this.$refs[this.topFixed[this.topFixed.length - 1].ref] && this.$refs[this.topFixed[this.topFixed.length - 1].ref].getBoundingClientRect().top < 180) {
            this.topFixed[this.topFixed.length - 1].isShow = true;
            return true;
          }

          if (this.$refs[this.topFixed[index + 1].ref] && this.$refs[this.topFixed[index + 1].ref].getBoundingClientRect().top > 0 && this.$refs[item.ref] && this.$refs[item.ref].getBoundingClientRect().top < 200) {

            this.topFixed.forEach(item => {
              item.isShow = false;
            })
            item.isShow = true;
          }

          return this.$refs[item.ref].getBoundingClientRect().top > 200
        })
      
      })

      let vm = this;

      let obtainTop = vm.$refs.obtain.getBoundingClientRect().top
      if (obtainTop < 0) {
        this.obtainShow = true;
      } else {
        this.obtainShow = false;

      }

    },


    selectCom(e) {
      console.log(e);
      this.visaObj = e;
      this.activeName = '0';
      this.initHandleClick(0);
    },
    openImg(el) {
      if (el.type == 1) {

        window.open(el.legend, '_blank');
      } else {



      }
    },
    handleClickInfo(e) {
      console.log(e.index, 8888);
      this.docs = []
      this.initHandleClick(e.index);

    },
    initHandleClick(index) {
      if (this.visaObj.data[index].docs.length == 0) {
        this.docs = [];
        return;
      }

      let arr = this.visaObj.data[index].docs;
      console.log(arr, 'arr')

      var obj = {};
      arr.forEach(item => {
        obj[item.categoryId] = [];
      })

      arr.forEach(item => {
        for (let o in obj) {
          if (item.categoryId == o) {
            obj[o].push(item);
          }
        }
      })

      console.log(obj);

      var arrObj = [];
      for (let o in obj) {
        // console.log(o, obj[o][0].categoryId);
        arrObj.push({
          categoryId: o,
          categoryName: obj[o][0].categoryName,
          children: obj[o]
        })

        // i++;
      }
      // this.activeChildrenName = arrObj[0].categoryId

      console.log(arrObj, 'arrObjarrObjarrObj');

      this.docs = arrObj;
    },
    handleChildClickInfo(e) {

      // this.activeChildrenName = this.docs[e.index].categoryId
    },
    handleClick() {

    },
    openMb() {


      if (this.visaObj.data[parseInt(this.activeName)].docs.length == 0) {
        this.$message({
          message: '暂无可下载模板',
          type: 'warning'
        });

        return;
      }

      downloadvisadata({
        VisaId: this.id,
        IdentityTypeId: this.visaObj.data[parseInt(this.activeName)].identityTypeId,
        Method: this.visaObj.value == 1 ? 0 : 1,
        typefile: 'download'
      }).then(res => {
        console.log(res);
      })
    },
    openQd() {
      if (this.visaObj.data[parseInt(this.activeName)].docs.length == 0) {
        this.$message({
          message: '暂无可下载模板',
          type: 'warning'
        });

        return;
      }

      getvisadatalist({
        VisaId: this.id,
        IdentityTypeId: this.visaObj.data[parseInt(this.activeName)].identityTypeId,
        Method: this.visaObj.value == 1 ? 0 : 1,
        typefile: 'download'
      }).then(res => {
        console.log(res);
      })
    }

  }
}
</script>

<style lang="scss" >
.child-right {
  .child-btn {
    margin-right: 20px;

    &.child-btn2 {
      color: #599fff;
      border: 1px solid #599fff;
      position: relative;

      .child-img {
        position: absolute;
        width: 98px;
        height: 36px;
        left: 0;
        top: 0;
        opacity: 0;


      }
    }
  }
}

.fx-class {
  background: none !important;
  border-radius: 12px;
  z-index: 3;
  .el-dialog {
    background: none;
  }

  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    width: 690px;
    height: 435px;
    // background: linear-gradient(180deg, #E7ECFB 0%, #F6F8FE 24%, #FFFFFF 100%);
    background: url('https://file.sport-syy.com/visa/admin/images/pc/bg-fx2.png') no-repeat;
    // border-radius: 12px;
    background-size: 100%;
    background-position: 100%;
    box-sizing: border-box;
    overflow: hidden;
    height: 413px;

  }

}

.email-class {
  background: none !important;
  border-radius: 12px;

  .el-dialog {
    background: none;
  }

  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    width: 525px;
    background: linear-gradient(180deg, #E7ECFB 0%, #F6F8FE 24%, #FFFFFF 100%);
    border-radius: 12px;
    box-sizing: border-box;
    overflow: hidden;
    height: 413px;
  }


}
</style>

<style lang="scss" scoped>
.fx-class {

  .fx-wrap {
    display: flex;
    align-items: center;
    position: relative;
    .fx-wrap-icon-close {
      position: absolute;
      right: 0px;
      top: 0px;
      .el-icon-close {
        font-size: 30px;
        color: #707070;
        cursor: pointer;
      }
    }
    .fx-left {
      margin-left: 46px;
      img {
        width: 149px;
        height: 325px;
      }
    }

    .fx-right {
        display: flex;
        flex-direction: column;
        margin-left: 45px;
        margin-top: 30px;
        .fx-r-top {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
          width: 401px;
            .fx-r-item {
              display: flex;
              align-items: center;
              margin-bottom: 15px;
              .fx-r-item-icon {
                width: 35px;
                height: 35px;
                line-height: 35px;
                background: #FFF;
                border-radius: 50%;
                background: #FFF;
                margin-right: 17px;
                box-shadow: 0px 5px 20px 1px rgba(101,101,101,0.15);
                text-align: center;
                i {
                  color: #3888E9;
                }
              }

              .fx-r-item-text {
                font-size: 17px;
                font-weight: bold;
              }
            }
        }
        .fx-r-bom {
          width: 401px;
          height: 159px;
          background: #FFF;
          display: flex;
          align-items: center;
          padding: 0 20px;
          box-sizing: border-box;
        
          .fx-r-bom-left {

            p {
              font-size: 24px;
              font-weight: bold;
              margin-bottom: 12px;
            }

            span {
              font-size: 16px;
              font-weight: bold;
            }
          }

          .fx-r-bom-wmn {
            width: 103px;
            height: 103px;
            background: #CCCCCC;
            margin-left: 34px;
            position: relative;

            img {
              width: 100%;
              height: 100%;
            }

            .recharge-bom-left-loading {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
                background: #000000;
                opacity: .45;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 2;
            }

            .recharge-bom-left-loading-p {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 3;
            }
          }

        }
    }
  }
}
.page-title-left {
  display: flex;
  align-items: center;
}

.email-class {
  .dialog-member-wrap {
    .dialog-member-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: -10px;

      .dialog-member-name {
        font-size: 18px;
        font-weight: bold;

      }

      .dialog-close {
        width: 25px;
        height: 25px;
        background: #E0E0E0;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .el-icon-close {
          color: #FFF;
          font-size: 16px;
        }
      }
    }
  }
}

.page-title-right {
  margin-bottom: -60px;
  z-index: 2;
}

.page-title2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page-product-p {
  font-size: 36px;
  color: #E50000;
  font-weight: bold;

  span {
    font-size: 22px;
  }
}

.page-product {
  width: 1160px;
  height: 86px;
  background: #FFFAF3;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;

  .page-product-left {

    .page-product-p2 {
      font-size: 16px;
      color: #666666;
      margin-top: -10px;
    }
  }

  .page-product-right {
    .page-product-fx {
      width: 152px;
      height: 48px;
      border: 1px solid #FF8033;
      font-size: 18px;
      color: #FF8033;
      background: #FFF;

      &:hover {}
    }


  }

}

.page-productbtn {
  width: 152px;
  height: 48px;
  background: #FF8033;
  border-radius: 4px 4px 4px 4px;
  color: #FFFFFF;
  font-size: 18px;
  border: none;

  &:hover {
    background: #FF8033;
    color: #FFF;
  }
}

.page {
  width: 100%;
  flex: 1;
  background: #F5F6F7;

  .page-content {
    min-height: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 16px;
    padding-bottom: 40px;
    position: relative;
    .page-visa {
      width: 1200px;
      background: #FFF;
      padding: 16px;
      box-sizing: border-box;
      position: relative;

      .el-icon-close {
        position: absolute;
        right: 24px;
        top: 24px;
        font-size: 26px;
        cursor: pointer;
      }

      .page-title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          font-size: 22px;
          font-weight: bold;
        }

        span {
          color: #E50000;
          font-size: 16px;
          font-weight: bold;
        }
      }





      .page-label {
        display: flex;
        align-items: center;
        margin-top: 4px;

        .page-type {
          height: 19px;
          line-height: 19px;
          background: #1890FF;
          padding: 0 9px;
          border-radius: 2px;
          font-size: 12px;
          color: #FFF;
        }

        .page-no {
          margin-left: 10px;
          color: #999999;
          font-size: 12px;
        }
      }

      .page-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 15px;

        .page-info-left {
          display: flex;
          align-items: center;

          .page-info-y {
            width: 1px;
            height: 28px;
            background: #EFEFEF;
            margin: 0 16px;
          }

          .page-info-item {
            display: flex;
            flex-direction: column;

            .el-icon-question {
              cursor: pointer;
              color: #b3aaaa;
            }

            p {
              color: #999999;
              font-size: 12px;
            }

            span {
              font-size: 16px;
              margin-top: 4px;
            }
          }
        }
      }

      .page-bom {
        width: 1160px;
        border-radius: 4px;
        border: 1px solid #C3E2FF;
        background: #F6FBFF;
        margin-top: 12px;
        padding: 12px;
        box-sizing: border-box;

        .page-bom-item {
          padding-bottom: 12px;
          border-bottom: 1px solid #EFEFEF;

          &.page-bom-item2 {
            border-bottom: none;
            padding-bottom: 0;
            margin-top: 12px;
          }

          .page-bom-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 18px;
            margin-bottom: 20px;
            .page-bom-title-l {
              font-size: 12px;
              color: #777;
              cursor: pointer;
              // margin-top: 70px;
            }
          }

          .page-bom-info {
            font-size: 14px;
            color: #777777;
            margin-top: 8px;
            height: 60px;
            overflow: hidden;
            cursor: pointer;
            margin-top: -15px;
          }
        }
      }
    }

    .visa-flex {
      display: flex;
    }

    .page-visa-menu {
      display: flex;
      flex-direction: column;

      .visa-children-2 {
        display: flex;
        flex-direction: column;

        .visa-children-2-item {
          display: flex;
          margin-bottom: 20px;

          .visa-children-left {
            width: 68px;
            background: #1890FF;
            color: #FFF;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            text-align: center;
          }

          .visa-children-right {
            width: 1076px;
            margin-left: 16px;
            border-top: 1px solid #EFEFEF;

            .visa-children-right-item {
              display: flex;
              align-items: center;
              padding: 16px 0;
              border-bottom: 1px solid #EFEFEF;

              .child-left {
                width: 180px;
                margin-right: 20px;
                font-size: 16px;

                span {
                  color: #E60000;
                }
              }

              .child-right {
                display: flex;
                align-items: center;

                span {
                  display: inline-block;
                  width: 744px;
                  font-size: 14px;

                }
              }

            }
          }
        }
      }

      .visa-children {
        width: 100%;
        margin: 0 20px;

        .visa-children-item {
          display: flex;
          margin-bottom: 17px;
          flex-wrap: wrap;

          .child-left {
            width: 144px;
            font-size: 16px;

            span {
              color: #E60000;
            }
          }

          .child-right {
            font-size: 14px;
            padding: 0 20px;
            padding-bottom: 10px;
            border-bottom: 1px solid #EFEFEF;
            flex: 1;
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;

            .child-btn {
              margin-right: 20px;

              &.child-btn2 {
                color: #599fff;
                border: 1px solid #599fff;
                position: relative;

                .child-img {
                  position: absolute;
                  width: 98px;
                  height: 36px;
                  left: 0;
                  top: 0;
                  opacity: 0;


                }
              }
            }
          }
        }
      }

      .page-visa-m-t {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;

        .page-visa-m-text1 {
          font-size: 16px;
        }

        .page-visa-m-text2 {
          margin-left: 10px;
          color: #666666;
          font-size: 14px;

          span {
            color: #E50000;
            font-size: 18px;
          }
        }
      }

    }

    .page-visa-docs {
      width: 1200px;
      background: #FFF;
      padding: 16px;
      box-sizing: border-box;
      margin-top: 24px;

      .dropdown-btn {
        margin-left: 18px;

        .el-dropdown-link {
          color: #66b1ff;
          cursor: pointer;
        }
      }

      .page-title {
        display: flex;
        align-items: center;

        p {
          font-size: 22px;
          font-weight: bold;
        }

      }
    }

    .basic-info {
      width: 1200px;
      background: #FFF;
      padding: 16px;
      box-sizing: border-box;
      margin-top: 24px;

      .basic-info-text {
        font-size: 14px;
      }
    }

    .visa-legend {
      width: 1200px;
      background: #FFF;
      padding: 16px;
      box-sizing: border-box;
      margin-top: 24px;

      .page-timeline {
        margin-top: 20px;

        .time-line-top {
          font-size: 16px;

        }

        .time-line-bom {
          font-size: 12px;
          color: #666;
        }
      }

      .page-title {
        display: flex;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid #EFEFEF;
        padding-bottom: 9px;

        p {
          font-size: 22px;
          font-weight: bold;
        }


      }

      .visa-img {
        width: 600px;
        margin-top: 20px;
        margin-bottom: 20px;

        img {
          width: 398px;
        }
      }
    }

  }
}


.child-btn3 {
  color: #599fff;
  border: 1px solid #599fff;
  cursor: pointer;
}


.zl-input {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  .zl-text {
    font-size: 16px;
    color: #CCCCCC;
    text-align: center;
    margin-bottom: 16px;
  }
  .zl-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .zl-item {
    display: flex;
    align-items: center;
    margin-bottom: 24px;


    .zl-label {
      font-size: 16px;
      font-weight: bold;
      width: 140px;
    }

    .zl-form {
      font-size: 16px;
      display: flex;
      flex-wrap: wrap;

      .zl-item-kk {
        width: 112px;
        height: 38px;
        border: 1px solid #DCDCDC;
        color: #CCCCCC;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 12px;
        margin-bottom: 12px;
        cursor: pointer;
        position: relative;

        &.zl-item-kk-active {
          border: 1px solid #3888E9;
          color: #3888E9;

          &::after {
            content: "";
            width: 24px;
            height: 24px;
            background: url('@/assets/images/case/go.png') no-repeat 50%/100% 100%;
            position: absolute;
            z-index: 5;
            right: 0px;
            bottom: 0px;
          }

        }
      }
    }
  }
}

.page-top-fixed {
  position: fixed;
  width: 1200px;

  background: #FFFFFF;
  z-index: 3;
  left: 50%;
  transform: translateX(-50%);


  box-sizing: border-box;
  transition: all .2s ease-in;
  // top: 99px;
  border-top: 20px solid #F7F8FA;

  .page-top-fixed-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 3px 5px 1px rgba(137, 137, 137, 0.14);
    width: 1200px;
    box-sizing: border-box;
    padding: 0 24px;
    height: 74px;
  }

  .page-top-fixed-left {
    display: flex;
    align-items: center;

    .page-top-fixed-item {
      font-size: 20px;
      color: #999999;
      margin-right: 36px;
      cursor: pointer;

      &.page-top-fixed-item-active {
        font-weight: bold;
        color: #333;
      }
    }
  }

  .page-top-fixed-right {
    width: 350px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}</style>