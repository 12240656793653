<template>
    <div>

        <el-dialog title="" :close-on-click-modal="false" center :visible.sync="isVisible" width="679px"
            :before-close="handleClose" class="dialog-wrap">
            <div class="page-info">
                <div class="page-left">
                    <p class="page-title">微信小程序，立即办理！</p>
                    <div class="page-content">
                        <img :src="qrImg" alt="" >
                    </div>
                    <div class="page-desc">
                        <span>免费拍摄签证照，护照顺丰包邮</span>
                        <span>资料扫描自动填表，一次提供永久使用</span>
                    </div>
                </div>
                <div class="page-rihgt">
                    <img :src="require('@/assets/images/product/phone.png')" alt="">
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    inject: ['pageReload'],
    data() {
        return {
    
            isVisible: this.config,
        }
    },
    props: {
        config: {
            type: Boolean,
            default: false,
        },
        qrImg:{
           type:String,
        }
    },
    methods: {
        handleClose() {
            // this.dialogVisible = false;   
            // 清空数据
            // this.ruleForm.checkItem = ''
            // 传递关闭事件给父组件 
            this.$emit('close')
        },
  
    }
}
</script>


<style lang="scss" scoped>
    .page-info {
        display: flex;
        align-items: center;
        justify-content: center ;
        .page-left {
            display: flex;
            flex-direction: column;
            align-items: center;
            .page-title {
                font-size: 22px;
                font-weight: bold;
            }
            .page-content {
                width: 192px;
                height: 192px;
                margin: 24px 0;
                background: #D8D8D8;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .page-desc {
                display: flex;
                flex-direction: column;
                align-items: center;
                span {
                    color: #777;
                    margin-bottom: 8px;
                }
            }
        }

        .page-rihgt {
            width: 192px;
            height: 398px;
            margin-left: 72px;
            img {
                width: 100%;
                height: 100%;
            }
        }
            
    }
</style>

<style lang="scss">
.login-input {
    .el-input__inner {
        height: 50px !important;

    }
}
</style>