<!-- 账号管理列表 -->
<template>
  <div class="wrap">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="账号管理" name="first"></el-tab-pane>
      <el-tab-pane label="权限管理" name="second"></el-tab-pane>
    </el-tabs>
    <div v-if="activeName == 'first'" class="tab">
      <div class="box-input">
        <div class="box-input-left">
          <el-select clearable v-model="accountForm.roleId" @change="getAccountData" placeholder="请选择权限">
            <el-option v-for="item in roleList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
          <el-input v-model="accountForm.name" style="width: 194px;margin-right: 12px;" placeholder="请输入姓名"></el-input>
          <el-input v-model="accountForm.phone" style="width: 194px;margin-right: 12px;" placeholder="请输入手机号"></el-input>
        </div>

        <div class="box-input-btn">
          <el-button class="default" @click="getAccountData">搜索</el-button>
          <el-button class="box-input-btn-cz" @click="resetAccount">重置</el-button>
          <el-button class="box-input-btn-add1" @click="accountDialog = true">添加子账号</el-button>
        </div>
      </div>
      <div class="box-table">
        <el-table :data="accountList"
          :header-cell-style="{ backgroundColor: '#F2F4F7', color: '#333', fontWeight: 'bold', textAlign: 'center', fontSize: '12px' }"
          style="width: 100%" v-loading="loading" :height="$store.state.product.screen ? '820px' : '470px'"
          :cell-style="{ 'text-align': 'center' }" ref="refTable1">
          <el-table-column prop="account" label="登录账号">
          </el-table-column>
          <el-table-column prop="name" label="姓名">
          </el-table-column>
          <el-table-column prop="phone" label="手机号">
          </el-table-column>
          <el-table-column prop="typeName" label="类型">
            <template slot-scope="scope">
              <div class="type-btn" :style="{ background: scope.row.color }">{{ scope.row.typeName }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="marks" label="备注">
          </el-table-column>
          <el-table-column label="操作" header-align="center">
            <template slot-scope="scope">
              <div class="edit-wrap">
                <div class="edit-item">
                  <span class="lan" @click="edit(scope.row)">编辑</span>
                  <span class="del" @click="handleDel(scope.row.id)">删除</span>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div v-if="activeName == 'second'" class="tab">
      <div class="box-input">
        <div class="box-input-left">
          <el-input v-model="authorityForm.name" style="width: 671px;margin-right: 12px;"
            placeholder="请输入角色名称"></el-input>
        </div>
        <div class="box-input-btn">
          <el-button class="default" @click="getAuthorityData">搜索</el-button>
          <el-button class="box-input-btn-cz" @click="resetAuthority">重置</el-button>
          <el-button class="box-input-btn-add1" @click="authorityDialog = true">添加角色</el-button>
        </div>
      </div>
      <div class="box-table" v-if="activeName == 'second'">
        <el-table :data="authorityList"
          :header-cell-style="{ backgroundColor: '#F2F4F7', color: '#333', fontWeight: 'bold', textAlign: 'center', fontSize: '12px' }"
          style="width: 100%" v-loading="loading" :height="$store.state.product.screen ? '820px' : '470px'"
          :cell-style="{ 'text-align': 'center' }" ref="refTable2">
          <el-table-column type="index" width="50" label="#">
          </el-table-column>
          <el-table-column prop="name" label="角色名称">
          </el-table-column>
          <el-table-column prop="mark" label="角色备注">
          </el-table-column>
          <el-table-column prop="createdTime" label="创建时间">
          </el-table-column>
          <el-table-column label="操作" header-align="center">
            <template slot-scope="scope">
              <div class="edit-wrap">
                <div class="edit-item">
                  <span class="lan" @click="editAuthority(scope.row.id)">查看</span>
                  <span class="del" @click="handleDelAuthority(scope.row.id)">删除</span>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>


    <div class="table-pagination">
      <el-pagination @current-change="currentChange" :current-page="pageIndex" :page-size="10"
        layout="total, prev, pager, next, jumper" :total="pageTotal">
      </el-pagination>
    </div>
    <!-- 添加/编辑账号 -->
    <el-dialog :visible.sync="accountDialog" width="470px" class="common-dialog-class" :close-on-click-modal="false"
      @close="resetForm('accountDialogForm')">
      <div class="dialog-member-wrap">
        <div class="dialog-member-title">
          <span class="dialog-member-name">{{ accountDialogForm.id ? '编辑子账号' : '添加子账号' }} </span>
          <div class="dialog-close" @click="resetForm('accountDialogForm')">
            <i class="el-icon-close"></i>
          </div>
        </div>
        <div class="dialog-form-wrap">
          <el-form :model="accountDialogForm" :rules="rules" ref="accountDialogForm" label-width="80px" class="demo-form">
            <el-form-item label="登录账号" prop="account">
              <el-input v-model="accountDialogForm.account" placeholder="请输入可接受验证码的手机号"></el-input>
            </el-form-item>
            <el-form-item label="姓名" prop="name">
              <el-input v-model="accountDialogForm.name" placeholder="请输入联系人姓名"></el-input>
            </el-form-item>
            <el-form-item label="登录密码" prop="password">
              <el-input v-model="accountDialogForm.password" placeholder="请输入登录密码"></el-input>
            </el-form-item>
            <el-form-item label="手机号码" prop="phone">
              <el-input v-model="accountDialogForm.phone" placeholder="请输入手机号码"></el-input>
            </el-form-item>

            <el-form-item label="备注" prop="marks">
              <el-input v-model="accountDialogForm.marks" type="textarea" placeholder="备注" resize="none"></el-input>
            </el-form-item>
          </el-form>
          <div class="permit">
            <p>选择订单操作权限</p>
            <el-radio-group v-model="accountDialogForm.orgRoleId">
              <el-radio :label="item.id" v-for="(item, index) in roleList" :key="index">{{ item.name }}</el-radio>
            </el-radio-group>
          </div>
          <div class="common-dialog-foot-class" style="margin-top: 0">
            <el-button class="common-btn" @click="resetForm('accountDialogForm')">取消</el-button>
            <el-button class="common-btn" type="primary" @click="submitAccountForm('accountDialogForm')">确认</el-button>
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 添加/编辑权限 -->
    <el-dialog :visible.sync="authorityDialog" width="570px" class="common-dialog-class" :close-on-click-modal="false"
      @close="resetForm('authorityDialogForm')" @open="getOrgMenus">
      <div class="dialog-member-wrap">
        <div class="dialog-member-title">
          <span class="dialog-member-name">{{ authorityDialogForm.id ? '查看角色' : '添加角色' }} </span>
          <div class="dialog-close" @click="resetForm('authorityDialogForm')">
            <i class="el-icon-close"></i>
          </div>
        </div>
        <div class="dialog-form-wrap auth-wrap">
          <el-form :model="authorityDialogForm" :rules="rules1" ref="authorityDialogForm" label-width="80px"
            class="demo-form auth-form">
            <el-row :gutter="24">
              <el-col :span="12">
                <el-form-item label="角色名称" prop="name">
                  <el-input v-model="authorityDialogForm.name" placeholder="请填写角色名称"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="角色备注" prop="mark">
                  <el-input v-model="authorityDialogForm.mark" placeholder="请填写角色备注"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <div class="permit">
            <p>菜单权限</p>
            <el-tree :data="treeData" ref="roleTree" show-checkbox node-key="id" default-expand-all
              @check="handleTreeCheck" :default-checked-keys="authorityDialogForm.menuIds" :props="defaultProps">
            </el-tree>
          </div>
          <div class="permit">
            <p>按钮权限</p>
            <el-checkbox-group v-model="authorityDialogForm.buttonIds">
              <el-checkbox :label="item.id" v-for="(item, index) in buttonsList" :key="index">{{ item.name
              }}</el-checkbox>
            </el-checkbox-group>
          </div>
          <div class="permit">
            <p>数据权限</p>
            <el-radio-group v-model="authorityDialogForm.dataPermission">
              <el-radio :label="true">全部用户数据</el-radio>
              <el-radio :label="false">部分用户数据</el-radio>
            </el-radio-group>
          </div>
          <div class="common-dialog-foot-class" style="margin-top: 0">
            <el-button class="common-btn" @click="resetForm('authorityDialogForm')">取消</el-button>
            <el-button class="common-btn" type="primary"
              @click="submitAuthorityForm('authorityDialogForm')">确认</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { queryorgroles, queryorgaccounts, addorgaccount, updateorgaccount, delorgaccount, seachorgmenus, queryorgrolesbyroleid, addorgroles, delorgrole } from '@/api/settings.js'

export default {
  data() {
    return {
      accountList: [],
      accountForm: {
        roleId: '',
        name: '',
        phone: ''
      },
      pageIndex: 1,
      pageTotal: 0,
      loading: false,
      activeName: 'first',
      accountDialog: false,
      accountDialogForm: {
        account: '',
        password: '',
        name: '',
        phone: '',
        marks: '',
        orgRoleId: '',
      },
      rules: {
        account: [
          { required: true, message: '请输入可接受验证码的手机号', trigger: ['click', 'change', 'blur'] },
        ],
        name: [
          { required: true, message: '请输入联系人姓名', trigger: ['click', 'change', 'blur'] },
        ],
        password: [
          { required: true, message: '请输入登录密码', trigger: ['click', 'change', 'blur'] },
        ],
        phone: [
          { required: true, message: '请输入手机号码', trigger: ['click', 'change', 'blur'] },
        ],
      },
      rules1: {
        name: [
          { required: true, message: '请输入角色名称', trigger: ['click', 'change', 'blur'] },
        ],
        mark: [
          { required: true, message: '请输入角色备注', trigger: ['click', 'change', 'blur'] },
        ],
      },
      roleList: [],
      colorOptions: ['#FF2519', '#337FFF', '#FF8033', '#08D38F'],
      authorityForm: {
        name: ''
      },
      authorityDialog: false,
      authorityDialogForm: {
        name: '',
        mark: '',
        dataPermission: true,
        buttonIds: [],
        menuIds: []
      },
      authorityList: [],
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      buttonsList: []
    }
  },
  porps: {
  },
  components: {
  },
  mounted() {
    this.getRoles()
  },
  methods: {
    handleClick(tab, event) {
      this.pageIndex = 1
      if (tab.label == "账号管理") {
        this.getAccountData()
        this.$nextTick(() => {
          console.log(this.$refs.refTable1)
          this.$refs.refTable1.doLayout()
        })
      } else {
        this.getAuthorityData()
        this.$nextTick(() => {
          console.log(this.$refs.refTable2)
          this.$refs.refTable2.doLayout()
        })
      }
    },
    edit(row) {
      this.accountDialog = true
      this.$nextTick(() => {
        this.accountDialogForm = JSON.parse(JSON.stringify(row))
      })
    },
    handleDel(id) {
      this.$confirm(`是否要删除该账号`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delorgaccount({ id }).then(res => {
          this.$message.success('删除成功');
          this.getAccountData();
        }).catch(res => {

        })
      }).catch(res => {

      })
    },
    resetForm(formName) {
      this.accountDialog = false
      this.authorityDialog = false
      this.$refs[formName].resetFields();
      this.accountDialogForm.id = ''
      this.authorityDialogForm.buttonIds = []
      this.authorityDialogForm.menuIds = []
    },
    getRoles() {
      queryorgroles({ 'Page.Index': 1, 'Page.Size': 1000 }).then(res => {
        this.roleList = res.data.rows.map((v, idx) => ({ ...v, color: this.colorOptions[idx] }))
        this.getAccountData()
      })
    },
    //获取账号管理数据
    getAccountData() {
      this.loading = true;
      this.accountForm['Page.Index'] = this.pageIndex
      this.accountForm['Page.Size'] = 10
      queryorgaccounts(this.accountForm).then(res => {
        this.loading = false;
        if (res.code == 1) {
          this.accountList = res.data.rows
          this.pageTotal = res.data.total
          this.accountList.forEach(v => {
            this.roleList.forEach(e => {
              if (v.orgRoleId == e.id) {
                this.$set(v, 'typeName', e.name)
                this.$set(v, 'color', e.color ? e.color : '#EBEEF5')
              }
            })
          })
        } else {
          this.$message.error(rea.msg)
        }
      })
    },
    resetAccount() {
      this.accountForm = {
        roleId: '',
        name: '',
        phone: ''
      }
      this.getAccountData();
    },
    submitAccountForm(formName) {
      if (!this.accountDialogForm.orgRoleId) {
        return this.$message.warning('权限未选择！')
      }
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (!this.accountDialogForm.id) {//添加账号
            addorgaccount(this.accountDialogForm).then(res => {
              if (res.code == 1) {
                this.$message.success('添加成功！')
                this.accountDialog = false
                this.getAccountData()
              } else {
                this.$message.error(res.msg)
              }
            })
          } else {//编辑账号
            updateorgaccount(this.accountDialogForm).then(res => {
              if (res.code == 1) {
                this.$message.success('编辑成功！')
                this.accountDialog = false
                this.getAccountData()
              } else {
                this.$message.error(res.msg)
              }
            })
          }
        } else {
          return false
        }
      })
    },
    //获取权限管理数据
    getAuthorityData() {
      this.loading = true;
      this.authorityForm['Page.Index'] = this.pageIndex
      this.authorityForm['Page.Size'] = 10
      queryorgroles(this.authorityForm).then(res => {
        this.loading = false;
        if (res.code == 1) {
          this.authorityList = res.data.rows
          this.pageTotal = res.data.total
        } else {
          this.$message.error(rea.msg)
        }
      })
    },
    resetAuthority() {
      this.authorityForm.name = '';
      this.getAuthorityData()
    },
    editAuthority(roleId) {
      queryorgrolesbyroleid({ roleId }).then(res => {
        this.authorityDialog = true
        this.$nextTick(() => {
          this.authorityDialogForm = JSON.parse(JSON.stringify(res.data))
        })
      })
    },
    handleDelAuthority(orgRoleId) {
      this.$confirm(`是否要删除该权限`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delorgrole({ orgRoleId }).then(res => {
          this.$message.success('删除成功');
          this.getAuthorityData();
        }).catch(res => {

        })
      }).catch(res => {

      })
    },
    handleTreeCheck(val, node) {
      this.nodeChecked = node.checkedKeys.concat(node.halfCheckedKeys)
    },
    submitAuthorityForm(formName) {
      let nodeChecked = this.$refs.roleTree.getCheckedKeys().concat(this.$refs.roleTree.getHalfCheckedKeys())
      if (!nodeChecked.length) {
        return this.$message.warning('菜单权限未选择！')
      }
      this.$refs[formName].validate(valid => {
        if (valid) {
          let reqData = {
            Id: this.authorityDialogForm.id || '',
            Name: this.authorityDialogForm.name,
            Mark: this.authorityDialogForm.mark,
            MenuIds: nodeChecked,
            ButtonIds: this.authorityDialogForm.buttonIds,
            DataPermission: this.authorityDialogForm.dataPermission,
          }
          addorgroles(reqData).then(res => {
            if (res.code == 1) {
              if (this.authorityDialogForm.id) {
                this.$message.success('修改成功！')
              } else {
                this.$message.success('添加成功！')
              }
              this.authorityDialog = false
              this.getAuthorityData()
            } else {
              this.$message.error(res.msg)
            }
          })
        } else {
          return false
        }
      })
    },
    //获取机构下拥有的菜单和按钮数据
    getOrgMenus() {
      seachorgmenus().then(res => {
        this.treeData = res.data.orgMenus
        this.buttonsList = res.data.orgButtons
      })
    },
    currentChange(val) {
      this.pageIndex = val;
      this.$refs.refTable.bodyWrapper.scrollTop = 0;
      if (this.activeName == 'first') {
        this.getAccountData()
      } else {
        this.getAuthorityData()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  width: 953px;

  ::v-deep .el-table__row {
    .cell {
      font-size: 12px;
    }
  }


  .tab {
    height: calc(100% - 100px);
  }

  .box-table {
    height: calc(100% - 100px);
  }

  .box-top {
    width: 593px;
    height: 81px;
    background: #FFFFFF;
    box-shadow: 0px 3px 12px 1px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 5px 10px;

    .top-item {
      width: 30%;

      p {
        color: #666;
        font-size: 12px;
      }

      .balance {
        color: #333333;
        font-size: 20px;
        font-weight: bold;
      }

      .iconfont {
        vertical-align: middle;
        margin-right: 5px;
      }
    }
  }
}

.box-input-left {
  display: flex;

  ::v-deep .el-select {
    margin-right: 5px;
  }

  ::v-deep .el-date-editor {
    margin-right: 5px;
    width: 240px;

    .el-range-input {
      background: transparent;
    }

    .el-range__close-icon {
      width: 10px;
    }
  }
}

.edit-wrap {

  .edit-item {
    // padding-left: 20px;


    span {
      cursor: pointer;
      display: inline-block;
      margin: 6px;

      &.lan {
        color: #3888E9;
      }

      &.copy {
        color: #9EA09E;
      }

      &.del {
        color: #E41313;
      }
    }
  }
}

.table-pagination {
  text-align: center;
  margin-top: 16px;
}

.box-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;

  ::v-deep .el-input__inner {
    background: #F2F4F7;
    border: none;
  }

  ::v-deep .el-select,
  .el-input {
    margin-right: 8px;
  }

  .box-input-btn {
    text-align: right;

    .box-input-btn-cz {
      background: #EAF3FF;
      color: #3888E9;
      border: none;
    }

    .box-input-btn-add1 {
      background: #FFFFFF;
      border-radius: 8px;
      border: 1px solid #3888E9;
      color: #3888E9;
    }

    .box-input-btn-add {
      background: #D8F5E4;
      color: #54D8A5;
      border: none;
    }
  }
}

.type-btn {
  color: #fff;
  line-height: 20px;
  height: 20px;
  text-align: center;
  padding: 0px 8px;
  display: inline-block;
  border-radius: 4px;
}

.permit {
  margin-bottom: 20px;

  p {
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 18px;
    color: #606266;
  }
}

.auth-wrap {
  .auth-form {
    background: #fff;
    height: 42px;
    border-radius: 5px;
    padding: 6px;
  }

  ::v-deep .el-tree {
    background: transparent;

    .el-icon-caret-right {
      &::before {
        display: none;
      }
    }

    .el-tree-node__children {
      display: flex;
      flex-wrap: wrap;

      .el-tree-node {
        display: inline-block;
      }
    }

  }
}
</style>