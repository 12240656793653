<template>
    <div class="page place">
        <div class="header">
            <div class="header-content">
                <div class="header-left">

                    <div class="header-logo" @click="toHome">
                        <img class="logo-img" :src="require('@/assets/images/header/logo.png')" />

                    </div>

                    <div class="place-title">确认订单</div>
                </div>

                <div class="steps">
                    <div class="steps-x"></div>
                    <div class="steps-item" @click="toStup(index)" :class="[active == index ? 'steps-active' : '']"
                        v-for="(item, index) in menuList" :key="index">
                        <p>{{ item.value }}</p>
                        <span>{{ item.name }}</span>
                    </div>
                </div>

                <div class="steps-right">
                    <div class="steps-r-left" @click="toPath(1)">
                        <el-avatar icon="el-icon-user-solid" :src="userInfo.avatar" size="small"></el-avatar>
                        <span class="steps-name">{{ userInfo.nickName }}</span>
                    </div>
                    <div class="steps-y"></div>
                    <div class="steps-r-right" @click="toPath(2)">
                        <span>我的订单</span>
                        <i class="iconfont icon-tuichu"></i>
                    </div>
                </div>
            </div>
        </div>


        <div class="place-content">
            <div class="place-wrap">
                <div class="place-title">
                    <p class="place-title-text">订单回寄</p>
                    <p class="place-title-label">包邮</p>
                    <p class="place-title-desc">出签后护照、签证页等资料将邮寄至该地址</p>
                </div>
                <div class="place-box">
                    <div class="address-list" v-if="addressDefault.length > 0">
                        <div class="address-item" v-for="(item, index) in addressDefault" :key="index">
                            <div class="address-item-top">
                                <p>{{ item.name }}</p>
                                <span>{{ item.phone }}</span>
                            </div>
                            <div class="address-center">
                                {{ item.province + item.city + item.district + item.address }}
                            </div>
                            <div class="address-email">
                                {{ item.email }}
                            </div>

                            <div class="address-is-default" v-if="item.isDefault">已选择</div>
                        </div>
                    </div>
                    <div class="address-add" @click="toAddress">
                        <img src="https://file.sport-syy.com/visa/admin/images/pc/dz.png" alt="">
                        <span>使用其他地址</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="place-content">
            <div class="place-wrap">
                <div class="place-title place-title2">
                    <span class="title-name">出行信息</span>
                    <div class="title-out-date" @click.stop="openCheck">
                        <span>预计出行时间</span>
                        <p>{{ dateInfo.str ? dateInfo.str : dateInfo.date ? dateInfo.date : '请选择' }} <i
                                class="el-icon-arrow-down"></i></p>
                        <dateSelect ref="dateSelect"
                            :processingDay="orderDetail.visaDetail && orderDetail.visaDetail.visa && orderDetail.visaDetail.visa.processingDay"
                            @setDate="setDate" :dateInfo="dateInfo" v-model="inputShow"
                            :countryId="orderDetail.order.countryId"></dateSelect>
                    </div>
                </div>

                <div class="place-user-info">
                    <div class="place-user">
                        <img :src="orderDetail.visaDetail && orderDetail.visaDetail.visa && orderDetail.visaDetail.visa.nationalFlagImg"
                            alt="">
                        <span> 【{{ orderDetail.visaDetail && orderDetail.visaDetail.visa &&
                            orderDetail.visaDetail.visa.areaName }}】</span>
                        <span>{{ orderDetail.visaDetail && orderDetail.visaDetail.visa && orderDetail.visaDetail.visa.title
                        }}</span>
                    </div>
                    <div class="place-user-right">
                        ￥{{ orderDetail.visaDetail && orderDetail.visaDetail.visa && orderDetail.visaDetail.visa.price4 }}/人
                    </div>
                </div>
                <div class="place-user-list">
                    <div class="place-user-item" v-for="(item, index) in orderDetail.orderApplicants" :key="index">
                        <div class="place-user-item-left">
                            <i v-if="orderDetail.orderApplicants.length > 1" class="el-icon-delete"
                                @click.stop="delUser(index)"></i>
                            <span class="place-appName">{{ item.applicantName }}</span>
                            <span class="place-appName-type">{{ item.identityTypeName }}</span>

                        </div>

                        <div class="place-user-item-right" @click="toDetail(item)">查看资料<i class="el-icon-arrow-right"></i>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="place-content">
            <div class="place-wrap">
                <div class="place-title">
                    <p class="place-title-text">订单联系人</p>
                    <p class="place-title-desc">审核资料时，如需补交或修改资料时将联系此号码</p>
                </div>
                <div class="place-input">
                    <div class="place-input-top">
                        <el-input v-model="user.name" placeholder="联系人" class="place-input-name"></el-input>
                        <el-input v-model="user.phone" class="place-input-phone" placeholder="联系手机"></el-input>
                    </div>
                    <div class="place-input-bom">
                        <el-input v-model="user.bz" placeholder="如有特殊要求，请备注"></el-input>
                    </div>
                </div>
            </div>

        </div>

        <div class="place-content">
            <div class="place-wrap" style="padding-bottom: 0;">
                <div class="place-title">
                    <p class="place-title-text"><el-checkbox class="checkbox-k" v-model="couponChecked"></el-checkbox>优惠券
                    </p>
                    <p class="place-title-desc">仅限非特价商品使用</p>
                </div>
                <div class="quan-list" v-if="quanList.length > 0">

                    <div class="quan-item" :class="[quanInfo.id == elem.id ? 'quan-item-active' : '']"
                        v-for="(elem, idx) in quanList" :key="idx" @click="checkQuan(elem)">
                        <p class="quan-item-left">
                            <span>￥</span>
                            {{ elem.amount }}
                        </p>

                        <div class="quan-item-right">
                            <p>{{ elem.fullAmount }}</p>
                            <span class="quan-item-desc">{{ elem.desc }}</span>
                            <span class="quan-item-desc2">{{ elem.startTime }}至{{ elem.expiredTime }}</span>
                        </div>

                        <img class="quan-r-icon" v-if="elem.status == 1" :src="require('@/assets/images/personal/yong.png')"
                            alt="">
                        <img class="quan-r-icon" v-if="elem.status == -1" :src="require('@/assets/images/personal/qi.png')"
                            alt="">
                    </div>

                    <!-- <el-empty class="quan-empty" :description="`暂无${item.name}优惠券`" v-if="item.arr.length == 0">
                    </el-empty> -->


                </div>
            </div>

        </div>


        <div class="play-wrap place-content">
            <div class="place-content-left">
                <img style="width: 178px;height: 168px" src="https://file.sport-syy.com/visa/admin/images/pc/place.png"
                    alt="">
                <div class="my-price">
                    <span>￥{{ $store.state.balance }}</span>
                    <span @click="isVisible = true">前往充值<i class="el-icon-arrow-right"></i></span>
                </div>
            </div>

            <div class="place-play-right">
                <p class="place-quan"><span>优惠券</span><span>-{{ quanInfo.amount }}</span></p>
                <p class="place-price"><span>应付金额</span><span>￥{{ sumPrice }}</span></p>
                <div class="place-play-x"></div>
                <el-button class="default" style="width: 279px;" @click="play">立即支付</el-button>
            </div>
        </div>

        <!-- 选择地址 -->
        <el-dialog :visible.sync="dialogMember" width="596px" class="address-order-class" :close-on-click-modal="false">
            <div class="dialog-member-wrap">
                <div class="dialog-member-title">
                    <span class="dialog-member-name">选择门店地址</span>
                    <div class="dialog-close" @click="dialogMember = false;">
                        <i class="el-icon-close"></i>
                    </div>
                </div>
                <div class="address-dialog">
                    <div class="address-dialog-item" @click="isDefault = item.id"
                        :class="[isDefault == item.id ? 'address-active' : '']" v-for="(item, index) in address"
                        :key="index">
                        <div class="address-dialog-item-top">
                            <p>{{ item.name }}</p>
                            <p>{{ item.phone }}</p>
                            <p>{{ item.email }}</p>
                        </div>
                        <div class="address-dialog-bom"> {{ item.province + item.city + item.district + item.address }}
                        </div>
                    </div>

                    <div class="address-dialog-btn">
                        <el-button style="width: 163px;margin: 0 15px;color: #3888E9;border: 1px solid #3888E9"
                            @click="dialogAddress = true">新增地址</el-button>
                        <el-button style="width: 163px;margin: 0 15px;" class="default" @click="addressBtn">确认</el-button>
                    </div>
                </div>
            </div>
        </el-dialog>


        <!-- 重要提示 -->
        <el-dialog :visible.sync="noDateShow" width="396px" class="address-nodate-class" :close-on-click-modal="false">
            <div class="dialog-member-wrap">
                <div class="dialog-member-title">
                    <span class="dialog-member-name">重要提示</span>
                </div>
                <div class="dialog-content">
                    <p>您的出行日期过早，在规定的办理时长内无法出签，请更改出行日期!</p>
                </div>
                <div class="address-dialog-btn">
                    <el-button style="width: 163px;margin: 0 15px;color: #3888E9;border: 1px solid #3888E9"
                        @click="gotoPath">取消</el-button>
                    <el-button style="width: 163px;margin: 0 15px;" class="default" @click="selectDate">更改日期</el-button>
                </div>
            </div>
        </el-dialog>
        <addAddres :config="dialogAddress" v-if="dialogAddress" :item="{}" @success="addresFormUpdate"
            @close="dialogAddress = false"></addAddres>

        <recharge :config="isVisible" v-if="isVisible" @close="isVisible = false"
            @initData="isVisible = false; getbalanceFn()"></recharge>
    </div>
</template>
  
<script>
import { orderdetail, upadateorderapplicants, confirmorder, getbalance, getordercoupon, balancepayment } from '@/api/order.js'
import { queryaddress, setdefaultaddress, queryusercoupon } from '@/api/personal.js'
import recharge from '@/components/recharge.vue';
import addAddres from '@/components/addAddres.vue';
import { getNowDate } from '@/utils/dateUtils.js';
import dateSelect from '@/components/date.vue'
import { queryembassy } from '@/api/product.js'
import { timeFormat } from '@/utils/dateUtils.js';
export default {
    data() {
        return {
            id: '',
            orderDetail: {
                order: {},
                orderApplicants: [],
                visaDetail: {
                    basicInfo: [],
                    offlineVisaDocs: [],
                    visa: {},
                    visaDocs: [],
                },
            },

            user: {
                name: '',
                phone: '',
                bz: '',
            },
            userInfo: {
                id: ''
            },
            dateInfo: {
                date: '',
            },
            quanInfo: {
                id: ''
            },
            quanList: [],
            address: [],
            holidayDate: [],
            addressDefault: [],
            addresForm: {},
            dateArray: [],
            active: 2,
            isDefault: 0,
            isFg: false,
            dialogMember: false,
            isVisible: false,
            dialogAddress: false,
            inputShow: false,
            noDateShow: false,
            menuList: [
                {
                    value: 1,
                    name: '资料上传'
                },
                {
                    value: 2,
                    name: '赴馆日期'
                },
                {
                    value: 3,
                    name: '确认订单'
                },
            ],
            visaDocs: [],
            dateArr: [],
            orderPlace: {
                orderId: '',
                centerIds: '',
                userProvince: '',
                userCity: '',
                userDistrict: '',
                userAddress: '',
                userName: '',
                userPhone: '',
                emailAddress: '',
                marks: '',
                orderUserName: '',
                orderUserPhone: '',
                num: '',
                price: '',
                amount: '',
                payableAmount: '',
                couponAmount: '',
                useCoupon: '',
                couponId: '',
                EmbassyDate: '',
                DepartureTime: '',
                unionPayType: 9,
            },
            allPrice: 0,
            myPrice: 0,
            couponChecked: false
        }
    },

    mounted() {
        this.id = this.$router.history.current.params.id
        this.userInfo = this.$store.state.userInfo
        this.getbalanceFn();
        this.getQueryaddress();
        this.getOrderdetail();

    },
    components: {
        recharge,
        dateSelect,
        addAddres
    },
    computed: {
        // checked() {
        //     if (this.quanInfo.id) {
        //         return true;
        //     } else {
        //         return false;
        //     }
        // },
        sumPrice() {


            if (this.quanInfo.id) {
                console.log(parseFloat(this.allPrice) < parseFloat(this.quanInfo.amount, this.allPrice, this.quanInfo.amount))


                if (parseFloat(this.allPrice) < parseFloat(this.quanInfo.amount)) {
                    console.log(123213213213)
                    this.$message({
                        message: '优惠券不能超过实付金额！',
                        type: 'warning'
                    });


                    return this.allPrice;
                } else {

                    return parseFloat(this.allPrice) - parseFloat(this.quanInfo.amount)
                }


            } else {
                return this.allPrice
            }
        }
    },
    methods: {

        play() {
            let vm = this;

            if (vm.orderDetail.order.status == 0) {
                if (vm.addressDefault.length == 0) {
                    this.$message({
                        message: '请选择默认收货地址！',
                        type: 'warning'
                    });

                    return;
                }

                if (!vm.dateInfo.date) {
                    this.noDateShow = true;
                    // this.$message({
                    //     message: '请选择预计出行日期！',
                    //     type: 'warning'
                    // }, () => {


                    // });
                    return;
                }

                if (!vm.user.name) {
                    this.$message({
                        message: '请填写订单联系人姓名！',
                        type: 'warning'
                    });

                    return;
                }

                if (!vm.user.phone) {
                    this.$message({
                        message: '请填写订单联系人手机号！',
                        type: 'warning'
                    });

                    return;
                }

                let reg = /^[1][1,2,3,4,5,7,8,9][0-9]{9}$/;		//	手机

                if (!reg.test(vm.user.phone)) {
                    this.$message({
                        message: '订单联系人手机号格式不正确！',
                        type: 'warning'
                    });
                    return
                }

                confirmorder({
                    token: vm.$store.state.token,
                    orderId: vm.id,
                    centerIds: vm.orderDetail.order.centerIds,
                    userProvince: vm.addressDefault[0].province,
                    userCity: vm.addressDefault[0].city,
                    userDistrict: vm.addressDefault[0].district,
                    userAddress: vm.addressDefault[0].address,
                    userName: vm.addressDefault[0].name,
                    userPhone: vm.addressDefault[0].phone,
                    emailAddress: vm.addressDefault[0].email,
                    orderUserName: vm.user.name,
                    marks: vm.user.bz,
                    orderUserPhone: vm.user.phone,
                    num: vm.orderDetail.orderApplicants.length,
                    price: vm.orderDetail.visaDetail && vm.orderDetail.visaDetail.visa && vm.orderDetail.visaDetail.visa.price4,
                    amount: parseFloat(vm.orderDetail.visaDetail && vm.orderDetail.visaDetail.visa && vm.orderDetail.visaDetail.visa.price4 * vm.orderDetail.orderApplicants.length),
                    payableAmount: vm.sumPrice,
                    useCoupon: vm.couponChecked,
                    couponAmount: vm.couponChecked && vm.quanInfo.amount ? vm.quanInfo.amount : '',
                    couponId: vm.couponChecked && vm.quanInfo.id ? vm.quanInfo.id : '',
                    EmbassyDate: vm.orderDetail.order.embassyDate,
                    DepartureTime: vm.dateInfo.date,
                    unionPayType: 99,
                    // 
                }).then(res => {
                    console.log(res);


                    if (res.code == 1) {
                        this.$router.push({ name: 'order' })

                        this.$message({
                            message: '支付成功！',
                            type: 'success'
                        });
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'warning'
                        });
                    }

                })
            } else if (vm.orderDetail.order.status == 2) {
                balancepayment({ orderId: vm.id }).then(res => {
                    console.log(res);


                    if (res.code == 1) {
                        this.$router.push({ name: 'order' })

                        this.$message({
                            message: '支付成功！',
                            type: 'success'
                        });
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'warning'
                        });
                    }

                })
            } else {
                this.$message({
                    message: "此订单为不可支付状态",
                    type: 'warning'
                });
            }

        },
        getOrderdetail() {
            let vm = this;
            orderdetail({
                Id: vm.id,
                token: vm.$store.state.token
            }).then(res => {
                vm.orderDetail = res.data;
                vm.isFg = vm.orderDetail.visaDetail && vm.orderDetail.visaDetail.visa && vm.orderDetail.visaDetail.visa.needReservation;

                if (vm.isFg) {


                    vm.menuList = [
                        {
                            value: 1,
                            name: '资料上传'
                        },
                        {
                            value: 2,
                            name: '赴馆日期'
                        },
                        {
                            value: 3,
                            name: '确认订单'
                        },
                    ]
                    this.active = 2
                    // if (this.orderDetail.order.embassyDate) {

                    //     this.embassyDateList.embassyDate = this.orderDetail.order.embassyDate
                    // }

                    // if (this.orderDetail.order.embassys) {
                    //     this.embassyDateList.centerIds = JSON.parse(this.orderDetail.order.embassys)[0]
                    // }



                } else {
                    vm.menuList = [
                        {
                            value: 1,
                            name: '资料上传'
                        },

                        {
                            value: 2,
                            name: '确认订单'
                        },
                    ]
                    this.active = 1
                }

                // this.orderDetail.orderApplicants[0].taskTable =  this.orderDetail.orderApplicants[0].taskTable * 0.01;
                console.log(vm.orderDetail.orderApplicants[0].taskTable)




                vm.visaDocs = vm.orderDetail.visaDetail.visaDocs
                vm.user.name = vm.orderDetail.order.contacts
                vm.user.phone = vm.orderDetail.order.contactPhone
                vm.allPrice = parseFloat(vm.orderDetail.visaDetail && vm.orderDetail.visaDetail.visa && vm.orderDetail.visaDetail.visa.price4 * vm.orderDetail.orderApplicants.length)

                vm.dateInfo.date = vm.orderDetail.order && vm.orderDetail.order.departureTime && vm.orderDetail.order.departureTime.split(' ')[0]
                vm.dateInfo.str = getNowDate(vm.dateInfo.date)

                vm.queryembassyFn();
                vm.dates();
                vm.getQueryusercoupon();

            })
        },
        queryembassyFn() {

            console.log(this.orderDetail);
            queryembassy({
                countryId: this.orderDetail.order.countryId
            }).then(res => {
                console.log(res);
                this.holidayDate = res.data;
            })
        },

        gotoPath() {
            this.$router.push({ name: 'order' })
        },

        dates() {
            let vm = this;

            let holidays = []
            console.log(this.holidayDate)
            this.holidayDate.map(h => {
                holidays.push(timeFormat(h.date, 'yyyy-mm-dd'))
            })

            const today = new Date();
            const futureDate = new Date(today.getTime() + 30 * 24 * 60 * 60 * 1000);

            const dateArray = [];

            for (let i = 0; i < 30; i++) {
                const currentDate = new Date(today.getTime() + i * 24 * 60 * 60 * 1000);
                const day = timeFormat(currentDate, 'yyyy-mm-dd');

                if (holidays.includes(day)) continue;

                dateArray.push(day);
            }
            console.log(vm.dateInfo.date, dateArray[0]);
            let noDateShow = vm.compareDate(dateArray[0], vm.dateInfo.date);

            this.noDateShow = noDateShow

            console.log(noDateShow)

            // this.dateArray = dateArray

        },


        compareDate(date1, date2) {
            var oDate1 = new Date(date1);
            var oDate2 = new Date(date2);
            if (oDate1.getTime() > oDate2.getTime()) {
                return true; //第一个大
            } else {
                return false; //第二个大
            }
        },

        addresFormUpdate() {

            console.log(2224)
            this.dialogAddress = false;
            this.getQueryaddress()
        },
        setDate(e) {
            console.log(e);
            this.dateInfo = e;
        },



        getQueryusercoupon() {
            let vm = this;
            getordercoupon({
                OrderId: this.orderDetail.order.id,
                Amount: parseFloat(this.orderDetail.visaDetail && this.orderDetail.visaDetail.visa && this.orderDetail.visaDetail.visa.price4 * this.orderDetail.orderApplicants.length),
            }).then(res => {
                console.log(res)
                let quanList = res.data.availableCoupons;
                let arr = []
                quanList.forEach(elem => {
                    elem.startTime = elem.startTime.split(' ')[0];
                    elem.expiredTime = elem.expiredTime.split(' ')[0];
                    if (0 == elem.status) {
                        if (elem.countryId == 0) {
                            arr.push(elem);

                        } else {
                            if (elem.countryId == this.orderDetail.order.countryId) {
                                arr.push(elem);
                            }
                        }
                    }
                })
                if (arr.length > 0) {
                    this.checkQuan(arr[0]);
                }
                this.quanList = arr;
            })
        },
        getbalanceFn() {
            getbalance({

            }).then(res => {
                console.log(res);
                this.myPrice = res.data;

                this.$store.commit('SET_BALANCE', res.data);
            })
        },
        toHome() {
            this.$router.push({ name: 'index' })
        },

        toStup(index) {
            console.log(index);

            this.$router.push({ name: 'orderDetail', params: { id: this.id }, query: { stup: index } })

        },

        addAddress() {
            this.addresForm = {}
            this.dialogAddress = true;
        },
        openCheck() {

            this.inputShow = !this.inputShow;
        },

        selectDate() {
            this.dateInfo = {
                date: '',
                str: '',
            }
            this.inputShow = true;
            this.noDateShow = false;
        },

        checkQuan(item) {
            if (this.quanInfo.id == item.id) {
                this.quanInfo = {
                    id: ''
                }
                this.couponChecked = false
                return;
            } else {
                if (parseFloat(this.orderDetail.visaDetail && this.orderDetail.visaDetail.visa && this.orderDetail.visaDetail.visa.price4) < item.amount) {
                    this.quanInfo = {
                        id: ''
                    }

                    this.$message({
                        message: '优惠券优惠金额不能超过余额！',
                        type: 'warning'
                    });
                    this.couponChecked = false
                    return;
                } else {
                    this.quanInfo = item;
                    this.couponChecked = true
                }
            }
        },
        delUser(index) {


            this.$confirm(`是否要要删除该申请人`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {

                this.orderDetail.orderApplicants.splice(index, 1)

                let oldArr = []
                this.orderDetail.orderApplicants.forEach(item => {
                    oldArr.push({
                        applicantName: item.applicantName,
                        identityTypeId: item.identityType,
                        id: item.id
                    })
                })

                let arr = [...oldArr]

                upadateorderapplicants({
                    orderId: this.id,
                    DepartureTime: this.dateInfo.date,
                    applicants: arr,

                }).then(res => {
                    console.log(res);
                    if (res.code == 1) {
                        this.$message({
                            message: '删除成功！',
                            type: 'success'
                        });
                        this.getOrderdetail();
                    }

                })
            }).catch(res => {

            })


        },

        addressBtn() {

            let vm = this;
            setdefaultaddress({
                id: vm.isDefault,
                token: vm.$store.state.token
            }).then(res => {
                console.log(res);

                vm.$message({
                    message: '修改成功！',
                    type: 'success'
                });

                vm.dialogMember = false;

                vm.getQueryaddress();
            })
        },
        toAddress() {


            this.isDefault = this.addressDefault.length > 0 && this.addressDefault[0].id
            this.dialogMember = true
        },
        toPath(num) {
            if (num == 1) {
                this.$router.push({ name: 'personal' })
                return;
            }

            if (num == 2) {
                this.$router.push({ name: 'order' })
                return;
            }
        },
        getQueryaddress() {
            this.loading = true;

            queryaddress({
            }).then(res => {
                console.log(res);
                this.address = res.data.rows;

                this.addressDefault = this.address.filter(item => item.isDefault)


            })
        },

        toDetail(item) {
            let vm = this;
            if (item.applicationForm) {
                vm.$router.push({
                    name: "electron",
                    params: {
                        id: `${item.applicantName}_${item.identityTypeName}_${vm.id}`,
                    },
                });
            } else {
                vm.$router.push({ name: 'information', params: { information: item.id, id: `${this.id}_${item.id}` } })
            }
        },
    }
}
</script>

<style lang="scss">
.address-order-class {
    background: none !important;
    border-radius: 12px;

    .el-dialog {
        background: none;
    }

    .el-dialog__header {
        display: none;
    }

    .el-dialog__body {
        width: 596px;
        background: linear-gradient(180deg, #E7ECFB 0%, #F6F8FE 24%, #FFFFFF 100%);
        border-radius: 12px;
        box-sizing: border-box;
        overflow: hidden;
    }

    &.address-class {
        .el-dialog__body {
            height: 519px;

        }
    }
}

.address-nodate-class {
    background: none !important;
    border-radius: 12px;

    .el-dialog {
        background: none;
    }

    .el-dialog__header {
        display: none;
    }

    .el-dialog__body {
        width: 396px;
        background: linear-gradient(180deg, #E7ECFB 0%, #F6F8FE 24%, #FFFFFF 100%);
        border-radius: 12px;
        box-sizing: border-box;
        overflow: hidden;
    }

    &.address-class {
        .el-dialog__body {
            height: 519px;

        }
    }
}


.place-input-top {
    display: flex;
    margin-bottom: 12px;

    .place-input-name {
        width: 119px;
        height: 44px;
        margin-right: 12px;
    }

    .place-input-phone {
        width: 236px;
        height: 44px;
    }

    input {
        border: none !important;
        background: #F7F8FA !important;
    }
}

.place-input-bom {
    input {
        border: none !important;
        background: #F7F8FA !important;
    }
}
</style>
  
<style lang="scss" scoped>
.place {
    background: #F7F8FA;
    padding: 100px;

    .place-content {
        margin: 0 auto;
        width: 1200px;
        margin-top: 20px;

        .place-content-left {
            position: relative;

            img {
                position: absolute;
                top: -60px;
            }
        }

        .my-price {
            width: 333px;
            height: 54px;
            background: #E9F4FF;
            border-radius: 8px 8px 8px 8px;
            margin-top: 70px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 10px;
            box-sizing: border-box;

            span {
                &:nth-child(2) {
                    cursor: pointer;
                    color: #3491EB;

                    i {
                        color: #3491EB;

                    }
                }
            }
        }
    }

    .place-wrap {
        background: #FFF;
        box-sizing: border-box;
        padding-bottom: 12px;

        .place-input {
            padding-top: 29px;
            padding-bottom: 12px;
            background: #FFF;
            margin: 0 16px;
            display: flex;
            flex-direction: column;

            .place-input-top {
                display: flex;

                .place-input-name {
                    width: 119px;
                    height: 44px;

                    border: none;

                    input {
                        border: none !important;
                        background: #F7F8FA !important;
                    }
                }
            }
        }

        .place-user-info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 16px;

            .place-user {
                font-size: 20px;
                font-weight: bold;

                img {
                    width: 24px;
                    height: 18px;
                }
            }

            .place-user-right {
                color: #F8912C;
                font-size: 20px;

            }
        }

        .place-user-list {
            background: #F7F8FA;
            margin: 12px;
            margin-top: 0px;
            padding: 0 18px;

            .place-user-item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 12px 0;
                border-bottom: 1px solid #E8E8E8;

                &:nth-last-child(1) {
                    border-bottom: none;
                }

                .place-user-item-left {
                    display: flex;
                    align-items: center;

                    i {
                        cursor: pointer;
                        color: #CCCCCC;
                        margin-right: 16px;
                    }
                }

                .place-user-item-right {
                    color: #3888E9;
                    font-size: 16px;
                    font-weight: bold;
                    cursor: pointer;
                }

                .place-appName {
                    font-size: 16px;
                    font-weight: bold;
                    margin-right: 52px;

                    width: 110px;
                }

                .place-appName-type {
                    color: #999999;
                    font-size: 16px;
                }
            }
        }

        .place-title {
            display: flex;
            align-items: center;
            padding: 12px 16px;
            box-sizing: border-box;
            border-bottom: 3px solid #F5F5F5;

            &.place-title2 {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .title-name {
                    font-size: 20px;
                    font-weight: bold;
                }

                .title-out-date {
                    display: flex;
                    align-items: center;
                    position: relative;

                    span {
                        color: #666666;
                        font-size: 12px;
                        margin-right: 16px;
                    }

                    p {
                        font-size: 16px;
                        color: #3888E9;
                        font-weight: bold;
                        cursor: pointer;
                    }
                }
            }


            .place-title-text {
                font-size: 20px;
                font-weight: bold;
                margin-right: 8px;

                .checkbox-k {
                    margin-right: 10px;
                }
            }

            .place-title-label {
                width: 36px;
                height: 19px;
                border-radius: 4px 4px 4px 4px;
                opacity: 1;
                border: 1px solid #F8912C;
                color: #F8912C;
                font-size: 12px;
                text-align: center;
                line-height: 19px;
                margin-right: 16px;

            }

            .place-title-desc {
                font-size: 16px;
                color: #999999;
            }
        }

    }

    .header {
        position: fixed;
        z-index: 100;
        width: 100%;
        height: 76px;
        top: 0;
        // box-shadow: 0px 2px 20px 1px rgba(0, 0, 0, 0.03);
        // border-radius: 0px 0px 0px 0px;
        display: flex;
        align-items: center;
        background: #FFFFFF;
        box-sizing: border-box;
        justify-content: center;
        left: 0;

        .header-content {
            width: 1200px;
            display: flex;
            align-items: center;
            box-sizing: border-box;

            .header-left {
                display: flex;
                align-items: center;
            }


            .steps-right {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                flex: 1;
                width: 100%;

                .steps-r-left {
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    .steps-name {
                        margin-left: 18px;
                    }
                }

                .steps-y {
                    height: 17px;
                    width: 1px;
                    background: #CCCCCC;
                    margin: 0 16px;
                }

                .steps-r-right {
                    cursor: pointer;
                }

            }
        }

        .header-logo {
            cursor: pointer;

            .logo-img {
                width: 173px;
                height: 38px;

            }
        }

        .place-title {
            font-size: 24px;
            font-weight: bold;
            margin-left: 32px;
        }
    }
}

.steps {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-left: 150px;
    width: 240px;

    .steps-x {
        position: absolute;
        border: 1px dashed #b7d6fc;
        width: 180px;
        margin-left: 40px;
        overflow: hidden;
        top: 12.5px;
        z-index: 0;
    }

    .steps-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 1;
        // margin: 0 20px;
        cursor: pointer;


        &.steps-active {

            p {

                background: #3888E9;
                color: #FFF;
                border: 1px solid #3888E9;
            }

            span {
                color: #3888E9;
            }
        }

        p {
            width: 25px;
            height: 25px;
            border-radius: 50%;
            font-size: 12px;
            text-align: center;
            line-height: 25px;
            background: #FFF;
            border: 1px solid #3888E9;
            color: #3888E9;



        }

        span {
            margin-top: 6px;
            font-size: 12px;
            color: #3888E9;
        }


    }
}

.address-list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-right: 15px;


    .address-item {
        width: 273px;
        height: 128px;
        box-shadow: 0px 3px 3px 1px rgba(#000000, 0.1);
        position: relative;
        padding: 16px;
        box-sizing: border-box;

        .address-is-default {
            position: absolute;
            width: 60px;
            height: 23px;
            line-height: 23px;
            background: #3888E9;
            border-radius: 4px 0px 0px 4px;
            top: 16px;
            right: 0;
            font-size: 12px;
            color: #FFF;
            text-align: center;

        }

        &::before {
            content: "";
            background: url('@/assets/images/personal/top-x.png');
            background-size: 100%;
            position: absolute;
            width: 100%;
            left: 0;
            top: 0;
            height: 2px;
        }

        .address-item-top {
            display: flex;
            align-items: flex-end;

            p {
                font-size: 16px;
                font-weight: bold;
            }

            span {
                font-size: 12px;
                color: #777777;
                margin-left: 8px;
            }
        }

        .address-center {
            font-size: 12px;
            color: #444444;
            margin-top: 16px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            height: 33px;
        }

        .address-email {
            margin-top: 8px;
            font-size: 12px;
        }


    }
}

.address-add {
    width: 128px;
    height: 128px;
    background: #F2F8FF;
    border-radius: 8px 8px 8px 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    img {
        width: 34px;
        height: 42px;
        margin-top: 28px;
    }

    span {
        margin-top: 13px;
        color: #3888E9;
        font-size: 12px;
    }
}

.place-box {
    display: flex;
    align-items: center;
    padding: 16px;
}

.dialog-member-wrap {

    .dialog-content {
        font-size: 18px;
        height: 150px;
        margin-top: 20px;

        p {
            flex-wrap: nowrap;
        }
    }

    .dialog-member-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: -10px;

        .dialog-member-name {
            font-size: 18px;
            font-weight: bold;

        }

        .dialog-close {
            width: 25px;
            height: 25px;
            background: #E0E0E0;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            .el-icon-close {
                color: #FFF;
                font-size: 16px;
            }
        }
    }
}

.address-nodate-class {
    .address-dialog-btn {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.address-dialog {
    display: flex;
    flex-direction: column;
    margin-top: 16px;

    .address-dialog-btn {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .address-dialog-item {
        width: 536px;
        // height: 78px;
        border-radius: 8px;
        border: 1px solid #CCCCCC;
        margin-bottom: 16px;
        padding: 12px;
        box-sizing: border-box;
        cursor: pointer;
        box-sizing: border-box;

        &.address-active {
            border: 1px solid #3888E9;
        }

        .address-dialog-item-top {
            display: flex;
            align-items: center;

            p {
                font-size: 12px;
                margin-right: 12px;
                color: #666666;

                &:nth-child(1) {
                    font-size: 16px;
                    font-weight: bold;
                    margin-right: 24px;

                }

            }
        }

        .address-dialog-bom {
            margin-top: 12px;
            font-size: 16px;
        }
    }
}

.quan-list {
    display: flex;
    flex-direction: flex;
    flex-wrap: wrap;
    // height: 450px;
    margin-top: 20px;
    margin-left: 16px;
    box-sizing: border-box;

    .quan-empty {
        margin: auto;
    }

    .quan-item {
        width: 334px;
        height: 92px;
        background: url('@/assets/images/personal/quan1.png') no-repeat;
        background-size: 100%;
        background-position: 100%;
        display: flex;
        margin-right: 20px;
        margin-bottom: 20px;
        position: relative;
        cursor: pointer;

        &.quan-item-active {
            &::before {
                content: '';
                background: url('@/assets/images/order/check.png') no-repeat;
                background-size: 100%;
                background-position: 100%;
                width: 51px;
                height: 51px;
                position: absolute;
                right: 0;
                top: 0;
            }
        }

        .quan-r-icon {
            width: 42px;
            height: 32px;
            position: absolute;
            right: 4px;
            top: 4px;
        }

        .quan-item-left {
            color: #FFF;
            font-size: 32px;
            display: flex;
            align-items: center;
            padding-left: 12px;
            width: 70px;

            span {
                font-size: 16px;
                margin-top: 12px;

            }
        }

        .quan-item-right {
            margin-left: 40px;
            margin-top: 14px;
            display: flex;
            flex-direction: column;

            p {
                font-size: 16px;
                font-weight: bold;
            }

            .quan-item-desc {
                font-size: 12px;
                color: #666666;
                margin-top: 4px;
            }

            .quan-item-desc2 {
                font-size: 12px;
                color: #666666;
                margin-top: 4px;
            }
        }
    }
}

.play-wrap {
    background: #FFF;
    display: flex;
    justify-content: space-between;
    padding: 16px;
    padding-top: 60px;
    box-sizing: border-box;
    height: 200px;

    .place-play-right {
        width: 279px;

        .place-quan {
            display: flex;
            justify-content: space-between;
            align-items: center;

            span {
                &:nth-child(1) {
                    color: #999;
                    font-size: 16px;
                }

                &:nth-child(2) {
                    color: #666666;
                    font-size: 16px;
                }
            }
        }

        .place-price {
            display: flex;
            justify-content: space-between;
            align-items: center;

            span {
                &:nth-child(1) {
                    color: #999;
                    font-size: 16px;
                }

                &:nth-child(2) {
                    color: #F13B4B;
                    font-size: 32px;
                    font-weight: bold;
                }
            }
        }
    }
}
</style>