<template>
    <div class="package-quan-wrap">
        <div class="box-input">

            <div class="box-input-left">

                <el-select v-model="form.type" style="margin-right: 12px;" clearable placeholder="优惠券类型" @change="getQuery">
                    <el-option v-for="item in quanTypeList" :key="item.value" :label="item.label" :value="item.value"
                        prefix-icon="el-icon-caret-top">
                    </el-option>
                </el-select>
                <el-input style="width: 465px;margin-right: 12px;" v-model="form.name" placeholder="请输入券名称"></el-input>


            </div>

            <div class="box-input-btn">
                <el-button class="default" @click="couponqueryFn">搜索</el-button>
                <el-button class="box-input-btn-cz" @click="reset">重置</el-button>
                <el-button class="box-input-btn-cz" @click="addPackge">新增券</el-button>
            </div>
        </div>

        <div class="box-table">
            <el-table :data="list"
                :header-cell-style="{ backgroundColor: '#F2F4F7', color: '#333', fontWeight: 'bold', textAlign: 'center', fontSize: '12px' }"
                style="width: 100%" :height="$store.state.product.screen ? '820px' : '470px'" v-loading="loading" :cell-style="{ 'text-align': 'center' }">

                <el-table-column prop="name" label="名称" width="120px">
                </el-table-column>

                <el-table-column prop="desc" label="描述" width="120px">
                </el-table-column>
                <el-table-column prop="typeName" label="类型" width="120px">
                    <template slot-scope="scope">
                        <div class="tag-sty" :style="{ color: setType(scope.row.type) }">
                            {{ scope.row.typeName }}
                        </div>
                    </template>
                </el-table-column>

                <el-table-column prop="countryName" label="国家">
                </el-table-column>
                <el-table-column prop="fullAmount" label="需满金额">
                </el-table-column>
                <el-table-column prop="amount" label="优惠金额">
                </el-table-column>
                <el-table-column prop="days" label="有效天数">
                </el-table-column>
                <el-table-column prop="total" label="发券数量">
                </el-table-column>
                <el-table-column prop="receiveCount" label="已领取数量">
                </el-table-column>
                <el-table-column prop="usedCount" label="已使用数量">
                </el-table-column>
                <el-table-column prop="enable" fixed="right" label="是否启用">
                    <template slot-scope="scope">
                        <div>
                            <el-switch v-model="scope.row.enable" active-color="#3888E9" inactive-color="#CCCCCC"
                                @change="switchChange(scope.row)">
                            </el-switch>

                        </div>
                    </template>
                </el-table-column>

                <el-table-column label="操作" fixed="right" header-align="center">
                    <template slot-scope="scope">
                        <el-dropdown trigger="click" @command="handleCommand($event, scope.row)">
                            <span class="lan" style="cursor: pointer;">展开<i
                                    class="el-icon-arrow-down el-icon--right"></i></span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item command="e">编辑</el-dropdown-item>
                                <el-dropdown-item command="a"> 添加券</el-dropdown-item>
                                <el-dropdown-item command="d">删除</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>


                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="table-pagination">

            <el-pagination @current-change="quanCurrentChange" :current-page="pageIndex" :page-size="10"
                layout="total, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </div>

        <!-- 添加优惠券 -->
        <el-dialog :visible.sync="dialogQuan" width="445px" class="quan-dialog-nei-class" :close-on-click-modal="false">
            <div class="dialog-quan-nei-wrap">
                <div class="dialog-member-title">
                    <span class="dialog-member-name">{{ruleForm.id ? '编辑优惠券' : '新增优惠券'}}</span>
                    <div class="dialog-close" @click="resetForm('ruleForm')">
                        <i class="el-icon-close"></i>
                    </div>
                </div>
                <div class="dialog-form-wrap">
                    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px"
                        class="packge-nei-ruleForm">
                        <el-form-item label="标题:" prop="name">
                            <el-input v-model="ruleForm.name" placeholder="请输入标题"></el-input>
                        </el-form-item>
                        <el-form-item label="有效天数:" prop="days">
                            <el-input v-model.number="ruleForm.days" type="Number" placeholder="请输入有效天数" min="1" step="1"></el-input>
                        </el-form-item>
                        <el-form-item label="国家:" prop="countryId">
                            <el-select class="packge-select" v-model="ruleForm.countryId" placeholder="请选择国家">
                                <el-option v-for="item in continent" :key="item.id" :label="item.zhName" :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="类型:" prop="type">
                            <el-select class="packge-select" v-model="ruleForm.type" placeholder="请选择类型">
                                <el-option v-for="item in quanTypeList" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="需满金额:" prop="fullAmount">
                            <el-input v-model.number="ruleForm.fullAmount" type="Number" placeholder="请输入需满金额"></el-input>
                        </el-form-item>
                        <el-form-item label="优惠金额:" prop="amount">
                            <el-input v-model.number="ruleForm.amount" type="Number" placeholder="请输入优惠金额"></el-input>
                        </el-form-item>
                        <el-form-item label="发券数量:" prop="total" v-if="!ruleForm.id">
                            <el-input v-model.number="ruleForm.total" type="Number" placeholder="请输入发券数量" min="1" step="1"></el-input>
                        </el-form-item>
                        <el-form-item label="描述:" prop="desc">
                            <el-input v-model="ruleForm.desc" placeholder="请输入描述"></el-input>
                        </el-form-item>

                        <el-form-item label="是否开启:" prop="enable">
                            <el-switch v-model="ruleForm.enable" active-color="#3888E9" inactive-color="#CCCCCC">
                            </el-switch>
                        </el-form-item>

                    </el-form>

                    <div class="packge-btn-add">

                        <el-button class="pageck-btn" @click="resetForm('ruleForm')">取消</el-button>
                        <el-button class="pageck-btn default" type="primary" @click="submitForm('ruleForm')">确认</el-button>
                    </div>
                </div>
            </div>
        </el-dialog>

        <!-- 添加优惠券 -->
        <el-dialog :visible.sync="dialogQuanAddNum" width="300px" class="quan-dialog-num-class"
            :close-on-click-modal="true">
            <div class="dialog-member-title">
                <span class="dialog-member-name">添加优惠券数量</span>
                <div class="dialog-close" @click="resetQuanNumForm">
                    <i class="el-icon-close"></i>
                </div>
            </div>
            <div class="quan-num-add">
                <div class="quan-num-add-item">
                    <span>发行数量：</span>
                    <span>{{ quanNum.total }}张</span>
                </div>

                <div class="quan-num-add-item">
                    <span>领取数量：</span>
                    <span>{{ quanNum.receiveCount }}张 </span>
                </div>

                <div class="quan-num-add-item">
                    <span>增加发放：</span>
                    <el-input :max="100000" style="width: 128px" type="Number" v-model="quanNum.count"></el-input>
                </div>

                <div class="packge-btn-add">

                    <el-button class="pageck-btn" @click="resetQuanNumForm">取消</el-button>
                    <el-button class="pageck-btn default" type="primary" @click="addQuanNum">确认</el-button>
                </div>
            </div>
        </el-dialog>

    </div>
</template>

<script>
import { couponquery, coupontypeselect, addcoupon, rechargepackageenable, updatecoupon, addcouponcount, deletecoupon,modifycouponenable } from '@/api/settings.js'
import { countrylist } from '@/api/personal.js'
let _this;

export default {
    data() {
        let validateAmout = function(rule, value, callback) {
            if(_this.$refs.ruleForm.model.fullAmount && _this.$refs.ruleForm.model.amount) {
                
                if(_this.$refs.ruleForm.model.fullAmount < _this.$refs.ruleForm.model.amount) {
                    callback(new Error('优惠金额必须小于需满金额'));
                }
            }
            
            callback();
        };

        return {
            form: {
                name: '',
                type: ""
            },
            list: [],
            quanTypeList: [],
            continent: [],
            total: 0,
            pageIndex: 0,
            loading: false,
            editQuan: false,
            dialogQuan: false,
            dialogQuanAddNum: false,
            quanNum: {
                total: 0,
                receiveCount: 0,
                id: '',
                count: 0
            },
            ruleForm: {
                name: '',
                type: '',
                countryId: 0,
                enable: true,
                days: '',
                amount: '',
                fullAmount: '',
                desc: '',
                total: '',
                id: '',
            },

            rules: {
                name: [
                    { required: true, message: '请输入标题', trigger: ['click', 'change', 'blur'] }
                ],
                days: [
                    { required: true, message: '请输入有效天数', trigger: ['click', 'change', 'blur'] },
                    { type: 'integer', min: 1, max: 10000, message: '请输入 1 ~ 10000 之间的整数', trigger: ['click', 'change', 'blur'] },
                ],
                countryId: [
                    { required: true, message: '请选择国家', trigger: ['click', 'change', 'blur'] }
                ],
                type: [
                    { required: true, message: '请选择类型', trigger: ['click', 'change', 'blur'] },
                ],
                fullAmount: [
                    { required: true, message: '请输入需满金额', trigger: ['click', 'change', 'blur'] },
                    { type: 'integer', min: 1, max: 100000, message: '请输入 1 ~ 100000 之间的整数', trigger: ['click', 'change', 'blur'] },
                    { validator: validateAmout, trigger: ['click', 'change', 'blur'] },
                ],
                amount: [
                    { required: true, message: '请输入优惠金额', trigger: ['click', 'change', 'blur'] },
                    { type: 'integer', min: 1, max: 100000, message: '请输入 1 ~ 100000 之间的整数', trigger: ['click', 'change', 'blur'] },
                    { validator: validateAmout, trigger: ['click', 'change', 'blur'] },
                ],
                total: [
                    { required: true, message: '请输入发券数量', trigger: ['click', 'change', 'blur'] },
                    { type: 'integer', min: 1, max: 999999, message: '请输入 1 ~ 999999 之间的整数', trigger: ['click', 'change', 'blur'] },
                ],
                desc: [
                    { required: true, message: '请输入描述', trigger: ['click', 'change', 'blur'] },
                ],

            },
        }
    },
    mounted() {
        _this = this;
        this.couponqueryFn();
        this.getCountrylist();
    },
    computed: {
        setType() {
            return function (type) {
                if (type == 1) {
                    return '#FF3125'
                } else if (type == 2) {
                    return '#FFB07E'
                } else {
                    return '#3888E9'
                }
            }
        }
    },
    methods: {
        couponqueryFn() {
            let vm = this;

            if (this.quanTypeList.length == 0) {

                this.coupontypeselectFn();
            }

            vm.loading = true;
            couponquery({
                token: vm.$store.state.token,
                'Page.Size': 10,
                'Page.Index': this.pageIndex,
                Name: vm.form.name,
                Type: vm.form.type
            }).then(res => {
                console.log(res);
                vm.loading = false;

                vm.list = res.data.rows;

                vm.list.forEach(item => {
                    vm.quanTypeList.forEach(elem => {
                        if (item.type == elem.value) {
                            item.typeName = elem.label
                        }
                    })
                })

                vm.total = res.data.total;
            })
        },

        submitForm(formName) {
            let vm = this;

            this.$refs[formName].validate((valid) => {
                if (valid) {

                    if (vm.ruleForm.id) {
                        updatecoupon({
                            token: vm.$store.state.token,
                            ...vm.ruleForm
                        }).then(res => {
                            this.$message({
                                message: '修改成功',
                                type: 'success'
                            });
                            this.dialogQuan = false;
                            this.resetForm('ruleForm');
                            this.couponqueryFn();
                        })
                    } else {

                        addcoupon({
                            token: vm.$store.state.token,
                            ...vm.ruleForm
                        }).then(res => {
                            this.$message({
                                message: '添加成功',
                                type: 'success'
                            });
                            this.dialogQuan = false;
                            this.resetForm('ruleForm');
                            this.couponqueryFn();
                        })
                    }


                }
            })
        },
        coupontypeselectFn() {
            let vm = this;

            coupontypeselect({
                token: vm.$store.state.token,
            }).then(res => {
                console.log(res);

                this.quanTypeList = res.data;
            })
        },

        switchChange (item) {
            console.log(item);
            item.enable = !item.enable
            this.$confirm(`是否${!item.enable ? '开启' : '关闭'}该优惠券`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                modifycouponenable({
                    id: item.id,
                    enable: !item.enable,
                }).then(res => {
                    this.couponqueryFn();
                })
            }).catch(res => {
                    
            })

        },

        resetQuanNumForm() {
            this.quanNum = {
                total: 0,
                receiveCount: 0,
                id: '',
                count: 0
            }

            this.dialogQuanAddNum = false;
        },

        addQuanNum() {


            let vm = this;
            addcouponcount({
                token: vm.$store.state.token,
                id: this.quanNum.id,
                count: this.quanNum.count,
            }).then(res => {

                if (res.code == 1) {

                    this.$message({
                        message: '添加成功',
                        type: 'success'
                    });
                    this.quanNum = {
                        total: 0,
                        receiveCount: 0,
                        id: '',
                        count: 0
                    }
                    this.dialogQuanAddNum = false;
                    this.couponqueryFn();
                } else {
                    this.$message({
                        message: '优惠券添加异常',
                        type: 'warning'
                    });
                }
            })
        },

        resetForm(formName) {
            this.dialogQuan = false;

            this.ruleForm = {
                name: '',
                type: '',
                countryId: 0,
                enable: true,
                days: '',
                amount: '',
                fullAmount: '',
                desc: '',
                total: '',
                id: '',
            }
            if (formName) {


                this.$refs[formName].resetFields();
            }
        },

        reset() {
            this.form = {
                name: '',
                type: ""
            }
            this.total = 0
            this.pageIndex = 0
            this.loading = false
            this.couponqueryFn();
        },
        handleCommand(e, item) {
            console.log(e, item);
            let vm = this;

            if (e == 'e') {
                this.dialogQuan = true;

                this.ruleForm = {
                    name: item.name,
                    type: item.type,
                    countryId: item.countryId,
                    enable: item.enable,
                    days: item.days,
                    amount: item.amount,
                    fullAmount: item.fullAmount,
                    desc: item.desc,
                    total: item.total,
                    id: item.id
                }
            } else if (e == 'a') {
                this.dialogQuanAddNum = true;

                this.quanNum = {
                    total: item.total,
                    receiveCount: item.receiveCount,
                    id: item.id,
                    count: item.count
                }
            } else {


                this.$confirm(`是否要删除该优惠券`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    deletecoupon({
                        id: item.id,
                         
                    }).then(res => {
                        if(res.msg && res.msg!='success'){
                            this.$message.error(res.msg); 
                            return
                        }
                        this.$message({
                            message: '删除成功',
                            type: 'success'
                        });
                        this.quanNum = {
                            total: 0,
                            receiveCount: 0,
                            id: '',
                            count: 0
                        }
                        this.couponqueryFn();
                    }).catch(res => {
                    
                    })
                }).catch(res => {
                    
                })

            }
        },
        getQuery() {
            this.couponqueryFn();
        },
        getCountrylist() {
            countrylist({
                 
            }).then(res => {
                console.log(res);
                this.continent = [{
                    zhName: '全部国家',
                    id: 0,
                },...res.data]
            })
        },
        addPackge() {
            this.resetForm();
            console.log(111);
            this.dialogQuan = true;
        },
        editQuanFn(item) {
            console.log(111);
            console.log(item);
        },
        quanCurrentChange(e) {
            this.pageIndex = e;
            this.couponqueryFn();
        }
    }
}
</script>
<style lang="scss">
.quan-dialog-nei-class {
    background: none !important;
    border-radius: 12px;

    .el-dialog {
        background: none;
    }

    .el-dialog__header {
        display: none;
    }

    .el-dialog__body {
        width: 445px;

        // height: 333px;
        background: linear-gradient(180deg, #E7ECFB 0%, #F6F8FE 24%, #FFFFFF 100%);
        border-radius: 12px;
        box-sizing: border-box;
        overflow: hidden;
    }


    .packge-nei-ruleForm {
        margin-top: 20px;
        box-sizing: border-box;


        .el-form-item {
            margin-left: 10px;
            margin-right: 10px;
            margin-bottom: 20px;
        }

        .packge-select {
            width: 135px;
            text-align: left;
        }

        .el-form-item__label {
            width: 50px !important;
            white-space: nowrap;
            height: 30px;
            text-align: left;
        }

        .el-form-item__content {
            // margin-left: 80px !important;
            // margin-right: 40px;
        }

        .el-form-item__content {
            margin: 0 auto;



            // .el-button {
            //     // margin-top: 20px;
            //     width: 105px;
            // }
        }
    }
}

.quan-dialog-num-class {
    background: none !important;
    border-radius: 12px;

    .el-dialog {
        background: none;
    }

    .el-dialog__header {
        display: none;
    }

    .el-dialog__body {
        width: 300px;

        height: 285px;
        background: linear-gradient(180deg, #E7ECFB 0%, #F6F8FE 24%, #FFFFFF 100%);
        border-radius: 12px;
        box-sizing: border-box;
        overflow: hidden;
    }

}
</style>


<style lang="scss" scoped>
.packge-nei-quan-class {}

.table-pagination {
    text-align: center;
    margin-top: 16px;
}

.quan-num-add {
    margin-top: 24px;

    .quan-num-add-item {
        margin-bottom: 24px;
    }
}

.dialog-quan-nei-wrap {
    .dialog-member-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: -10px;

        .dialog-member-name {
            font-size: 18px;
            font-weight: bold;

        }

        .dialog-close {
            width: 25px;
            height: 25px;
            background: #E0E0E0;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            .el-icon-close {
                color: #FFF;
                font-size: 16px;
            }
        }
    }
}

.packge-btn-add {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;

    .pageck-btn {
        width: 145px;
        margin: 0 16px;
    }
}

.package-quan-wrap {
    padding: 16px;
    width: 973px;

    .box-input {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        width: 100%;

        .box-input-btn {
            text-align: right;

            .box-input-btn-cz {
                background: #EAF3FF;
                color: #3888E9;
                border: none;
            }

            .box-input-btn-add {
                background: #D8F5E4;
                color: #54D8A5;
                border: none;
            }
        }
    }

}

.lan {
    color: #3888E9;
    font-size: 12px;
}
</style>