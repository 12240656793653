<template>
    <div class="page order-list">

        <div class="content">
            <div class="tab-btn">
                <!-- <el-button style="height: 40px;border-color: #5e9be1;color: #5e9be1;">开发票</el-button> -->
                <el-button @click="openFz" style="height: 40px;border-color: #5e9be1;color: #5e9be1;"
                    v-if="userInfo.userType == 3 && !fzUser">查看本店全部订单</el-button>
                <el-button @click="openNoFz" style="height: 40px;border-color: #5e9be1;color: #5e9be1;"
                    v-if="userInfo.userType == 3 && fzUser">我的订单</el-button>
            </div>
            <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                <el-tab-pane :label="fzUser ? '全部订单' : '签证'" name="first">
                    <div class="tab-wrap">
                        <div class="tab-box tab-box-mTop">
                            <el-select v-if="fzUser" v-model="fromOrder.UserId" clearable placeholder="负责成员"
                                @change="getQuery">
                                <el-option v-for="item in merchatUser" :key="item.id" :label="item.name" :value="item.id"
                                    prefix-icon="el-icon-caret-top">
                                </el-option>
                            </el-select>
                            <el-select v-model="fromOrder.OrderStatus" clearable placeholder="订单状态" @change="getQuery">
                                <el-option v-for="item in orderStatusList" :key="item.value" :label="item.label"
                                    :value="item.value" prefix-icon="el-icon-caret-top">
                                </el-option>
                            </el-select>
                            <el-select v-model="fromOrder.ApplcantStatus" clearable placeholder="申请人状态" @change="getQuery">
                                <el-option v-for="item in selectStatus" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                            <el-select v-model="fromOrder.IsEvaluated" clearable placeholder="评价状态" @change="getQuery">
                                <el-option v-for="item in IsEvaluatedList" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                            <el-date-picker v-model="orderDate" type="daterange" start-placeholder="支付时间"
                                end-placeholder="结束时间" @change="changeOrderDate" value-format="yyyy-MM-dd">
                            </el-date-picker>
                        </div>
                        <div class="tab-box tab-box-two">
                            <el-input v-model="fromOrder.Key" style="width: 750px" clearable
                                placeholder="订单号、申请人姓名、收件人手机号、商品名称等"></el-input>
                            <el-button type="primary" style="height: 40px;" @click="getQuery">查询</el-button>
                            <el-button style="height: 40px;" @click="resetOrder">重置</el-button>
                        </div>
                    </div>
                    <div class="box-list">
                        <div class="box-item" v-for="(item, index) in list" :key="index">
                            <div class="box-item-top">
                                <div class="box-item-top-l">
                                    <div class="box-item-no">{{ item.orderNo }}</div>
                                    <div class="box-item-name">{{ item.visaTitle }}</div>
                                </div>
                                <div class="box-item-top-r">
                                    <div class="box-item-type">
                                        <i class="el-icon-position"></i>
                                        <span>{{ item.statusName }}</span>
                                    </div>
                                    <div class="box-item-btn">
                                        <el-button style="height: 30px;line-height: 30px;width: 82px;padding: 0;"
                                            v-if="[0, 1,2].indexOf(item.status) !== -1"
                                            @click="noOrderFn(item)">取消订单</el-button>
                                        <el-button type="primary"
                                            style="height: 30px;line-height: 30px;width: 82px;padding: 0;"
                                            @click.stop="toDetail(item)"
                                            v-if="[0, 1].indexOf(item.status) !== -1">待上传资料</el-button>
                                        <el-button type="primary"
                                            style="height: 30px;line-height: 30px;width: 82px;padding: 0;"
                                            @click.stop="toPay(item)" v-if="item.status === 2">去支付</el-button>
                                        <el-button type="primary"
                                            style="height: 30px;line-height: 30px;width: 82px;padding: 0;"
                                            @click.stop="toPayDetail(item)"
                                            v-if="[9, 10, -3, -1, 5, 4].indexOf(item.status) !== -1">去查看</el-button>
                                    </div>
                                </div>
                            </div>
                            <div class="box-item-bottom">
                                <div class="box-item-uesr" v-for="(elem, idx) in item.orderApplicants" :key="idx">
                                    <div class="box-item-user-name">{{ elem.applicantName }}</div>
                                    <div class="box-item-user-code">{{ elem.identityTypeName }}</div>
                                </div>
                            </div>
                        </div>
                        <el-empty description="这里空荡荡，即刻开启快马签证的全新办签体验~" v-if="list.length == 0">
                            <el-button type="primary" @click="openProduct">查看签证商品</el-button>
                        </el-empty>
                        <!-- <div class="pagination-bom">
                            <el-pagination :hide-on-single-page="list.length == 0" :total="totalOrder"
                                layout="prev, pager, next" :page-size="pageSizeOrder" :pager-count="pageIndexOrder"
                                @size-change="handleSizeChange" @current-change="handleCurrentChange">
                            </el-pagination>
                        </div> -->
                        <div class="table-pagination" v-show="list.length">
                            <el-pagination @current-change="handleCurrentChange" :current-page="pageIndexOrder" 
                                layout="total, prev, pager, next, jumper" :total="totalOrder" :page-size="pageSizeOrder" >
                            </el-pagination>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="保险" name="second" v-if="!fzUser">
                    <div class="tab-wrap">
                        <div class="tab-title">保险由第三方提供，如需开具发票，请联系客服电话 400-678-XXXX</div>
                        <div class="tab-box tab-box-flex tab-box-mTop">
                            <el-select v-model="fromInsurance.Status" clearable placeholder="订单状态"
                                @change="getQueryInsurance">
                                <el-option v-for="item in insuranceStatus" :key="item.value" :label="item.label"
                                    :value="item.value" prefix-icon="el-icon-caret-top">
                                </el-option>
                            </el-select>
                            <el-date-picker v-model="insuranceDate" type="daterange" clearable start-placeholder="支付时间"
                                end-placeholder="结束时间" @change="changeInsuranceDate" value-format="yyyy-MM-dd">
                            </el-date-picker>
                        </div>
                        <div class="tab-box tab-box-two">
                            <el-input v-model="fromInsurance.Key" style="width: 750px" clearable
                                placeholder="请输入内容"></el-input>
                            <el-button type="primary" style="height: 40px;">查询</el-button>
                            <el-button style="height: 40px;" @click="resetInsurance">重置</el-button>
                        </div>
                    </div>
                    <div class="box-list">
                        <div class="box-item" v-for="(item, index) in listInsurance" :key="index">
                            <div class="box-item-top">
                                <div class="box-item-top-l">
                                    <div class="box-item-no">{{ item.orderNo }}</div>
                                    <div class="box-item-name">{{ item.productTile }}</div>
                                </div>
                                <div class="box-item-top-r">
                                    <div class="box-item-type">
                                        <i class="el-icon-position"></i>
                                        <span>{{ item.statusName }}</span>
                                    </div>
                                    <div class="box-item-btn">
                                        <el-button
                                            style="height: 30px;line-height: 30px;width: 82px;padding: 0;">取消订单</el-button>
                                        <el-button type="primary"
                                            style="height: 30px;line-height: 30px;width: 82px;padding: 0;">立即支付</el-button>
                                    </div>
                                </div>
                            </div>
                            <div class="box-item-bottom" v-for="(elem, idx) in item.details" :key="idx">
                                <div class="box-item-uesr">
                                    <div class="box-item-user-name">{{ elem.name }}</div>
                                    <div class="box-item-user-code">{{ elem.idNumber }}</div>
                                </div>
                            </div>
                            <div class="box-item-b" v-if="item.insuranceStatTime && item.insuranceEndTime">
                                <div>支付时间 未支付</div>
                                <div>保障期限 {{ item.insuranceStatTime }} 至 {{ item.insuranceEndTime }}</div>
                            </div>
                        </div>
                        <el-empty description="这里空荡荡，试试给顾客推荐保险吧~" v-if="listInsurance.length == 0">
                            <el-button type="primary" @click="openProductInsurance">查看保险商品</el-button>
                        </el-empty>
                        <div class="pagination-bom">
                            <el-pagination :hide-on-single-page="listInsurance.length == 0" :total="totalInsurance"
                                layout="prev, pager, next" :page-size="pageSizeInsurance" :pager-count="pageIndexInsurance"
                                @size-change="handleSizeChangeInsurance" @current-change="handleCurrentChangeInsurance">
                            </el-pagination>
                        </div>
                    </div>

                </el-tab-pane>
                <!-- <el-tab-pane label="机票" name="third">角色管理</el-tab-pane> -->
            </el-tabs>
        </div>


        <!-- 取消订单 -->

        <el-dialog title="请选择取消原因" :close-on-click-modal="false" center :visible.sync="noOrderBol" width="360px"
            :before-close="handleClose" class="dialog-wrap">
            <div class="sf-list">
                <el-radio v-model="noOrder.noOrderActive" size="medium" class="sf-radio" :label="item.value"
                    v-for="(item, index) in propList" :key="index">{{ item.name }}</el-radio>
                <el-input type="textarea" :rows="3" placeholder="请留下您的宝贵意见，帮助我们改进服务质 量~" v-model="noOrder.noOrderTextarea"
                    v-if="noOrder.noOrderActive == 3" style="margin-bottom: 15px;">
                </el-input>
                <div class="sf-btn">
                    <el-button @click="noOrderBol = false;">取消</el-button>
                    <el-button class="default" @click="noOrderSubmit">确认</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { query, queryInsurance, orderstatusselect, orderapplcantstatusselect, insuranceorderstatusselect, merchatuserlist, cancelorder } from '@/api/order.js'

export default {
    inject: ['pageReload'],
    data() {
        return {
            token: '',
            activeName: 'first',
            noOrder: {
                noOrderActive: 0,
                noOrderTextarea: '',
                id: ''
            },
            statusActive: 0,
            orderDate: [],
            insuranceDate: [],
            insuranceStatus: [],
            fromOrder: {
                Key: '',
                OrderStatus: '',
                ApplcantStatus: '',
                IsEvaluated: '',
                StartTime: '',
                EndTime: '',
                merchatUser: '',
                IsAll:false
            },
            IsAll:false,
            fromInsurance: {
                Key: '',
                Status: '',
                StartTime: '',
                EndTime: '',
            },
            pageSizeOrder: 10,
            pageIndexOrder: 1,
            pageSizeInsurance: 10,
            pageIndexInsurance: 5,
            totalOrder: 0,
            totalInsurance: 0,
            IsEvaluatedList: [
                { label: '是', value: true },
                { label: '否', value: false },
            ],
            bxStatus: [
                // { label: '', value:  }
            ],
            propList: [{
                name: '商品选错了，重新下单',
                value: 0,
                disabled: false
            },
            {
                name: '出行计划变更',
                value: 1,
                disabled: false
            },
            {
                name: '选择在其他平台办理',
                value: 2,
                disabled: false
            },
            {
                name: '其他原因',
                value: 3,
                disabled: false
            }
            ],
            list: [],
            listInsurance: [],
            orderStatusList: [],
            selectStatus: [],
            merchatUser: [],
            userInfo: {},
            fzUser: false,
            noOrderBol: false,
            
        }
    },
    watch: {
        'noOrder.noOrderActive'(val) {
            if (val == 3) {

            }
        },
        $route(val, oldVal) {
            console.log(val, oldVal, 45646464654)

            this.resetOrder();
            // this.$router.go(0)
        }
    },
    mounted() {
        this.userInfo = this.$store.state.userInfo
        if (this.$router.history.current.query.OrderStatus || this.$router.history.current.query.ApplcantStatus) {
            if (this.$router.history.current.query.OrderStatus) {
                this.fromOrder.OrderStatus = parseInt(this.$router.history.current.query.OrderStatus)
            }
            if (this.$router.history.current.query.ApplcantStatus) {
                this.fromOrder.ApplcantStatus = parseInt(this.$router.history.current.query.ApplcantStatus)
            }
        }
        this.token = this.$store.state.token
        this.getInsuranceorderstatusselect();
        this.getOrderstatusselect();
        this.getQuery();
        this.getQueryInsurance();
        this.getOrderapplcantstatusselect();
        this.$forceUpdate();
    },
    methods: {
        handleClick() {

        },
        openFz() {
            this.fzUser = true

            this.activeName = 'first'
            this.IsAll=true;

            this.resetOrder();
        },

        openNoFz() {
            this.fzUser = false;

            this.IsAll=false;
            this.resetOrder();
        },

        noOrderSubmit() {
            let vm = this;



            cancelorder({
                token: vm.$store.state.token,
                id: vm.noOrder.id,
                cancelReason: vm.noOrder.noOrderActive == 3 ? vm.noOrder.noOrderTextarea : vm.propList[vm.noOrder.noOrderActive].name
            }).then(res => {
                vm.$message({
                    message: '取消成功！',
                    type: 'success'
                });
                vm.getQuery();
                vm.noOrderBol = false;
            })
        },

        toDetail(item) {
            console.log(item)
            this.$router.push({ name: 'orderDetail', params: { id: item.id } })
        },
        toPay(item){
            this.$router.push({ name: "place", params: { id:item.id, place: item.id } });
        },

        handleClose(item) {


            this.noOrderBol = false;
        },

        toPayDetail(item) {
            this.$router.push({ name: 'orderInfoDetails', params: { details: item.id } })
        },

        //  签证订单 列表
        async getQuery() {
            let vm = this;

            let res = await query({
                ...vm.fromOrder,
                'Page.Index': vm.pageIndexOrder,
                'Page.Size': vm.pageSizeOrder,

            })

            console.log(res,'jdsah ');
            // vm.loading = false;
            vm.list = res.data.rows
            vm.totalOrder = res.data.total;
        },

        async getQueryInsurance() {
            let vm = this;
            let res = await queryInsurance({
                ...vm.fromInsurance
            })
            console.log(res)
            vm.listInsurance = res.data.rows||[];
            vm.totalInsurance = res.data.total;
        },

        async getOrderstatusselect() {
            let vm = this;

            let res = await orderstatusselect({})
            vm.orderStatusList = res.data;
        },

        async getOrderapplcantstatusselect() {
            let res = await orderapplcantstatusselect({})

            this.selectStatus = res.data
            console.log(res);
        },

        async getInsuranceorderstatusselect() {
            let res = await insuranceorderstatusselect({})


            this.insuranceStatus = res.data;
            console.log(res);
        },

        openProduct() {
            let vm = this;
            vm.$router.push({ name: 'product' })
        },

        openProductInsurance() {
            let vm = this;
            vm.$router.push({ name: 'case' })
        },

        changeOrderDate(e) {
            console.log(e);
            let vm = this;
            if (e) {

                vm.fromOrder.StartTime = e[0];
                vm.fromOrder.EndTime = e[1];

            } else {
                vm.fromOrder.StartTime = '';
                vm.fromOrder.EndTime = '';

            }


            vm.getQuery();
        },

        noOrderFn(item) {
            console.log(item);

            this.noOrder = {
                noOrderActive: 0,
                noOrderTextarea: '',
                id: item.id
            }
            this.noOrderBol = true;
        },

        changeInsuranceDate(e) {
            console.log(e);
            let vm = this;
            if (e) {

                vm.fromInsurance.StartTime = e[0];
                vm.fromInsurance.EndTime = e[1];

            } else {
                vm.fromInsurance.StartTime = '';
                vm.fromInsurance.EndTime = '';

            }

            vm.getQueryInsurance();
        },
        resetOrder() {
            let vm = this;

            vm.fromOrder = {
                Key: '',
                OrderStatus: '',
                ApplcantStatus: '',
                IsEvaluated: '',
                StartTime: '',
                EndTime: '',
                merchatUser: '',
                IsAll: this.IsAll,
            }

            vm.orderDate = []

            vm.pageSizeOrder = 10;
            vm.pageIndexOrder = 1;

            vm.getQuery();
        },

        resetInsurance() {
            let vm = this;

            vm.fromOrder = {
                Key: '',
                Status: '',
                StartTime: '',
                EndTime: '',
            }

            // vm.pageSizeOrder = 10;
            // vm.pageIndexOrder = 1;

            vm.pageSizeInsurance = 10,
                vm.pageIndexInsurance = 1,

                vm.getQueryInsurance();
        },
        handleSizeChange(val) {
            let vm = this;
            vm.pageSizeOrder = val;
            vm.getQuery();
        },
        handleCurrentChange(val) {
            let vm = this;
            vm.pageIndexOrder = val;
            document.documentElement.scrollTop = 0
            vm.getQuery();
        },
        handleSizeChangeInsurance() {
            let vm = this;
            vm.pageSizeInsurance = val;
            vm.getQueryInsurance();
        },
        handleCurrentChangeInsurance() {
            let vm = this;
            vm.pageIndexInsurance = val;
            vm.getQueryInsurance();
        }
    }
}
</script>
 
<style lang="scss">
.order-list {
    .el-input {
        // width: 220px;
        margin-right: 20px;
        margin-bottom: 14px;
    }

    .el-tabs__header {
        margin-bottom: 0;
        border-bottom-color: #d3e4f8;
    }

    .el-tabs__nav {
        margin-left: 0px;
    }

    .el-tabs__nav-wrap {
        height: 50px;
    }

    .el-tabs__item {
        height: 60px;
        line-height: 60px;

        background-color: #fff;
        border-bottom-color: #d3e4f8;
        width: 140px;
        color: #777;
        text-align: center;
        font-size: 16px;

        &.is-active {

            border-color: #d3e4f8 #d3e4f8 #eaf3ff;
            border-bottom-color: #eaf3ff !important;
            background-color: #eaf3ff;
            color: #5e9be1;
            font-weight: 700;
        }
    }


    .el-pager {
        .active {
            cursor: default;
            background: #1492E6;
            color: #FFF !important;
            border-radius: 100%;
            width: 28px;
            height: 28px;
            min-width: 10px;
            box-shadow: 0px 2px 3px 1px rgba(19, 152, 231, 0.26);
        }
    }

    .el-table__header-wrapper {
        border-radius: 8px 8px 0 0;
    }
}
</style>

<style lang="scss" scoped>
.page {
    background: #F7F8FA;
    padding-bottom: 100px;

    .content {
        width: 1000px;
        // background: #FFF;
        margin: 0 auto;
        margin-top: 30px;
        position: relative;

        .tab-btn {
            position: absolute;
            right: 0;
            top: -6px;
            z-index: 2;
        }

        .tab-wrap {
            padding-bottom: 6px;
            background: #eaf3ff;

            .tab-title {
                width: 100%;
                height: 36px;
                background: #fffac4;
                font-size: 12px;
                display: flex;
                align-items: center;
                padding-left: 20px;
            }
        }

        .box-list {
            background: #FFF;
            padding: 20px;
            min-height: 700px;

            .box-item {
                border: 1px solid #f0f0f0;
                box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .08);
                font-size: 16px;
                margin-bottom: 24px;
                border-radius: 4px;
                margin-bottom: 24px;

                // height: 132px;
                .box-item-top {
                    border-bottom: 1px solid #f0f0f0;
                    padding-left: 20px;
                    padding-right: 20px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: 56px;

                    .box-item-top-l {
                        display: flex;
                        align-items: center;
                        .box-item-no {
                            background: #f7f8fa;
                            font-size: 14px;
                            color: #777;
                            height: 30px;
                            line-height: 30px;
                            padding: 0 12px;
                            margin-right: 16px;
                        }
                        .box-item-name {
                            width: 480px;
                            color: #333;
                            font-weight: 700;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                            flex: 1;
                            padding-right: 20px;
                        }
                    }
                    .box-item-top-r {
                        display: flex;
                        align-items: center;
                        .box-item-type {
                            display: flex;
                            align-items: center;
                            margin-right: 30px;
                            color: #ff5a60;
                        }
                        .box-item-btn{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            flex-wrap: nowrap;
                        }
                    }
                }

                .box-item-bottom {
                    padding: 0 25px;

                    .box-item-uesr {
                        display: flex;
                        border-bottom: 1px solid #f0f0f0;
                        padding: 25px 10px;

                        &:nth-last-child(1) {
                            border-bottom: 1px solid #FFFFFF;
                        }

                        .box-item-user-name {
                            width: 130px;
                            white-space: nowrap;
                            font-display: 16px;

                        }

                        .box-item-user-code {
                            color: #aaa;
                        }
                    }
                }

                .box-item-b {
                    background: #fbfbfb;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    height: 43px;
                    padding: 0 20px;
                    font-size: 14px;
                    color: #aaa;
                }
            }

            .pagination-bom {
                text-align: center;
            }
        }

        .tab-box {
            display: flex;
            justify-content: space-between;
            flex-wrap: nowrap;
            padding: 0 20px;

            &.tab-box-mTop {
                padding-top: 20px;
            }

            &.tab-box-flex {
                display: flex;
                justify-content: flex-start;
            }

        }
    }
}

.table-pagination {
    text-align: center;
    margin-top: 16px;
}

.sf-list {
    display: flex;
    flex-direction: column;
    margin-top: -20px;

    .sf-label {
        margin-bottom: 15px;
        color: #999;
    }

    .sf-radio {
        margin-bottom: 15px;
    }

    .sf-btn {
        text-align: right;
    }
}
</style>