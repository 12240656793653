<template>
    <div class="page personal">
        <div class="page-content">


            <div class="page-user">
                <el-avatar :size="63" :src="mechatinfo.logo"></el-avatar>
                <div class="page-user-info">
                    <p class="user-name">{{ mechatinfo.companyName }}</p>
                    <!-- <p>{{userInfo.}}</p> -->
                    <p class="user-addr">{{ mechatinfo.addressArea }} </p>
                    <ul class="page-user-pay">
                        <li class="user-pay-item" @click="openOrder(2, 'orderStatus')">
                            <p>待支付 <i class="el-icon-arrow-right"></i></p>
                            <span>{{ orgData.unpaidCount }} <span style="font-size: 14px">单</span> </span>
                        </li>
                        <li class="user-pay-item" @click="openOrder(2, 'ApplcantStatus')">
                            <p>待预审<i class="el-icon-arrow-right"></i></p>
                            <span>{{ orgData.auditCount }}<span style="font-size: 14px">人</span></span>
                        </li>
                        <li class="user-pay-item" @click="openOrder(14, 'ApplcantStatus')">
                            <p>使馆审核<i class="el-icon-arrow-right"></i></p>
                            <span>{{ orgData.embassyCount }}<span style="font-size: 14px">人</span></span>
                        </li>
                        <li class="user-pay-item" @click="openOrder(16, 'ApplcantStatus')">
                            <p>已出签<i class="el-icon-arrow-right"></i></p>
                            <span>{{ orgData.succeedCount }}<span style="font-size: 14px">本</span></span>
                        </li>
                    </ul>

                </div>
                <div class="user-right">
                    <span class="user-right-label">可用金额</span>
                    <p class="user-right-price">￥{{ $store.state.balance }}</p>
                    <div class="user-btn-bg">充值送券</div>
                    <el-button class="price-btn price-btn-user" @click="isVisible = true">余额充值</el-button>
                </div>
                <!-- <div class="page-content">


            </div> -->
                <img class="img-right" :src="require('../../assets/images/personal/logo.png')" />
            </div>

            <div class="page-box">
                <div class="page-box-left">
                    <div class="page-box-item" @click="checkMenu(item)"
                        :class="[menuActive == item.value ? 'page-box-item-active' : '']" v-for="(item, index) in menuList"
                        :key="index">
                        <div :class="['page-box-icon', item.icon]"></div>
                        <div class="page-box-label">{{ item.label }}</div>
                    </div>
                </div>

                <!-- 消费订单 -->
                <div class="page-box-right" v-if="menuActive == 0">
                    <div class="box-input">
                        <div>
                            <el-date-picker v-model="value1" type="daterange" range-separator="至" start-placeholder="开始日期"
                                end-placeholder="结束日期" @change="selectDate" value-format="yyyy-MM-dd">
                            </el-date-picker>
                            <!-- v-if="userType == 3" -->
                            <el-select v-if="1" v-model="financeUserId" clearable placeholder="负责成员"
                                @change="getQueryfinance">
                                <el-option v-for="item in merchatUser" :key="item.id" :label="item.name" :value="item.id"
                                    prefix-icon="el-icon-caret-top">
                                </el-option>
                            </el-select>
                        </div>

                        <el-button class="button-color" @click="getQueryfinance(true)">下载Excel表格</el-button>
                    </div>
                    <div class="box-table">
                        <el-table :data="financeList"
                            :header-cell-style="{ backgroundColor: '#F2F4F7', color: '#333', fontWeight: 'bold', textAlign: 'center', }"
                            style="width: 100%" :height="$store.state.product.screen ? '820px' : '470px'"
                            v-loading="loading" :cell-style="{ 'text-align': 'center' }">
                            <el-table-column prop="createdTime" label="时间" width="180">
                            </el-table-column>
                            <el-table-column prop="amount" label="余额变化" width="180">
                                <template slot-scope="scope">
                                    <div>
                                        <!-- 收入 -->
                                        <span v-if="scope.row.flag == 0" class="color-l">+{{ scope.row.amount }}</span>
                                        <!-- 支出 -->
                                        <span v-else class="color-red">-{{ scope.row.amount }}</span>
                                    </div>

                                </template>
                            </el-table-column>
                            <el-table-column prop="balance" label="剩余余额">
                            </el-table-column>
                            <el-table-column prop="userName" label="操作人">
                            </el-table-column>
                            <el-table-column prop="typeName" label="事件">
                            </el-table-column>
                            <el-table-column prop="marks" label="备注">
                            </el-table-column>
                            <el-table-column label="操作" header-align="center">
                                <template slot-scope="scope">
                                    <div>
                                        <span class="member-edit" v-if="scope.row.type == 2"
                                            @click="editOrder(scope.row)">详情</span>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>

                    <div class="table-pagination">

                        <el-pagination @current-change="handleCurrentChange" :current-page="finance.pageIndex"
                            :page-sizes="[100, 200, 300, 400]" :page-size="10" layout="total, prev, pager, next, jumper"
                            :total="finance.pageTotal">
                        </el-pagination>
                    </div>
                </div>

                <!-- 消费券 -->
                <div class="page-box-certificate" v-if="menuActive == 1">
                    <div class="page-box-certificate-input">
                        <input type="text" class="page-ys-input" v-model="quanMa">
                        <button class="page-ys-btn" :class="[quanMa.length < 6 ? 'page-ys-btn-bg' : '']"
                            @click="checkTh">确认兑换</button>
                    </div>
                    <!-- @tab-click="handleClick" -->
                    <el-tabs v-model="quanActive" @tab-click="handleClick">
                        <el-tab-pane :label="item.name" :name="item.value" v-for="(item, index) in quanMenu" :key="index">

                            <div class="quan-content">
                                <div class="quan-list">

                                    <!-- 可以用 -->
                                    <div class="quan-item" :class="elem.status != 0 ? 'quan-item-bg2' : ''"
                                        v-for="(elem, idx) in item.arr" :key="idx">
                                        <p class="quan-item-left">
                                            <span>￥</span>
                                            {{ elem.amount }}
                                        </p>

                                        <div class="quan-item-right">
                                            <p>{{ elem.fullAmount }}</p>
                                            <span class="quan-item-desc">{{ elem.desc }}</span>
                                            <span class="quan-item-desc2">{{ elem.startTime }}至{{ elem.expiredTime }}</span>
                                        </div>

                                        <img class="quan-r-icon" v-if="elem.status == 1"
                                            :src="require('@/assets/images/personal/yong.png')" alt="">
                                        <img class="quan-r-icon" v-if="elem.status == -1"
                                            :src="require('@/assets/images/personal/qi.png')" alt="">
                                    </div>

                                    <el-empty class="quan-empty" :description="`暂无${item.name}优惠券`"
                                        v-if="item.arr.length == 0">
                                    </el-empty>

                                </div>
                            </div>

                            <div class="quan-pagintion" v-if="item.arr.length > 0">

                                <el-pagination @current-change="quanCurrentChange" :current-page="quan.pageIndex"
                                    :page-size="8" layout="total, prev, pager, next, jumper" :total="quan.pageTotal">
                                </el-pagination>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </div>

                <!-- 成员管理 -->
                <div class="member" v-if="menuActive == 2">
                    <div class="box-input">
                        <el-input v-model="member.Key" placeholder="姓名/账号" style="width: 407px;"></el-input>
                        <el-button class="button-color" @click="addMember">+新增成员</el-button>
                    </div>
                    <div class="box-table">
                        <el-table :data="memberList"
                            :header-cell-style="{ backgroundColor: '#F2F4F7', color: '#333', fontWeight: 'bold' }"
                            style="width: 100%" :height="$store.state.product.screen ? '820px' : '470px'"
                            v-loading="loading">
                            <el-table-column prop="merchatId" label="ID">
                                <template slot-scope="scope">
                                    {{ scope.$index + 1 }}
                                </template>
                            </el-table-column>
                            <el-table-column prop="name" label="姓名">
                            </el-table-column>
                            <el-table-column prop="phone" label="账号">
                            </el-table-column>
                            <el-table-column prop="orderCount" label="累计单量">
                            </el-table-column>
                            <el-table-column prop="createdTime" label="创建时间" width="180px">
                            </el-table-column>
                            <el-table-column prop="enable" label="状态">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.enable">在职</span>
                                    <span v-else style="color: #E41313;">离职</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作" header-align="center">
                                <template slot-scope="scope">
                                    <div>
                                        <span class="member-edit" @click="editMember(scope.row)">编辑</span>

                                        <span class="member-edit" v-if="scope.row.enable"
                                            @click="setEnable(scope.row)">停用</span>
                                        <span class="member-edit" v-else @click="setEnable(scope.row)">启用</span>

                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>

                    <div class="table-pagination">

                        <el-pagination @current-change="memberCurrentChange" :current-page="member.pageIndex"
                            :page-size="10" layout="total, prev, pager, next, jumper" :total="member.pageTotal">
                        </el-pagination>
                    </div>
                </div>
                <!-- 地址管理 -->
                <div class="address" v-if="menuActive == 3">

                    <div class="box-input">
                        <el-button class="button-color" @click="addAddress">+新增收货地址</el-button>
                    </div>
                    <div class="address-content">

                        <div class="address-list">
                            <div class="address-item" v-for="(item, index) in address" :key="index"
                                @mouseover="yrChange(item)" @mouseout="ycChange(item)">
                                <div class="address-item-top">
                                    <p>{{ item.name }}</p>
                                    <span>{{ item.phone }}</span>
                                </div>
                                <div class="address-center">
                                    {{ item.province + item.city + item.district + item.address }}
                                </div>
                                <div class="address-email">
                                    {{ item.email }}
                                </div>
                                <div class="address-btn" v-show="item.isShow">
                                    <el-button class="address-btn-1" v-if="!item.isDefault"
                                        @click="setDefault(item)">设为默认</el-button>
                                    <el-button class="address-btn-1" @click="editAddress(item)">编辑地址</el-button>
                                    <div class="address-del" @click="closeAddress(item)">
                                        <i class="el-icon-delete-solid"></i>
                                    </div>
                                </div>
                                <div class="address-is-default" v-if="item.isDefault">默认地址</div>
                            </div>
                        </div>
                    </div>
                    <div class="table-pagination" v-if="address.length != 0">

                        <el-pagination @current-change="addressCurrentChange" :current-page="addressf.pageIndex"
                            :page-size="6" layout="total, prev, pager, next, jumper" :total="addressf.pageTotal">
                        </el-pagination>
                    </div>

                    <el-empty class="quan-empty" :description="`暂无收货地址`" v-if="address.length == 0">
                    </el-empty>

                </div>
                <!-- 商户设置 -->
                <div class="merchant-set" v-if="menuActive == 4">
                    <img class="img-right-1" :src="require('../../assets/images/personal/sh.png')" />
                    <p class="merchant-set-title">请联系商务经理开通功能，按文档操作添加飞猪 店铺，自动同步飞猪订单至快马签证</p>
                    <el-button class="merchant-set-btn">+新增商铺授权</el-button>
                    <div class="merchant-set-bom">
                        <img class="img-right-2" :src="require('../../assets/images/personal/fz.png')" />
                        <span>飞猪店铺对接说明文档</span>
                    </div>
                </div>
                <!-- 账号管理 -->
                <div class="account-manage" v-if="menuActive == 5">
                    <p class="account-title account-title-user">
                        <span>账号管理</span>

                    </p>
                    <div class="account-content">
                        <span class="account-label">账号</span>
                        <span class="account-input">{{ merchatAccountView.account }}</span>
                    </div>
                    <p class="account-title account-title-user" style="margin-top: 24px;">
                        <span>账号信息</span>
                        <span @click="editUser" v-if="!isEdit">修改信息</span>
                        <el-button class="account-btn" v-else @click="editUserInfo">保存</el-button>
                    </p>
                    <div class="account-user-info">
                        <el-form :model="merchatAccountView" :rules="rules1" ref="userForm" label-width="80px"
                            label-position="left" class="user-form">
                            <el-form-item prop="name" label="联系人">
                                <div class="account-item">
                                    <span class="account-input" v-if="!isEdit">{{ merchatAccountView.name }}</span>
                                    <el-input v-model="merchatAccountView.name" v-else></el-input>
                                </div>
                            </el-form-item>
                            <el-form-item prop="phone" label="手机号">
                                <div class="account-item">
                                    <span class="account-input" v-if="!isEdit">{{ merchatAccountView.phone }}</span>
                                    <el-input v-model="merchatAccountView.phone" v-else></el-input>
                                </div>
                            </el-form-item>
                            <el-form-item prop="email" label="邮箱">
                                <div class="account-item">
                                    <span class="account-input" v-if="!isEdit">{{ merchatAccountView.email }}</span>
                                    <el-input v-model="merchatAccountView.email" v-else></el-input>
                                </div>
                            </el-form-item>
                        </el-form>
                    </div>
                    <p class="account-title account-title-user" style="margin-top: 24px;">
                        <span>微信绑定</span>
                        <span @click="dialogAccount = true; getEwmInfo();">修改微信</span>
                    </p>
                    <div class="account-wx">
                        <el-avatar size="14" :src="userInfo.avatar"></el-avatar>
                        <div class="wx-name">{{ userInfo.nickName }}</div>
                    </div>
                </div>
                <!-- <div class="product" v-if="menuActive == 6">
                    <div v-if="!productEditShow">

                        <product v-if="!setting.id" @open="showEditProduct" @openSettings="showSetting"
                            :init="productEditShow"></product>

                        <dataSettings v-else :setting="setting"></dataSettings>
                    </div>
                    <div v-else>
                        <product-edit @open="openProduct" :id="productId"></product-edit>
                    </div>

                </div> -->

            </div>

        </div>

        <!-- 成员管理 添加or编辑 -->
        <el-dialog :visible.sync="dialogMember" width="372px" class="common-dialog-class" :close-on-click-modal="false">
            <div class="dialog-member-wrap">
                <div class="dialog-member-title">
                    <span class="dialog-member-name">{{ ruleForm.id ? '编辑成员' : '添加成员' }} </span>
                    <div class="dialog-close" @click="resetForm('ruleForm')">
                        <i class="el-icon-close"></i>
                    </div>
                </div>
                <div class="dialog-form-wrap">
                    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" label-position="top"
                        class="demo-ruleForm">
                        <el-form-item label="账号" prop="account">
                            <el-input v-model="ruleForm.account" placeholder="请输入可接受验证码的手机号"></el-input>
                        </el-form-item>
                        <el-form-item label="联系人" prop="name">
                            <el-input v-model="ruleForm.name" placeholder="请输入联系人姓名"></el-input>
                        </el-form-item>
                        <el-form-item label="手机号" prop="phone">
                            <el-input v-model="ruleForm.phone" placeholder="请输入联系方式"></el-input>
                        </el-form-item>

                        <el-form-item label="邮箱地址" prop="email">
                            <el-input v-model="ruleForm.email" placeholder="请输入邮箱"></el-input>
                        </el-form-item>


                        <el-form-item>
                            <el-button @click="resetForm('ruleForm')">取消</el-button>
                            <el-button type="primary" @click="submitForm('ruleForm')">确认</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </el-dialog>



        <!-- 微信绑定 -->
        <el-dialog :visible.sync="dialogAccount" width="631px" class="account-class">
            <div class="dialog-account-wrap">
                <div class="dialog-close" @click="dialogAccount = false">
                    <i class="el-icon-close"></i>
                </div>
                <div class="account-left">
                    <p class="account-left-title">微信扫描二维码享更多服务</p>
                    <ul class="account-left-list">
                        <li class="account-left-item">
                            <div class="account-left-icon">
                                <i class="iconfont icon-gaoqinglogotoumingdiyuansezi"></i>
                                <div class="iconfont icon-a-zu24 op-ifoncnt">
                                </div>
                            </div>
                            <span>微信小程序线下接单</span>
                        </li>
                        <li class="account-left-item">
                            <div class="account-left-icon">
                                <i class="iconfont icon-a-zu23"></i>

                            </div>
                            <span>一键分享商品给顾客</span>
                        </li>
                        <li class="account-left-item">
                            <div class="account-left-icon">
                                <i class="iconfont icon-xiangqingliebiao_view-list"></i>
                            </div>
                            <span>顾客下单传资料进度随时查</span>
                        </li>
                        <li class="account-left-item">
                            <div class="account-left-icon">
                                <i class="iconfont icon-xiaoxi"></i>

                            </div>
                            <span>签证状态微信同步推送</span>
                        </li>
                    </ul>
                </div>
                <div class="account-right">
                    <div class="account-dwc">
                        <img :src="ewmImg" alt="" v-if="ewmImg">
                        <div class="recharge-bom-left-loading" v-if="ewmLoadding || !ewmImg">
                        </div>
                        <div class="recharge-bom-left-loading-p" v-if="ewmLoadding || !ewmImg">

                            <i class="el-icon-loading" style="color: #ffffff;font-size: 30px;"></i>
                        </div>
                    </div>
                    <div class="account-bom">
                        <span>扫描二维码</span>
                        <span>“允许”申请操作完成绑定</span>
                    </div>
                </div>
            </div>
        </el-dialog>
        <recharge :config="isVisible" v-if="isVisible" @close="isVisible = false"
            @initData="isVisible = false; getbalanceFn()"></recharge>
        <addAddres :config="dialogAddress" v-if="dialogAddress" :item="addresForm" @success="addresFormUpdate"
            @close="dialogAddress = false"></addAddres>
    </div>
</template>

<script>
import { getprovinces, setdefaultaddress, deladdress, getmechatinfo, getbalance, queryfinance, queryusercoupon, exchangecoupon, querymerchatmembers, addmerchatmember, modifymemberstatus, updatemerchatmember, queryaddress, getorgdata ,gethomepagedata} from '@/api/personal.js'
import { merchatuserlist } from '@/api/order.js'

import { getuserinfo } from '@/api/common.js'
import recharge from '@/components/recharge.vue';
import addAddres from '@/components/addAddres.vue';
import { getorginfo, wechatshareqrcode, querybingaccountstatus } from '@/api/login.js';

import { export_json_to_excel } from '@/utils/Export2Excel';
export default {
    components: {
        recharge,
        addAddres,
    },
    data() {
        var validatorAccount = (rule, value, callback) => {
            let reg = /^[1][1,2,3,4,5,7,8,9][0-9]{9}$/;		//	手机

            if (!value) {
                // message: '请输入手机号',
                return callback(new Error('请输入手机号'));
            }


            if (!reg.test(value)) {
                return callback(new Error('手机号格式不正确'));
            } else {

                callback();
            }


        };

        var validatorEmail = (rule, value, callback) => {
            let reg = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;		//	手机

            if (!value) {
                // message: '请输入手机号',
                return callback(new Error('请输入邮箱地址'));
            }


            if (!reg.test(value)) {
                return callback(new Error('请输入正确的邮箱地址格式'));
            } else {

                callback();
            }


        };
        return {
            userInfo: {
            },
            merchatAccountView: {},
            mechatinfo: {},
            priceNum: 0,
            userType: 0,
            menuList: [
                { label: '消费订单', value: 0, icon: 'iconfont icon-a-zu2' },
                { label: '我的优惠券', value: 1, icon: 'iconfont icon-a-zu3' },
                { label: '成员管理', value: 2, icon: 'iconfont icon-a-zu4' },
                { label: '地址管理', value: 3, icon: 'iconfont icon-a-zu5' },
                { label: '商户设置', value: 4, icon: 'iconfont icon-a-zu6' },
                { label: '账号管理', value: 5, icon: 'iconfont icon-a-zu281' },
            ],
            menuActive: 0,
            financeUserId: '',
            productId: '',
            ewmLoadding: false,
            loading: false,
            financeLoading: false,
            financeList: [],
            quanList: [],
            merchatUser: [],
            memberList: [],
            address: [],
            addresForm: {},
            selectCs: {
                provinces: '',
                citys: '',
                districts: '',
            },
            member: {
                pageIndex: 1,
                pageTotal: 0,
                Key: '',
            },
            finance: {
                pageIndex: 1,
                pageTotal: 0,
                StartTime: '',
                EndTime: '',
            },
            addressf: {
                pageIndex: 1,
                pageTotal: 0,
            },
            quan: {
                pageIndex: 1,
                pageTotal: 0,
            },
            status: 0,
            quanMa: '',
            orginfo: {},
            orgData: {},
            circleUrl: '',
            setting: {
                id: '',
            },
            quanMenu: [
                {
                    name: '未使用',
                    value: '0',
                    arr: [],
                },
                {
                    name: '已使用',
                    value: '1',
                    arr: [],
                },
                {
                    name: '已过期',
                    value: '-1',
                    arr: [],
                },
            ],
            value1: '',
            ewmImg: '',
            quanActive: 0,
            dialogMember: false,
            dialogAddress: false,
            dialogAccount: false,
            isVisible: false,
            isEdit: false,
            productEditShow: false,
            ruleForm: {
                name: "",
                account: '',
                phone: '',
                email: '',
                id: '',
            },

            rules: {
                account: [
                    { validator: validatorAccount, required: true, message: '请输入账号', trigger: ['click', 'change', 'blur'] }
                ],
                name: [
                    { required: true, message: '请输入联系人', trigger: ['click', 'change', 'blur'] },
                ],
                phone: [
                    { validator: validatorAccount, required: true, trigger: ['click', 'change', 'blur'] }
                ],
                email: [
                    { validator: validatorEmail, required: true, trigger: ['click', 'change', 'blur'] },
                ]

            },
            rules1: {
                phone: [
                    { validator: validatorAccount, required: true, trigger: ['click', 'change', 'blur'] }
                ],
                email: [
                    { validator: validatorEmail, required: true, trigger: ['click', 'change', 'blur'] },
                ]
            }


        }
    },
    created() {
        this.userType = this.$store.state.userInfo.userType;
        //userType 2是商铺成员3是商铺管理员
        if (this.userType == 2) {
            this.menuList = this.menuList.filter(v => { return v.label !== '成员管理' && v.label !== '商户设置' })
        }
    },
    mounted() {
        //this.getorgdataFn();
        this.gethomepagedataFn();
        if (this.$router.history.current.query.active) {
            this.menuActive = this.$router.history.current.query.active;
        } else {
            this.menuActive = 0;
        }
        this.openFz(false);
        this.getmechatinfoFn();
        this.getbalanceFn();
        this.getQueryfinance();
    },


    watch: {
        quanActive(val) {
            this.status = val

            this.getQueryusercoupon();
        },
        quanMa(e) {
            console.log(e);
        },
        'member.Key'(e) {
            console.log(e);
            this.getQuerymerchatmembers();
        },

        $route(val, oldVal) {
            if (val.query.active != oldVal.query.active) {
                this.menuActive = val.query.active
            }

            console.log(val);
        },

    },
    methods: {
        exportExcel() {

        },
        openOrder(status, str) {

            var query = {}

            if (str == 'orderStatus') {
                query = {
                    OrderStatus: status
                }
            } else {
                query = {
                    ApplcantStatus: status
                }
            }

            this.$router.push({ name: 'order', query })
        },
        getmechatinfoFn() {
            getmechatinfo({}).then(res => {


                console.log(res);
                this.mechatinfo = res.data ? res.data : []
            })
        },
        getbalanceFn() {
            getbalance({}).then(res => {
                console.log(res);

                this.priceNum = res.data
                this.$store.commit('SET_BALANCE', res.data);
            })
        },
        getorgdataFn() {
            getorgdata({}).then(res => {
                console.log(res);

                this.orgData = res.data;
            })
        },
        //查询店铺首页数据
        gethomepagedataFn(){
            gethomepagedata({}).then(res=>{
                this.orgData = res.data;
            })
        },
        getQueryfinance(bol = false) {
            this.loading = true;
            queryfinance({
                'Page.Size': 10, 'Page.Index': this.finance.pageIndex,
                StartTime: this.finance.StartTime, EndTime: this.finance.EndTime,
                UserId: this.financeUserId,
                IsExport: bol === true ? true : false
            }).then(res => {
                this.loading = false;
                this.financeList = res.data.rows
                this.finance.pageTotal = parseFloat(res.data.total)
                if (bol === true) {
                    this.financeList.forEach(item => {
                        if (item.flag == 0) {
                            item.amountStr = '+' + item.amount
                        } else {
                            item.amountStr = '-' + item.amount

                        }
                    })
                    // this.fromOrder.UserId
                    const tHeader = ['商品名称', '国家', '时间', '余额变化（元）', '剩余余额（元）', '操作人', '事由', '备注',] // 表格头部
                    const filterVal = ["title", 'countryName', 'createdTime', 'amountStr', 'balance', 'userName', 'typeName', 'marks',] // 表格头部对应的数据字段名，用来过滤
                    const filename = '消费记录'
                    const data = this.formatJson(filterVal, this.financeList)

                    // 文件数据处理完成后，会自动下载
                    export_json_to_excel({
                        header: tHeader,
                        data,
                        filename
                    })
                }
            })
        },

        getQuerymerchatmembers() {

            this.loading = true;
            querymerchatmembers({
                'Page.Size': 10,
                'Page.Index': this.member.pageIndex,
                Key: this.member.Key
            }).then(res => {
                console.log(res);
                this.loading = false;
                this.memberList = res.data.rows;
                this.member.pageTotal = parseFloat(res.data.total)
            })
        },

        getQueryaddress(bol = false) {
            this.loading = true;

            queryaddress({
                'Page.Size': 6,
                'Page.Index': this.addressf.pageIndex,

            }).then(res => {
                console.log(res);
                this.loading = false;
                this.address = res.data.rows;
                this.addressf.pageTotal = res.data.total
                this.address.forEach(item => {
                    item.isShow = false;
                })


            })
        },




        getprovincesFn() {
            getprovinces({}).then(res => {
                console.log(res);

                this.provinces = res.data
            })
        },

        getuserinfoFn() {
            getuserinfo({
            }).then(res => {
                this.userInfo = res.data
                this.merchatAccountView = this.userInfo.merchatAccountView
            })
        },

        openFz(bol = false) {

            merchatuserlist({
            }).then(res => {
                console.log(res, 999);


                this.merchatUser = res.data
            })
        },
        /**
         *  格式数据，对象数据格式 转为 json格式
         *  @filterVal  格式头
         *  @tableData  用来格式的表格数据
         */
        formatJson(filterVal, tableData) {
            return tableData.map((v) => {
                return filterVal.map((j) => {
                    return v[j]
                })
            })
        },


        handleCurrentChange(value) {
            this.pageIndex = value
            this.getQueryfinance();
        },
        quanCurrentChange(e) {
            this.quan.pageIndex = e;
            this.getQueryusercoupon();
        },
        memberCurrentChange(e) {
            this.member.pageIndex = e;
            this.getQuerymerchatmembers();
        },
        addressCurrentChange(e) {
            this.addressf.pageIndex = e;
            this.getQueryaddress(false);
        },
        addresFormUpdate() {

            console.log(2224)
            this.dialogAddress = false;
            this.getQueryaddress(false)
        },
        getQueryusercoupon() {
            let vm = this;

            queryusercoupon({ token: vm.$store.state.token, Status: this.status, 'Page.Size': 8, 'Page.Index': vm.quan.pageIndex }).then(res => {
                console.log(res)
                vm.quanList = [];
                vm.quanList = res.data.rows
                vm.quan.pageTotal = parseFloat(res.data.total)
                vm.quanMenu.forEach(item => {
                    item.arr = []
                    vm.quanList.forEach(elem => {

                        elem.startTime = elem.startTime.split(' ')[0];
                        elem.expiredTime = elem.expiredTime.split(' ')[0];

                        if (item.value == elem.status) {
                            item.arr.push(elem);
                        }
                    })
                })
            })
        },

        selectDate(e) {
            let vm = this;

            console.log(e)
            if (e) {
                vm.finance.StartTime = e[0]
                vm.finance.EndTime = e[1]

            } else {
                vm.finance.StartTime = ''
                vm.finance.EndTime = ''
            }
            vm.getQueryfinance();
        },

        editOrder(item) {

            this.$router.push({ name: 'orderInfoDetails', params: { details: item.orderId } })

        },

        checkTh() {
            if (this.quanMa.length < 6) {
                return;
            } else {




                // this.$message('这是一条消息提示');
                exchangecoupon({
                    code: this.quanMa
                }).then(res => {
                    console.log(res);

                    if (res.code == 0) {
                        this.$message({
                            message: res.msg,
                            type: 'error',
                        });
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'success',
                        });
                    }
                })
            }
        },
        addMember() {
            this.dialogMember = true
        },
        showEditProduct(e) {
            console.log(e);

            if (e) {
                this.productId = e;
            } else {
                this.productId = '';
            }

            this.productEditShow = true;
        },
        showSetting(item) {
            this.setting = item;
        },
        openProduct() {
            this.productEditShow = false;
            this.setting = {
                id: '',
            }
        },
        addAddress() {
            this.addresForm = {}
            this.dialogAddress = true;
        },
        yrChange(item) {
            console.log(1);
            this.address.forEach(elem => {
                if (item.id == elem.id) {
                    elem.isShow = true;
                } else {
                    elem.isShow = false;
                }
            })
            this.$forceUpdate();
        },
        ycChange(item) {
            this.address.forEach(elem => {
                // if (item.id == elem.id) {
                //     elem.isShow = true;
                // } else {
                // }
                elem.isShow = false;
            })
            this.$forceUpdate();
        },
        submitForm(formName) {
            console.log(this.$refs[formName]);
            this.$refs[formName].validate((valid) => {
                if (valid) {

                    if (this.ruleForm.id) {
                        updatemerchatmember({
                            ...this.ruleForm,
                            enable: true,
                        }).then(res => {
                            this.$message({
                                message: '修改成功',
                                type: 'success'
                            });
                            this.dialogMember = false;
                            this.getQuerymerchatmembers();
                        })
                    } else {

                        addmerchatmember({
                            ...this.ruleForm,
                            enable: true,
                        }).then(res => {
                            console.log(res);

                            this.$message({
                                message: '添加成功',
                                type: 'success'
                            });
                            this.dialogMember = false;
                            this.getQuerymerchatmembers();
                        })
                    }
                    this.resetForm('ruleForm')
                } else {
                    return false;
                }
            });
        },

        resetForm(formName) {
            this.dialogMember = false;
            this.ruleForm = {
                name: '',
                account: '',
                phone: '',
                email: '',
                id: '',
            }
            if (formName) {


                this.$refs[formName].resetFields();
            }
        },



        setEnable(item) {
            console.log(item);
            this.$confirm(`是否要${item.enable ? '停用' : '启用' + item.name}`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                modifymemberstatus({
                    id: item.id,
                    enable: !item.enable,
                }).then(res => {
                    this.$message({
                        message: '修改成功',
                        type: 'success'
                    });
                    this.getQuerymerchatmembers();
                })
            }).catch(res => {

            })


        },
        editMember(item) {
            this.dialogMember = true

            this.ruleForm = {
                name: item.name,
                account: item.account,
                phone: item.phone,
                email: item.email,
                id: item.id,
            }
        },

        editAddress(item) {
            let vm = this;




            vm.addresForm = item

            this.dialogAddress = true;
        },
        closeAddress(item) {

            this.$confirm(`是否要要删除该条地址`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                deladdress({
                    id: item.id,
                }).then(res => {
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    });
                    this.getQueryaddress(false);
                })
            }).catch(res => {

            })


        },
        getEwmInfo() {
            console.log(this.merchatAccountView);

            this.ewmLoadding = true;
            wechatshareqrcode({
                appId: this.orginfo.wechatApp.appId,
                path: `pages/user/merchant/bind_account?type=${1}&accountId=${this.merchatAccountView.id}&phone=${this.merchatAccountView.phone}`,
                orgId: this.merchatAccountView.orgId
            }).then(res => {
                console.log(res);
                this.ewmImg = res.data;
                this.ewmLoadding = false;
                // this.timetwo = setInterval(() => {
                //     querybingaccountstatus({
                //         type:1,
                //         accountId: this.merchatAccountView.id,
                //         changeBindUser: true,
                //     }).then(res => {

                //         if (res.data == '绑定成功') {
                //             clearInterval(this.timetwo)
                //             // this.onLogin();
                //             this.dialogAccount = false;
                //         }
                //     })
                // }, 1000)


            })
        },
        setDefault(item) {
            this.$confirm(`是否设置该条地址为默认地址`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                setdefaultaddress({
                    id: item.id,
                }).then(res => {
                    this.$message({
                        message: '设置成功',
                        type: 'success'
                    });
                    this.getQueryaddress(false);
                })
            }).catch(res => {

            })
        },
        editUser() {
            this.isEdit = true;
        },
        editUserInfo() {
            this.$refs['userForm'].validate(valid => {
                if (valid) {
                    updatemerchatmember({
                        id: this.merchatAccountView.id,
                        name: this.merchatAccountView.name,
                        phone: this.merchatAccountView.phone,
                        email: this.merchatAccountView.email,
                        account: this.merchatAccountView.account
                    }).then(res => {
                        console.log(res);
                        this.$message({
                            message: '修改成功',
                            type: 'success'
                        });
                        this.isEdit = false;
                    })
                } else {
                    return false;
                }
            })
        },
        getorginfoFn() {
            getorginfo({
            }).then(res => {
                console.log(res);

                this.orginfo = res.data

            })
        },
        checkMenu(item) {
            this.menuActive = item.value
            if (item.value == 0) {

                this.getQueryfinance();
            }

            if (item.value == 1) {
                this.getQueryusercoupon();
            }

            if (item.value == 2) {
                this.getQuerymerchatmembers();
            }

            if (item.value == 3) {
                this.getQueryaddress(false);
                this.getprovincesFn();

            }

            if (item.value == 5) {
                this.getorginfoFn();
                this.getuserinfoFn();
            }

            if (item.value == 6) {
                this.productEditShow = false;
            }
        },
        handleClick(val) {
            this.quanActive = val.name
        },
    }
}


</script>

<style lang="scss" scoped>
.personal {
    display: flex;
    height: 100%;
    flex-direction: column;
    white-space: nowrap;
    flex: 1;

    .el-pager {
        .active {
            cursor: default;
            background: #1492E6;
            color: #FFF !important;
            border-radius: 100%;
            width: 28px;
            height: 28px;
            min-width: 10px;
            box-shadow: 0px 2px 3px 1px rgba(19, 152, 231, 0.26);
        }
    }

    .el-table__header-wrapper {
        border-radius: 8px 8px 0 0;
    }
}



.account-class {
    background: none !important;
    border-radius: 12px;

    .el-dialog {
        background: none;
    }

    .el-dialog__header {
        display: none;
    }

    .el-dialog__body {
        width: 631px;
        height: 323px;
        background: linear-gradient(180deg, #E7ECFB 0%, #F6F8FE 24%, #FFFFFF 100%);
        border-radius: 12px;
        box-sizing: border-box;
        overflow: hidden;
    }

    &.address-class {
        .el-dialog__body {
            height: 519px;

        }
    }
}


.demo-ruleForm {
    ::v-deep .el-form-item {
        display: flex;
        flex-direction: column;
        margin-bottom: 6px;

        .el-form-item__label {
            width: 50px !important;
            white-space: nowrap;
            height: 30px;
        }

        .el-form-item__content {
            margin-left: 0px !important;
        }
    }

    ::v-deep .el-form-item__content {
        text-align: right;

        .el-button {
            margin-top: 20px;
        }
    }
}

.member-class {
    background: none !important;
    border-radius: 12px;

    .el-dialog {
        background: none;
    }

    .el-dialog__header {
        display: none;
    }

    .el-dialog__body {
        width: 372px;
        height: 441px;
        background: linear-gradient(180deg, #E7ECFB 0%, #F6F8FE 24%, #FFFFFF 100%);
        border-radius: 12px;
        box-sizing: border-box;
        overflow: hidden;
    }

    &.address-class {
        .el-dialog__body {
            height: 519px;

        }
    }
}
</style>
<style lang="scss" scoped>
.page {
    background: #F7F8FA;
    padding-bottom: 24px;
}

.page-content {
    width: 1200px;
    margin: 0 auto;
    height: calc(100vh - 100px);
}

.address {
    flex: 1;
    padding: 20px 28px;
    box-sizing: border-box;
    position: relative;
    // min-height: 600px;

    .box-input {
        position: absolute;
        right: 20px;
        top: 20px;
    }

    .address-list {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-top: 60px;


        .address-item {
            width: 273px;
            height: 158px;
            box-shadow: 0px 3px 3px 1px rgba(#000000, 0.1);
            position: relative;
            padding: 16px;
            box-sizing: border-box;
            margin-right: 28px;
            margin-bottom: 28px;

            .address-is-default {
                position: absolute;
                width: 60px;
                height: 23px;
                line-height: 23px;
                background: #3888E9;
                border-radius: 4px 0px 0px 4px;
                top: 16px;
                right: 0;
                font-size: 12px;
                color: #FFF;
                text-align: center;

            }

            &::before {
                content: "";
                background: url('../../assets/images/personal/top-x.png');
                background-size: 100%;
                position: absolute;
                width: 100%;
                left: 0;
                top: 0;
                height: 2px;
            }

            .address-item-top {
                display: flex;
                align-items: flex-end;

                p {
                    font-size: 16px;
                    font-weight: bold;
                }

                span {
                    font-size: 12px;
                    color: #777777;
                    margin-left: 8px;
                }
            }

            .address-center {
                font-size: 12px;
                color: #444444;
                margin-top: 16px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                height: 33px;
            }

            .address-email {
                margin-top: 8px;
                font-size: 12px;
            }

            .address-btn {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin-top: 12px;

                .address-btn-1 {
                    background: #3888E9;
                    width: 64px;
                    height: 22px;
                    line-height: 22px;
                    border-radius: 4px;
                    font-size: 10px;
                    color: #FFF;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .address-del {
                    width: 22px;
                    height: 22px;
                    border-radius: 4px;
                    background: #E41313;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: 8px;
                    cursor: pointer;

                    i {
                        color: #FFF;
                    }
                }

            }
        }
    }
}

.dialog-account-wrap {
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    position: relative;

    .dialog-close {
        position: absolute;
        right: 0px;
        top: -20px;
        width: 25px;
        height: 25px;
        background: #E0E0E0;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .el-icon-close {
            color: #FFF;
            font-size: 16px;
        }
    }

    .account-left {
        display: flex;
        flex-direction: column;

        .account-left-title {
            font-size: 24px;
            font-weight: bold;
        }

        .account-left-list {
            margin-top: 28px;

            .account-left-item {
                display: flex;
                align-items: center;
                margin-bottom: 12px;

                .account-left-icon {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    background: #C0D4FF;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;

                    i {
                        color: #3888E9;
                    }

                    .op-ifoncnt {
                        position: absolute;
                        font-size: 8px;
                        bottom: 2px;
                        right: 2px;
                        color: #5FDBB3;
                    }

                }

                span {
                    font-size: 16px;
                    font-weight: bold;
                    margin-left: 16px;
                }
            }

        }
    }

    .account-right {

        .account-dwc {
            width: 183px;
            height: 183px;
            background: #FFFFFF;
            box-shadow: 0px 0px 6px 1px rgba(56, 136, 233, 0.44);
            border-radius: 8px 8px 8px 8px;
            position: relative;

            img {
                width: 100%;
                height: 100%;
            }

            .recharge-bom-left-loading {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
                background: #000000;
                opacity: .45;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 2;
            }

            .recharge-bom-left-loading-p {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 3;
            }
        }

        .account-bom {
            margin-top: 16px;
            display: flex;
            flex-direction: column;
            align-items: center;

            span {
                font-size: 16px;
                font-weight: 700;
            }
        }
    }

}

.dialog-member-wrap {
    .dialog-member-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: -10px;

        .dialog-member-name {
            font-size: 18px;
            font-weight: bold;

        }

        .dialog-close {
            width: 25px;
            height: 25px;
            background: #E0E0E0;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            .el-icon-close {
                color: #FFF;
                font-size: 16px;
            }
        }
    }
}

.page-user {
    width: 1200px;
    height: 194px;
    background: #3888E9;
    border-radius: 8px;
    margin-top: 24px;
    padding: 24px 20px;
    display: flex;
    box-sizing: border-box;
    position: relative;
    margin-bottom: 24px;

    .img-right {
        position: absolute;
        right: 110px;
        width: 178px;
        height: 170px;



    }

    .page-user-info {
        display: flex;
        flex-direction: column;
        margin-left: 27px;

        .user-name {
            font-size: 28px;
            color: #FFF;
            font-weight: bold;
        }

        .user-addr {
            font-size: 16px;
            color: #FFF;
            // margin-top: 4px;
        }

        .page-user-pay {
            display: flex;
            // margin-top: 10px;

            .user-pay-item {
                width: 141px;
                height: 70px;
                background: #FFF;
                border-radius: 8px;
                margin-right: 16px;
                display: flex;
                flex-direction: column;
                padding: 8px 16px;
                box-sizing: border-box;
                cursor: pointer;

                &:hover {
                    p {
                        color: #3888E9;
                    }

                    span {
                        color: #3888E9;
                    }
                }

                p {
                    font-size: 12px;
                    color: #666666;

                }

                span {
                    font-size: 24px;
                    font-weight: bold;
                    margin-top: 4px;
                }
            }
        }
    }

    .user-right {
        flex: 1;
        text-align: right;
        position: relative;

        .user-right-label {
            font-size: 16px;
            color: #FFF;
        }

        .user-right-price {
            font-size: 28px;
            color: #FFF;
            font-weight: bold;
        }

        .user-btn-bg {
            position: absolute;
            right: -10px;
            bottom: 38px;
            width: 61px;
            height: 22px;
            background: url('@/assets/images/personal/kuang.png');
            background-position: 100%;
            background-size: 100%;
            text-align: right;
            font-size: 10px;
            color: #FFF;
            text-align: center;
        }

        .price-btn {
            background: #F8912C;
            color: #FFF;
            font-size: 16px;
            border: 1px solid #F8912C;
            margin-top: 32px;
            border-radius: 8px;

        }
    }
}

.member {
    flex: 1;
    padding: 20px 28px;
    box-sizing: border-box;
    position: relative;


}

.member-edit {
    color: #3888E9;
    margin: 6px;
    font-weight: bold;
    cursor: pointer;
}

// 高度铺满
.page-box {
    height: calc(100% - 255px);

    // >div {
    //     height: 100%;
    // }
    .box-table {
        height: calc(100% - 100px);

        .el-table {
            height: 100% !important;
        }
    }

    .el-tabs {
        height: 100%;

        ::v-deep .el-tabs__content {
            height: calc(100% - 60px);

            >div {
                height: 100%;

                .quan-content {
                    height: calc(100% - 100px);
                    overflow-y: auto;

                    .quan-list {
                        // height: calc(100% - 100px);
                    }
                }
            }
        }
    }

    .address {
        overflow: auto;

        .address-content {
            height: calc(100% - 115px);
            overflow-y: auto;
            margin-top: 60px;

            .address-list {
                margin-top: 0;
                // height: calc( 100% - 100px);
            }
        }
    }


}

.page-box {
    width: 1200px;
    background: #FFF;
    border-radius: 8px;
    // height: 645px;
    // margin-top: 24px;
    display: flex;

    // min-height: 600;
    // margin-bottom: 100px;
    // min-height: 700px;
    .page-box-left {
        width: 187px;
        // height: 100%;
        border-right: 1px solid #E6E6E6;
        display: flex;
        flex-direction: column;
        padding: 20px;
        box-sizing: border-box;

        .page-box-item {
            display: flex;
            align-items: center;
            height: 46px;
            border-radius: 8px;
            margin-bottom: 12px;
            cursor: pointer;

            .page-box-icon {
                margin-left: 20px;
                margin-right: 13px;
            }

            .page-box-label {
                font-size: 16px;
                white-space: nowrap;
            }

            &.page-box-item-active {

                background: #F2F6FF;

                .page-box-icon {
                    color: #3888E9;
                }

                .page-box-label {
                    color: #3888E9;

                }
            }

        }
    }

    .box-input {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        .button-color {
            border: 1px solid #3CA4E9;
            color: #3CA4E9;
        }

    }

    .page-box-right {
        padding: 20px 28px;
        width: 100%;
        flex: 1;



        .box-table {

            .color-l {
                color: #1C9C1C;
            }

            .color-red {
                color: #E41313;
            }
        }

    }

    .table-pagination {
        text-align: center;
        margin-top: 16px;
    }

    .page-box-certificate {
        flex: 1;
        padding: 20px 28px;
        box-sizing: border-box;
        position: relative;

        .page-box-certificate-input {
            position: absolute;
            right: 20px;
            top: 17px;
            z-index: 2;

            .page-ys-input {
                background: #F2F4F7;
                height: 29px;
                line-height: 29px;
                width: 148px;
                border-radius: 100px 0 0 100px;
                padding-left: 30px;
                padding-right: 30px;
                box-sizing: border-box;
            }

            .page-ys-btn {
                background: #3888E9;
                border-radius: 0 100px 100px 0;
                width: 73px;
                height: 29px;
                border: none;
                font-size: 12px;
                color: #FFF;
                margin-left: -12px;
                cursor: pointer;

                &.page-ys-btn-bg {
                    background: #CCCCCC;

                }
            }
        }

        .quan-pagintion {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 45px;
        }

        .quan-list {
            display: flex;
            flex-direction: flex;
            align-items: flex-start;
            flex-wrap: wrap;
            // height: 450px;

            .quan-empty {
                margin: auto;
            }

            .quan-item {
                width: 334px;
                height: 92px;
                background: url('@/assets/images/personal/quan1.png') no-repeat;
                background-size: 100%;
                background-position: 100%;
                display: flex;
                margin-right: 20px;
                margin-bottom: 20px;
                position: relative;

                &.quan-item-bg2 {
                    background: url('@/assets/images/personal/quan2.png') no-repeat;
                    background-size: 100%;
                    background-position: 100%;
                }

                .quan-r-icon {
                    width: 42px;
                    height: 32px;
                    position: absolute;
                    right: 4px;
                    top: 4px;
                }

                .quan-item-left {
                    color: #FFF;
                    font-size: 32px;
                    display: flex;
                    align-items: center;
                    padding-left: 12px;
                    width: 70px;

                    span {
                        font-size: 16px;
                        margin-top: 12px;

                    }
                }

                .quan-item-right {
                    margin-left: 40px;
                    margin-top: 14px;
                    display: flex;
                    flex-direction: column;

                    p {
                        font-size: 16px;
                        font-weight: bold;
                    }

                    .quan-item-desc {
                        font-size: 12px;
                        color: #666666;
                        margin-top: 4px;
                    }

                    .quan-item-desc2 {
                        font-size: 12px;
                        color: #666666;
                        margin-top: 4px;
                    }
                }
            }
        }
    }
}




.merchant-set {
    flex: 1;
    padding: 20px 28px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 76px;
    overflow: auto;
    // min-height: 600px;

    .img-right-1 {
        width: 210px;
        height: 173px;
    }

    .merchant-set-title {
        width: 336px;
        text-align: center;
        font-size: 16px;
        color: #CCCCCC;
        margin-top: 34px;
    }

    .merchant-set-btn {
        width: 138px;
        height: 49px;
        background: #3888E9;
        border-radius: 8px;
        color: #FFF;
        font-size: 16px;
        margin-top: 15px;
    }

    .merchant-set-bom {
        margin-top: 34px;

        img {
            width: 26px;
            height: 22px;
        }

        span {
            font-size: 16px;
            color: #3888E9;
            margin-left: 16px;
        }
    }

}

.account-manage {
    padding: 20px 28px;
    overflow: auto;
    // min-height: 600px;

    .account-title {
        font-size: 16px;
        color: #969696;

        &.account-title-user {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .account-btn {
                width: 48px;
                height: 30px;
                line-height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #3888E9;
                border-radius: 4px;
                color: #FFF;
            }

            span {
                &:nth-child(2) {
                    color: #3888E9;
                    font-size: 16px;
                    cursor: pointer;
                }
            }
        }
    }

    .account-user-info {
        width: 877px;
        background: #FFFFFF;
        border-radius: 8px;
        border: 1px solid #E7E7E7;
        box-sizing: border-box;
        margin-top: 16px;
        display: flex;
        flex-direction: column;

        .account-item {
            flex: 3;
            // border-bottom: 1px solid #E7E7E7;
            display: flex;
            align-items: center;
            padding: 0 16px;

            &:nth-last-child(1) {
                border-bottom: none;
            }

            .account-label {
                font-size: 16px;
                font-weight: bold;
                width: 96px;
            }

            .account-input {
                font-size: 16px;
                color: #333;

            }
        }
    }

    .account-wx {
        width: 877px;
        height: 85px;
        background: #FFFFFF;
        box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.12);
        border-radius: 8px 8px 8px 8px;
        margin-top: 16px;
        display: flex;
        align-items: center;
        padding-left: 16px;
        box-sizing: border-box;

        .wx-name {
            margin-left: 25px;
        }
    }

    .account-content {
        width: 877px;
        height: 54px;
        border: 1px solid #E7E7E7;
        border-radius: 8px;
        display: flex;
        align-items: center;
        padding: 0 16px;
        margin-top: 16px;
        box-sizing: border-box;

        .account-label {
            font-size: 16px;
            font-weight: bold;
            width: 96px;
        }

        .account-input {
            font-size: 16px;
            color: #333;

        }
    }
}

.user-form {
    ::v-deep .el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
        display: none;
    }

    ::v-deep .el-form-item__error {
        left: 15px;
    }

    ::v-deep .el-form-item {
        padding: 0 16px;
        margin-bottom: 17px;

        .el-form-item__label {
            font-size: 16px;
            font-weight: bold;
            color: #000;
        }
    }
}
</style>