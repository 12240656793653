import Vue from 'vue'


const MyDirectives = {}

export default MyDirectives.install = function (vue, options) {

  // 防止el-button重复点击
  Vue.directive('preventReClick', {
    bind(el, binding) {
      el.addEventListener('click', () => {
        if (!el.disabled) {
          el.disabled = true
          setTimeout(() => {
            el.disabled = false
          }, binding.value || 1500)
        }
      })
    }
  })

  Vue.directive('clickOutside', {
    bind(el, binding, vnode) {
      function documentHandler(e) {
        if (el.contains(e.target)) {
          return false;
        }
        if (binding.expression) {
          binding.value(e);
        }
      }
      el.__vueClickOutside__ = documentHandler;
      document.addEventListener('click', documentHandler);
    },
    unbind(el, binding) {
      document.removeEventListener('click', el.__vueClickOutside__);
      delete el.__vueClickOutside__;
    }
  });

}