<!-- 产品管理列表 -->
<template>
    <div class="product-user">
        <div class="box-input">

            <div class="box-input-left">
                <el-select class="box-input-left-z" @clear="clearContinent" style="width: 120px" clearable
                    v-model="productForm.continentId" placeholder="请选择洲区" @change="changeContinet">
                    <el-option v-for="item in continent" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>

                <el-select clearable v-model="productForm.countryId" @change="getQuery" placeholder="请选择国家">
                    <el-option v-for="item in country" :key="item.id" :label="item.zhName" :value="item.id">
                    </el-option>
                </el-select>

                <el-select clearable v-model="productForm.visaTypeId" @change="getQuery" placeholder="请选择签证大类">
                    <el-option v-for="item in visaType" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>

                <!-- <el-select v-model="productForm.areaId" placeholder="请选择领区">
                    <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select> -->

                <el-input style="width: 130px;" v-model="productForm.name" placeholder="产品名称"></el-input>

            </div>

            <div class="box-input-btn">
                <el-button class="default" @click="submit">搜索</el-button>
                <el-button class="box-input-btn-cz" @click="reset">重置</el-button>
                <el-button class="box-input-btn-cz" @click="openAdd">添加</el-button>

            </div>
        </div>


        <div class="box-table">
            <el-table :data="productList"
                :header-cell-style="{ backgroundColor: '#F2F4F7', color: '#333', fontWeight: 'bold', textAlign: 'center', fontSize: '12px' }"
                style="width: 100%" v-loading="loading" :height="$store.state.product.screen ? '820px' : '470px'"
                :cell-style="{ 'text-align': 'center' }" ref="refTable">

                <el-table-column prop="title" label="产品名称">
                </el-table-column>
                <el-table-column prop="areaName" label="国家-领区">
                    <template slot-scope="scope">
                        <div>
                            {{ scope.row.countryName }} - {{ scope.row.areaName }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="typeName" label="签证">
                    <template slot-scope="scope">
                        <div>
                            {{ scope.row.typeName }} - {{ scope.row.subTypeName }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="visaLegend" label="签证图例">
                    <template slot-scope="scope">
                        <div v-if="scope.row.visaLegend">
                            <el-image style="width: 88px; height: 55px;border-radius: 8px;" :src="scope.row.visaLegend"
                                :preview-src-list="[scope.row.visaLegend]">
                            </el-image>
                        </div>
                        <p v-else>未配置</p>
                    </template>
                </el-table-column>

                <el-table-column prop="price2" label="成本价" width="70px">
                </el-table-column>
                <el-table-column prop="price3" label="商户价" width="70px">
                </el-table-column>
                <el-table-column prop="price4" label="零售价" width="70px">
                </el-table-column>
                <el-table-column prop="sort" label="排序" width="60px">
                </el-table-column>
                <el-table-column prop="enable" label="是否上架" width="70px">
                    <template slot-scope="scope">
                        <div>
                            <el-switch v-model="scope.row.enable" active-color="#3888E9" inactive-color="#CCCCCC"
                                @change="switchChange(scope.row)">
                            </el-switch>

                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="操作" header-align="center">
                    <template slot-scope="scope">
                        <div class="edit-wrap">
                            <div class="edit-item">
                                <span class="lan" @click="editProduct(scope.row.id)">编辑</span>
                                <span class="lan" @click="toDataSettings(scope.row)">资料</span>
                            </div>
                            <div class="edit-item">
                                <span class="copy" @click="setProductCopy(scope.row.id)">复制</span>
                                <span class="del" @click="delProduct(scope.row.id)">删除</span>
                            </div>

                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <div class="table-pagination">

            <el-pagination @current-change="currentChange" :current-page="pageIndex" :page-size="10"
                layout="total, prev, pager, next, jumper" :total="pageTotal">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import { query, visatypelist, arealist, countrylist, deleteProduct, visaenable, getcountrys, productCopy } from '@/api/personal.js'


export default {
    data() {
        return {
            continent: [],
            visaType: [],
            area: [],
            country: [],
            productList: [],
            countryData: [],
            productForm: {
                continentId: '',
                visaTypeId: '',
                countryId: '',
                areaId: '',
                name: ''
            },
            pageIndex: 1,
            pageTotal: 0,
            loading: false,

        }
    },
    porps: {
        init: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        this.continent = this.$store.state.product.countrys.continentCountry


        console.log(this.$store.state.product.screen);
        this.getQuery();
        this.getVisatypelist();

        // this.getArealist();
        this.getCountrylist();

        this.getcountrysFn();

        if (this.init) {
            this.continent = this.$store.state.countrys.continentCountry

            this.getQuery();
            this.getVisatypelist();

            // this.getArealist();
            // this.getCountrylist();
        }
    },
    methods: {
        getQuery() {
            this.loading = true;

            query({
                'Page.Size': 10,
                'Page.Index': this.pageIndex,
                Title: this.productForm.name,
                Continent: this.productForm.continentId,
                VisaTypeId: this.productForm.visaTypeId,
                CountryId: this.productForm.countryId
            }).then(res => {
                console.log(res);
                this.loading = false;
                this.productList = res.data.rows
                this.pageTotal = res.data.total
            })
        },

        setProductCopy(id) {

            this.$confirm(`是否要复制该产品`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {


                productCopy({
                    id
                }).then(res => {
                    this.$message({
                        message: '复制成功！',
                        type: 'success'
                    });
                    this.getQuery();
                })


            }).catch(err => {

            })

            return;

        },

        reset() {
            this.productForm = {
                continentId: '',
                visaTypeId: '',
                countryId: '',
                areaId: '',
            }
            this.getQuery();
        },

        submit() {
            this.getQuery();
        },
        clearContinent(e) {

            this.country = []
            this.productForm.countryId = ''
            console.log(e);
        },
        getcountrysFn() {
            getcountrys({

            }).then(res => {
                console.log(res);

                this.countryData = res.data.continentCountry
            })
        },

        editProduct(id) {
            this.$emit('open', id);
        },

        toDataSettings(item) {
            this.$emit('openSettings', item);
        },

        delProduct(id) {
            this.$confirm(`是否要删除该产品`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                deleteProduct({
                    id: id,

                }).then(res => {
                    this.$message({
                        message: '删除成功！',
                        type: 'success'
                    });
                    this.getQuery();
                }).catch(res => {

                })

            }).catch(err => {

            })
        },

        changeContinet(e) {

            console.log(e);

            this.countryData.forEach(item => {
                if (item.value == e) {
                    this.country = item.datas
                }
            })


            this.getQuery();
        },

        openAdd() {
            console.log(111);
            this.$emit('open');
        },

        switchChange(item) {
            item.enable = !item.enable
            this.$confirm(`是否要${!item.enable ? '上架' : '下架'}该产品`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                visaenable({
                    id: item.id,
                    enable: !item.enable,

                }).then(res => {
                    this.getQuery();
                }).catch(res => {

                })

            }).catch(res => {

            })
        },

        // 签证大类
        getVisatypelist() {
            visatypelist({

            }).then(res => {
                console.log(res);
                this.visaType = res.data;
            })
        },
        // 领区
        // getArealist () {
        //     arealist({
        //          
        //     }).then(res => {
        //         console.log(res);
        //         this.area = res.data;
        //     })
        // },

        getCountrylist() {
            countrylist({

            }).then(res => {
                console.log(res);

                // this.country = res.data
            })
        },
        currentChange(val) {
            this.pageIndex = val;
            this.$refs.refTable.bodyWrapper.scrollTop = 0;
            this.getQuery();
        }

    }
}
</script>

<style lang="scss" >
.product-user {
    padding: 16px;

    .el-select {
        margin-right: 8px;
    }

    .el-input__inner {
        background: #F2F4F7;
        border: none;
    }

    .el-table__row {
        .cell {
            font-size: 12px;
        }
    }
}
</style>

<style lang="scss" scoped>
// @media screen and (min-width: 2100px) {
//         .product-user {
//             height: 1000px;
//         }
// }

// @media screen and (max-width: 1800px) {
//         .page-box {
//             height: 400px;
//         }
// }

// @media screen and (max-width: 2100px) and (min-width: 1800px) {
//     .product-user {
//         height: 600px;
//     }
// }

.product-user {
    width: 973px;


}

.box-input-left {
    display: flex;

}

.box-table {
    // flex-grow: 1;
    // height: 100%;
    // display: flex;
    // flex-direction: column;
    // flex: 1;
}

.edit-wrap {

    .edit-item {
        // padding-left: 20px;


        span {
            cursor: pointer;
            display: inline-block;
            margin: 6px;

            &.lan {
                color: #3888E9;
            }

            &.copy {
                color: #9EA09E;
            }

            &.del {
                color: #E41313;
            }
        }
    }
}

.table-pagination {
    text-align: center;
    margin-top: 16px;
}

.box-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;

    .box-input-btn {
        text-align: right;

        .box-input-btn-cz {
            background: #EAF3FF;
            color: #3888E9;
            border: none;
        }

        .box-input-btn-add {
            background: #D8F5E4;
            color: #54D8A5;
            border: none;
        }
    }
}
</style>