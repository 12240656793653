<template>
    <div class="page order-pay">

        <div class="content-wrap">

            <div class="flex-scroll">
                <div class="scroll-info">
                    <div :class="['scroll-info-item', { 'isCurrent': item.isCurrent }]"
                        v-for="(item, index) in orderDetail.orderProcess" :key="index">
                        <div class="scroll-info-item-left">
                            <div class="scroll-icon" :class="[item.isCurrent ? 'scroll-icon-isCurrent' : '']">
                                <div class="scroll-icon-yes" v-if="item.isCurrent">
                                    <img src="@/assets/images/common/logo2.png" alt="">
                                </div>

                                <img v-else src="https://file.sport-syy.com/visa/admin/images/pc/yes.png" alt="">
                            </div>
                            <p class="scroll-text">{{ item.title }}</p>
                            <p class="scroll-time">{{ item.time }}</p>
                        </div>
                        <div class="scroll-info-item-right" v-if="index !== orderDetail.orderProcess.length - 1"></div>
                    </div>
                </div>
            </div>
            <div class="flex-content-info">
                温馨提示：出签前请勿提前预定机票酒店！由于近期领事馆政策变动较频繁，预计出签时间仅为往常经验参考，实际出参时间可能延迟，请以领事馆或签证中心结果为准。
            </div>

            <div class="flex-box">
                <div class="flex-title">
                    <span class="flex-title-y"></span>
                    <p class="flex-title-name">物流信息</p>
                </div>
                <div class="flex-wl-box">
                    <div class="flex-wl-top">
                        <span>{{ orderDetail.order.exCompany }}</span>
                        <span>{{ orderDetail.order.expressNo }}</span>
                    </div>
                    <div class="flex-wl-bom" :style="{ height: !outheight ? '100%' : '220px' }" v-if="wuList.length > 0">
                        <el-timeline>
                            <!-- :color="`${ activity.isCurrent ? '#3888E9' : '#CCCCCC' }`" -->
                            <el-timeline-item v-for="(activity, index) in wuList" :key="index" :timestamp="activity.Ftime"
                                :color="`${wuList.length == (index + 1) ? '#3888E9' : '#CCCCCC'}`">
                                <div class="flex-wl-infoo">
                                    <p>{{ activity.Context }}</p>
                                    <!-- <p>{{ activity.Time }} - {{ activity.Ftime }}</p> -->
                                </div>

                            </el-timeline-item>
                        </el-timeline>
                    </div>
                    <div class="flex-wl-up" v-if="wuList.length > 0" @click="outheight = !outheight">{{ outheight ? '查看更多' :
                        '收起' }}<i :class="outheight ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"></i></div>
                    <p v-if="!wuList.length" class="no-info"> <i class="iconfont icon-a-paichu4"></i> 未绑定物流信息哦！</p>
                </div>
            </div>


            <div class="flex-box">
                <div class="flex-title">
                    <div class="place-user">
                        <img :src="orderDetail.visaDetail && orderDetail.visaDetail.visa && orderDetail.visaDetail.visa.nationalFlagImg"
                            alt="">
                        <span> 【{{ orderDetail.visaDetail && orderDetail.visaDetail.visa &&
                            orderDetail.visaDetail.visa.areaName }}】</span>
                        <span>{{ orderDetail.visaDetail && orderDetail.visaDetail.visa && orderDetail.visaDetail.visa.title
                        }}</span>
                    </div>
                </div>
                <div class="place-user-list">
                    <div class="place-user-item" v-for="(item, index) in orderDetail.orderApplicants" :key="index">
                        <div class="place-user-item-left">
                            <i v-if="orderDetail.orderApplicants.length > 1" class="el-icon-delete"
                                @click.stop="delUser(index)"></i>
                            <span class="place-appName">{{ item.applicantName }}</span>
                            <span class="place-appName-type">{{ item.identityTypeName }}</span>

                        </div>

                        <div class="place-user-item-right" @click="toDetail(item)" v-if="!item.applicationForm">查看资料</div>
                        <div class="place-user-item-right" @click="openRlectron(item)" v-if="item.applicationForm">查看资料
                        </div>
                    </div>


                </div>
            </div>


            <div class="flex-box">
                <div class="flex-title">
                    <span class="flex-title-y"></span>
                    <p class="flex-title-name">支付信息</p>
                </div>
                <div class="flex-content">
                    <ul class="flex-content-list">
                        <li class="flex-content-item">
                            <span class="flex-content-lable">应付金额</span>
                            <p class="flex-content-value">{{ orderDetail.order.payableAmount }}</p>
                        </li>
                        <li class="flex-content-item">
                            <span class="flex-content-lable">商品金额</span>
                            <p class="flex-content-value">{{ orderDetail.order.amount }}</p>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="flex-box">
                <div class="flex-title">
                    <span class="flex-title-y"></span>
                    <p class="flex-title-name">出签回寄</p>
                </div>
                <div class="flex-content">
                    <ul class="flex-content-list">
                        <li class="flex-content-item">
                            <span class="flex-content-lable">收件人</span>
                            <p class="flex-content-value">{{ orderDetail.order.userName }}</p>
                        </li>
                        <li class="flex-content-item">
                            <span class="flex-content-lable">电话号码</span>
                            <p class="flex-content-value">{{ orderDetail.order.userPhone }}</p>
                        </li>
                        <li class="flex-content-item">
                            <span class="flex-content-lable">邮箱地址</span>
                            <p class="flex-content-value">{{ orderDetail.order.emailAddress }}</p>
                        </li>
                        <li class="flex-content-item">
                            <span class="flex-content-lable">收货地址</span>
                            <p class="flex-content-value">{{ orderDetail.order.userProvince }}</p>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="flex-box">
                <div class="flex-title">
                    <span class="flex-title-y"></span>
                    <p class="flex-title-name">订单信息</p>
                </div>
                <div class="flex-content">
                    <ul class="flex-content-list">
                        <li class="flex-content-item">
                            <span class="flex-content-lable">联系人</span>
                            <p class="flex-content-value">{{ orderDetail.order.contacts }}</p>
                        </li>
                        <li class="flex-content-item">
                            <span class="flex-content-lable">电话号码</span>
                            <p class="flex-content-value">{{ orderDetail.order.contactPhone }}</p>
                        </li>
                        <li class="flex-content-item">
                            <span class="flex-content-lable">订单号</span>
                            <p class="flex-content-value">{{ orderDetail.order.orderNo }}</p>
                        </li>
                        <li class="flex-content-item">
                            <span class="flex-content-lable">商品名称</span>
                            <p class="flex-content-value">{{ orderDetail.order.visaTitle }}</p>
                        </li>
                        <li class="flex-content-item">
                            <span class="flex-content-lable">订单状态</span>
                            <p class="flex-content-value">{{ orderDetail.order.statusName }}</p>
                        </li>
                        <li class="flex-content-item">
                            <span class="flex-content-lable">预计出行</span>
                            <p class="flex-content-value">{{ orderDetail.order.departureTime }}</p>
                        </li>
                        <!-- <li class="flex-content-item">
                            <span class="flex-content-lable">生效时间</span>
                            <p class="flex-content-value">{{ orderDetail.order.createdTime }}</p>
                        </li> -->
                        <li class="flex-content-item">
                            <span class="flex-content-lable">下单时间</span>
                            <p class="flex-content-value">{{ orderDetail.order.createdTime }}</p>
                        </li>
                        <li class="flex-content-item">
                            <span class="flex-content-lable">支付时间</span>
                            <p class="flex-content-value">{{ orderDetail.order.paymentTime }}</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { orderdetail } from '@/api/order.js'

export default {
    data() {
        return {
            id: '',
            orderDetail: {
                order: {},
                orderApplicants: [],
                orderProcess: [],
                visaDetail: {
                    basicInfo: [],
                    offlineVisaDocs: [],
                    visa: {},
                    visaDocs: [],
                },
            },
            wuList: [],
            outheight: true,
        }
    },
    created() {
        this.id = this.$router.history.current.params.details
        this.getOrderdetail();
    },
    methods: {
        getOrderdetail() {
            let vm = this;

            orderdetail({
                id: vm.id,
                token: vm.$store.state.token
            }).then(res => {
                console.log(res);
                vm.orderDetail = res.data;

                if (vm.orderDetail.order.expressResult) {

                    vm.wuList = JSON.parse(vm.orderDetail.order.expressResult).data

                    vm.orderDetail.orderProcess.forEach(item => {
                        item.time = item.time.split(' ')[0].slice(5)
                    })
                }

            })
        },
        toDetail(item) {
            console.log(item)
            let vm = this;

            vm.$router.push({ name: 'information', params: { id: `${this.id}_${item.id}` } })
        },
        openRlectron(item) {
            let vm = this;
            vm.$router.push({
                name: "electron",
                params: {
                    id: `${item.applicantName}_${item.identityTypeName}_${vm.id}`,
                },
            });
        },
    }
}
</script>

<style lang="scss" scoped>
.place-user {
    font-size: 20px;
    font-weight: bold;

    img {
        width: 24px;
        height: 18px;
    }
}

.order-pay {
    min-height: 700px;
    background: #F7F8FA;
    padding-bottom: 60px;

    .content-wrap {
        width: 1200px;

        margin: 0 auto;
        margin-top: 49px;
    }

    .flex-content-info {
        width: 1200px;
        background: #FFEBCF;
        font-size: 16px;
        color: #F8912C;
        padding: 16px;
        box-sizing: border-box;
        font-weight: bold;
    }

    .flex-box {
        margin-top: 20px;
        background: #FFF;
        box-sizing: border-box;
        padding-bottom: 6px;

        .flex-wl-box {
            padding: 16px;

            .flex-wl-top {
                display: flex;
                align-items: center;

                span {
                    font-size: 16px;
                    font-weight: bold;
                    margin-right: 10px;
                }
            }

            .flex-wl-bom {
                margin-top: 24px;
                overflow: hidden;

                .flex-wl-infoo {
                    display: flex;
                    align-items: center;

                    p {
                        &:nth-child(2) {
                            color: #c5bfbf;
                            margin-left: 10px;
                        }
                    }
                }
            }

            .flex-wl-up {
                color: #3888E9;
                cursor: pointer;
            }

            .no-info {
                color: #F8912C;
                text-align: center;
                font-size: 13px;
                padding-left: 10px;
            }
        }

        .flex-title {
            display: flex;
            align-items: center;
            padding: 16px 0;
            margin: 0 16px;
            border-bottom: 1px solid #EEEEEE;

            .flex-title-y {
                width: 3px;
                height: 20px;
                margin-right: 10px;
                background: #3888E9;
            }

            .flex-title-name {
                font-size: 20px;
            }
        }
    }

    .flex-content {
        padding: 20px;

        .flex-content-list {
            display: flex;
            flex-direction: column;

            .flex-content-item {
                display: flex;
                align-items: center;
                margin-bottom: 20px;

                &:last-child {
                    margin-bottom: 0px;
                }

                .flex-content-lable {
                    width: 150px;
                    font-display: 16px;
                    color: #999999;
                }

                .flex-content-value {
                    font-size: 16px;
                    font-weight: bold;
                }
            }
        }
    }

    .flex-scroll {
        height: 191px;
        width: 1200px;
        background: url('https://file.sport-syy.com/visa/admin/images/pc/pay-bg.png') no-repeat;
        background-size: 100%;
        background-position: 100%;
        // overflow: hidden;

        .scroll-info {
            display: flex;
            height: 100%;
            align-items: center;
            justify-content: center;
            padding: 12px;

            .scroll-info-item {
                display: flex;
                opacity: .65;

                .scroll-info-item-right {
                    width: 80px;
                    height: 2px;
                    background: #FFF;
                    margin-top: 29px;
                }

                .scroll-info-item-left {


                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .scroll-icon {
                        width: 58px;
                        height: 58px;
                        border: 1px solid #FFFFFF;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .scroll-icon-yes {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 50px;
                            height: 50px;
                            border: 3px solid #3888E9;
                            border-radius: 500%;

                            img {
                                width: 37px;
                                height: 34px;
                            }

                        }

                        &.scroll-icon-isCurrent {
                            background: #FFF;
                            margin: 4px;
                        }

                        img {
                            width: 26px;
                            height: 17px;
                        }
                    }

                    .scroll-text {
                        color: #FFF;
                        font-size: 16px;
                        width: 150px;
                        text-align: center;
                        height: 60px;
                        margin-top: 10px;
                        display: flex;
                        // align-items: center;
                        justify-content: center;
                    }

                    .scroll-time {
                        color: #FFF;
                        height: 40px;
                    }
                }
            }

            .isCurrent {
                opacity: 1;
                font-weight: bold;
            }
        }
    }
}

.place-user-list {
    // background: #F7F8FA;
    margin: 12px;
    // margin-top: 0px;
    padding: 0 18px;
    box-sizing: border-box;

    .place-user-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 0;
        border-bottom: 1px solid #E8E8E8;

        &:nth-last-child(1) {
            border-bottom: none;
        }

        .place-user-item-left {
            display: flex;
            align-items: center;

            i {
                cursor: pointer;
                color: #CCCCCC;
                margin-right: 16px;
            }
        }

        .place-user-item-right {
            color: #3888E9;
            font-size: 16px;
            font-weight: bold;
            cursor: pointer;
            border: 1px solid #3888E9;
            padding: 6px 12px;
            border-radius: 8px;
        }

        .place-appName {
            font-size: 16px;
            font-weight: bold;
            margin-right: 52px;

            width: 110px;
        }

        .place-appName-type {
            color: #999999;
            font-size: 16px;
        }
    }
}</style>