<template>
    <div class="page qz">
        <div class="page-top">
            <el-input placeholder="搜索办签国家/地区" v-model="valueInput" @focus="inputShow" @blur="outInput()"
                class="input-with-select" style="height: 40px;width: 973px;">
                <!-- <el-button slot="suffix" ic
          on="el-icon-search" class="btn-style"></el-button> -->
                <i slot="suffix" class="el-icon-search"></i>
            </el-input>
            <div class="srarch-input" v-if="inputShowBom" @mouseleave="inputShowBom = false; valueInput = ''">
                <div class="search-input-item" v-for="(item, index) in searchList" @click="pathRoute(item)" :key="index">
                    <span class="input-item-text1">{{ item.zhName }}</span>
                    <span class="input-item-text2">{{ item.visaTypes }}</span>
                </div>
            </div>
            <div class="page-search" v-if="isShow" @mouseleave="isShow = false">
                <div class="page-search-menu">
                    <div class="page-menu-item" :class="[index == menuActive ? 'menu-active' : '']"
                        @click="menuCheck(index)" v-for="(item, index) in list" :key="index">{{ item.name }}</div>
                </div>
                <div class="page-search-content">
                    <div class="page-content-item" v-for="(item, index) in contentObj.no" :key="index">
                        <span class="page-content-p">{{ item }}</span>
                        <div class="page-content-info">
                            <span v-for="(item, index) in contentObj.data[item]" :key="index" @click="pathRoute(item)">{{
                                item.zhName
                            }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="page-content">
            <div class="page-menu">
                <div class="page-menu-flex">

                    <p class="page-name">
                        <img :src="countrysDetail.nationalFlagImg" alt="">
                        <span>{{ countrysDetail.zhName }}</span>
                    </p>
                    <p class="page-y"></p>
                    <div class="page-y-flex" @mouseenter="flexShowFn" @mouseleave="flexHideFn">
                        <i class="el-icon-location"></i>
                        <span class="el-dropdown-link" slot="reference" @click="visible = true">
                            常住地-{{ provinceName.name }}<i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <div class="page-y-list" v-show="isflexShow">
                            <div class="page-y-item" @click="provinceId = item.id"
                                :class="[provinceId == item.id ? 'color-pro' : '']" v-for="(item, index) in province"
                                :key="index">{{
                                    item.name }}</div>
                        </div>
                    </div>

                    <!-- selectCom -->

                    <!-- <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item v-for="(item, index) in province" :key="index" :command="item">{{
                                item.name }}</el-dropdown-item>

                        </el-dropdown-menu> -->

                    <p class="page-y"></p>
                    <div class="page-visa" v-if="visaTypes.length > 0">
                        <div class="page-visa-item" v-for="(item, index) in visaTypes" @click="visaActive = item.id"
                            :class="[item.id == visaActive ? 'visa-active' : '']" :key="index"> <span class=""></span>
                            {{ item.name }}</div>
                    </div>
                </div>
                <!-- @click="openRl" -->
                <div class="page-menu-flex-r">
                    <p class="page-y"></p>
                    <span @click="()=>{initDate();dialogVisible = true}">使馆工作日日历</span>

                </div>
            </div>
            <div class="page-list">
                <div class="page-item" @click="toDetail(item)" v-for="(item, index) in visas" :key="index">
                    <div class="page-item-top">
                        <div class="page-info">
                            <span class="text-1">{{ item.typeName }}</span>
                            <span class="text-2">【{{ item.areaName }}】</span>
                            <span class="text-3">{{ item.title }}</span>
                        </div>
                        <div class="page-price" v-if="$store.state.user.token">{{ `￥${item.price1}` }}</div>
                        <div class="page-price-r" v-else>￥- <span>登录可见</span></div>
                    </div>
                    <div class="page-item-bom">
                        <span class="text-4">入境次数说明：{{ item.entyCountDesc }}</span>
                        <span class="text-4">有效期：{{ item.validityPeriod }}</span>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog title="使馆工作日日历" :visible.sync="dialogVisible" width="1100px">
            <div class="dialog-title">
                <p><span></span>公休日</p>
                <p><span style="background: #FF5A60;"></span>假期日</p>
            </div>
            <div class="wfcalendar-flex">
                <i class="el-icon-arrow-left" @click="monthtoleft"></i>
                <div id="wfcalendar">
                    <div class="calendar_header">
                        <!-- <p style="width:33%;cursor: pointer;" > 《 </p> -->
                        <p style="width:34%;text-align:center;font-size: 18px;">
                            <span>{{ thisyear }}</span> -
                            <span>{{ thismonth }}</span>
                            <!-- <span>{{ today }}</span> <br> -->
                            <!-- <span>星期{{ week[weekday] }}</span> -->
                        </p>
                        <!-- <p style="width:33%;text-align:right;cursor: pointer;" >》</p> -->

                    </div>
                    <hr>
                    <ul class="wf-week">
                        <li v-for="(item, index) in week" :key="index"><span>{{ item
                        }}</span>
                        </li>
                    </ul>
                    <ul class="wf-days">
                        <!-- :class='{ check: item.weeks == 6 | item.weeks == 0 && item.ismonth == 1 && item.checkShow , checkShow: item.checkShow, }'> -->

                        <li v-for="(item, index) in daylist" :key="index"
                            :class='{ check: item.checkShow == 1, checkShow: item.checkShow == 2, }'>
                            <span class="wf-days-flex" v-if="item.checkShow == 1">公</span>
                            <span class="wf-days-flex" v-if="item.checkShow == 2">假</span>
                            <span :class="{ fontcolor: item.ismonth == 0 }">{{ item.day }}</span>
                        </li>
                    </ul>
                </div>
                <div id="wfcalendar">
                    <div class="calendar_header">
                        <!-- <p style="width:33%;cursor: pointer;" @click="monthtoleft"> 《 </p> -->
                        <p style="width:34%;text-align:center;font-size: 18px;">
                            <span>{{ thisyear2 }}</span> -
                            <span>{{ thismonth2 }}</span>
                            <!-- <span>{{ today }}</span> <br> -->
                            <!-- <span>星期{{ week[weekday] }}</span> -->
                        </p>
                        <!-- <p style="width:33%;text-align:right;cursor: pointer;" @click="monthtoright">》</p> -->

                    </div>
                    <hr>
                    <ul class="wf-week">
                        <li v-for="(item, index) in week" :key="index"><span>{{ item
                        }}</span>
                        </li>
                    </ul>
                    <ul class="wf-days">
                        <!-- -->
                        <li v-for="(item, index) in daylist2" :key="index"
                            :class='{ check: item.checkShow == 1, checkShow: item.checkShow == 2, }'>
                            <span class="wf-days-flex" v-if="item.checkShow == 1">公</span>
                            <span class="wf-days-flex" v-if="item.checkShow == 2">假</span>
                            <span :class="{ fontcolor: item.ismonth == 0 }">{{ item.day }}</span>
                        </li>
                    </ul>
                </div>
                <i class="el-icon-arrow-right" @click="monthtoright"></i>
            </div>

            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </span>
        </el-dialog>
        <login :config="config.dialogVisible" v-if="config.dialogVisible" @close="config.dialogVisible = false"
            @refresh="handleRefresh"></login>
    </div>
</template>

<script>
import login from '@/components/login.vue';
import { getvisas, provincedata, queryembassy, getcountryandabbr } from '@/api/product.js';
import { getcountryandvisatype } from '@/api/common.js'
const myDate = new Date();
export default {
    watchQuery: true,
    components: {
        login: () => import('@/components/login.vue'),
    },
    data() {
        return {
            visible: false,
            list: [
                {
                    name: 'ABCD',
                    no: ['A', 'B', 'C', 'D'],
                    data: {
                        A: [],
                        B: [],
                        C: [],
                        D: [],
                    }
                },
                {
                    name: 'EFGHJK',
                    no: ['E', 'F', 'G', 'H', 'J', 'K'],
                    data: {
                        E: [],
                        F: [],
                        G: [],
                        H: [],
                        J: [],
                        K: [],
                    }
                },
                {
                    name: 'LMNO',
                    no: ['L', 'M', 'N', 'O'],
                    data: {
                        L: [],
                        M: [],
                        N: [],
                        O: [],
                    }
                },
                {
                    name: 'PQRST',
                    no: ['P', 'Q', 'R', 'S', 'T'],
                    data: {
                        P: [],
                        Q: [],
                        R: [],
                        S: [],
                        T: [],
                    }
                },
                {
                    name: 'WXYZ',
                    no: ['W', 'X', 'Y', 'Z'],
                    data: {
                        W: [],
                        X: [],
                        Y: [],
                        Z: [],
                    }
                },
            ],
            contentObj: {},
            dialogVisible: false,
            isflexShow: false,
            config: {
                dialogVisible: false,
            },
            valueInput: '',
            countrysDetail: {},
            id: '',
            name: '',
            provinceId: 19, //  省id
            province: [],   //  省份列表
            visaTypes: [],  //  签证类型
            visasList: [],
            visaActive: 0,
            value4: [],
            menuActive: 0,
            valueInput: '',
            isShow: false,
            inputShowBom: false,
            searchList: [],

            week: ['日', '一', '二', '三', '四', '五', '六'],
            daylist: [],
            daylist2: [],
            thisyear: '',
            thisyear2: '',
            thismonth: '',
            thismonth2: '',
            today: '',
            weekday: '',
            year: '',
            month: '',
            day: '',
            weeks: '',

        }
    },

    watch: {

        valueInput(val, oldVal) {

            // if (this.isShow && ) {

            // }
            if (val != oldVal) {

                if (val && this.isShow) {
                    this.isShow = false;
                    this.getcountrysInput(val)
                    this.inputShowBom = true;
                }

                if (!val && !this.isShow) {
                    this.isShow = true;
                    this.inputShowBom = false;
                    // this.getcountrysInput(val)
                }

                if (!val) {
                    this.inputShowBom = false;
                }
            }


        },
        $route(val, oldVal) {
            if (val.params.id != oldVal.params.id) {
                this.initData();
                // this.$forceUpdate();
            }
        }
    },
    computed: {
        provinceName() {
            var obj = {}
            this.province.forEach(item => {
                if (item.id == this.provinceId) {
                    obj = item
                }
            })
            this.getvisasFn(this.id);
            return obj
        },
        visas() {
            let visas = []

            visas = this.visasList.filter(item => {
                if (item.visaTypeId == this.visaActive) {
                    return item;
                }
            })

            return visas
        }
    },

    async mounted() {
        this.initData();
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    },
    methods: {
        async initData() {
            if (this.$router.history.current.params.id) {
                this.$store.commit('SET_COUNTRYS_DETAIL_ID', this.$router.history.current.params.id.split('_')[0]);
                this.id = this.$router.history.current.params.id.split('_')[0]
            } else {
                this.id = this.$store.state.countrysDetailId;
            }
            //初始化年月日星期
            this.initDate()
            this.getcountryandabbrFn();
            this.provincedataFn();
            this.getvisasFn(this.id);
            this.content = this.$router.history
        },
        async initDate(){
            this.year = this.thisyear = myDate.getFullYear()
            this.month = this.thismonth = (myDate.getMonth() + 1 < 10) ? "0" + (myDate.getMonth() + 1) : myDate.getMonth() + 1
            this.day = this.today = (myDate.getDate() < 10) ? "0" + (myDate.getDate()) : myDate.getDate()
            this.weeks = this.weekday = myDate.getDay()
            myDate.getMonth()
            this.thismonth2 = this.thismonth == 12 ? '01' : (myDate.getMonth() + 2 < 10) ? "0" + (myDate.getMonth() + 2) : myDate.getMonth() + 2
            this.thisyear2 = this.thismonth == 12 ? myDate.getFullYear() + 1 : myDate.getFullYear()
            this.daylist = await this.getcalendar(this.thisyear, this.thismonth)
            this.daylist2 = await this.getcalendar(this.thisyear2, this.thismonth2)
        },
        async getvisasFn(id) {

            if (!id) return

            let res = await getvisas({ CountryId: id, ProvinceId: this.provinceId });
            // this.$store.commit('SET_COUNTRYS_DETAIL', res.data);
            // this.countrysDetail = this.$store.state.countrysDetail;
            this.countrysDetail = res.data.country;
            this.visaTypes = res.data.visaTypes
            this.visasList = res.data.visas;


            if (this.visaTypes.length > 0) {
                this.visaActive = this.visaTypes[0].id
            }
        },
        provincedataFn() {
            provincedata({}).then(res => {
                this.province = res.data.province
            })
        },
        async getcountrysInput(val) {
            let res = await getcountryandvisatype({
                key: val
            })
            this.searchList = res.data
        },
        menuCheck(index) {
            this.menuActive = index;
            this.contentObj = JSON.parse(JSON.stringify(this.list[index]));
        },
        async getcountryandabbrFn() {
            let res = await getcountryandabbr({})
            let arr = res.data;
            arr.forEach(item => {
                this.list.forEach(elem => {
                    if (item.abbreviation && elem.name.indexOf(item.abbreviation) != -1) {
                        elem.data[item.abbreviation].push({
                            ...item,
                        });
                    }
                })
            })
            this.contentObj = JSON.parse(JSON.stringify(this.list[0]));
        },
        selectCom(e) {

            this.provinceId = e.id
        },

        openRl() {
            this.dialogVisible = true;
        },
        pathRoute(item) {
            this.$router.push({ name: 'productDetail', params: { id: item.id + "_" + item.zhName } })
        },
        inputShow() {
            if (!this.valueInput) {
                this.isShow = true;
            }
        },
        flexShowFn() {
            this.isflexShow = true;
        },
        flexHideFn() {
            this.isflexShow = false;
        },

        outInput() {
            if (this.inputShowBom) {
                this.inputShowBom = true;
            } else {
                this.inputShowBom = false;
                this.valueInput = ''
            }
        },
        toDetail(item) {

            if (!this.$store.state.user.token) {

                this.config.dialogVisible = true;
            } else {
                this.$router.push({ name: 'productDetailId', params: { id: item.id, provinceId: this.provinceId } })

            }
        },
        handleRefresh() {
            this.config.dialogVisible = false
        },

        async getcalendar(thisyear, thismonth) {
            if (this.value4.length > 0) {
            } else {

                let res = await queryembassy({
                    countryId: this.id
                })
                this.value4 = JSON.parse(JSON.stringify(res.data));
            }
            var arrs = this.value4.map(item => {

                item.date = item.date.split(' ')[0]

                return item
            })
            let daysnumber = new Date(thisyear, thismonth, 0).getDate();//获取当月天数
            let beginweeks = (new Date(thisyear, parseInt(thismonth) - 1, 1).getDay());//获取当月1日是星期几，确定1日开始位置
            let list = []
            let mos = ''
            // 获取上个月的天数
            if (thismonth == 1) {
                mos = new Date(thisyear, 11, 0).getDate()
            } else {
                mos = new Date(thisyear, thismonth - 1, 0).getDate()
            }
            //补全当前月之前空缺位置
            if (beginweeks != -1) {

                for (var i = beginweeks; i > 0; i--) {
                    let arr = {
                        day: mos--,
                        ismonth: '0'
                    }
                    list.push(arr)
                }
            } else {
                list = []
            }
            var daylist = list.length > 0 ? list.reverse() : []

            for (var j = 1; j <= daysnumber; j++) {
                var weeks = this.weekday = new Date(thisyear, (parseInt(thismonth) - 1), j).getDay()
                let data = j < 10 ? ("0" + j) : j
                let dateStr = thisyear + '-' + thismonth + '-' + data;
                let checkShow = 0;
                arrs.forEach(item => {
                    if (item.date == dateStr) {
                        checkShow = item.type;
                    }
                })
                let arr = {
                    day: j,
                    ismonth: '1',
                    weeks,
                    checkShow,
                }
                daylist.push(arr)
            }
            let endweeks = new Date(thisyear, thismonth - 1, daysnumber).getDay();//获取当月最后一天是星期几

            if (daylist.length > 35) {
                var num = 7
            } else {
                var num = 14
            }
            //补全每月结束之后空缺位置
            for (var m = 1; m < num - endweeks; m++) {
                let arr = {
                    day: m,
                    ismonth: '0'
                }
                daylist.push(arr)
            }

            return daylist;
        },
        //上一月
        async monthtoleft() {
            if (this.thismonth > 2) {
                if (this.thismonth == 12) {
                    this.thisyear2 = this.thisyear2 - 1
                }


                this.thismonth = parseInt(this.thismonth - 2)
                this.thismonth2 = this.thismonth + 1
                this.thismonth = (this.thismonth < 10) ? '0' + this.thismonth : this.thismonth
                this.thismonth2 = (this.thismonth2 < 10) ? '0' + this.thismonth2 : this.thismonth2


            } else {


                if (this.thismonth == 2) {
                    this.thismonth2 = '01'
                    this.thismonth = 12
                    this.thisyear = this.thisyear - 1

                }

                if (this.thismonth == 1) {
                    this.thismonth = 11
                    this.thismonth2 = 12
                    this.thisyear = this.thisyear - 1
                    this.thisyear2 = this.thisyear2 - 1

                }

            }
            this.daylist = await this.getcalendar(this.thisyear, this.thismonth);
            this.daylist2 = await this.getcalendar(this.thisyear2, this.thismonth2);

        },
        //下一月
        async monthtoright() {
            if (this.thismonth < 10) {

                this.thismonth = parseInt(this.thismonth) + 2
                this.thismonth2 = this.thismonth + 1

                this.thismonth = (this.thismonth < 10) ? '0' + this.thismonth : this.thismonth
                this.thismonth2 = (this.thismonth2 < 10) ? '0' + this.thismonth2 : this.thismonth2

            } else {
                if (this.thismonth == 10) {

                    this.thismonth = 12
                    this.thismonth2 = '01'
                    this.thisyear = this.thisyear
                    this.thisyear2 = this.thisyear + 1

                }

                if (this.thismonth == 11) {
                    this.thismonth = '01'
                    this.thisyear = this.thisyear + 1;
                    this.thismonth2 = '02'
                    this.thisyear2 = this.thisyear

                }

                if (this.thismonth == 12) {
                    this.thismonth = '02'
                    this.thisyear = this.thisyear + 1;
                    this.thismonth2 = '03'
                }


            }
            this.daylist = await this.getcalendar(this.thisyear, this.thismonth);
            this.daylist2 = await this.getcalendar(this.thisyear2, this.thismonth2);


        },
        //获取点击日期
        // checkday(days, ismonth) {
        //     if (ismonth == 1) {
        //         this.year = this.thisyear
        //         this.month = this.thismonth
        //         this.day = this.today = days
        //         this.weeks = this.weekday = new Date(this.thisyear, this.thismonth - 1, days).getDay()
        //     }
        // },


    }
}
</script>

<style lang="scss">
.qz {

    .el-dropdown-link {
        color: #409EFF;
        font-size: 16px;
        // padding: 0 17px;
        z-index: 11;
        margin-left: 5px;
    }

    .el-icon-arrow-down {
        font-size: 12px;
    }

    .el-input__inner {
        cursor: pointer;
    }

    .el-picker-panel__footer {
        display: none !important;
    }

    .el-date-table {
        pointer-events: none;
    }

    .wfcalendar-flex {
        display: flex;
        align-items: center;
        padding: 0 20px;

        .el-icon-arrow-left,
        .el-icon-arrow-right {
            font-size: 30px;
            cursor: pointer;
        }
    }

    // .wf-days-flex {

    // }

    #wfcalendar {
        background: white;
        padding: 10px;
        margin: 0 20px;
        /* width: 624px; */
        border: 1px solid #eaebed;
        max-height: 503px;
        box-sizing: border-box;
    }

    #wfcalendar hr {
        background: #eaebed;
        height: 1px;
        border: none;
        margin: 0;
        /* width: 644px; */
        margin-left: -10px;
    }

    #wfcalendar .wf-week li {
        width: 14%;
        height: 58px;
        line-height: 58px;
        text-align: center;
        display: inline-block;
        cursor: pointer;
        margin-bottom: 1px;
        position: relative;
    }

    #wfcalendar .wf-week li .weektoday {
        display: inline-block;
        width: 30px;
        height: 30px;
        background: #ffb400;
        line-height: 30px;
        border-radius: 15px;
        color: white;
    }

    #wfcalendar .wf-days li {
        display: inline-block;
        height: 58px;
        width: 14%;
        line-height: 58px;
        margin: 0px auto;
        border-radius: 4px;
        background: #eef7fe;
        text-align: center;
        margin-right: 1px;
        margin-bottom: 1px;
        position: relative;
        .wf-days-flex {
            position: absolute;
            bottom: -15px;
            right: 24px;
            font-size: 12px;
        }
    }

    #wfcalendar .wf-days li .fontcolor {
        color: #ccc;
        cursor: not-allowed;
        display: inline-block;
        width: 100%;
        height: 100%;
        /* pointer-events:none; */
    }

    #wfcalendar .wf-days .checkShow {
        background: #FF5A60;

        span {
            color: #FFF;
        }
    }

    #wfcalendar .wf-days .check {
        background: #599FFF;

        span {
            color: #FFF;
        }
    }

    #wfcalendar .calendar_header {
        height: 37px;
        width: 100%;
        color: #5e7a88;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #wfcalendar .calendar_header p {
        display: inline-block;
    }

    #wfcalendar .calendar_header p img {
        width: 26px;
        height: 26px;
        cursor: pointer;
    }

    #wfcalendar .calendar_header p span {
        display: inline-block;
        cursor: pointer;
        /* width: 100%; */
    }

    #wfcalendar .calendar_header p span:hover {
        color: #5cb3ff;
    }

    #wfcalendar .checkyears li {
        display: inline-block;
        width: 25%;
        text-align: center;
        line-height: 100px;
        cursor: pointer;
    }

    #wfcalendar .checkyears .check {
        color: #2176d9;
    }
}
</style>

<style lang="scss" scoped>
.srarch-input {
    position: absolute;
    top: 60px;
    width: 970px;
    border-radius: 2px;
    box-shadow: 0 8px 12px 0 rgba(57, 61, 70, .1);
    z-index: 11;

    .search-input-item {
        height: 50px;
        background: #FFF;
        display: flex;
        align-items: center;
        padding: 0 20px;

        .input-item-text1 {
            font-weight: bold;
            font-size: 18px;
            padding: 0 10px;
        }

        .input-item-text2 {
            font-weight: bold;
            font-size: 18px;
            margin-left: 24px;
            color: #91999f;
        }

        &:hover {
            background: #f7f8fa;
            cursor: pointer;

            .input-item-text1 {
                color: #1890FF;
            }
        }
    }
}

.page-search {
    position: absolute;
    top: 64px;
    width: 626px;
    margin-left: -340px;
    background: #FFF;
    box-shadow: 0 8px 12px 0 rgba(57, 61, 70, .1);
    border-radius: 4px;
    z-index: 11;

    .page-search-menu {
        display: flex;
        align-items: center;
        height: 60px;
        padding: 10px 20px;
        box-sizing: border-box;
        background: rgba(89, 159, 255, .08);

        .page-menu-item {
            margin-right: 20px;
            cursor: pointer;

            &.menu-active {
                color: #599fff;
                font-weight: bold;
            }
        }
    }

    .page-search-content {
        background: #FFF;
        padding: 20px 30px;

        .page-content-item {
            display: flex;
            margin-bottom: 15px;
        }

        .page-content-p {
            color: #0064FF;
        }

        .page-content-info {
            margin-left: 12px;
            display: flex;
            flex-wrap: wrap;

            span {
                font-size: 14px;
                color: #2f333d;
                margin-right: 15px;
                cursor: pointer;

                &:hover {
                    color: #0064FF;
                }
            }


        }
    }
}

.page-y-flex {
    display: flex;
    align-items: center;
    padding: 0 17px;
    cursor: pointer;
    height: 60px;
    position: relative;
    z-index: 2;

    &:hover {
        -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .12);

        &::before {

            content: "";
            width: 99%;
            position: absolute;
            left: 0;
            bottom: -5px;
            height: 10px;
            z-index: 2;
            background-color: #fff;

        }
    }


    .page-y-list {
        position: absolute;
        width: 440px;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .12);
        top: 100%;
        left: 0;
        background: #FFF;
        padding: 20px;
        padding-right: 0px;
        padding-bottom: 10px;
        box-sizing: border-box;
        z-index: -1;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;

        // display: none;
        .page-y-item {
            width: 70px;
            font-size: 14px;
            margin-bottom: 10px;

            &:hover {
                color: #1890FF;
            }
        }
    }

    .el-icon-location {
        font-size: 16px;
        color: #1890FF;
        margin-top: 3px;

    }
}

.color-pro {
    color: #1890FF;
}

.display-block {
    display: block;
}

.display-none {
    display: none;
}

.page {
    width: 100%;
    flex: 1;
    background: #F5F6F7;
}

.page-picker {
    height: 400px;
}

.dialog-title {
    display: flex;
    margin-top: -10px;
    margin-bottom: 10px;
    margin-left: 70px;

    p {
        font-size: 16px;
        display: flex;
        align-items: center;
        margin-right: 15px;

        span {
            width: 16px;
            height: 16px;
            background: #599FFF;
            border-radius: 4px;
            margin-right: 5px;
        }
    }
}

.page-top {
    height: 80px;
    flex: 1;
    background: url('../../assets/images/common/bg2.png');
    background-size: cover;
    background-position: center;

    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
}

.page-content {
    min-height: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .page-menu {
        width: 1200px;
        height: 60px;
        background: #FFF;
        margin-top: 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 16px;
        box-sizing: border-box;

        .page-menu-flex {
            display: flex;
            align-items: center;
        }

        .page-menu-flex-r {
            display: flex;
            align-items: center;
            position: relative;

            span {
                color: #1890FF;
                font-size: 16px;
                cursor: pointer;
            }

        }

        .page-name {
            display: flex;
            align-items: center;
            margin-right: 17px;

            img {
                width: 23px;
                height: 15px;
                margin-left: 16px;
            }

            span {
                font-size: 20px;
                font-weight: bold;
                margin-left: 7px;
            }
        }

        .page-y {
            // margin-left: 17px;
            // margin-right: 17px;
            width: 1px;
            height: 16px;
            background: #EFEFEF;
        }

        .page-visa {
            display: flex;
            margin-left: 17px;
            height: 100%;

            .page-visa-item {
                margin-right: 32px;
                cursor: pointer;

                &.visa-active {
                    color: #1890FF;
                    position: relative;

                    span {
                        position: absolute;
                        bottom: -5px;
                        left: 50%;
                        margin-left: -9px;
                        width: 18px;
                        height: 3px;
                        background: #0064FF;
                        border-radius: 2px;
                    }
                }
            }
        }

    }


    .page-list {
        width: 1200px;
        display: flex;
        flex-direction: column;
        margin-top: 16px;

        .page-item {
            width: 1200px;
            height: 94px;
            padding: 24px;
            border-bottom: 1px solid #EFEFEF;
            background: #FFF;
            box-sizing: border-box;
            border-left: 3px solid #FFFFFF;

            &:hover {
                background: #F3F9FF;
                cursor: pointer;
                border-left: 3px solid #1890FF;
            }

            .page-item-top {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                .page-info {
                    display: flex;
                    align-items: center;
                    width: 100%;

                    .text-1 {
                        padding: 0 4px;
                        height: 19px;
                        line-height: 19px;
                        color: #FFF;
                        font-size: 12px;
                        background: #1890FF;
                        border-radius: 2px;
                    }

                    .text-2,
                    .text-3 {
                        display: flex;
                        font-size: 16px;
                        font-weight: bold;
                        margin-left: 5px;
                    }
                }

                .page-price {
                    color: #E50000;
                    font-weight: bold;
                    font-size: 14px;
                }

                .page-price-r {
                    display: flex;
                    flex-wrap: nowrap;
                    align-items: center;
                    font-size: 14px;
                    margin-right: 14px;
                    color: #E50000;

                    span {
                        display: block;
                        white-space: nowrap;
                        height: 20px;
                        line-height: 20px;
                        padding: 0 5px;
                        margin-left: 10px;
                        color: #1890FF;
                        border: 1px solid #1890FF;
                        font-size: 12px;
                    }
                }
            }

            .page-item-bom {
                margin-top: 9px;

                .text-4 {
                    color: #999999;
                    font-size: 12px;
                    margin-right: 12px;
                }
            }
        }
    }
}

.el-icon-search {
    height: 40px;
    line-height: 40px;
    margin-right: 24px;
    font-size: 24px;
}</style>

