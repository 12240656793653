<template>
  <div class="package-page">
    <div class="box-input">
      <div class="box-input-left">
        <el-input style="width: 671px; margin-right: 12px" v-model="formInfo.name" placeholder="请输入套餐描述"></el-input>
      </div>

      <div class="box-input-btn">
        <el-button class="default" @click="queryFn">搜索</el-button>
        <el-button class="box-input-btn-cz" @click="reset">重置</el-button>
        <el-button class="box-input-btn-cz" @click="addPackge">新增套餐</el-button>
      </div>
    </div>

    <div class="box-table">
      <el-table :data="list" :header-cell-style="{
        backgroundColor: '#F2F4F7',
        color: '#333',
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: '12px',
      }" style="width: 100%" :height="$store.state.product.screen ? '820px' : '470px'" v-loading="formInfo.loading"
        :cell-style="{ 'text-align': 'center' }"
        ref="refTable">
        <el-table-column prop="desc" label="描述"> </el-table-column>
        <el-table-column prop="price" label="实际支付"> </el-table-column>
        <el-table-column prop="rechargeAmount" label="充值金额"> </el-table-column>
        <el-table-column prop="tag" label="标签">
          <template slot-scope="scope">
            <div class="tag-sty" :class="[setTag(scope.row.tag).bg]">
              {{ setTag(scope.row.tag).text }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="couponList" label="优惠券">
          <template slot-scope="scope">
            <div class="coupon-list" @click="editQuan(scope.row)">
              {{ !scope.row.couponList ? "未配置" : scope.row.str }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="saleCount" label="已售次数"> </el-table-column>
        <el-table-column prop="enable" label="是否展示">
          <template slot-scope="scope">
            <div>
              <el-switch v-model="scope.row.enable" active-color="#3888E9" inactive-color="#CCCCCC"
                @change="switchChange(scope.row)">
              </el-switch>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" header-align="center">
          <template slot-scope="scope">
            <div class="edit-wrap">
              <div class="edit-item">
                <span class="lan" @click="editPackage(scope.row)">编辑</span>
                <span class="del" @click="delProduct(scope.row.id)">删除</span>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="table-pagination">
      <el-pagination @current-change="memberCurrentChange" :current-page="formInfo.pageIndex" :page-size="10"
        layout="total, prev, pager, next, jumper" :total="formInfo.total">
      </el-pagination>
    </div>

    <!-- 添加套餐 -->
    <el-dialog :visible.sync="dialogMember" width="385px" class="packge-class" :close-on-click-modal="false">
      <div class="dialog-member-wrap">
        <div class="dialog-member-title">
          <span class="dialog-member-name">新增充值套餐</span>
          <div class="dialog-close" @click="resetForm('ruleForm')">
            <i class="el-icon-close"></i>
          </div>
        </div>
        <div class="dialog-form-wrap">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="packge-ruleForm">
            <el-form-item label="充值金额:" prop="rechargeAmount">
              <el-input v-model="ruleForm.rechargeAmount" type="Number" placeholder="请输入充值金额"></el-input>
            </el-form-item>
            <el-form-item label="实际支付:" prop="price">
              <el-input v-model="ruleForm.price" type="Number" placeholder="请输入价格" max="10000000"></el-input>
            </el-form-item>
            <el-form-item label="描述:" prop="desc">
              <el-input v-model="ruleForm.desc" placeholder="请输入描述"></el-input>
            </el-form-item>
            <el-form-item label="套餐标签:" prop="tag">
              <el-select class="packge-select" v-model="ruleForm.tag" placeholder="请选择">
                <el-option v-for="item in tagList" :key="item.value" :label="item.name" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="是否开启:" prop="enable">
              <el-switch v-model="ruleForm.enable" active-color="#3888E9" inactive-color="#CCCCCC">
              </el-switch>
            </el-form-item>
          </el-form>

          <div class="packge-btn-add" style="margin-top: 0">
            <el-button class="pageck-btn" @click="resetForm('ruleForm')">取消</el-button>
            <el-button class="pageck-btn default" type="primary" @click="submitForm('ruleForm')">确认</el-button>
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 优惠券编辑 -->
    <el-dialog :visible.sync="dialogQuan" width="620px" class="quan-dialog-class" :close-on-click-modal="false">
      <div class="dialog-member-wrap">
        <div class="dialog-member-title">
          <span class="dialog-member-name">优惠券套餐编辑</span>
          <div class="dialog-close" @click="resetQuanForm('quan-dialog-class')">
            <i class="el-icon-close"></i>
          </div>
        </div>
        <div class="pageck-quan">
          <div class="to-quan" @click="toPathQuan">
            前往优惠券管理<i class="el-icon-arrow-right"></i>
          </div>
          <div class="pageck-quan-form-edit">
            <el-form :model="form" ref="rulesQuan" class="packge-num-quan-ruleForm">
              <div class="pageck-quan-item" v-for="(item, index) in form.orders" :key="index">
                <el-form-item :label="`优惠券${index + 1}:`" :prop="'orders.' + index + '.couponId'" :rules="[
                  {
                    required: true,
                    trigger: 'change',
                    message: `请选择优惠券`,
                  },
                ]">
                  <el-select clearable v-model="item.couponId" style="width: 283px" @change="getQuery($event, item)"
                    placeholder="请选择优惠券">
                    <el-option v-for="item in couponList" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>

                <el-form-item label="数量:" :prop="'orders.' + index + '.count'" style="margin-left: 20px" :rules="[
                  {
                    required: true,
                    trigger: 'change',
                    message: `请输入数量`,
                  },
                ]">
                  <el-input v-model="item.count" style="width: 103px" type="Number" placeholder="券数量" min="0"
                    :max="couponMax"></el-input>
                </el-form-item>

                <el-form-item v-if="form.orders.length != 1" :prop="'orders.' + index + '.count'"
                  style="margin-left: 5px">
                  <i class="el-icon-delete" @click="delQuan(item, index)"></i>
                </el-form-item>
              </div>
            </el-form>
            <div class="packge-btn-add">
              <el-button class="pageck-btn pageck-btn-add" @click="addPackageQuan">添加券</el-button>
              <el-button class="pageck-btn default" type="primary" @click="setCoupon('rulesQuan')">确认</el-button>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  query,
  add,
  update,
  couponlist,
  packagecouponmodify,
  rechargepackageenable,
  deletePackage,
} from "@/api/settings.js";

export default {
  data() {
    return {
      list: [],
      formInfo: {
        name: "",
        pageIndex: 0,
        total: 0,
        loading: false,
      },
      ruleForm: {
        rechargeAmount: "",
        price: "",
        enable: false,
        tag: 0,
        desc: "",
        id: "",
      },
      dialogQuanInfo: {},
      form: {
        orders: [
          {
            id: "",
            couponId: "",
            count: "",
          },
        ],
      },
      dialogMember: false,
      dialogQuan: false,
      tagList: [
        {
          name: "无",
          value: 0,
        },
        {
          name: "超值推荐",
          value: 1,
        },
        {
          name: "限时优惠",
          value: 2,
        },
      ],

      rules: {
        rechargeAmount: [
          {
            required: true,
            message: "请输入充值金额",
            trigger: ["click", "change", "blur"],
          },
        ],
        price: [
          { required: true, message: "请输入价格", trigger: ["click", "change", "blur"] },
        ],
        desc: [
          { required: true, message: "请输入描述", trigger: ["click", "change", "blur"] },
        ],
        tag: [
          { required: true, message: "请选择标签", trigger: ["click", "change", "blur"] },
        ],
      },

      couponList: [],
      couponMax: 0,
    };
  },
  mounted() {
    this.queryFn();
    this.queryFn();
  },
  computed: {
    setTag() {
      return function (tag) {
        let bg = "";
        let text = "";

        if (tag === 0) {
          bg = "tag-color";
          text = "无";
        } else if (tag == 1) {
          bg = "tag-cz";
          text = "超值推荐";
        } else {
          bg = "tag-xs";
          text = "限时优惠";
        }

        return { bg, text };
      };
    },
  },
  methods: {
    queryFn() {
      this.formInfo.loading = true;
      this.couponlistFn();
      query({
        "Page.Size": 10,
        "Page.Index": this.formInfo.pageIndex,
        desc: this.formInfo.name,
      }).then((res) => {
        this.formInfo.loading = false;

        console.log(res);
        console.log(this.couponList);
        this.list = res.data.rows;
        this.list.forEach((item) => {
          item.str = "";
          if (item.couponList) {
            item.couponList.forEach((elem) => {
              this.couponList.forEach((el) => {
                if (elem.couponId == el.id) {
                  elem.name = el.name;

                  item.str += `${el.name}:${elem.count}张;`;
                }
              });
            });
            this.$forceUpdate();
          }
        });

        this.formInfo.total = res.data.total;
      });
    },
    couponlistFn() {
      couponlist({}).then((res) => {
        console.log(res);
        this.couponList = res.data;
      });
    },

    delQuan(item, index) {
      this.form.orders.splice(index, 1);
    },

    delProduct(id) {
      this.$confirm(`是否要删除该套餐`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deletePackage({
            id: id,
          })
            .then((res) => {
              console.log(res);

              this.$message({
                message: "删除成功",
                type: "success",
              });

              this.queryFn();
            })
            .catch((res) => { });
        })
        .catch((res) => { });
    },

    switchChange(item) {
      console.log(item);
      item.enable = !item.enable;
      this.$confirm(`是否${!item.enable ? "开启" : "关闭"}该套餐`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          rechargepackageenable({
            id: item.id,
            enable: !item.enable,
          })
            .then((res) => {
              this.queryFn();
            })
            .catch((res) => { });
        })
        .catch((res) => { });
    },
    reset() {
      this.formInfo = {
        name: "",
        pageIndex: 0,
        total: 0,
        loading: false,
      };

      this.queryFn();
    },

    setCoupon(formName) {
      this.$refs[formName].validate((valid) => {
        console.log(valid);
        if (valid) {
          let flag = true,
            index = 0;
          for (let i = 0; i < this.form.orders.length; i++) {
            const element = this.form.orders[i];
            if (Number(element.count) > element.couponMax) {
              flag = false;
              index = i;
              break;
            }
          }
          if (!flag) {
            this.$message.error(
              `优惠券${index + 1}数量有误，不能大于发行数量${this.form.orders[index].couponMax
              }`
            );
            return;
          }
          let couponList = [];
          this.form.orders.forEach((item) => {
            couponList.push({
              couponId: item.couponId,
              count: item.count,
            });
          });

          let coupon = {
            id: this.dialogQuanInfo.id,
            couponList: couponList,
          };

          packagecouponmodify({
            ...coupon,
          }).then((res) => {
            console.log(res);
            if (res.msg == "success") {
              this.$message({
                message: "修改成功",
                type: "success",
              });
              this.queryFn();
              this.dialogQuan = false;
            }
          });
        } else {
          // console.log('error submit!!');
          return false;
        }
      });
    },
    addPackge() {
      this.dialogMember = true;
    },
    addPackageQuan() {
      this.form.orders.push({
        id: "",
        couponId: "",
        count: "",
      });
    },
    switchIsNeedChange() { },
    toPathQuan() {
      this.$emit("toQuan");
    },
    editQuan(item) {
      console.log(item);

      if (!item.couponList) {
        this.form = {
          orders: [
            {
              id: "",
              couponId: "",
              count: "",
            },
          ],
        };
      } else {
        this.form = { orders: [] };
        item.couponList.forEach((item) => {
          this.form.orders.push({
            id: "",
            couponId: item.couponId,
            count: item.count,
          });
        });
      }

      this.dialogQuan = true;
      this.dialogQuanInfo = item;
    },
    editPackage(item) {
      console.log(item);
      this.dialogMember = true;
      this.ruleForm = {
        rechargeAmount: item.rechargeAmount,
        price: item.price,
        enable: item.enable,
        tag: item.tag,
        desc: item.desc,
        id: item.id,
      };
    },
    resetForm(formName) {
      this.dialogMember = false;
      this.ruleForm = {
        rechargeAmount: "",
        price: "",
        enable: false,
        tag: 0,
        desc: "",
        id: "",
      };
      if (formName) {
        this.$refs[formName].resetFields();
      }
    },

    resetQuanForm(formName) {
      this.dialogQuan = false;
    },

    memberCurrentChange(e) {
      this.formInfo.pageIndex = e;
      this.$refs.refTable.bodyWrapper.scrollTop = 0;
      this.queryFn();
    },

    submitForm(formName) {
      let vm = this;
      console.log(this.ruleForm);

      this.$refs[formName].validate((valid) => {
        // update
        if (valid) {
          if (Number(this.ruleForm.price) > 1000000) {
            this.$message.error('数额不能超过一百万')
            return
          }
          if (vm.ruleForm.id) {
            update({
              token: vm.$store.state.token,
              ...this.ruleForm,
            }).then((res) => {
              if (res.msg == 'success') {
                this.$message({
                  message: "编辑成功",
                  type: "success",
                });
                this.resetForm("ruleForm");
                this.queryFn();
              } else {
                this.$message.error(res.msg)
              }
            });
          } else {
            add({
              token: vm.$store.state.token,
              ...this.ruleForm,
            }).then((res) => {
              this.$message({
                message: "添加成功",
                type: "success",
              });
              this.resetForm("ruleForm");
              this.queryFn();
            });
          }

          this.dialogMember = false;
          this.resetForm();
        }
      });
    },
    getQuery(val, item) {
      console.log(val, "aaaa", item);
      let couponMax = 0;
      this.couponList.forEach((v) => {
        if (v.id == val) {
          couponMax = v.total;
        }
      });
      this.$set(item, "couponMax", couponMax);
    },
  },
};
</script>
<style lang="scss">
.package {
  padding: 16px;
  width: 973px;

  .cell {
    font-size: 12px;
  }

  .packge-class {
    background: none !important;
    border-radius: 12px;

    .el-dialog {
      background: none;
    }

    .el-dialog__header {
      display: none;
    }

    .el-dialog__body {
      width: 385px;
      height: 431px;
      background: linear-gradient(180deg, #e7ecfb 0%, #f6f8fe 24%, #ffffff 100%);
      border-radius: 12px;
      box-sizing: border-box;
      overflow: hidden;
    }
  }

  .quan-dialog-class {
    background: none !important;
    border-radius: 12px;

    .el-dialog {
      background: none;
    }

    .el-dialog__header {
      display: none;
    }

    .el-dialog__body {
      width: 620px;

      // height: 333px;
      background: linear-gradient(180deg, #e7ecfb 0%, #f6f8fe 24%, #ffffff 100%);
      border-radius: 12px;
      box-sizing: border-box;
      overflow: hidden;
    }
  }

  .packge-ruleForm {
    margin-top: 20px;
    box-sizing: border-box;

    .el-form-item {
      margin-left: 10px;
      margin-right: 10px;
      margin-bottom: 20px;
    }

    .packge-select {
      width: 135px;
      text-align: left;
    }

    .el-form-item__label {
      width: 50px !important;
      white-space: nowrap;
      height: 30px;
      text-align: left;
    }

    .el-form-item__content {}

    .el-form-item__content {
      margin: 0 auto;
    }
  }

  .packge-num-quan-ruleForm {
    .el-form-item {
      display: flex;
    }
  }
}
</style>

<style lang="scss" scoped>
.pageck-quan-item {
  display: flex;
  align-items: center;
}

.package {
  // padding: 16px;

  .coupon-list {
    color: #3888e9;
    cursor: pointer;
  }
}

.packge-btn-add {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  margin-top: 50px;

  .pageck-btn {
    width: 145px;
    margin: 0 16px;
  }

  .pageck-btn-add {
    border: 1px solid #3888e9;
    color: #3888e9;
  }
}

.pageck-quan {
  padding: 12px;
  width: 100%;
  text-align: right;
  font-size: 16px;
  color: #3888e9;

  .to-quan {
    cursor: pointer;
  }

  .pageck-quan-form-edit {
    margin-top: 20px;

    .el-icon-delete {
      font-size: 16px;
      margin-left: 6px;
      cursor: pointer;
    }
  }
}

.tag-sty {
  width: 72px;
  height: 25px;
  line-height: 25px;
  color: #fff;
  font-size: 12px;
  border-radius: 5px;
  margin: 0 auto;
}

.tag-color {
  background: #ececec;
  // background: red;
  color: #999999;
}

.tag-cz {
  background: linear-gradient(48deg, #ff9890 0%, #ff2519 100%);
}

.tag-xs {
  background: linear-gradient(49deg, #ffc9a5 0%, #ff8033 100%);
}

.table-pagination {
  text-align: center;
  margin-top: 16px;
}

.dialog-member-wrap {
  .dialog-member-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -10px;

    .dialog-member-name {
      font-size: 18px;
      font-weight: bold;
    }

    .dialog-close {
      width: 25px;
      height: 25px;
      background: #e0e0e0;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .el-icon-close {
        color: #fff;
        font-size: 16px;
      }
    }
  }
}

.box-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;

  .box-input-btn {
    text-align: right;

    .box-input-btn-cz {
      background: #eaf3ff;
      color: #3888e9;
      border: none;
    }

    .box-input-btn-add {
      background: #d8f5e4;
      color: #54d8a5;
      border: none;
    }
  }
}

.edit-wrap {
  .edit-item {
    // padding-left: 20px;

    span {
      cursor: pointer;
      display: inline-block;
      margin: 6px;

      &.lan {
        color: #3888e9;
      }

      &.copy {
        color: #9ea09e;
      }

      &.del {
        color: #e41313;
      }
    }
  }
}
</style>
