<!--  -->
<template>
  <div class="wrap">
    <el-card
      class="box-card"
      v-for="(item, index) in countryList"
      :key="item.id"
    >
      <div class="top">
        <img class="bgc" :src="item.nationalFlagImg" />
        <p>{{ item.productName + item.type }}</p>
      </div>
      <div class="center">
        <p v-if="false">行程设置</p>
        <p v-if="false">基础设置</p>
        <p v-if="item.tutorialUrl" @click="openUrl(item.tutorialUrl)">
          使用说明
        </p>
        <p v-if="false">高危人群告知</p>
        <p v-if="false">送签信息</p>
      </div>
      <div class="bottom">
        <div class="icon-box">
          <div class="icon" @click="isCollected(item, index)">
            <img
              :src="
                item.isCollected
                  ? require('../../../assets/images/visa/collect-on.png')
                  : require('../../../assets/images/visa/collect-off.png')
              "
              alt="收藏"
            />
          </div>
        </div>
        <p class="btn" @click="toOrderCenter(item.countryId)">订单列表</p>
        <!-- <p
          v-loading="loading"
          element-loading-text="拼命加载中"
          class="btn"
          @click="toOrder(item)"
        >
          立即办理
        </p> -->
        <p class="btn">
          <el-button
            :loading="item.loading"
            @click="toOrder(item, index)"
            style="
              width: 110px;
              height: 38px;
              font-size: 17px;
              border-radius: 4px;
              background-color: #3888e9;
              color: #ffffff;
            "
            >立即办理</el-button
          >
        </p>
      </div>
    </el-card>
  </div>
</template>

<script>
import { getordertools, toolcollect, orgorder } from "@/api/settings";
export default {
  name: "visa",
  components: {},
  data() {
    return {
      //国家签证列表
      countryList: [],
      addH: false,
      loading: false,
    };
  },
  created() {
    this.getordertool();
  },
  mounted() {},
  //方法集合
  methods: {
    //立即办理
    toOrder(item, i, userId) {
      this.countryList[i].loading = true;
      orgorder({ systemVisaId: item.systemVisaId })
        .then((res) => {
          let item = res.data;
          this.countryList[i].loading = false;
          //马来西亚插件
          if (item.systemVisaId === 1) {
            this.$router.push({
              name: "malaysia",
              query: {
                id: item.id,
              },
            });
            return;
          }
          //其他国家的插件
          if (item && item.autocommit) {
            this.$router.push({
              name: "orderCenterElectron",
              params: {
                id: `${item.applicantName}_${item.identityTypeName}_${item.id}`,
              },
            });
          } else {
            this.$router.push({
              name: "informations",
              params: { id: `${item.id}` },
            });
          }
        })
        .catch((err) => {
          this.countryList[i].loading = false;
          console.log(err);
        });
    },
    //获取机构数据
    getordertool() {
      getordertools().then((res) => {
        if (res.code == 1) {
          res.data.forEach((item) => {
            item.loading = false;
          });
          this.countryList = res.data;
          console.log(this.countryList);
        }
      });
    },
    //是否收藏
    isCollected(item, i) {
      toolcollect({
        id: item.id,
        isCollected: !item.isCollected,
      }).then((res) => {
        this.countryList[i].isCollected = !this.countryList[i].isCollected;
        this.getordertool();
      });
    },
    toOrderCenter(id) {
      this.$router.push({
        name: "orderCenter",
        params: { id },
      });
    },
    openUrl(url) {
      window.open(url);
    },
  },
};
</script>
<style lang="scss" scoped>
.wrap {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  .box-card {
    width: 287px;
    height: 213px;
    padding: 12px 12px 0 12px;
    margin-bottom: 20px;
    margin-right: 16px;
    ::v-deep .el-card__body {
      padding: 0;
    }
    .top {
      height: 34px;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      justify-content: left;
      margin-bottom: 20px;
      .bgc {
        width: 55px;
        height: 36px;
      }
      p {
        font-size: 16px;
        font-weight: bold;
        color: #333333;
        margin-left: 12px;
      }
    }
    .center {
      height: 92px;
      display: flex;
      justify-content: left;
      flex-wrap: wrap;
      p {
        height: 22px;
        padding: 4px 12px;
        font-size: 13px;
        font-weight: 400;
        color: #666666;
        border-radius: 4px;
        border: 1px solid #dcdfe6;
        line-height: 22px;
        margin-bottom: 12px;
        margin-right: 10px;
        cursor: pointer;
        &:hover {
          color: #3888e9;
          border: 1px solid #3888e9;
        }
      }
    }
    .bottom {
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;
      .icon-box {
        width: 42px;
        height: 40px;
        .icon {
          width: 39px;
          height: 36px;
          border: 1px solid #dcdfe6;
          border-radius: 4px;
          text-align: center;
          line-height: 36px;
          cursor: pointer;
          img {
            width: 18px;
            height: 18px;
          }
          &:hover {
            border: none;
            box-shadow: 0px 0px 6px 1px rgba(56, 136, 233, 0.55);
          }
        }
      }
      .btn {
        width: 108px;
        height: 36px;
        font-size: 17px;
        border-radius: 4px;
        text-align: center;
        line-height: 36px;
        cursor: pointer;
        &:nth-child(2) {
          color: #3888e9;
          border: 1px solid #3888e9;
        }
        &:nth-child(3) {
          opacity: 1;
        }
        &:nth-child(2):hover {
          background-color: #3888e9;
          color: #ffffff;
        }
        &:nth-child(3):hover {
          opacity: 0.8;
        }
        ::v-deep .el-icon-loading {
          margin-left: -10px;
        }
      }
    }
  }
}
</style>
