
/**
 * @description 格式化时间
 * @param {String|Number} dateTime 需要格式化的时间戳
 * @param {String} fmt 格式化规则 yyyy:mm:dd|yyyy:mm|yyyy年mm月dd日|yyyy年mm月dd日 hh时MM分等,可自定义组合 默认yyyy-mm-dd
 * @returns {string} 返回格式化后的字符串
 */
function timeFormat(dateTime = null, formatStr = 'yyyy-mm-dd') {
    let date
      // 若传入时间为假值，则取当前时间
    if (!dateTime) {
      date = new Date()
    }
    // 若为unix秒时间戳，则转为毫秒时间戳（逻辑有点奇怪，但不敢改，以保证历史兼容）
    else if (/^\d{10}$/.test(dateTime && dateTime.toString().trim())) {
      date = new Date(dateTime * 1000)
    }
    // 若用户传入字符串格式时间戳，new Date无法解析，需做兼容
    else if (typeof dateTime === 'string' && /^\d+$/.test(dateTime.trim())) {
      date = new Date(Number(dateTime))
    }
      // 处理平台性差异，在Safari/Webkit中，new Date仅支持/作为分割符的字符串时间
      // 处理 '2022-07-10 01:02:03'，跳过 '2022-07-10T01:02:03'
      else if (typeof dateTime === 'string' && dateTime.includes('-') && !dateTime.includes('T')) {
          date = new Date(dateTime.replace(/-/g, '/'))
      }
      // 其他都认为符合 RFC 2822 规范
      else {
          date = new Date(dateTime)
      }
  
      const timeSource = {
          'y': date.getFullYear().toString(), // 年
          'm': (date.getMonth() + 1).toString().padStart(2, '0'), // 月
          'd': date.getDate().toString().padStart(2, '0'), // 日
          'h': date.getHours().toString().padStart(2, '0'), // 时
          'M': date.getMinutes().toString().padStart(2, '0'), // 分
          's': date.getSeconds().toString().padStart(2, '0') // 秒
          // 有其他格式化字符需求可以继续添加，必须转化成字符串
      } 
  
    for (const key in timeSource) {
      const [ret] = new RegExp(`${key}+`).exec(formatStr) || []
      if (ret) {
        // 年可能只需展示两位
        const beginIndex = key === 'y' && ret.length === 2 ? 2 : 0
        formatStr = formatStr.replace(ret, timeSource[key].slice(beginIndex))
      }
    }
  
    return formatStr
  }

  function getNowDate(data) {
    let date = new Date(data);
    let sign2 = ":";
    let year = date.getFullYear() // 年
    let month = date.getMonth() + 1; // 月
    let day = date.getDate(); // 日 
    let hour = date.getHours(); // 时
    let minutes = date.getMinutes(); // 分
    let seconds = date.getSeconds() //秒
    let weekArr = ['星期天', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
    let week = weekArr[date.getDay()]; //getDay() 从 Date 对象返回一周中的某一天 (0 ~ 6)
    // 给一位数的数据前面加 “0”
    if (month >= 1 && month <= 9) {
        month = "0" + month;
    }
    if (day >= 0 && day <= 9) {
        day = "0" + day;
    }
    if (hour >= 0 && hour <= 9) {
        hour = "0" + hour;
    }
    if (minutes >= 0 && minutes <= 9) {
        minutes = "0" + minutes;
    }
    if (seconds >= 0 && seconds <= 9) {
        seconds = "0" + seconds;
    }
    return year + "年" + month + "月" + day + "日" + " " + week;


}


  export  {
    timeFormat,
    getNowDate
  }