import Vue from 'vue'
import Vuex from 'vuex'

import user from './modules/user';
import product from './modules/product'
import Cookies from 'js-cookie'

import createPersistedstate from 'vuex-persistedstate'
Vue.use(Vuex)


export default new Vuex.Store({
  state: {
    countrys: {},
    countrysDetailId: '',
    countrysDetail: {},
    countryId: '',
    bxId: '',
    token: '',
    userInfo: '',
    screen: false,
    balance: 0,
    ticketsBalance: 0, //点券
    orgBalance:0,//机构点券余额
  },
  getters: {
  },
  mutations: {
    SET_COUNTRYS(state, data) {
      Cookies.set('countrys', data)
      state.countrys = data;
    },
    SET_COUNTRYS_DETAIL_ID(state, data) {
      state.countrysDetailId = data;
    },
    SET_COUNTRYS_DETAIL(state, data) {
      state.countrysDetail = data;
    },
    SET_TOKEN(state, data) {
      state.token = data;
    },
    SET_COUNTRY_ID(state, data) {
      state.countryId = data;
    },
    SET_BX_ID(state, data) {
      state.bxId = data;
    },
    SET_USERINFO(state, data) {
      state.userInfo = data;
    },
    SET_SCREEN(state, data) {
      state.screen = data;
    },
    SET_BALANCE(state, data) {
      state.balance = data;
    },
    SET_TICKETSBALANCE(state, data) {
      Cookies.set('ticketsBalance', data)
      state.ticketsBalance = data;
    },
    SET_ORGBALANCE(state, data) {
      state.orgBalance = data;
    },
  },
  actions: {
  },
  modules: {
    user,
    product
  },

  plugins: [
    createPersistedstate({
      key: 'vist-state',// 存数据的key名   自定义的  要有语义化
      paths: ['user', 'product'] // 要把那些模块加入缓存
    })
  ]
})
