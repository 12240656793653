<template>
    <div class="page bx">
        <div class="page-top">
            <div class="page-top-l">
                <img class="page-bg" :src="product.backgroundImg" alt="">
                <img class="page-logo" :src="supplier.logo" alt="">
            </div>
            <div class="page-top-r">
                <div class="page-title">{{ product.title }}</div>
                <div class="page-price">
                    <p class="page-price-label">保费金额</p>
                    <p class="page-price-info"><span>￥</span>{{ priceInfo.price }}</p>
                    <p class="page-price-y">原价￥<s>{{ priceInfo.originalPrice }}</s></p>
                </div>
                <div class="page-top-list">
                    <div class="page-list-label">套餐选择</div>
                    <div class="page-list">

                        <div class="page-item" :class="[index == productActive ? 'active' : '']"
                            v-for="(item, index) in product.packages" :key="index" @click="productCheckFn(index)">
                            {{ item.name }}
                        </div>
                    </div>
                </div>

                <div class="page-top-list">
                    <div class="page-list-label">承保年龄</div>
                    <div class="page-list">
                        <div class="page-item" :class="[index == yearActive ? 'active' : '']"
                            v-for="(item, index) in yearInfo.settings" :key="index" @click="yearCheckFn(index)">
                            {{ item.ageRange }}
                        </div>
                    </div>
                </div>
                <div class="page-top-list">
                    <div class="page-list-label">保障期限</div>
                    <div class="page-list">
                        <div class="page-item" :class="[index == dayActive ? 'active' : '']"
                            v-for="(item, index) in dayInfo.options" :key="index" @click="dayCheckFn(index)"
                            :style="{ display: item.type == 0 ? 'block' : 'none' }">
                            {{ item.max }}
                        </div>
                        <el-select v-model="value" placeholder="更多选择" v-for="(item, index) in dayInfo.options" :key="item+index"
                            :style="{ display: item.type == 1 ? 'block' : 'none' }" @change="changeSelect(index)"
                            class="select" :class="[index == dayActive ? 'select-active' : '']">
                            <el-option v-for="(elem, idx) in item.list" :key="idx" :label="elem.label"
                                :value="elem.max">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="submit-btn">
                    <button class="submit-b" @click="toDetail()">立即办理</button>
                </div>
            </div>
        </div>
        <div class="page-xz">
            <div class="page-head">
                <div class="page-head-l">

                    <div class="page-xz-title page-xz-title-w">
                        {{ product.title }}
                    </div>
                </div>
                <div class="page-head-item" :class="index == productActive ? 'body-active' : ''"
                    v-for="(item, index) in product.packages" :key="index" @click="productCheckFn(index)">
                    <div class="page-body">

                        <div class="page-body-name">
                            <span>{{ item.name }}</span>
                            <div class="icon">

                            </div>
                        </div>
                        <div class="page-body-price">
                            <p>￥{{ item.bottomPrice }}<span>起</span></p>
                        </div>
                    </div>
                </div>

            </div>
            <div class="page-wrap">
                <div class="page-wrap-w" v-for="(item, index) in packgesList" :key="index">

                    <div class="page-wrap-l">
                        {{ item.title }}
                    </div>

                    <div class="page-wrap-item" v-for="(elem, idx) in item.list"
                        :class="[idx == productActive ? index == len ? 'wrap-active wrap-active-t' : 'wrap-active' : '']"
                        :key="idx">
                        {{ elem }}
                    </div>
                </div>

            </div>
        </div>

        <div class="page-xz">
            <div class="page-xz-title">
                投保须知
            </div>
            <div class="page-xz-text" :style="{ maxHeight: bxShow ? '400px' : '' }">
                <p style="white-space: pre-line;">{{ product.insuranceNotice }}</p>
            </div>
            <div class="page-xz-btn" @click="bxShow = !bxShow">
                <button v-if="bxShow">查看全部<i class="el-icon-arrow-down"></i></button>

                <button v-else>收起<i class="el-icon-arrow-up"></i></button>
            </div>
        </div>

        <div class="page-xz">
            <div class="page-xz-title">
                理赔流程
            </div>
            <div class="page-lc-content">
                <div class="page-lc-item" v-for="(item, index) in product.processList" :key="index">
                    <img class="page-lc-img" :src="require(`../../assets/images/case/1-${index + 1}.png`)" alt="">
                    <div class="process-right">
                        <img class="process-right-img" :src="require(`../../assets/images/case/${index + 1}.png`)" alt="">
                        <div class="page-flex">
                            <div class="page-num">
                                0{{ index + 1 }}
                            </div>
                            <div class="page-num-title">{{ item.title }}</div>
                        </div>
                        <div class="page-content">{{ item.content }}</div>
                    </div>
                </div>
            </div>
        </div>
        <login :config="config.dialogVisible" v-if="config.dialogVisible" @close="config.dialogVisible = false"
            @refresh="handleRefresh"></login>
        <handle :config="config.dialogVisible2" v-if="config.dialogVisible2" @close="config.dialogVisible2 = false"
            @refresh="handleRefresh" :qrImg="qrImg"></handle>
    </div>
</template>

<script>
import { getinsuranceproductdetail, getinsuranceprice } from '@/api/case.js';
import { wechatshareqrcode, getorginfo } from '@/api/login.js'

// import login from '@/components/login.vue';
import handle from '@/components/handle.vue';
export default {
    components:{
        login: () => import('@/components/login.vue'),
        handle
    },
    data() {
        return {
            value: '',
            len: 0,
            id: '',
            info: {},
            supplier: {},

            productActive: 0,       // 套餐下标
            product: {},    //  套餐信息

            yearActive: 0,   //  岁 下标
            yearInfo: {}, //  岁 信息

            dayActive: 0,   //  天 下标
            dayInfo: {},    //  天 信息

            dayObj: {},     //  选中的天数范围
            priceInfo: {},     //  选中的价格
            bxShow: true,

            packgesList: [],

            config: {
                dialogVisible: false,
                dialogVisible2: false,
            },
            orginfo: {},
            qrImg: ''
        }
    },
    async created() {
        if (this.$router.history.current.params.id) {
            console.log(1);
            this.$store.commit('SET_BX_ID', this.$router.history.current.params.id);
            this.id = this.$router.history.current.params.id
        } else {
            console.log(2)
            this.id = this.$router.history.current.params.id;
        }
        this.getinsuranceproductdetailFn();
        this.getorginfoFn();
    },

    methods: {
        toDetail() {
            if (!this.$store.state.user.token) {
                this.config.dialogVisible = true;
            } else {
                wechatshareqrcode({
                    appId: this.orginfo.wechatApp.appId,
                    path: 'pages/insure/detail/detail?id=10'
                }).then(res => {
                    this.config.dialogVisible2 = true;
                    this.qrImg = res.data
                })
            }
        },
        getorginfoFn() {
            getorginfo({
            }).then(res => {
                this.orginfo = res.data
            })
        },
        async getinsuranceproductdetailFn() {
            if (!this.info.product) {
                let res = await getinsuranceproductdetail({
                    id: this.id
                })
                this.info = JSON.parse(JSON.stringify(res.data));
            }
            this.info.product=this.info.product||{}
            this.product = JSON.parse(JSON.stringify(this.info.product))
            let packgesList = (this.product&&this.product.packages)||[];

            packgesList.forEach((item, index) => {
                item.list = []
            })

            packgesList.forEach((item, index) => {
                item.list.push(item.content)
            })

            this.product.packages.forEach((item, index) => {

                packgesList.forEach((elem, idx) => {

                    if (index != 0) {
                        elem.list.push(elem.content)
                    }
                })
            })
            this.packgesList = JSON.parse(JSON.stringify(packgesList));
            this.yearInfo = this.product.packages[this.productActive];
            this.dayInfo = this.yearInfo.settings[this.yearActive];
            this.dayObj = this.dayInfo.options[this.dayActive];

            this.dayInfo.options.forEach(item => {
                if (item.type == 1) {
                    let list = []

                    for (var i = item.min; i <= item.max; i + item.step) {

                        i = i + item.step;
                        console.log(i);
                        list.push({ max: i, min: i - item.step, label: `${i - item.step}-${i}天` });
                    }
                    console.log(list, 'list');
                    item.list = list;
                }
            })
            this.supplier = JSON.parse(JSON.stringify(this.info.supplier))

            let priceInfo = await getinsuranceprice({
                PackageId: this.yearInfo.id,
                Days: this.dayObj.type == 1 ? this.value : this.dayObj.max,
                Range: this.dayInfo.ageRange,
            })

            console.log(priceInfo)
            this.len = packgesList.length - 1;
            this.priceInfo = priceInfo.data
        },
        productCheckFn(index) {
            this.productActive = index
            this.yearActive = 0;
            this.dayActive = 0;
            this.value = ''
            this.getinsuranceproductdetailFn();
        },
        yearCheckFn(index) {
            this.yearActive = index
            this.dayActive = 0;
            this.value = ''
            this.getinsuranceproductdetailFn();
        },
        dayCheckFn(index) {
            this.dayActive = index;
            this.value = ''
            this.getinsuranceproductdetailFn();
        },
        changeSelect(index) {
            this.dayActive = index
            this.getinsuranceproductdetailFn();
        },
        handleRefresh(){

        }
        // productCheckFn (index) {
        //     this.productActive = index
        //     this.dayActive = 0;
        //     this.getinsuranceproductdetailFn();
        // }
    }
}
</script>

<style lang="scss">
.bx {
    .select {

        .el-input__inner {

            height: 38px;
            border-radius: 2px;
            border: 1px solid #dee2e6;
            max-width: 140px;
        }
    }

    .select-active {

        &::after {
            content: "";
            width: 24px;
            height: 24px;
            background: url('../../assets/images/case/go.png') no-repeat 50%/100% 100%;
            position: absolute;
            z-index: 5;
            right: 1px;
            bottom: 8px;
        }

        .el-input__inner {

            height: 38px;
            border-radius: 2px;
            max-width: 140px;
            position: relative !important;

            border: 2px solid #599fff;
            color: #2f333d;


        }
    }
}
</style>

<style lang="scss" scoped>
.page {
    min-height: 800px;
    background: #F7F8FA;
    display: flex;
    flex-direction: column;
    align-items: center;
}





.submit-btn {
    margin-top: 24px;
    padding-bottom: 16px;
    text-align: right;

    .submit-b {
        box-shadow: 0 12px 20px -8px rgba(255, 128, 51, .5);
        width: 146px;
        border-radius: 24px;
        background: #ff8033;
        color: #fff;
        height: 40px;
        border: 1px solid #ff8033;
        cursor: pointer;
    }
}


.page-xz {
    background: #FFF;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .06);
    padding: 24px 24px 32px;
    width: 1120px;
    box-sizing: border-box;
    margin-top: 16px;
    margin-bottom: 16px;
    padding-bottom: 40px;

    .page-wrap {
        .page-wrap-w {
            display: flex;

            &:nth-child(odd) {
                .page-wrap-l {
                    background: #fafbfc;

                }

                .page-wrap-item {
                    background: #fafbfc;

                }
            }

            .page-wrap-l {
                flex-shrink: 0;
                width: 396px;
                padding: 12px 16px;
                color: #2f333d;
                font-size: 16px;
                line-height: 24px;
                border-bottom: 1px solid #e9eef2;
                background: #FFFFFF;
                box-sizing: border-box;

            }

            .page-wrap-item {
                width: 100%;
                flex: 1;
                padding: 12px 16px;
                position: relative;
                cursor: pointer;
                box-sizing: border-box;
                background: #FFFFFF;
                border-bottom: 1px solid #e9eef2;
                text-align: center;
                font-weight: bold;
                box-sizing: border-box;


                &.wrap-active {
                    &:before {
                        content: "";
                        position: absolute;
                        left: 1px;
                        height: 50px;
                        bottom: 0px;
                        width: 2px;
                        background: #599fff;
                    }

                    &:after {
                        content: "";
                        position: absolute;
                        right: 0;
                        height: 50px;
                        bottom: 0px;
                        width: 2px;
                        background: #599fff;
                    }



                }

                &.wrap-active-t {
                    &:before {
                        display: none;
                        content: "";
                        position: absolute;
                        left: 0px;
                        height: 50px;
                        bottom: 0px;
                        width: 2px;
                        background: #599fff;
                    }

                    &:after {

                        // border-radius: 0 0 8px 8px;
                        // width: 100%;
                        // border-bottom: 2px solid #599fff;
                        // -webkit-box-sizing: border-box;
                        // box-sizing: border-box;

                        border-radius: 0 0 8px 8px;
                        width: 100%;
                        left: 0px;
                        border: 2px solid #599fff;
                        border-top: none;
                        background: none;
                        -webkit-box-sizing: border-box;
                        box-sizing: border-box;
                    }
                }
            }
        }
    }

    .page-head {
        display: flex;

        .page-head-l {
            width: 396px;

        }

        .page-head-item {
            width: 100%;
            flex: 1;
            padding: 16px 0 24px;
            line-height: 1;
            display: flex;
            flex-direction: column;
            border-left: 1px solid #fff;
            position: relative;
            cursor: pointer;

            &.body-active {
                box-shadow: 0 8px 20px -6px rgba(89, 159, 255, .4);
                color: #fff;
                // padding: 18px 12px 16px;
                padding-top: 0;

                .page-body {
                    background: linear-gradient(1turn, #599fff, #4083ff);
                    border-radius: 8px 8px 0 0;

                    &:before {
                        content: "";
                        position: absolute;
                        left: 0;
                        height: 30px;
                        bottom: -1px;
                        width: 2px;
                        background: #599fff;
                    }

                    &:after {
                        content: "";
                        position: absolute;
                        right: 0;
                        height: 30px;
                        bottom: -1px;
                        width: 2px;
                        background: #599fff;
                    }

                    .page-body-price {
                        p {

                            color: #fff;
                        }
                    }

                    .page-body-name {

                        .icon {
                            background: #FFF;

                            &::after {
                                content: "";
                                width: 6px;
                                height: 5px;
                                background: url(../../assets/images/case/bom2.png) no-repeat 50%/100% 100%;
                            }
                        }
                    }
                }
            }

            .page-body {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                background: #f3f6f9;
                border-radius: 8px 8px 0 0;
                padding: 18px 12px 16px;



                .page-body-price {
                    margin-top: 12px;
                    font-size: 18px;
                    display: flex;
                    align-items: baseline;
                    margin-bottom: 18px;
                    color: #91999f;

                    span {
                        font-size: 12px;
                        margin-left: 2px;
                    }
                }

                .page-body-name {
                    font-size: 20px;
                    font-weight: bold;
                    display: flex;
                    align-items: center;
                    margin-top: 18px;

                    .icon {
                        vertical-align: top;
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        flex-shrink: 0;
                        width: 14px;
                        height: 14px;
                        background: -webkit-gradient(linear, right top, left top, from(#c9cdd1), to(#91999f));
                        background: linear-gradient(270deg, #c9cdd1, #91999f);
                        opacity: .8;
                        margin-top: 0px;
                        border-radius: 50%;
                        margin-left: 4px;

                        &::after {
                            content: "";
                            width: 6px;
                            height: 5px;
                            background: url(../../assets/images/case/bom1.png) no-repeat 50%/100% 100%;
                        }
                    }
                }
            }
        }

        .page-head-r {}
    }

    .page-lc-content {
        display: flex;
        flex-direction: column;

        .page-lc-item {
            display: flex;
            margin-bottom: 25px;

            .page-lc-img {
                width: 100px;
                height: 104px;
            }

            .process-right {
                padding: 24px;
                width: 815px;
                position: relative;
                border-radius: 0 8px 8px 40px;
                background: -webkit-gradient(linear, right top, left top, from(#fbfcff), to(#f4f9ff));
                background: linear-gradient(270deg, #fbfcff, #f4f9ff);
                display: -webkit-box;
                display: flex;
                flex-direction: column;
                position: relative;
                min-height: 120px;

                .process-right-img {
                    width: 122px;
                    height: 102px;
                    position: absolute;
                    right: 24px;
                    bottom: 0;
                }

                .page-flex {
                    display: flex;
                    align-items: center;
                }

                .page-content {
                    margin-left: 40px;
                    margin-top: 10px;
                }

                .page-num {
                    flex-shrink: 0;
                    color: #599fff;
                    font-size: 26px;
                    font-weight: 600;
                    margin-right: 4px;
                }

                .page-num-title {
                    color: #2f333d;
                    font-size: 20px;
                    font-weight: 600;
                }
            }
        }
    }

    .page-xz-title {
        font-size: 24px;
        font-weight: 600;
        line-height: 1;
        color: #2f333d;
        position: relative;
        z-index: 1;
        margin-bottom: 24px;
        font-weight: bold;

        &.page-xz-title-w {
            width: 280px;
            white-space: wrap;
            line-height: 40px;

            &::before {
                top: 24px;
            }
        }

        &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 82px;
            height: 7px;
            background: -webkit-gradient(linear, right top, left top, from(rgba(89, 159, 255, 0)), to(rgba(89, 159, 255, .6)));
            background: linear-gradient(270deg, rgba(89, 159, 255, 0), rgba(89, 159, 255, .6));
            z-index: -1;
        }
    }

    .page-xz-text {
        overflow: hidden;

        p {
            font-size: 16px;
            line-height: 36px;
            overflow: hidden;
        }
    }

    .page-xz-btn {
        display: flex;
        justify-content: center;
        margin-top: 30px;

        button {
            width: 128px;
            height: 36px;
            border: 1px solid #599fff;
            color: #599fff;
            font-size: 16px;
            border-radius: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #FFF;
            text-align: center;
            cursor: pointer;

        }
    }
}

.page-top {
    width: 1120px;
    background: #FFF;
    display: flex;
    padding: 25px;
    box-sizing: border-box;
    margin-top: 35px;
    height: 100%;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .06);

    .page-top-l {
        display: flex;
        flex-direction: column;

        .page-bg {
            width: 400px;
            height: 240px;
        }

        .page-logo {
            width: 152px;
            height: 45px;
            margin-top: 16px;
        }
    }

    .page-top-r {
        margin-left: 24px;
        flex: 1;

        .page-title {
            font-size: 28px;
            font-weight: 600;
            margin-bottom: 18px;


        }

        .page-top-list {
            display: flex;
            align-items: center;
            margin-top: 16px;

            .page-list-label {
                font-size: 14px;
                margin-right: 16px;
            }

            .page-list {
                display: flex;
                flex-wrap: wrap;

                .page-item {
                    border-radius: 2px;
                    padding: 0 24px;
                    height: 38px;
                    line-height: 34px;
                    text-align: center;
                    border: 1px solid #dee2e6;
                    margin-right: 16px;
                    margin-bottom: 8px;
                    box-sizing: border-box;
                    color: #5f666e;
                    cursor: pointer;
                    position: relative;

                    &.active {
                        border: 2px solid #599fff;
                        color: #2f333d;

                        &::after {
                            content: "";
                            width: 24px;
                            height: 24px;
                            background: url('../../assets/images/case/go.png') no-repeat 50%/100% 100%;
                            position: absolute;
                            z-index: 2;
                            right: -1px;
                            bottom: -1px;
                        }
                    }
                }

            }
        }

        .page-price {
            background: linear-gradient(270deg, rgba(255, 128, 51, 0), rgba(255, 128, 51, .08));
            display: flex;
            align-items: center;
            padding-left: 8px;
            height: 62px;

            .page-price-label {
                margin-right: 16px;

            }

            .page-price-info {
                font-size: 28px;
                font-weight: 600;
                color: #ff8033;
                margin-top: -5px;
                margin-right: 16px;

                span {
                    font-size: 18px;
                }
            }

            .page-price-y {
                margin-right: 16px;
                font-size: 14px;
                color: #91999f;
            }
        }
    }

}
</style>