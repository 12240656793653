<template>
  <div class="page">
    <div class="page-content">
      <div class="page-top-m">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane :label="item.name" :name="item.value" v-for="(item, index) in menuList" :key="index">
          </el-tab-pane>
        </el-tabs>
        <div class="page-t">
          <span class="page-t-in" @click="isPop(0)" :style="{ color: sortType == 0 ? '#1890FF' : '' }">综合</span>
          <span class="page-t-in" @click="isPop(1)" :style="{ color: sortType == 1 ? '#1890FF' : '' }"
            v-if="sortType != 2">销量↑</span>
          <span class="page-t-in" @click="isPop(2)" :style="{ color: sortType == 2 ? '#1890FF' : '' }"
            v-if="sortType == 2">销量↓</span>
          <span class="page-t-in" @click="isPop(3)" :style="{ color: sortType == 3 ? '#1890FF' : '' }"
            v-if="sortType != 4">价格↑</span>
          <span class="page-t-in" @click="isPop(4)" :style="{ color: sortType == 4 ? '#1890FF' : '' }"
            v-if="sortType == 4">价格↓</span>
        </div>
      </div>
      <div class="page-list">
        <div class="page-item" v-for="(item, index) in priduct" :key="index">
          <div class="page-item-left">
            <div class="page-item-i">
              <img class="page-item-img1" :src="item.backgroundImg" alt="">
              <img class="page-item-img2" :src="require('@/assets/images/common/tj.png')" alt="">
            </div>
            <div class="page-item-info">
              <span class="page-item-title">{{ item.title }}</span>
              <p class="page-insuranceNotice"><span class="page-icon"><i class="el-icon-check"></i></span> <span
                  class="page-icon-i">{{ item.insuranceNotice }}</span></p>
            </div>
          </div>
          <div class="page-item-right">
            <img :src="item.supplierLogo" alt="">
            <el-button type="primary" @click="toHandle(item)" round>立即办理</el-button>
          </div>
        </div>
      </div>
    </div>
    <handle :config="config.dialogVisible" v-if="config.dialogVisible" @close="config.dialogVisible = false"
      @refresh="handleRefresh"></handle>
  </div>
</template>

<script>
import handle from '@/components/handle.vue';
import { queryinsuranceproducts } from '@/api/case.js'
export default {
  name: 'cases',
  data() {
    return {
      activeName: "-1",
      menuList: [
        {
          name: '全部',
          value: "-1"
        },
        {
          name: '出境险',
          value: "1"
        },
        {
          name: '国内旅游险',
          value: "2"
        },
      ],
      priduct: [],
      pageIndex: 0,
      pageSize: 10,
      total: 0,
      sortType: 0,
      config: {
        dialogVisible: false,
      },
    }
  },
  components: {
    handle,
  },
  created() {
    this.queryinsuranceproductsFn(true);
  },
  methods: {
    queryinsuranceproductsFn(result = false) {
      if (result) {
        this.pageIndex = 0;
        this.pageSize = 10;
        this.queryinsuranceproductsFn();

        return;
      }


      queryinsuranceproducts({
        category: this.activeName == -1 ? '' : this.activeName,
        'Page.Size': this.pageSize,
        'Page.Index': this.pageIndex,
        SortType: this.sortType
      }).then(res => {
        console.log(res);
        this.priduct = res.data.rows
      })
    },
    handleRefresh() {
      this.config.dialogVisible = false
    },
    toHandle(item) {
      this.$router.push({ name: 'casebDetail', params: { id: item.id } })
    },
    handleClick() {
      this.sortType = 0;
      this.queryinsuranceproductsFn(true);
    },
    isPop(num) {
      console.log(num, 'num', this.sortType)
      if (num == 0) {

        this.sortType = 0;
        this.queryinsuranceproductsFn();
        return;
      }

      if (num == 1 && this.sortType == 0 | this.sortType == 3 | this.sortType == 4) {
        this.sortType = 1;
        this.queryinsuranceproductsFn();
        return;
      }

      if (num == 1 && this.sortType == 1) {
        this.sortType = 2;
        this.queryinsuranceproductsFn();
        return;
      }

      if (num == 2 && this.sortType == 2) {
        this.sortType = 1;
        this.queryinsuranceproductsFn();
        return;
      }

      if (num == 3 && this.sortType == 0 || this.sortType == 1 || this.sortType == 2) {
        this.sortType = 3;
        this.queryinsuranceproductsFn();
        return;
      }

      if (num == 3 && this.sortType == 3) {
        this.sortType = 4;
        this.queryinsuranceproductsFn();
        return;
      }

      if (num == 4 && this.sortType == 4) {
        this.sortType = 3;
        this.queryinsuranceproductsFn();
        return;
      }




    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/cases.scss';

.page {
  background: #F5F6F7;
}
</style>