import axios from "axios";
import { getToken } from '@/utils/auth'
import store from '@/store'
import { Message } from 'element-ui';

// 创建实例
// Set config defaults when creating the instance
const instance = axios.create({
  baseURL: process.env.VUE_APP_URL,//直接使用博客链接
  // baseURL: 'https://visa.sport-syy.com/org_api',
  timeout: 60000,
});

// 添加请求拦截器
instance.interceptors.request.use((config) => {
  // 在发送请求之前做些什么
  
    //  excel下载
    // console.log(config, 'configconfigconfigconfigconfig');
    // console.log(store.state.user.token);
    if (config.params && config.params.typefile && config.params.typefile == 'download') {
      config.responseType = 'blob';
      config.headers.Accept = 'application/json;charset=UTF-8'
    }

    // config.headers['Authorization'] = "Bearer " + "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOiIyIiwibmFtZSI6IiIsInBob25lIjoiMTUwNzIzNzEyMDQiLCJwbGF0Zm9ybSI6IldlY2hhdFNtYWxsQXBwIiwiYXBwSWQiOiIxIiwibWVyY2hhdElkIjoiMCIsIm9yZ0lkIjoiMSIsInVzZXJUeXBlIjoiQ29tbW9uIiwidHkiOiIiLCJsdCI6IjE2OTA5NTg0OTUiLCJuYmYiOjE2OTA5NTg0OTQsImV4cCI6MTc2Mjk1ODQ5NCwiaXNzIjoiaHR0cDovLzEyNy4wLjAuMTo2MjIwIiwiYXVkIjoiaHR0cDovLzEyNy4wLjAuMTo2MzAwIn0.8_AVrRKqlZkqKFYWimZxASyclLNNYq4IuFBqM5zvKMU"
    // config.params.AppId = 'wxe9eafc3d6973fcdd'
    if (config.params && config.params.token) {
      config.headers['Authorization'] = "Bearer " + config.params.token
      // config.headers['Authorization'] = "Bearer " + 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOiIyIiwibmFtZSI6IiIsInBob25lIjoiMTUwNzIzNzEyMDQiLCJwbGF0Zm9ybSI6IldlY2hhdFNtYWxsQXBwIiwiYXBwSWQiOiIxIiwibWVyY2hhdElkIjoiMCIsIm9yZ0lkIjoiMSIsInVzZXJUeXBlIjoiQ29tbW9uIiwidHkiOiIiLCJsdCI6IjE2OTA5NTg0OTUiLCJuYmYiOjE2OTA5NTg0OTQsImV4cCI6MTc2Mjk1ODQ5NCwiaXNzIjoiaHR0cDovLzEyNy4wLjAuMTo2MjIwIiwiYXVkIjoiaHR0cDovLzEyNy4wLjAuMTo2MzAwIn0.8_AVrRKqlZkqKFYWimZxASyclLNNYq4IuFBqM5zvKMU'
      delete config.params.token
    } else {
      if (config.method == 'get') {
        config.params.AppId = 'wxe9eafc3d6973fcdd'
      }
    }
    if (store.state.user.token || store.state.user.orgToken) {
      config.headers['Authorization'] = "Bearer " + (store.state.user.token || store.state.user.orgToken)
    }
    if ((config.params && config.params.secretKey) || (config.data && config.data.secretKey)) {
      config.headers['Secret-Key'] = config.params ? config.params.secretKey : config.data.secretKey
      if(config.params){
        delete config.params.secretKey
      }else{
        delete config.data.secretKey
      }
    }
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 添加响应拦截器
instance.interceptors.response.use((response) => {
  // 对响应数据做点什么
  // console.log('response', response);
  const {
    config,
    data,
    headers,
    request,
    status,
    statusText
  } = response
  const res = response.data
  if(response.config.params && response.config.params.typefile == "download"){
    //headers请求头的乱码数据转换为中文
    const fileName = decodeURI(response.headers['content-disposition'].split(';')[1].split('=')[1])
    //下载文件
    let blob = new Blob([response.data], {type: "application/vnd.ms-excel"})
        let objectUrl = URL.createObjectURL(blob) // 创建URL
        const link = document.createElement('a')
        link.href = objectUrl
        // link.download = '.xlsx'// 自定义文件名
        //这里是获取后台传过来的文件名
        link.setAttribute("download",fileName)
        link.click() // 下载文件
        URL.revokeObjectURL(objectUrl) // 释放内存
        return res
  } else {
    // console.log(res);
    if (res.code != 1) {
      Message.error(res.msg)
      return  Promise.reject(res.msg)
    }
    return response.data;
  }
  return response.data;
}, function (error) {
  // 对响应错误做点什么
  return Promise.reject(error);
});

export default instance


