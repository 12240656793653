import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/index.vue'
import privacyPolicy from '../views/agreement/privacyPolicy.vue'
import userAgreement from '../views/agreement/userAgreement.vue'
import productDetail from '../views/product/detail.vue';
import product from '../views/product/index.vue';
import productDetailId from '../views/product/detailid';
import caseb from '../views/case/index';
import casebDetail from '../views/case/detail';
import about from '../views/about/index';
import order from '../views/order/index';
import orderDetail from '../views/order/_id/index';
import information from '../views/order/_id/information/index';
import electron from '../views/order/_id/electron/index';
import place from '../views/order/_id/place/index';
import orderInfoDetails from '../views/order/_details/details/index';
import personal from '../views/personal/index';
import settings from '../views/settings/index';
import orderCenter from '../views/orderCenter/index';
import orderCenterElectron from '../views/orderCenter/electron/index';
import informations from '../views/orderCenter/informations/index';
import system from '../views/system/index';
import orgLogin from '../views/organization/login';
import malaysia from '../views/orderCenter/malaysia/index';
import { getLocalStorage,getSessionStorage } from '@/utils/auth'
let orgAuthInfo = getSessionStorage('orgAuthInfo')

Vue.use(VueRouter)

export default new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component:orgAuthInfo && orgAuthInfo.isOrg ? settings :  HomeView,
    },
    {
      path: '/agreement/privacyPolicy',
      name: 'privacyPolicy',
      component: privacyPolicy
    },
    {
      path: '/agreement/userAgreement',
      name: 'userAgreement',
      component: userAgreement
    },
    {
      path: '/product',
      name: 'product',
      component: product,
    },
    {
      path: '/product/:id',
      name: 'productDetail',
      component: productDetail,
    },
    {
      path: '/product/:id/detail',
      name: 'productDetailId',
      component: productDetailId,
    },
    {
      path: '/caseb',
      name: 'caseb',
      component: caseb,
    },
    {
      path: '/caseb/:id',
      name: 'casebDetail',
      component: casebDetail,
    },
    {
      path: '/about',
      name: 'about',
      component: about,
    },
    {
      path: '/order',
      name: 'order',
      component: order,
    },
    {
      path: '/order/:id',
      name: 'orderDetail',
      component: orderDetail,
    },
    {
      path: '/order/:id/information',
      name: 'information',
      component: information,
    },
    {
      path: '/order/:id/electron',
      name: 'electron',
      component: electron,
    },
    {
      path: '/order/:id/place',
      name: 'place',
      component: place,
    },
    {
      path: '/order/:details/details',
      name: 'orderInfoDetails',
      component: orderInfoDetails,
    },
    {
      path: '/personal',
      name: 'personal',
      component: personal,
    },
    {
      path: '/settings',
      name: 'settings',
      component: settings,
    },
    {
      path: '/orderCenter',
      name: 'orderCenter',
      component: orderCenter,
    },
    {
      path: '/orderCenter/:id',
      name: 'orderCenterElectron',
      component: orderCenterElectron,
    },
    {
      path: '/orderCenter/:id/informations',
      name: 'informations',
      component: informations,
    },
    {
      path: '/system',
      name: 'system',
      component: system,
    },
    {
      path: '/orgLogin',
      name: 'orgLogin',
      component: orgLogin,
    },
    {
      path: '/malaysia',
      name: 'malaysia',
      component: malaysia,
    },
  ]
})
