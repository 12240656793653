// 个人中心
import request from '@/utils/request';


  //    查询当前用户商铺信息
  export function getmechatinfo(params) {
    return request('/app_api/v1/user/getmechatinfo', {
      method: 'get',
      params: {...params}//get请求使用params,直接拼接在请求url上
    })
  }

    //    获取商铺余额
    export function getbalance(params) {
        return request('/app_api/v1/user/getbalance', {
            method: 'get',
            params: {...params}//get请求使用params,直接拼接在请求url上
        })
    }

    //    获取商户流水
    export function queryfinance(params) {
        return request('/app_api/v1/user/queryfinance', {
            method: 'get',
            params: {...params}//get请求使用params,直接拼接在请求url上
        })
    }


    //    查询当前用户优惠券
    export function queryusercoupon(params) {
      return request('/app_api/v1/user/queryusercoupon', {
          method: 'get',
          params: {...params}//get请求使用params,直接拼接在请求url上
      })
    }


  export const exchangecoupon = (params) => {
    return request('/app_api/v1/user/exchangecoupon', {
      method: 'post',
      data: {...params},//post请求使用post

    })
  }


   //    查询店铺成员
   export function querymerchatmembers(params) {
    return request('/app_api/v1/merchat/querymerchatmembers', {
        method: 'get',
        params: {...params}//get请求使用params,直接拼接在请求url上
    })
  }

    //    添加店铺成员
    export function addmerchatmember(params) {
      return request('/app_api/v1/merchat/addmerchatmember', {
          method: 'post',
          data: {...params}//get请求使用params,直接拼接在请求url上
      })
    }

    //    店铺成员启用状态修改
    export function modifymemberstatus(params) {
      return request('/app_api/v1/merchat/modifymemberstatus', {
          method: 'post',
          data: {...params}//get请求使用params,直接拼接在请求url上
      })
    }

    //    修改店铺成员信息
    export function updatemerchatmember(params) {
      return request('/app_api/v1/merchat/updatemerchatmember', {
          method: 'post',
          data: {...params}//get请求使用params,直接拼接在请求url上
      })
    }

       //    查询当前用户地址
   export function queryaddress(params) {
    return request('/app_api/v1/user/queryaddress', {
        method: 'get',
        params: {...params}//get请求使用params,直接拼接在请求url上
    })
  }



  //    省市县数据
  export function provincedata(params) {
    return request('/app_api/v1/country/provincedata', {
        method: 'get',
        params: {...params}//get请求使用params,直接拼接在请求url上
    })
  }


    //    省数据
    export function getprovinces(params) {
      return request('/app_api/v1/country/getprovinces', {
          method: 'get',
          params: {...params}//get请求使用params,直接拼接在请求url上
      })
    }

       //    市数据
       export function getcitys(params) {
        return request('/app_api/v1/country/getcitys', {
            method: 'get',
            params: {...params}//get请求使用params,直接拼接在请求url上
        })
      }

         //    县数据
    export function getdistricts(params) {
      return request('/app_api/v1/country/getdistricts', {
          method: 'get',
          params: {...params}//get请求使用params,直接拼接在请求url上
      })
    }

    //    添加Or更新用户地址
    export function addaddress(params) {
      return request('/app_api/v1/user/addaddress', {
          method: 'post',
          data: {...params}//get请求使用params,直接拼接在请求url上
      })
    }

      //    删除用户地址
    export function deladdress(params) {
      return request('/app_api/v1/user/deladdress', {
          method: 'get',
          params: {...params}//get请求使用params,直接拼接在请求url上
      })
    }

    //    设置用户地址为默认地址
    export function setdefaultaddress(params) {
      return request('/app_api/v1/user/setdefaultaddress', {
          method: 'get',
          params: {...params}//get请求使用params,直接拼接在请求url上
      })
    }

    //    查询签证列表
    export function query(params) {
      return request('/org_api/v1/product/query', {
          method: 'get',
          params: {...params}//get请求使用params,直接拼接在请求url上
      })
    }

    //    修改店铺成员信息
    // export function updatemerchatmember(params) {
    //   return request('/v1/merchat/updatemerchatmember', {
    //       method: 'post',
    //       data: {...params}//get请求使用params,直接拼接在请求url上
    //   })
    // }

    //    所有签证类型数据
    export function visatypelist(params) {
      return request('/org_api/v1/product/visatypelist', {
          method: 'get',
          params: {...params}//get请求使用params,直接拼接在请求url上
      })
    }

    //    所有领区数据
    export function arealist(params) {
      return request('/org_api/v1/product/arealist', {
          method: 'get',
          params: {...params}//get请求使用params,直接拼接在请求url上
      })
    }

    //    所有国家数据
    export function countrylist(params) {
      return request('/org_api/v1/product/countrylist', {
          method: 'get',
          params: {...params}//get请求使用params,直接拼接在请求url上
      })
    }

    
    //    获取服务类型
    export function servicetypeselect(params) {
      return request('/org_api/v1/product/servicetypeselect', {
          method: 'get',
          params: {...params}//get请求使用params,直接拼接在请求url上
      })
    }

    //    获取办理类型类型
    export function visamethodselect(params) {
      return request('/org_api/v1/product/visamethodselect', {
          method: 'get',
          params: {...params}//get请求使用params,直接拼接在请求url上
      })
    }

    //    获取所有订单流程
    export function orderprocesseselect(params) {
      return request('/org_api/v1/product/orderprocesseselect', {
          method: 'get',
          params: {...params}//get请求使用params,直接拼接在请求url上
      })
    }
    
    //    添加签证产品
    export function add(params) {
      return request('/org_api/v1/product/add', {
          method: 'post',
          data: {...params}//get请求使用params,直接拼接在请求url上
      })
    }


  // 编辑签证
  export function editProduct(params) {
    return request('/org_api/v1/product/edit', {
      method: 'get',
      params: {...params}//get请求使用params,直接拼接在请求url上
    })
  }

  // 修改签证 
  export function updateProduct(params) {
    return request('/org_api/v1/product/update', {
      method: 'post',
      data: {...params}//get请求使用params,直接拼接在请求url上
    })
  }
  
  // 删除 
  export function deleteProduct(params) {
    return request('/org_api/v1/product/delete', {
      method: 'get',
      params: {...params}
    })
  }

  // 修改是否上架信息 
  export function visaenable(params) {
    return request('/org_api/v1/product/visaenable', {
      method: 'post',
      data: {...params}//get请求使用params,直接拼接在请求url上
    })
  }

  // 查询指定签证配置的身份类型 
  export function getvisaidentitys(params) {
    return request('/org_api/v1/product/getvisaidentitys', {
      method: 'get',
      params: {...params}
    })
  }

  // 查询可配置身份类型身份类型 
  export function getdefaultvisaidentitys(params) {
    return request('/org_api/v1/product/getdefaultvisaidentitys', {
      method: 'get',
      params: {...params}
    })
  }

  // 签证批量开启或者关闭身份类型 
  export function visaidentitys(params) {
    return request('/org_api/v1/product/visaidentitys', {
      method: 'post',
      data: {...params}//get请求使用params,直接拼接在请求url上
    })
  }

  // 查询指定签证配置的资料 
  export function queryvisadocs(params) {
    return request('/org_api/v1/product/queryvisadocs', {
      method: 'get',
      params: {...params}
    })
  } 

  // 查询指定签证身份类型配置的所有资料 
  export function getvisadocs(params) {
    return request('/org_api/v1/product/getvisadocs', {
      method: 'get',
      params: {...params}
    })
  } 

  // 获取所有资料数据 
  export function doclist(params) {
    return request('/org_api/v1/product/doclist', {
      method: 'get',
      params: {...params}
    })
  } 

  // 指定身份类型签证修改绑定资料 
  export function modifyvisadoc(params) {
    return request('/org_api/v1/product/modifyvisadoc', {
      method: 'post',
      data: {...params}//get请求使用params,直接拼接在请求url上
    })
  }

  // 修改是否多张或者是否必须 
  export function modifymultipleorisneed(params) {
    return request('/org_api/v1/product/modifymultipleorisneed', {
      method: 'post',
      data: {...params}//get请求使用params,直接拼接在请求url上
    })
  }

  // 删除签证资料 
  export function deletevisadoc(params) {
    return request('/org_api/v1/product/deletevisadoc', {
      method: 'get',
      params: {...params}
    })
  } 

  // 获取签证国家 
  export function getcountrys(params) {
    return request('/app_api/v1/country/getcountrys', {
      method: 'get',
      params: {...params}
    })
  } 

  // 查询机构数据 
  export function getorgdata(params) {
    return request('/org_api/v1/orgfinance/getorgdata', {
      method: 'get',
      params: {...params}
    })
  } 

  // 查询机构数据 
  export function productCopy(params) {
    return request('/org_api/v1/product/copy', {
      method: 'get',
      params: {...params}
    })
  } 

  // 查询系统签证列表 
  export function querysystemvisa(params) {
    return request('/org_api/v1/product/querysystemvisa', {
      method: 'get',
      params: {...params}
    })
  } 

  // 绑定系统签证 
  export function bindsystemvisa(params) {
    return request('/org_api/v1/product/bindsystemvisa', {
      method: 'post',
      data: {...params}//get请求使用params,直接拼接在请求url上
    })
  }
  
  //查询店铺首页数据
  export function gethomepagedata(params){
    return request("/app_api/v1/merchat/gethomepagedata",{
      method:'get',
      params:{...params}
    })
  }  

  
