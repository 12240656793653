<template>
    <div>
        <!-- 地址管理    添加or编辑 -->
        <!--  config = 开关  -->
        <!--  item = 编辑地址的数据  -->
        <!-- @close = 关闭函数 -->
        <!-- @success = 编辑成功返回 -->
        <el-dialog :visible.sync="dialogAddress" width="372px" @close="resetAddresForm('ruleAddressForm')"
            class="common-dialog-class address-class" :close-on-click-modal="false">
            <div class="dialog-member-wrap">
                <div class="dialog-member-title">
                    <span class="dialog-member-name">添加地址</span>
                    <div class="dialog-close" @click="resetAddresForm('ruleAddressForm')">
                        <i class="el-icon-close"></i>
                    </div>
                </div>
                <div class="dialog-form-wrap">
                    <el-form :model="addresForm" :rules="addresRules" ref="ruleAddressForm" label-width="100px"
                        class="demo-ruleForm">
                        <el-form-item label="姓名" prop="name">
                            <el-input v-model="addresForm.name"></el-input>
                        </el-form-item>
                        <el-form-item label="联系方式" prop="phone">
                            <el-input v-model="addresForm.phone"></el-input>
                        </el-form-item>
                        <el-form-item label="地址" prop="districtId">

                            <div class="address-select">
                                <div class="address-top">
                                    <el-select v-model="addresForm.provinceId" placeholder="省" @change="checkProvinceId">
                                        <el-option v-for="item in provinces" :key="item.id" :label="item.name"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>

                                    <el-select v-model="addresForm.cityId" placeholder="市" @change="checkCitysId">
                                        <el-option v-for="item in citys" :key="item.id" :label="item.name" :value="item.id">
                                        </el-option>
                                    </el-select>

                                    <el-select v-model="addresForm.districtId" placeholder="县">
                                        <el-option v-for="item in districts" :key="item.id" :label="item.name"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </div>
                                <div class="address-textarea">
                                    <el-input type="textarea" :rows="2" placeholder="详细地址" v-model="addresForm.address">
                                    </el-input>
                                </div>
                            </div>
                        </el-form-item>


                        <el-form-item label="邮箱" prop="email">
                            <el-input v-model="addresForm.email" placeholder="用于接收面试信息，通知书等消息"></el-input>
                        </el-form-item>


                        <el-form-item>
                            <el-button @click="resetAddresForm('ruleAddressForm')">取消</el-button>
                            <el-button type="primary" @click="submitAddresForm('ruleAddressForm')">确认</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { addaddress, getdistricts, getcitys, getprovinces } from '@/api/personal.js'

export default {
    data() {
        var validatorAccount = (rule, value, callback) => {
            let reg = /^[1][1,2,3,4,5,7,8,9][0-9]{9}$/;		//	手机

            if (!value) {
                // message: '请输入手机号',
                return callback(new Error('请输入手机号'));
            }


            if (!reg.test(value)) {
                return callback(new Error('手机号格式不正确'));
            } else {

                callback();
            }


        };

        var validatorEmail = (rule, value, callback) => {
            let reg = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;		//	手机

            if (!value) {
                // message: '请输入手机号',
                return callback(new Error('请输入邮箱地址'));
            }


            if (!reg.test(value)) {
                return callback(new Error('请输入正确的邮箱地址格式'));
            } else {

                callback();
            }


        };
        return {
            dialogAddress: this.config,
            addresForm: {
                id: '',
                name: '',
                phone: '',
                provinceId: '',
                cityId: '',
                districtId: '',
                address: '',
                province: '',
                city: '',
                district: '',
                email: '',
            },
            districts: [],
            citys: [],
            provinces: [],
            addresRules: {
                name: [
                    { required: true, message: '请输入联系人', trigger: ['click', 'change', 'blur'] },
                ],
                phone: [
                    { validator: validatorAccount, required: true, trigger: ['click', 'change', 'blur'] }
                ],
                districtId: [
                    { required: true, message: '请选择收货地址', trigger: ['click', 'change', 'blur'] }
                ],
                address: [
                    { required: true, message: '请选择收货地址', trigger: ['click', 'change', 'blur'] }
                ],
                email: [
                    { validator: validatorEmail, required: true, trigger: ['click', 'change', 'blur'] }
                ],
            }
        }
    },
    props: {
        //  打开关闭
        config: {
            type: Boolean,
            default: false,
        },
        //  编辑地址的详情
        item: {
            type: Object,
            default: {}
        }
        
    },
    watch: {
        'addresForm.provinceId'(e) {
            this.provinces.forEach(item => {
                if (item.id == e) {
                    this.addresForm.province = item.name
                }
            })

        },
        'addresForm.cityId'(e) {
            this.citys.forEach(item => {
                if (item.id == e) {
                    this.addresForm.city = item.name
                }
            })
        },
        'addresForm.districtId'(e) {
            this.districts.forEach(item => {
                if (item.id == e) {
                    this.addresForm.district = item.name
                }
            })
        },
    },
    mounted () {
        if (this.item.id) {
            this.editAddress(this.item);
        }

        this.getprovincesFn();
    },
    methods: {
        resetAddresForm(formName) {
            // this.dialogMember = false;
            // this.ruleForm = {
            //     name: '',
            //     account: '',
            //     phone: '',
            //     email: '',
            //     id: '',
            // }
            this.dialogAddress = false;
            this.addresForm = {
                id: '',
                name: '',
                phone: '',
                provinceId: '',
                cityId: '',
                districtId: '',
                address: '',
                province: '',
                city: '',
                district: '',
                email: '',
            }

            if (formName) {

                this.$refs[formName].resetFields();
            }

            this.$emit('close')
        },

        getcitysFn(e) {
            getcitys({ ProvicesId: e }).then(res => {
                console.log(res);
                this.citys = res.data;
                // this.citys = res.data.citys
            })
        },

        getdistrictsFn(e) {
            getdistricts({ CityId: e }).then(res => {
                console.log(res);
                this.districts = res.data

            })
        },

        getprovincesFn() {
            getprovinces({ }).then(res => {
                console.log(res);

                this.provinces = res.data
            })
        },

        checkProvinceId(e) {
            this.addresForm.cityId = '';
            this.addresForm.districtId = '';
            this.getcitysFn(e)
        },
        checkCitysId(e) {
            this.addresForm.districtId = '';
            this.getdistrictsFn(e);
        },

        editAddress(item) {
            let vm = this;
           
            this.getcitysFn(item.provinceId)
            this.getdistrictsFn(item.cityId);



            vm.addresForm = {
                id: item.id,
                name: item.name,
                phone: item.phone,
                provinceId: item.provinceId,
                cityId: item.cityId,
                districtId: item.districtId,
                address: item.address,
                province: item.province,
                city: item.city,
                district: item.district,
                email: item.email
            }

            this.dialogAddress = true;
            console.log(item)
        },


        submitAddresForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {



                    addaddress({
                        ...this.addresForm
                    }).then(res => {
                        console.log(res);

                        if (res.code == 1) {

                            this.$message({
                                message: '添加成功',
                                type: 'success'
                            });
                 

                            this.addresForm = {
                                id: '',
                                name: '',
                                phone: '',
                                provinceId: '',
                                cityId: '',
                                districtId: '',
                                address: '',
                                province: '',
                                city: '',
                                district: '',
                                email: '',
                            }

                            this.$emit('success')

                            // this.$emit('close')
                        } else {
                            this.$message({
                                message: res.msg,
                                type: 'error'
                            });
                            // this.dialogAddress = false;
                            // this.getQueryaddress();
                        }

                    })
                } else {
                    return false;
                }
            });
        },
    }
}
</script>

<style lang="scss" scoped>
.address-class {
    ::v-deep .el-dialog__body {
        height: 519px;

    }
}
.demo-ruleForm {
    ::v-deep .el-form-item {
        display: flex;
        flex-direction: column;
        margin-bottom: 6px;

        .el-form-item__label {
            width: 50px !important;
            white-space: nowrap;
            height: 30px;
        }

        .el-form-item__content {
            margin-left: 0px !important;
        }
    }

    ::v-deep .el-form-item__content {
        text-align: right;

    .el-button {
            margin-top: 20px;
        }
    }
}
.dialog-member-wrap {
    .dialog-member-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: -10px;

        .dialog-member-name {
            font-size: 18px;
            font-weight: bold;

        }

        .dialog-close {
            width: 25px;
            height: 25px;
            background: #E0E0E0;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            .el-icon-close {
                color: #FFF;
                font-size: 16px;
            }
        }
    }
}

.address-select {

    .address-top {
        display: flex;

    }

    .address-textarea {
        margin-top: 12px;
    }
}
</style>