import request from '@/utils/request';


export function continentselect(params) {
  return request('/app_api/v1/country/continentselect', {
    method: 'get',
    params: { ...params }//get请求使用params,直接拼接在请求url上
  })
}

export function getvisas(params) {
  return request('/app_api/v1/visa/getvisas', {
    method: 'get',
    params: { ...params }//get请求使用params,直接拼接在请求url上
  })
}


export function provincedata(params) {
  return request('/app_api/v1/country/provincedata', {
    method: 'get',
    params: { ...params }//get请求使用params,直接拼接在请求url上
  })
}
//获取国家使馆未来60天假期日情况
export function queryembassy(params) {
  return request('/app_api/v1/country/queryembassy', {
    method: 'get',
    params: { ...params }//get请求使用params,直接拼接在请求url上
  })
}

export function getvisadetail(params) {
  return request('/app_api/v1/visa/getvisadetail', {
    method: 'get',
    params: { ...params }//get请求使用params,直接拼接在请求url上
  })
}

export function getcountryandabbr(params) {
  return request('/app_api/v1/country/getcountryandabbr', {
    method: 'get',
    params: { ...params }//get请求使用params,直接拼接在请求url上
  })
}

export function downloadvisadata(params) {
  return request('/app_api/v1/visa/downloadvisadata', {
    method: 'get',
    params: { ...params }//get请求使用params,直接拼接在请求url上
  })
}


export function getvisadatalist(params) {
  return request('/app_api/v1/visa/getvisadatalist', {
    method: 'get',
    params: { ...params }//get请求使用params,直接拼接在请求url上
  })
}

export function getdefaultidentity(params) {
  return request('/app_api/v1/visa/getdefaultidentity', {
    method: 'get',
    params: { ...params }//get请求使用params,直接拼接在请求url上
  })
}

// 预下单接口
export function preorder(params) {
  return request('/app_api/v1/visaorder/preorder', {
    method: 'post',
    data: { ...params }//get请求使用params,直接拼接在请求url上
  })
}

// 发送到邮箱
export function sendemailvisa(params) {
  return request('/app_api/v1/visa/sendemailvisa', {
    method: 'get',
    params: { ...params }
  })
}

// 护照识别申请人
export function passportrecognitionapplicant(params) {
  return request('/app_api/v1/visaorder/passportrecognitionapplicant', {
    method: 'post',
    data: { ...params }//get请求使用params,直接拼接在请求url上
  })
}

// 获取系统产品
export function querysystemvisa(params) {
  return request('/org_api/v1/product/querysystemvisa', {
    method: 'get',
    params: { ...params }
  })
}

// 订单添加申请人
export function addorderapplicant(params) {
  return request('/org_api/v1/orgfinance/addorderapplicant', {
    method: 'post',
    data: { ...params }//get请求使用params,直接拼接在请求url上
  })
}






