<template>
  <footer class="footer-wrap">
    <div class="footer-box container">
      <div class="footer-box-top">
        <div  class="footer-box-left">
          <ul>
            <li>
              <h3>产品</h3>
              <span v-for="(item, index) in list1" :key="index" @click="toRoute(item.path)">{{item.title}}</span>
            </li>
          </ul>
          <ul>
            <li>
              <h3>关于我们</h3>
              <span v-for="(item, index) in list2" :key="index"  @click="toRoute(item.path)">{{item.title}}</span>
            </li>
          </ul>
          <ul>
            <li>
              <h3>联系我们</h3>
              <span v-for="(item, index) in list3" :key="index">{{item.title}}</span>
            </li>
          </ul>
        </div>
        <div class="footer-box-center">
          <p>181-7084-3717</p>
          <h3></h3>
          <span>周一至周五</span>
          <span>9:00-12:00 14:00-20:00</span>
        </div>
        <div class="footer-box-right">
     
          <div>
              <!-- <el-image :src="require('@/assets/images/common/kf.jpg')"  style="width: 148px; height: 148px" fit="fit"></el-image> -->
              <div class="sms-img">
                <el-image :src="require('@/assets/images/common/appletQrcode.jpg')"  style="width: 148px; height: 148px" fit="fit"></el-image>
              </div>
              <span>小程序</span>
          </div>
        </div>
      </div>
      <div class="footer-box-x"></div>
      <div class="footer-box-bq">
        <p>友情链接: <a href="http://chinese.usembassy-china.org.cn/" target="_blank">美国驻华使领馆</a> | <a  href="http://china.embassy.gov.au/bjngchinese/home.html" target="_blank">澳大利亚驻华使领馆</a> | <a href="http://www.canadainternational.gc.ca/china-chine/contact-contactez.aspx?lang=zh-CN" target="_blank">加拿大驻华使领馆</a> | <a href="http://www.peking.diplo.de/Vertretung/peking/zh/Startseite.html" target="_blank">德国驻华使领馆</a> | <a href="https://www.gov.uk/government/world/organisations/british-embassy-beijing" target="_blank">英国驻华使领馆</a> | <a href="http://www.ambafrance-cn.org/%E4%B8%BB%E9%A1%B5.html?lang=zh" target="_blank">法国驻华使领馆</a> | <a href="http://www.cn.emb-japan.go.jp/" target="_blank">日本驻华使领馆</a> | <a href="http://www.kln.gov.my/web/chn_beijing/home" target="_blank">马来西亚驻华使领馆</a>
           <!-- | 申请友情链接 1042752863@qq.com -->
        </p>
        <p @click="toBa">粤ICP备2023098659号-2 Copyright© 深圳快马文旅科技有限公司 2021-{{new Date().getFullYear()}}, 版权所有</p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
    name: 'Footer',
    data () {
      return {
        list1: [
          { title: '签证办理', path: '/product' },
          { title: '保险商城', path: '/caseb' },
        ],
        list2: [
          { title: '平台介绍', path: '/about' },
          { title: '关于我们', path: '/about' },
          // { title: '产品动态', path: '/about' },
        ],
        list3: [
          { title: '微信：kmvisa666', path: '' },
          { title: '邮箱：1042752863@qq.com', path: '' },
          // { title: 'QQ：1042752863', path: '' },
          // { title: '地址：广东省深圳市宝安区松岗燕罗街道锦江路6号', path: '' },
        ],
      }
    },
    methods: {
      toRoute (route) {
        document.documentElement.scrollTop = 0
        this.$router.push(route)
      },
      toBa () {
        window.open("https://beian.miit.gov.cn", "_blank");
      }
    }
}
</script>

<style lang="scss" scoped>
  .footer-wrap {
    background: #22252A;
    height: 364px;
  }
  .footer-box {
      display: flex;
      flex-direction: column;
      width: 1200px;
    .footer-box-top {
      display: flex;
      justify-content: space-between;
      margin-top: 58px;

    }
    .footer-box-x {
      width: 100%;
      height: 1px;
      background: #464646;
      margin-top: 16px;
    }
     .footer-box-bq {
      // flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 19px;
      p {
        margin-top: 4px;
        font-size: 13px;
        color: #FFFFFF;
        &:nth-child(2) {
          cursor: pointer;
          &:hover {
            color: #ba2636;
          }
        }
        a{
          color: #FFFFFF;
        }
      }
    }
    .footer-box-left {
       display: flex;
       ul {
        margin-right: 88px;
          display: flex;
          flex-direction: column;
          li {
            display: flex;
            flex-direction: column;
        
            span {
              font-size: 14px;
              color: #999999;
              margin-bottom: 12px;
              &:hover {
                color: #F37716;
                cursor: pointer;
              }
            }
            h3 {
              font-size: 16px;
              color: #FFFFFF;
              margin-bottom: 18px;
            }
          }
       }
    }
    .footer-box-center {
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 70px;
      p {
        font-size: 32px;
        color: #FFFFFF;
        // font-weight: bold;
      }
      span {
        color: #999999;
        font-size: 16px;
        margin-top: 8px;
        display: block;
      }
      .footer-btn {
        border: none;
        width: 162px;
        height: 38px;
        margin-top: 16px;
      }
    }
    .footer-box-right {
      display: flex;
      margin-left: 52px;
      div {
        display: flex;
        flex-direction: column;
        // align-items: center;
        &:nth-child(1) {
          margin-right: 24px;
          }
        span {
          width: 148px;
          font-size: 14px;
          color: #FFFFFF;
          margin-top: 16px;
          text-align: center;
        }
      }

      .sms-img {
        width: 149px;
        height: 148px;
        background: #EBEBEB;
        border-radius: 4px;
      }
    }
  }
</style>