<template>
  <div class="page order-detail-child order-detail-input">
    <div class="page-card-top-fixd" v-if="topShow">
      <div class="page-card-top">
        <div class="page-card-top-flex">
          <span class="page-top-back" @click="toPath(3)"><i class="el-icon-arrow-left"></i>返回</span>
          <p class="page-top-name">{{ applicantdocsObj.orderApplicant.applicantName }}</p>
          <p class="page-top-identity">{{ applicantdocsObj.orderApplicant.identityTypeName }}</p>
        </div>
        <el-button class="default" @click="toPath(3)">确认</el-button>
      </div>
    </div>
    <div class="order-wrap">
      <div class="page-path">
        <span @click="toPath(1)">快马签证</span> > <span @click="toPath(2)">订单中心</span> > <span @click="toPath(3)">资料上传</span>
      </div>
      <div class="left-wrap">
        <div class="left-top">
          <p>
            <img :src="orderDetail.visaDetail.visa.nationalFlagImg" alt="" class="nation-img">
            {{ orderDetail.visaDetail.visa.countryName }}-停留{{ orderDetail.visaDetail.visa.dataSpendTime }}
          </p>
          <div class="left-content-item">
            <span class="title">订单号</span>
            <span class="content">{{ orderDetail.order.orderNo }} <i class="iconfont icon-fuzhi"
                v-clipboard:copy="orderDetail.order.orderNo" v-clipboard:success="copySuccess"
                v-clipboard:error="copyError"></i> </span>
          </div>
          <div class="left-content-item">
            <span class="title">国家</span>
            <span class="content">{{ orderDetail.visaDetail.visa.countryName }}</span>
          </div>
          <div class="left-content-item">
            <span class="title">类型</span>
            <span class="content">{{ orderDetail.visaDetail.visa.typeName }}</span>
          </div>
          <div class="left-content-item" v-show="showMore">
            <span class="title">下单时间</span>
            <span class="content">{{ orderDetail.order.createdTime ? orderDetail.order.createdTime.slice(0, 10) : '' }}
            </span>
          </div>
          <div class="left-content-item" v-show="showMore">
            <span class="title">预约出行</span>
            <span class="content">{{ orderDetail.order.departureTime ? orderDetail.order.departureTime.slice(0, 10) : ''
            }}</span>
          </div>
          <div class="more-switch" @click="showMore = !showMore">
            <p v-if="!showMore">展开<i class="el-icon-arrow-down"></i></p>
            <p v-else>收起<i class="el-icon-arrow-up"></i></p>
          </div>
        </div>
        <div class="left-table">
          <div class="left-table-btn">
            <el-button type="primary" class="btn" @click="newAddUserShow = true">添加申请人</el-button>
            <el-button type="primary" class="btn" @click="batchShow = true">批量下载</el-button>
          </div>
          <div class="page-order-ul">
            <div class="page-order-li" :class="[item.id == detailId ? 'page-order-active' : '']"
              v-for="(item, index) in userList" :key="index" @click="selectUser(item)">
              <span>{{ item.applicantName }}</span>
              <div class="select-input">
                <el-select v-model="item.status" :class="setTopClass(item.status)" placeholder="请选择"
                  @change="selectStatus($event, item)">
                  <el-option v-for="elem in statusSelect" :key="elem.value" :label="elem.label" :value="elem.value">
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="page-card">
        <!-- <div class="page-order-table-box" v-show="false">
          <div class="page-order-table-box-title">
            <span>申请人列表</span>
            <div class="page-order-table-box-icon">
              <i class="iconfont icon-hanbaotubiao_hamburger-button"></i>
            </div>
          </div>
          <div class="page-order-ul">
            <div class="page-order-li" :class="[item.id == detailId ? 'page-order-active' : '']"
              v-for="(item, index) in userList" :key="index" @click="selectUser(item)">
              <span>{{ item.applicantName }}</span>
              <div class="select-input">
                <el-select v-model="item.status" :class="setTopClass(item.status)" placeholder="请选择"
                  @change="selectStatus($event, item)">
                  <el-option v-for="elem in statusSelect" :key="elem.value" :label="elem.label" :value="elem.value">
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>

          <div class="table-pagination">
            <el-pagination @current-change="addressCurrentChange" :current-page="pageIndex" :page-size="pageSize"
              layout="total, prev, pager, next" :total="total">
            </el-pagination>
          </div>
        </div> -->
        <div class="page-card-top">
          <div class="page-card-top-flex">
            <span class="page-top-back" @click="toPath(2)"><i class="el-icon-arrow-left"></i>返回</span>
            <p class="page-top-name">{{ applicantdocsObj.orderApplicant.applicantName }}</p>
            <!-- <p class="page-top-identity">{{ applicantdocsObj.orderApplicant.identityTypeName }}</p> -->
            <el-select class="page-top-name-select" v-model="applicantdocsObj.orderApplicant.identityType"
              placeholder="请选择" @change="selectIdentit($event, applicantdocsObj.orderApplicant)">
              <el-option v-for="item in orderDetail.visaDetail.visaDocs" :key="item.identityTypeId" :label="item.name"
                :value="item.identityTypeId">
              </el-option>
            </el-select>
          </div>
          <el-upload action="" :http-request="uploadVisapage" v-if="!applicantdocsObj.orderApplicant.visaPage">
            <el-button round class="default">上传签证页 <i class="iconfont icon-shangchuan"></i> </el-button>
          </el-upload>
          <div v-else>
            <span class="fine-name">
              {{ applicantdocsObj.orderApplicant.visaPageName }}
            </span>
            <i class="iconfont icon-search" @click="openFile"></i>
            <i class="el-icon-delete" @click="deleteFile"></i>
          </div>

        </div>
        <div class="page-require-box">
          <div class="page-title">必备资料</div>
          <div class="page-item" v-for="(item, index) in requiredList" :key="index">

            <!-- 头像 -->
            <div class="page-item-avatar" v-if="item.docId == 10">
              <span class="page-item-name">{{ item.name }}</span>
              <div class="page-item-flex">
                <upload @uploading="uploadHandle" :disabled="!isEdit" :auto-upload="true" :is-delete="true"
                  @deleteDoc="deleteDoc(item, 0)" :image-url="item.uploadedData[0].path" :drag="'drag'" thisType="avater"
                  @input="toImg($event, item)">
                </upload>

                <el-image class="page-item-legend" :src="item.legend" alt="" :preview-src-list="[item.legend]"></el-image>

                <div class="page-item-desc">
                  <span>资料要求</span>
                  <p style="white-space: pre-line;">{{ item.desc }}</p>
                </div>
              </div>
            </div>

            <!-- 护照 -->
            <div class="page-item-avatar" v-if="item.docId == 2">
              <span class="page-item-name">{{ item.name }}</span>
              <div class="page-item-flex">
                <upload v-model="item.uploadedData[0].path" @uploading="uploadHandle" :disabled="!isEdit"
                  @deleteDoc="deleteDoc(item, 0)" :is-delete="true" :auto-upload="true"
                  :image-url="item.uploadedData[0].path" :drag="'drag'" thisType="passport" @input="toImg($event, item)">
                </upload>
                <div v-if="!item.uploadedData[0].path">

                  <div class="page-item-no-passport">

                    <el-image class="page-item-legend" :src="item.legend" alt="" :preview-src-list="[item.legend]">
                    </el-image>

                    <div class="page-item-desc" style="margin-left: 40px">
                      <span>资料要求</span>
                      <p style="white-space: pre-line;">{{ item.desc }}</p>
                    </div>
                  </div>
                </div>

                <!-- 编辑护照信息 -->
                <div class="page-item-input page-input" v-else>
                  <div class="model-upload" v-if="loading">
                    <div class="model-upload-flex">
                      <span style="font-size: 20px;color: #FFF;">识别中</span>
                      <i class="el-icon-loading" style="font-size: 30px;color: #FFF;"></i>
                    </div>

                  </div>
                  <div class="page-item-title">护照信息读取</div>
                  <div class="page-item-wrap">
                    <div class="page-item-top">
                      <p class="page-item-waning"><i class="el-icon-warning-outline"></i>证件信息错误可能导致拒签，请仔细核对</p>
                      <p v-if="isEdit" class="page-edit" @click="inputShow = true;"> <i
                          class="iconfont icon-bianji"></i>修改资料 </p>
                    </div>
                    <div class="page-input-form" v-if="inputShow">
                      <div class="dialog-form-wrap">
                        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="110px"
                          class="demo-ruleForm1">
                          <el-form-item label="护照号码" prop="customer_PassportNumber">
                            <el-input v-model="ruleForm.customer_PassportNumber" placeholder="请填写护照号码"></el-input>
                          </el-form-item>
                          <el-row :gutter="24">
                            <el-col :span="12">
                              <el-form-item label="姓(中文)" prop="customer_SurName">
                                <el-input v-model="ruleForm.customer_SurName" placeholder="请填写姓(中文)"></el-input>
                              </el-form-item>

                              <el-form-item label="名(中文)" prop="customer_Name">
                                <el-input v-model="ruleForm.customer_Name" placeholder="请填写名(中文)"></el-input>
                              </el-form-item>
                              <el-form-item label="性别" prop="customer_Gender">
                                <div class="demo-ruleForm-radio">
                                  <el-radio v-model="ruleForm.customer_Gender" label="M">男</el-radio>
                                  <el-radio v-model="ruleForm.customer_Gender" label="F">女</el-radio>
                                </div>
                              </el-form-item>
                              <el-form-item label="签发日期" prop="customer_PassportIssue">
                                <el-date-picker v-model="ruleForm.customer_PassportIssue" type="date" placeholder="选择签发日期"
                                  value-format="yyyy-MM-dd">
                                </el-date-picker>
                              </el-form-item>
                              <el-form-item label="出生地点" prop="customer_PassportProvinceName">
                                <el-input v-model="ruleForm.customer_PassportProvinceName"
                                  placeholder="请填写出生地点"></el-input>
                              </el-form-item>
                              <el-form-item label="签发地点" prop="customer_ProvinceName">
                                <el-input v-model="ruleForm.customer_ProvinceName" placeholder="请填写签发地点"></el-input>
                              </el-form-item>
                              <el-form-item label="签发机关" prop="customer_PassportAuthority">
                                <el-input v-model="ruleForm.customer_PassportAuthority" placeholder="请填写签发机关"></el-input>
                              </el-form-item>
                            </el-col>
                            <el-col :span="12">
                              <el-form-item label="姓(拼音)" prop="customer_SurnameEn">
                                <el-input v-model="ruleForm.customer_SurnameEn" placeholder="请填写姓(拼音)"></el-input>
                              </el-form-item>
                              <el-form-item label="名(拼音)" prop="customer_NameEn">
                                <el-input v-model="ruleForm.customer_NameEn" placeholder="请填写名(拼音)"></el-input>
                              </el-form-item>
                              <el-form-item label="出生日期" prop="customer_BirthDay">
                                <el-date-picker v-model="ruleForm.customer_BirthDay" type="date" placeholder="请选择出生日期"
                                  value-format="yyyy-MM-dd">
                                </el-date-picker>
                              </el-form-item>
                              <el-form-item label="有效期至" prop="customer_PassportValidity">
                                <el-date-picker v-model="ruleForm.customer_PassportValidity" type="date"
                                  placeholder="选择日期有效期" value-format="yyyy-MM-dd">
                                </el-date-picker>
                              </el-form-item>
                              <el-form-item label="出生地点英文" prop="customer_PassportProvinceEn">
                                <el-input v-model="ruleForm.customer_PassportProvinceEn"
                                  placeholder="请填写出生地点英文"></el-input>
                              </el-form-item>
                              <el-form-item label="签发地点英文" prop="customer_ProvinceNameEn">
                                <el-input v-model="ruleForm.customer_ProvinceNameEn" placeholder="请填写签发地点英文"></el-input>
                              </el-form-item>
                              <el-form-item label="签发机关英文" prop="customer_PassportAuthorityEn">
                                <el-input v-model="ruleForm.customer_PassportAuthorityEn"
                                  placeholder="请填写签发机关英文"></el-input>
                              </el-form-item>
                            </el-col>
                          </el-row>
                          <el-form-item class="form-item-btn">

                            <el-button type="primary" class="default" @click="submitForm('ruleForm')">保存</el-button>
                          </el-form-item>
                        </el-form>
                      </div>

                    </div>

                    <!-- 护照信息展示 -->
                    <div class="page-input-form" v-else>
                      <div class="dialog-form-wrap">
                        <ul class="demo-list">
                          <li>
                            <span>护照号码</span>
                            <p>{{ ruleForm.customer_PassportNumber }}</p>
                          </li>
                          <li>
                            <span>姓 (中文)</span>
                            <p>{{ ruleForm.customer_SurName }}</p>
                          </li>

                          <li>
                            <span>姓（拼音）</span>
                            <p>{{ ruleForm.customer_SurnameEn }}</p>
                          </li>

                          <li>
                            <span>名（中文）</span>
                            <p>{{ ruleForm.customer_Name }}</p>
                          </li>

                          <li>
                            <span>名（拼音）</span>
                            <p>{{ ruleForm.customer_NameEn }}</p>
                          </li>

                          <li>
                            <span>性别</span>
                            <p>{{ ruleForm.customer_Gender == '男' || ruleForm.customer_Gender ==
                              '女' ? ruleForm.customer_Gender : ruleForm.customer_Gender == 'M'
                              ? '男' : '女' }}</p>
                          </li>

                          <li>
                            <span>出生日期</span>
                            <p>{{ ruleForm.customer_BirthDay }}</p>
                          </li>

                          <li>
                            <span>签发日期</span>
                            <p>{{ ruleForm.customer_PassportIssue }}</p>

                          </li>

                          <li>
                            <span>有效期至</span>
                            <p>{{ ruleForm.customer_PassportValidity }}</p>
                          </li>


                          <li>
                            <span>出生地点</span>
                            <p>{{ ruleForm.customer_PassportProvinceName }}</p>
                          </li>

                          <li>
                            <span>出生地点英文</span>
                            <p>{{ ruleForm.customer_PassportProvinceEn }}</p>
                          </li>

                          <li>
                            <span>签发地点</span>
                            <p>{{ ruleForm.customer_ProvinceName }}</p>
                          </li>

                          <li>
                            <span>签发地点英文</span>
                            <p>{{ ruleForm.customer_ProvinceNameEn }}</p>
                          </li>

                          <li>
                            <span>签发机关</span>
                            <p>{{ ruleForm.customer_PassportAuthority }}</p>
                          </li>

                          <li>
                            <span>签发机关英文</span>
                            <p>{{ ruleForm.customer_PassportAuthorityEn }}</p>
                          </li>


                        </ul>
                      </div>


                    </div>
                  </div>



                </div>

              </div>

            </div>

            <!-- 身份证正反面照 -->
            <div class="page-item-avatar" v-if="item.docId == 1 || item.docId == 8">
              <span class="page-item-name">{{ item.name }}</span>
              <div class="page-item-flex">
                <upload @uploading="uploadHandle" :disabled="!isEdit" :auto-upload="true" :is-delete="true"
                  @deleteDoc="deleteDoc(item, 0)" :image-url="item.uploadedData[0].path" :drag="'drag'" thisType="other"
                  @input="toImg($event, item)">
                </upload>

                <el-image class="page-item-legend" style="margin-left: 36px" :src="item.legend" alt=""
                  :preview-src-list="[item.legend]"> </el-image>

                <div class="page-item-desc" v-if="item.desc" style="margin-left: 36px">
                  <span>资料要求</span>
                  <p style="white-space: pre-line;">{{ item.desc }}</p>
                </div>
              </div>
            </div>

          </div>
          <div class="page-title">其他资料</div>
          <div class="page-item" v-for="(item, index) in addNewArr" :key="'addNewArr' + index">
            <!-- 其他资料 - 单文件上传 -->
            <div class="page-item-avatar" v-if="!item.isMultiple">
              <span class="page-item-name">{{ item.name }}</span>
              <div class="page-item-flex">
                <upload @uploading="uploadHandle" :disabled="!isEdit" :auto-upload="true" :is-delete="true"
                  @deleteDoc="deleteDoc(item, 0)" :image-url="item.uploadedData[0].path" :drag="'drag'" thisType="other"
                  @input="toImg($event, item)">
                </upload>

                <el-image class="page-item-legend" style="margin-left: 36px" v-if="item.legend" :src="item.legend" alt=""
                  :preview-src-list="[item.legend]"></el-image>

                <div class="page-item-desc" style="margin-left: 36px">
                  <span>资料要求</span>
                  <p style="white-space: pre-line;">{{ item.desc }}</p>
                </div>
              </div>
            </div>
            <!-- 其他资料 - 多文件上传 -->
            <div v-if="item.isMultiple">
              <span class="page-item-name" style="margin-bottom: 12px">{{ item.name }}</span>
              <div class="page-item-content">
                <div v-for="(elem, idx) in item.uploadedData" :key="idx" class="page-item-content-list">
                  <upload @uploading="uploadHandle" :disabled="!isEdit" :auto-upload="true" class="page-item-content-item"
                    :image-url="elem.path" :drag="'drag'" @deleteDoc="deleteDoc(item, idx)" :is-delete="true"
                    thisType="other" @input="toImg($event, item, idx)">
                  </upload>
                </div>
              </div>

              <div class="page-item-desc-2">
                <span>资料要求</span>
                <p style="white-space: pre-line;">{{ item.desc }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <addUserInfoTwo :config="newAddUserShow" @addUserFn="addUserFn" @close="newAddUserShow = false"
      :visaDocsInfo="orderDetail.visaDetail.visaDocs" v-if="newAddUserShow">
    </addUserInfoTwo>
    <batchDownload v-model="batchShow" :orderId="orderDetail.order.id"></batchDownload>
  </div>
</template>

<script>
import {
  applicantdocs, orderapplcantstatusselect, postuploadpassport, updatedocimg, savepassportinfo, savedocimg, savedocimgnodel, deletedocimgtwo, queryorderapplicants, orderapplicantmodifystatus, orderdetail,
  orderapplicantmodifyidentity, addorderapplicants, uploadvisapage
} from '@/api/order.js'
import upload from '@/components/upload.vue'
import addUserInfoTwo from "@/components/addUserInfoTwo.vue";
import batchDownload from "@/components/batchDownload.vue";
import alyoss from "@/utils/alyoss";



export default {
  data() {
    return {
      isEdit: true,
      uploading: true,
      inputShow: false,
      topShow: false,
      id: '',
      loading: false,
      detailId: '',
      applicantdocsObj: {
        orderApplicant: {
          identityTypeName: '',
          applicantName: ''
        }
      },
      orderDetail: {
        order: {},
        orderApplicants: [],
        visaDetail: {
          basicInfo: [],
          offlineVisaDocs: [],
          visa: {},
          visaDocs: [],
        },
      },
      bbList: [],
      avatar: '',
      requiredList: [],   //  常见资料
      other: [],  //  其他资料
      addNewArr: [],
      statusSelect: [],
      userList: [],
      applicationForm: {},
      ruleForm: {
        customer_PassportNumber: "",
        customer_SurName: '',
        customer_SurnameEn: '',
        customer_Name: '',
        customer_NameEn: '',
        customer_Gender: 'M',
        customer_GenderName: '男',
        customer_PassportAuthority: '',
        customer_PassportAuthorityEn: '',
        customer_BirthDay: '',  //  出生日期
        customer_PassportIssue: '', //  签发日期
        customer_PassportValidity: '',  //  有效日期
        customer_SavePassportInfo: false,
      },

      downList: {
        pageIndex: 1,
        pageSize: 10,
        total: '',
        data: []
      },

      pageIndex: 1,
      pageSize: 10,
      total: 0,

      rules: {
        customer_PassportNumber: [
          { required: true, message: '请填写护照号码', trigger: ['click', 'change', 'blur'] }
        ],
        customer_SurName: [
          { required: true, message: '请填写姓（中文）', trigger: ['click', 'change', 'blur'] },
        ],
        customer_SurnameEn: [
          { required: true, message: '请填写姓（拼音）', trigger: ['click', 'change', 'blur'] }
        ],
        customer_Name: [
          { required: true, message: '请填写名（中文）', trigger: ['click', 'change', 'blur'] },
        ],

        customer_NameEn: [
          { required: true, message: '请填写名（拼音）', trigger: ['click', 'change', 'blur'] },
        ],

        customer_Gender: [
          { required: true, message: '请选择性别', trigger: ['click', 'change', 'blur'] },
        ],
        customer_BirthDay: [
          { required: true, message: '请选择出生日期', trigger: ['click', 'change', 'blur'] },
        ],
        customer_PassportIssue: [
          { required: true, message: '选择签发日期', trigger: ['click', 'change', 'blur'] },
        ],
        customer_PassportValidity: [
          { required: true, message: '选择日期有效期', trigger: ['click', 'change', 'blur'] },
        ],
        customer_PassportProvinceName: [
          { required: true, message: '请填写出生地点', trigger: ['click', 'change', 'blur'] },
        ],
        customer_PassportProvinceEn: [
          { required: true, message: '请填写出生地点英文', trigger: ['click', 'change', 'blur'] },
        ],
        customer_ProvinceName: [
          { required: true, message: '请填写签发地点', trigger: ['click', 'change', 'blur'] },
        ],
        customer_ProvinceNameEn: [
          { required: true, message: '请填写签发地点英文', trigger: ['click', 'change', 'blur'] },
        ],
        customer_PassportAuthority: [
          { required: true, message: '请填写签发机关', trigger: ['click', 'change', 'blur'] },
        ],

        customer_PassportAuthorityEn: [
          { required: true, message: '请填写签发机关英文', trigger: ['click', 'change', 'blur'] },
        ],
      },
      showMore: false,
      newAddUserShow: false,
      batchShow: false,
    }
  },

  mounted() {
    console.log(this.$router.history)
    this.id = this.$router.history.current.params.id.split('_')[0]
    this.detailId = this.$router.history.current.params.id.split('_')[1]
    this.orderdetailFn();

    this.orderapplcantstatusselectFn();
    // 滚动条的获取
    window.addEventListener('scroll', this.handleScrollx, true)
    this.queryorderapplicantsFn();
  },
  components: {
    upload,
    addUserInfoTwo,
    batchDownload
  },
  computed: {
    setTopClass() {
      return function (identityType) {
        if ([-1, 4, 20, 17].includes(identityType)) {
          return 'red-cls'
        } else if ([8, 16, 12, 10].includes(identityType)) {
          return 'green-cls'
        } else {
          return 'yellow-cls'
        }
      }
    }
  },
  methods: {
    toPath(num) {
      if (num == 1) {
        // this.$router.push({ name: 'index' }) 
        this.$router.push({ name: 'home' })
      }

      if (num == 2) {
        this.$router.push({ name: 'orderCenter' })
      }

      if (num == 3) {
        this.$router.push({ name: 'orderCenter' })
        // this.$router.push({ name: 'orderDetail', params: { id: this.id } })
        // this.$router.go(-1)
      }

    },

    uploadHandle(val) {
      var vm = this;
      vm.uploading = val;
    },
    queryorderapplicantsFn() {
      let vm = this;

      queryorderapplicants({
        OrderId: this.id,
        'Page.Size': this.pageSize,
        'Page.Index': this.pageIndex,
      }).then(res => {
        this.total = res.data.total;
        this.userList = res.data.rows;

        if (this.detailId > 0) {
          vm.userList.forEach(item => {
            if (item.id == this.detailId) {
              vm.applicationForm = item
              vm.isEdit = [8, 10, 14, 16, 20].indexOf(vm.applicationForm.status) === -1;

            }
          })
        } else {
          vm.applicationForm = vm.userList[0]
          vm.isEdit = [8, 10, 14, 16, 20].indexOf(vm.applicationForm.status) === -1;
          // this.$nextTick(() => {


          //     this.$refs.tableOrder.setCurrentRow(this.userList[0])
          // })
        }
      })
    },
    orderdetailFn() {
      let vm = this;

      orderdetail({
        id: vm.id
      }).then(res => {
        vm.orderDetail = res.data;

        // vm.dateInfo.date = vm.orderDetail.order && vm.orderDetail.order.departureTime && vm.orderDetail.order.departureTime.split(' ')[0]


        if (vm.orderDetail.order && vm.orderDetail.order.embassyDate) {
          vm.orderDetail.order.embassyDate = JSON.parse(vm.orderDetail.order.embassyDate)

          if (vm.orderDetail.order.embassyDate.label == '近30天内均无法前往') {
            vm.orderDetail.order.embassyDate.str = vm.orderDetail.order.embassyDate.value[0];
          } else if (vm.orderDetail.order.embassyDate.label == '指定最佳赴馆时间前往') {
            vm.orderDetail.order.embassyDate.str = vm.orderDetail.order.embassyDate.value.join('、');
          } else {
            vm.orderDetail.order.embassyDate.str = vm.orderDetail.order.embassyDate.label;
          }
        }


        if (this.detailId > 0) {

          this.getApplicantdocs();
        } else {
          this.detailId = vm.userList[0].id
          this.getApplicantdocs();
        }

      })
    },
    addressCurrentChange(e) {
      this.pageIndex = e;
      this.queryorderapplicantsFn();
    },
    handleScrollx() {
      if (window.pageYOffset > 200) {
        this.topShow = true;
      } else {
        this.topShow = false;
      }
    },
    toImg(e, item, idx) {
      
      let vm = this;
      if (item.docId == 2) {
        this.loading = true;
        postuploadpassport({
          orderApplicantId: vm.detailId,
          docId: item.docId,
          url: e,
          token: vm.$store.state.token
        }).then(res => {
          if (res.code == 1) {
            this.ruleForm = res.data;
            this.inputShow = true;

          } else {
            this.$message({
              message: res.msg,
              type: 'warning'
            });
          }
          this.loading = false;
        })

      } else {
        if (item.isMultiple) {
          savedocimgnodel({
            orderApplicantId: vm.detailId,
            docId: item.docId,
            url: e,
            docImgId: item.uploadedData[idx].id ? item.uploadedData[idx].id : 0,
            token: vm.$store.state.token
          }).then(res => {
            vm.getApplicantdocs();
          })
        } else {
          // savedocimgnodel({
          //   orderApplicantId: vm.detailId,
          //   docId: item.docId,
          //   url: e,
          //   docImgId: item.uploadedData[0].id ? item.uploadedData[0].id : 0,
          //   token: vm.$store.state.token
          // }).then(res => {
          //   // vm.$message({
          //   //     message: '上传成功!',
          //   //     type: 'success'
          //   //  });
          //   vm.getApplicantdocs();
          // })
          savedocimg({
            orderApplicantId: vm.detailId,
            docId: item.docId,
            url: e,
            docImgId: item.uploadedData[0].id ? item.uploadedData[0].id : 0,
            token: vm.$store.state.token
          }).then(res => {
            this.getApplicantdocs();
          })
        }
      }
      // if (item.docId == 10 || item.docId == 1 || item.docId == 8) {
      //   savedocimgnodel({
      //     orderApplicantId: vm.detailId,
      //     docId: item.docId,
      //     url: e,
      //     docImgId: item.id,
      //     token: vm.$store.state.token
      //   }).then(res => {
      //     // vm.$message({
      //     //     message: '上传成功!',
      //     //     type: 'success'
      //     //  });
      //     vm.getApplicantdocs();
      //   })
      // } else if (item.docId == 2) {
      //   postuploadpassport({
      //     orderApplicantId: vm.detailId,
      //     docId: item.docId,
      //     url: e,
      //     token: vm.$store.state.token
      //   }).then(res => {
      //     this.inputShow = true;
      //     this.ruleForm = res.data;

      //   })

      // } else {

      //   //  其他材料多张上传
      //   if (item.isMultiple) {

      //     // if (item.path) {

      //     // } else {

      //     // }
      //     if (elem) {
      //       updatedocimg({
      //         docImgId: item.pathLId[idx],
      //         url: e,
      //         token: vm.$store.state.token
      //       }).then(res => {
      //         this.getApplicantdocs();
      //       })

      //     } else {
      //       savedocimgnodel({
      //         orderApplicantId: vm.detailId,
      //         docId: item.docId,
      //         url: e,
      //         token: vm.$store.state.token
      //       }).then(res => {
      //         this.getApplicantdocs();

      //       })
      //       item.pathList.push('')
      //       this.$forceUpdate();
      //     }


      //     // 单张上传图片
      //   } else {


      //     if (item.path) {
      //       updatedocimg({
      //         docImgId: item.id,
      //         url: e,
      //         token: vm.$store.state.token
      //       }).then(res => {
      //         this.getApplicantdocs();
      //       })
      //     } else {
      //       savedocimg({
      //         orderApplicantId: vm.detailId,
      //         docId: item.docId,
      //         url: e,
      //         token: vm.$store.state.token
      //       }).then(res => {
      //         this.getApplicantdocs();
      //       })
      //     }
      //   }
      // }
    },

    deleteDoc(item, idx) {
      let vm = this;
      deletedocimgtwo({
        docImgId: item.uploadedData[idx].id,
        token: vm.$store.state.token
      }).then(res => {
        this.getApplicantdocs();
      })
    },

    orderapplcantstatusselectFn() {
      orderapplcantstatusselect({
      }).then(res => {
        this.statusSelect = res.data;
      })
    },

    selectStatus(e, item) {
      orderapplicantmodifystatus({
        orderApplicantId: item.id,
        status: e,
      }).then(res => {
        this.$message({
          message: '修改成功！',
          type: 'success'
        });
        this.queryorderapplicantsFn();
      })
    },


    /** 图片上传成功回调 */
    uploadImgBack(val) {
      this.avatar = val
    },

    selectIdentit(e, item) {
      // this.editSf(e, item)
      orderapplicantmodifyidentity({
        orderApplicantId: item.id,
        status: item.status,
        identityTypeId: e,
      }).then(res => {
        if (res.msg == 'success') {
          this.$message({
            message: '修改成功！',
            type: 'success'
          });
          this.getApplicantdocs()
        }
      })
    },

    getApplicantdocs() {
      applicantdocs({
        ApplicantId: this.detailId,
      }).then(res => {
        this.applicantdocsObj = res.data

        let required = []
        let other = []
        if (this.applicantdocsObj.orderApplicant.visaPage) {
          let arr = this.applicantdocsObj.orderApplicant.visaPage.split('/'),
            fineName = arr[arr.length - 1];
          this.applicantdocsObj.orderApplicant.visaPageName = fineName
        }
        this.applicantdocsObj.orderApplicant.electronicData.forEach(item => {
          if (item.docId == 1 || item.docId == 2 || item.docId == 8 || item.docId == 10) {

            // if (item.docId != 2) {
            if (item.uploadedData.length == 0) {

              item.uploadedData = [{
                id: '',
                docId: '',
                path: '',
              }]
            }

            // }
            required.push(item)
          } else {
            other.push(item);
          }
        })

        this.ruleForm = this.applicantdocsObj.passPortInfo
        this.inputShow = !this.ruleForm.customer_SavePassportInfo
        let arr = []
        let newArr = []
        let addNewArr = []
        other.forEach(item => {
          arr.push(item.docId)
        })

        newArr = Array.from(new Set(arr));


        newArr.forEach(item => {
          addNewArr.push({
            docId: item,
            pathList: [],
            pathLId: [],
            uploadedData: [],
            path: '',
            isMultiple: false,
            desc: '',
            name: '',
            id: ''
          })
        })

        other.forEach(item => {
          addNewArr.forEach(elem => {
            if (item.docId == elem.docId) {
              elem.path = item.path
              elem.isMultiple = item.isMultiple
              elem.desc = item.desc
              elem.name = item.name
              elem.id = item.id
              if (item.uploadedData.length == 0) {
                elem.uploadedData = [{
                  id: '',
                  docId: '',
                  path: '',
                }]
              } else {
                elem.uploadedData = item.uploadedData

                if (item.isMultiple) {
                  elem.uploadedData.push({
                    id: '',
                    docId: '',
                    path: '',
                  })
                }
              }

              if (item.isMultiple) {
                if (item.path) {

                  elem.pathList.push(item.path)
                  elem.pathLId.push(item.id)
                }
              }
            }
          })
        })
        addNewArr.forEach(item => {
          if (item.isMultiple) {
            item.pathList.push('')
          }
        })

        this.addNewArr = addNewArr;
        this.requiredList = required;
        this.other = other;
        // this.submitForm('passportForm');
      })
    },
    selectUser(item) {
      // console.log('itemitem', item);
      let vm = this;
      vm.detailId = item.id;
      vm.applicationForm = item;
      vm.isEdit = [8, 10, 14, 16, 20].indexOf(vm.applicationForm.status) === -1;
      vm.getApplicantdocs();
    },
    isChong(array) {
      array.sort();  //数组排序
      var reNum = 0;  //返回结果
      //遍历整个数组对象
      for (var i = 0; i < array.length; i++) {
        //跳过最后一个元素的比较
        if (i + 1 == array.length) {
          continue;
        }
        //判断相邻的元素是否相同
        if (array[i] == array[i + 1]) {
          reNum += 1;
        }
      }
      return reNum;
    },
    submitForm(formName) {
      let vm = this;
      // return;
      vm.$refs[formName][0].validate((valid) => {
        if (valid) {
          this.inputShow = true;
          savepassportinfo({
            ...vm.ruleForm,
            customer_SavePassportInfo: true,
            token: vm.$store.state.token
          }).then(res => {
            // this.$message({
            //     message: '保存成功',
            //     type: 'success'
            // });
            this.inputShow = false;
          })

        } else {
          // this.inputShow = false;
          return false;
        }
      });
    },
    copySuccess() {
      this.$message({
        message: '复制成功！',
        type: 'success'
      });
    },
    copyError() {
      this.$message({
        message: '复制失败，请手动复制！',
        type: 'error'
      });
    },
    addUserFn(arr) {
      addorderapplicants({
        orderId: this.orderDetail.order.id,
        applicants: arr
      }).then(res => {
        if (res.code == 1) {
          this.$message.success('添加成功');
          this.queryorderapplicantsFn()
          this.newAddUserShow = false;
        }
      })
    },
    async uploadVisapage(file) {
      let alyossData = await alyoss(file.file);
      uploadvisapage({
        orderApplicantId: this.applicantdocsObj.orderApplicant.id,
        visaPage: alyossData,
      }).then(res => {
        if (res.code === 1) {
          this.$message({
            message: '上传成功！',
            type: 'success'
          });
          this.getApplicantdocs()
          this.queryorderapplicantsFn()
        }
      })
    },
    openFile() {
      window.open(this.applicantdocsObj.orderApplicant.visaPage)
    },
    deleteFile() {
      this.$confirm('是否要删除该签证页?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        uploadvisapage({
          orderApplicantId: this.applicantdocsObj.orderApplicant.id,
          visaPage: '',
        }).then(res => {
          if (res.code === 1) {
            this.$message({
              message: '删除成功！',
              type: 'success'
            });
            this.getApplicantdocs()
            this.queryorderapplicantsFn()
          } else {
            this.$message.error(res.msg)
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    }
  }
}
</script>

<style lang="scss">
.order-detail-input {
  .yellow-cls {
    input {
      background: #FDF5DC;
      color: #EEBB17;
    }
  }

  .green-cls {
    input {
      background: #DDF0DD;
      color: #1C9C1C;
    }
  }

  .red-cls {
    input {

      background: #FBDBDB;
      color: #E41313;
    }
  }
}

.order-detail-child {
  .select-input {
    input {
      width: 89px;
      height: 28px;
      line-height: 28px;
      padding: 0 5px;
      font-size: 12px;
      border-radius: 8px !important;
      border: none;
    }
  }

  .el-select .el-input .el-select__caret {
    color: #333333;
  }





  .page-top-name-select {
    width: 112px;
    border-radius: 8px;
    background: #ECEEF1;

    input {
      background: #ECEEF1;
      width: 115px;
      height: 29px;
      border: none;
    }
  }

  .el-pagination {
    .el-pagination__total {
      font-size: 10px;
    }

    .el-pager {
      .number {
        font-size: 10px;
        line-height: 20px;
        margin-top: 4px;

        &.active {
          width: 20px;
          height: 20px;

        }
      }
    }
  }

  .form-item-btn {
    text-align: right;
  }

  .demo-ruleForm {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .demo-ruleForm-radio {
      text-align: left;
    }

    .el-form-item {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 6px;
      width: 323px;

      &.form-item-btn {
        width: 100%;
      }

      .el-date-editor {
        width: 100%;
      }

      &:nth-child(1) {
        margin-right: 130px;
      }

      .el-form-item__label {
        width: 50px !important;
        white-space: nowrap;
        height: 30px;
      }

      .el-form-item__content {
        margin-left: 0px !important;
      }
    }

    .el-form-item__content {
      text-align: right;

      .el-button {
        margin-top: 20px;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.page-btn {
  height: 100px;
  text-align: right;
  margin-top: 34px;
  margin-right: 24px;
  box-sizing: border-box;

  .default {
    width: 132px;
    height: 49px;
    font-size: 24px;
  }
}

.page-order-table-box {
  // position: absolute;
  // left: 0px;
  // top: 0;
  position: fixed;
  height: 671px;
  width: 202px;
  background: #FFF;
  border-radius: 8px;

  .page-order-table-box-title {
    padding: 16px 15px 13px 24px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #F3F3F3;

    span {
      font-size: 20px;

    }

    .page-order-table-box-icon {
      width: 25px;
      height: 25px;
      background: #FFFFFF;
      box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.1);
      border-radius: 8px 8px 8px 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      i {
        font-size: 12px;
      }
    }
  }
}

.order-detail-child {
  min-height: 800px;
  background: #F7F8FA;
  padding-bottom: 100px;

  .page-card-top-fixd {
    position: fixed;
    top: 0;
    left: 50%;
    width: 1240px;
    height: 76px;

    z-index: 11;
    background: #FFF;
    transform: translateX(-50%);
    // padding-top: 10px;
    box-sizing: border-box;

  }
}

.page-card-top {
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1000px;
  // left: 210px;
  // position: absolute;
  background-color: #fff;

  .page-card-top-flex {
    display: flex;
    align-items: center;
  }

  .page-top-back {
    font-size: 16px;
    color: #3888E9;
    cursor: pointer;

    i {
      color: #3888E9;
      font-weight: bold;
    }

  }

  .page-top-name {
    margin-left: 32px;
    font-size: 18px;
    font-weight: bold;
    margin-right: 20px;

  }

  .page-top-name-select {
    width: 112px;
    border-radius: 8px;
    background: #ECEEF1;
  }

  .page-top-identity {
    margin-left: 20px;
    font-size: 16px;
    font-weight: bold;
    color: #666666;

  }

  .fine-name {
    color: #3888E9;
    margin-right: 5px;
  }

  i {
    cursor: pointer;
    display: inline-block;
  }

  .icon-search {
    color: #3888E9;
    margin-right: 10px;
  }

  .el-icon-delete {
    font-size: 18px;
    color: #FF5967;
  }
}

.order-wrap {
  width: 780px;
  margin: 0 auto;
  position: relative;

  .page-path {
    margin: 16px 0;
    font-size: 12px;
    // color: #CCCCCC;
    margin-left: -220px;
    color: #a19f9f;

    span {
      cursor: pointer;

    }
  }

  .page-card {
    width: 1000px;
    position: relative;

    .page-require-box {
      width: 1000px;
      // position: absolute;
      // left: 210px;
      // top: 85px;
      padding-bottom: 80px;
      // height: 585px;
      // overflow: auto;

      .page-title {
        height: 42px;
        padding-left: 24px;
        font-size: 16px;
        color: #3888E9;
        display: flex;
        align-items: center;
        background: #F2F8FF;
      }

      .page-item {
        padding: 20px 24px;
        background: #FFF;
        border-bottom: 5px solid #F7F8FA;

        .page-item-desc-2 {
          margin-top: 20px;

          span {
            font-size: 16px;

          }

          p {
            font-size: 14px;
            color: #999999;
            margin-top: 8px;
          }
        }

        .page-item-content {
          margin-top: 15px;
          width: 1200px;
          display: flex;
          flex-wrap: wrap;

          .page-item-content-list {
            margin-right: 38px;
            margin-bottom: 20px;
          }
        }

        .page-item-avatar {
          .page-item-name {
            display: block;
            margin-bottom: 12px;
          }

          .page-item-flex {
            display: flex;
            //  align-items: center;

            .page-item-no-passport {
              display: flex;
              align-items: center;
              margin-left: 77px;
              // margin-top: 38px;
            }

            .page-input {
              width: 734px;
              margin-left: 26px;
              position: relative;



              .model-upload {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: #000000;
                opacity: .45;
                z-index: 2;
                display: flex;
                justify-content: center;
                align-items: center;

                .model-upload-flex {
                  display: flex;
                  align-items: center;
                }
              }

              .page-item-title {
                width: 100%;
                height: 38px;
                background: #F2F8FF;
                color: #3888E9;
                font-size: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
              }

              .page-item-waning {
                color: #FF5967;

                .el-icon-warning-outline {
                  margin-right: 8px;
                }
              }

              .page-item-wrap {
                background: #F7F8FA;
                min-height: 400px;
                width: 100%;
                padding: 12px 28px;
                box-sizing: border-box;

                .page-item-top {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  font-size: 14px;

                  .page-edit {
                    color: #5F9FED;
                    cursor: pointer;
                  }
                }




                .demo-list {
                  display: flex;
                  flex-wrap: wrap;
                  margin-top: 16px;

                  li {

                    width: 330px;
                    display: flex;
                    margin-bottom: 24px;

                    span {
                      width: 110px;
                      color: #666;
                      font-size: 14px;
                    }

                    p {
                      font-size: 16px;
                      font-weight: bold;
                    }
                  }
                }
              }
            }

            .page-item-legend {
              width: 222px;
              height: 151px;
              margin-left: 16px;
            }

            .page-item-desc {
              max-width: 336px;
              flex: flex;
              flex-direction: column;
              margin-left: 20px;

              span {
                font-size: 16px;
              }

              p {
                font-size: 14px;
                color: #999999;
                margin-top: 8px;
              }
            }


          }
        }
      }
    }

  }

  .left-wrap {
    position: absolute;
    left: -220px;
    top: 32px;
    width: 202px;

    .left-top {
      background: #FFFFFF;
      border-radius: 8px;
      color: #333;
      padding: 8px;

      p {
        font-size: 14px;
        text-align: left;
        margin-bottom: 10px;

        .nation-img {
          display: inline-block;
          width: 20px;
          height: 14px;
          margin-right: 5px;
        }
      }

      .left-content-item {
        margin-bottom: 5px;

        .title {
          display: inline-block;
          width: 60px;
          text-align-last: justify;
          position: relative;

          &::after {
            content: ':';
            position: absolute;
            right: -4px;
            top: -1px;
          }
        }

        .content {
          margin-left: 10px;
        }

        .icon-fuzhi {
          color: #3888E9;
          margin-left: 10px;
          cursor: pointer;
        }
      }

      .more-switch {
        cursor: pointer;
        border-top: 1px solid #EBEBEB;
        margin-top: 10px;

        p {
          color: #0094E8;
          text-align: center;
          margin: 5px 0 0 0;
        }
      }
    }

    .left-table {
      background: #FFFFFF;
      border-radius: 8px;
      color: #333;
      padding: 8px;
      margin-top: 15px;

      .left-table-btn {
        border-bottom: 1px solid #EBEBEB;
        height: 35px;
        text-align: center;
        padding: 5px 10px;
        display: flex;

        .btn {
          width: 80px;
          font-size: 11px;
          height: 30px;
          text-align: center;
          padding: 0;
        }
      }

      .page-order-ul {
        padding: 10px;
        padding-bottom: 0px;
        min-height: 500px;

        .page-order-li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          height: 35px;
          padding: 0 10px;
          margin-bottom: 10px;

          &.page-order-active {
            background: #3888E9;
            box-shadow: 0px 3px 10px 1px rgba(56, 136, 233, 0.57);
            border-radius: 8px 8px 8px 8px;
            color: #FFF;
          }
        }
      }
    }
  }
}

.footer-wrap {
  display: none !important;
}

.demo-ruleForm1 {
  ::v-deep .el-date-editor.el-input {
    width:117px;
    .el-input--suffix .el-input__inner{
      padding-right: 0;
    }
    .el-input__icon{
      width: 10px;
    }
  }
}
</style>