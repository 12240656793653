<template>
    <div class="page">
  
      <div class="banner1">
        <div class="banner1-input">
          <div class="input-title">
            <div class="input-text">签证</div>
            <div class="input-title-right">
            </div>
          </div>
          <div class="input-content">
            <el-input placeholder="搜索办签国家/地区" @focus="inputShow" @blur="outInput()"  v-model="valueInput"
              class="input-with-select" style="height: 54px;">
              <el-button slot="append" icon="el-icon-search" class="btn-style"></el-button>
            </el-input>
            <div v-if="isShow" class="children-list"  @mouseleave="isShow = false">
              <div class="children-left">
                <div v-for="(item, index) in countrysList" @click="initContinue(item.value)"
                  :class="[continueActiveHome == item.value ? 'continue-active' : '']" :key="index"
                  class="children-left-item">
                  {{ item.label }}</div>
              </div>
              <div class="children-right" >
                <div class="children-right-item" @click="pathRoute(item)" v-for="(item, index) in datas" :key="index"
                  :style="{ backgroundImage: `url(${item.imgUrl})` }">{{ item.zhName }}</div>
              </div>
            </div>
            <div class="srarch-input" v-if="inputShowBom" @mouseleave="inputShowBom = false;">
              <div class="search-input-item" v-for="(item, index) in searchList" @click="pathRoute(item)" :key="index">
                <span class="input-item-text1">{{ item.zhName }}</span>
                <span class="input-item-text2">{{ item.visaTypes }}</span>
              </div>
            </div>
            <ul class="input-list" >
              <li class="input-item" v-for="(item, index) in inputList" :key="index"
                @click="$router.push({ name: 'productDetail', params: { id: item.id + '_' + item.name } })"><img
                  v-if="index == 0" :src="require('@/assets/images/common/tj2.png')" alt="">{{ item.name }}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="box1">
        <ul class="box1-list">
  
  
          <li class="box1-item">
            <div class="box1-num">
              <countTo :start-val="0" :end-val="2000" :duration="2000" style="font-size:48px;" />
              +
            </div>
            <span>合作同业</span>
            <div class="box1-left"></div>
          </li>
          <li class="box1-item">
            <div class="box1-num">
              <countTo :start-val="0" :end-val="200000" :duration="2000" style="font-size:48px;" />+
            </div>
            <span>累计出签本数</span>
            <div class="box1-left"></div>
          </li>
          <li class="box1-item">
            <div class="box1-num">
              <countTo :start-val="0" :end-val="78" :duration="2000" style="font-size:48px;" />+
            </div>
            <span>国家覆盖</span>
            <div class="box1-left"></div>
          </li>
          <li class="box1-item">
            <div class="box1-num">
              <countTo :start-val="0" :end-val="15" :duration="2000" style="font-size:48px;" />
            </div>
            <span>领区覆盖</span>
            <div class="box1-left"></div>
          </li>
        </ul>
        <img class="box1-left" :src="require('@/assets/images/home/rgiht.png')" alt="">
        <img class="box1-right" :src="require('@/assets/images/home/left.png')" alt="">
      </div>
      <div class="box2">
        <div class="box2-title">热门目的地</div>
        <div class="box2-center1">
          <div class="box2-item"
            :style="{ background: `linear-gradient(270deg, rgba(0,153,255,0) 0%, rgba(0,96,255,0.45)), url(${key1.imgUrl}) no-repeat` }">
            <!-- <img :src="key1.imgUrl" class="box2-item-img" alt=""> -->
            <div class="box2-item-top">
              <!-- {{countrys.hotCountry[0].country}} -->
              <p class="box2-p1">{{ key1.country }}</p>
              <p class="box2-p2"></p>
              <p class="box2-p3">{{ key1.desc }}</p>
            </div>
  
            <div class="box2-btn" @click="pathInfo(key1, 1)">了解更多<span>▶</span></div>
          </div>
          <div class="box2-right">
            <div class="box2-item2"
              :style="{ background: `linear-gradient(270deg, rgba(0,0,0,0) 0%, rgba(0,255,182,0.45)), url(${key2.imgUrl})` }">
              <div class="box2-item-top">
                <p class="box2-p1">{{ key2.country }}</p>
                <p class="box2-p2"></p>
                <p class="box2-p3">{{ key2.desc }}</p>
              </div>
  
              <div class="box2-btn" @click="pathInfo(key2, 1)">了解更多<span>▶</span></div>
            </div>
            <div class="box2-item2"
              :style="{ background: `linear-gradient(270deg, rgba(0,0,0,0) 0%, rgba(255,165,0,0.45)), url(${key3.imgUrl})` }">
              <div class="box2-item-top">
                <p class="box2-p1">{{ key3.country }}</p>
                <p class="box2-p2"></p>
                <p class="box2-p3">{{ key3.desc }}</p>
              </div>
  
              <div class="box2-btn" @click="pathInfo(key3, 1)">了解更多<span>▶</span></div>
            </div>
          </div>
        </div>
        <div class="box2-bottom">
          <div class="box2-item2"
            :style="{ background: `linear-gradient(270deg, rgba(0,0,0,0) 0%, rgba(255,165,0,0.45)), url(${key4.imgUrl})` }">
            <div class="box2-item-top">
              <p class="box2-p1">{{ key4.country }}</p>
              <p class="box2-p2"></p>
              <p class="box2-p3">{{ key4.desc }}</p>
            </div>
  
            <div class="box2-btn" @click="pathInfo(key4, 1)">了解更多<span>▶</span></div>
          </div>
          <div class="box2-item2"
            :style="{ background: `linear-gradient(270deg, rgba(0,0,0,0) 0%, rgba(0,205,255,0.45)), url(${key5.imgUrl})` }">
            <div class="box2-item-top">
              <p class="box2-p1">{{ key5.country }}</p>
              <p class="box2-p2"></p>
              <p class="box2-p3">{{ key5.desc }}</p>
            </div>
  
            <div class="box2-btn" @click="pathInfo(key5, 1)">了解更多<span>▶</span></div>
          </div>
          <div class="box2-item2"
            :style="{ background: `linear-gradient(270deg, rgba(0,0,0,0) 0%, rgba(90,0,255,0.45)), url(${key6.imgUrl})` }">
            <div class="box2-item-top">
              <p class="box2-p1">{{ key6.country }}</p>
              <p class="box2-p2"></p>
              <p class="box2-p3">{{ key6.desc }}</p>
            </div>
  
            <div class="box2-btn" @click="pathInfo(key6, 1)">了解更多<span>▶</span></div>
          </div>
        </div>
      </div>
  
      <div class="box3">
  
        <div class="box3-top">
          <div class="box3-t">
            <span class="box3-t1">{{ countrysItem.label }}</span>
            <span class="box3-t2">{{ continueActive + 1 }}/{{ continueLen }}</span>
          </div>
          <div class="box3-x"></div>
        </div>
        <div class="box3-bottom">
          <!-- {{countrysItem.datas}} -->
          <!-- {{typeof countrysItem.datas}} -->
          <div class="box3-item" v-for="(item, index) in countrysItem.datas" :key="index" @click="pathInfo(item, 2)">
            <span class="box3-text1">{{ item.zhName }}</span>
            <span class="box3-text2">{{ item.enName }}</span>
          </div>
          <div class="teahcer-btn">
            <div class="t-btn" @click="isUp" style="border-right: 0px">
              <i class="el-icon-arrow-left"></i>
            </div>
            <div class="t-btn" @click="isNext">
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="box4">
        <div class="box4-title">热门商品</div>
        <div class="box-content">
          <el-carousel :interval="4000" type="card" height="425px">
            <el-carousel-item v-for="(item, index) in hotvisas" :key="index">
              <div class="carousel-img" @click="pathInfo(item, 3)">
                <img :src="item.countryImgUrl" alt="">
              </div>
              <div class="carousel-content" @click="pathInfo(item, 3)">
                <p class="carousel-content-p1">{{ item.countryName }} {{ item.title }}
                  {{ item.validityPeriod }}【{{ item.rangeDesc }}】{{ item.spendTime }}</p>
                <p class="carousel-content-p2">¥{{ item.price4 }}</p>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="box5">
        <div class="box5-title">签证助手</div>
        <div class="box5-info">
          快马签证采用技术手段解决传统签证办理痛点，简化资料提交流程，办理签证实时最终，实现办签业务全面线上化。小程序随时随地云接单，办签资料一键分享，为同业提供一键式办签体验。快马签证（www.kuaimavisa.com）致力于运用技术手段，提升签证行业效率。通过全面实现签证办理流程电子化，智能化来解决传统签证行业办理痛点，为同业旅行社和终端用户都提供了极大的便利。。
        </div>
        <div class="box5-btn">
          <div class="box5-btn-1">荣誉资质</div>
          <div class="box5-btn-1">企业愿景</div>
        </div>
        <div class="box5-bom">
          <div class="box5-bom-left">
            <p class="box5-bom-p1">想了解更多关于我们？</p>
            <p class="box5-bom-p2">Want to learn more about us</p>
          </div>
          <div class="box5-bom-right">
            <i class="el-icon-arrow-right"></i>
          </div>
          <div class="box5-img">
            <img :src="require('@/assets/images/common/bg1.jpg')" alt="">
          </div>
        </div>
      </div>
      <div class="box6">
        <div class="box5-title">一站式专业服务</div>
        <div class="box6-content">
          <div class="box6-item" v-for="(item, index) in boxList6" :key="index">
            <img :src="item.img" alt="">
            <span>{{ item.name }}</span>
            <p>{{ item.label }}</p>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { getcountrys, gethotvisas, getrecommendcountrys, getcountryandvisatype } from '@/api/common.js'
  import { mapState } from 'vuex'
  import countTo from "vue-count-to"
  export default {
    name: 'IndexPage',
    layout: 'default',
    inject: ['pageReload'],
    components: {
        countTo
    },
    data() {
      return {
        valueInput: '',
        boxList1: [],
        continueActive: 0,
        continueLen: 0,
        continueActiveHome: 0,
        isShow: false,
        hotvisas: [],
        countrysHot: {},
        countrys: {},
        countrysList: {},
        countrysItem: {},
        hotObj: {},
        inputList: [],
        inputShowBom: false,
        numList: [
          {
            name: '合作同业',
            value: 2000,
            showValue: 0,
          },
          {
            name: '累计出签本数',
            value: 200000,
            showValue: 0,
          },
          {
            name: '国家覆盖',
            value: 78,
            showValue: 0,
          },
          {
            name: '领区覆盖',
            value: 15,
            showValue: 0,
          },
        ],
        boxList3: [
          {
            name: '越南',
            lable: 'Vietnam'
          },
          {
            name: '越南',
            lable: 'Vietnam'
          },
          {
            name: '越南',
            lable: 'Vietnam'
          },
          {
            name: '新加坡',
            lable: 'Singapore'
          },
          {
            name: '新加坡',
            lable: 'Singapore'
          },
          {
            name: '新加坡',
            lable: 'Singapore'
          },
        ],
        boxList6: [
          {
            name: '资料在线快捷提交',
            label: '上传、增补资料在线完成并智能填表，免去来回邮寄，还可转发给顾客自行上传',
            img: require('@/assets/images/home/3.png')
          },
          {
            name: '免费拍摄签证照片',
            label: '小程序拍摄并智能生成标准签证照，不用跑照相馆',
            img: require('@/assets/images/home/5.png')
          },
          {
            name: '一键转发给顾客',
            label: '办签要求更新不过时，小程序一键发给顾客查看',
            img: require('@/assets/images/home/6.png')
          },
          {
            name: '随时随地跟踪订单',
            label: '审核、出签、发货状态实时更新和提醒',
            img: require('@/assets/images/home/3.png')
          },
          {
            name: '1对1专家服务',
            label: '数十名资深签证专家1对1服务7*12小时',
            img: require('@/assets/images/home/1.png')
          },
          { 
            name: '无缝对接 顺丰包邮',
            label: '一键呼顺丰上门取件，出签包回邮，快捷安全',
            img: require('@/assets/images/home/2.png')
          },
        ],
        key1: {},
        key2: {},
        key3: {},
        key4: {},
        key5: {},
        key6: {},
        recommendcountrys: [],
        datas: [],
        searchList: [],
      }
    },
  
    mounted() {
      this.getcountrysFn();
  
  
      this.gethotvisasFn();
      this.getrecommendcountrysFn();
      this.numList.forEach(item => {
  
      })
      
      // this.$store.user.commit('SET_SCREEN', true);
      this.$store.commit('SET_SCREEN', false);
      // this.$store.dispatch("product/setScreen", true);
      if (window.innerWidth > 2000) {
        this.$store.commit('SET_SCREEN', true);
      } else {
        this.$store.commit('SET_SCREEN', false);
      }
  
    },
  
    watch: {
      valueInput(val, oldVal) {
  
        // if (this.isShow && ) {
  
        // }
  
          if (val) {
            this.isShow = true;
          }
  
        if (val != oldVal) {
  
          if (val && this.isShow) {
            this.isShow = false;
            this.getcountrysInput(val)
            this.inputShowBom = true;
          }
    
          if (!val && !this.isShow) {
            this.isShow = true;
            this.inputShowBom = false;
            // this.getcountrysInput(val)
          } 
    
          if (!val) {
            this.inputShowBom = false;
          } 
        }
        
  
      }
    },
  
    methods: {
      outInput () {
        if (this.inputShowBom) {
          this.inputShowBom = true;
         
        } else {
          this.inputShowBom = false;
          this.valueInput = ''
        }
      },
      async getcountrysInput(val) {
        let res = await getcountryandvisatype({
          key: val
        })
        this.searchList = res.data
  
      },
      async getcountrysFn() {
  
        let res = await getcountrys({})
        this.countrys = res.data;
        this.countrysList = res.data.continentCountry;
        this.continueLen = this.countrys.continentCountry.length
        this.countrysItem = JSON.parse(JSON.stringify(this.countrys.continentCountry[this.continueActive]));
  
  
        let obj = {}
        this.countrysHot = JSON.parse(JSON.stringify(this.countrys.hotCountry));
        for (var i = 0; i < 6; i++) {
          this[`key${i + 1}`] = this.countrysHot[i]
        }
        // this.hotObj = JSON.parse(JSON.stringify(obj));
  
        this.$store.commit('SET_COUNTRYS', res.data);
        // this.$store.dispatch("product/setCountrys", res.data);
        this.initContinue(0)
      },
  
      inputShow() {
        if (!this.valueInput) {
          this.isShow = true;
        }
      },
      clearInput () {
        this.valueInput = '';
        this.isShow = false;
        this.inputShowBom = false;
        this.inputList = []
      },
      initContinue(value) {
        this.continueActiveHome = value
        this.datas = this.countrysList[this.continueActiveHome].datas;
      },
      async gethotvisasFn() {
        let res = await gethotvisas({})
  
        this.hotvisas = res.data;
      },
      async getrecommendcountrysFn() {
  
        let res = await getrecommendcountrys({   })
        this.inputList = res.data
      },
  
      isUp() {
        if (this.continueActive == 0) {
          this.continueActive = this.countrys.continentCountry.length - 1;
        } else {
          this.continueActive = this.continueActive - 1;
  
        }
        this.countrysItem = JSON.parse(JSON.stringify(this.countrys.continentCountry[this.continueActive]));
      },
      pathRoute(item) {
        this.$router.push({ name: 'productDetail', params: { id: item.id + "_" + item.zhName } })
      },
      isNext() {
  
        if ((this.continueActive + 1) == this.countrys.continentCountry.length) {
          this.continueActive = 0;
        } else {
          this.continueActive = this.continueActive + 1;
  
        }
        this.countrysItem = JSON.parse(JSON.stringify(this.countrys.continentCountry[this.continueActive]));
      },
      pathInfo(item, num) {
  
        if (num == 1) {
          this.$router.push({ name: 'productDetail', params: { id: item.countryId + "_" + item.country } })
  
        } else if (num == 3) {
          this.$router.push({ name: 'productDetail', params: { id: item.countryId + "_" + item.countryName } })
        } else {
  
          this.$router.push({ name: 'productDetail', params: { id: item.id + "_" + item.zhName } })
        }
      }
    }
  }
  </script>
  
  
  <style lang="scss" scoped>
  @import '@/assets/css/home/index.scss';
  
  .banner1 {
    height: 800px;
    width: 100%;
    background: url('@/assets/images/home/banner.jpg');
    background-size: 100% 800px;
    background-position: center;
    position: relative;
  
  }
  </style>
  
  <style lang="scss">
  .input-content {
    position: relative;
  
    .input-with-select {
      input {
        height: 54px;
      }
    }
  
    .el-input-group__append {
      background: #0075FF;
      color: #FFF;
      width: 92px;
      font-size: 22px;
      padding: 0;
  
      .el-input__inner {
        border: 1px solid #E2E2E2;
      }
  
      .el-button--default {
        margin-left: 15px;
      }
    }
  }
  
  .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 200px;
    margin: 0;
  }
  
  .srarch-input {
    position: absolute;
    top: 78px;
    width: 768px;
    border-radius: 2px;
    z-index: 3;
    .search-input-item {
      height: 50px;
      background: #FFF;
      display: flex;
      align-items: center;
      padding: 0 20px;
  
      .input-item-text1 {
        font-weight: bold;
        font-size: 18px;
        padding: 0 10px;
      }
  
      .input-item-text2 {
        font-weight: bold;
        font-size: 18px;
        margin-left: 24px;
        color: #91999f;
      }
  
      &:hover {
        background: #f7f8fa;
        cursor: pointer;
  
        .input-item-text1 {
          color: #1890FF;
        }
      }
    }
  }
  
  .children-list {
    // margin-top: 80px;
    width: 735px;
    // height: 262px;
    box-shadow: 0px 8px 32px 1px rgba(0, 0, 0, 0.06);
    background: #FFFFFF;
    border-radius: 0px 0px 4px 4px;
    z-index: 999999999999;
    display: flex;
    position: absolute;
    top: 76px;
    z-index: 3;
  
    .children-left {
      width: 98px;
      flex-direction: column;
      background: #333333;
      cursor: pointer;
  
      .children-left-item {
        height: 44px;
        line-height: 44px;
        color: #FFF;
        font-size: 14px;
        text-align: center;
  
        &.continue-active {
          background: #1890FF;
          color: #FFF;
        }
      }
    }
  
    .children-right {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      flex: 1;
      padding: 23px 20px;
      box-sizing: border-box;
  
      .children-right-item {
        width: 98px;
        height: 65px;
        line-height: 65px;
        border-radius: 4px;
        background: rgba(0, 0, 0, 0.45);
        margin-bottom: 10px;
        margin-right: 14px;
        cursor: pointer;
        font-size: 14px;
        color: #FFF;
        background-size: 100% 100%;
        background-position: center;
        display: flex;
        justify-content: center;
        align-items: center;
  
        &:hover {
          transform: scale(1.1);
          transition: transform .3s ease-in;
        }
      }
    }
  
    .children-item {
      height: 50px;
      line-height: 50px;
  
      a {
        height: 50px;
        line-height: 50px;
  
      }
  
      &:hover {
        a {
          color: #F68634 !important;
  
        }
      }
  
      &.children-active {
        background: #F68634;
  
        a {
          color: #FFFFFF !important;
  
        }
      }
    }
  }
  
  // .el-carousel__item:nth-child(2n) {
  //   background-color: #99a9bf;
  // }
  
  // .el-carousel__item:nth-child(2n+1) {
  //   background-color: #d3dce6;
  // }</style>
  