<template>
  <div class="upload-in">
    <el-dialog :title="!checkShow ? '资料递交方式' : '添加申请人'" :close-on-click-modal="false" center :visible.sync="isVisible"
      width="680px" :before-close="handleClose" class="dialog-wrap">
      <!-- <div class="upload-zl" v-if="!checkShow">
        <div class="upload-list">
          <div class="upload-item upload-left"
            :class="[xxActive == 0 ? 'upload-active' : '', visa.method == 2 ? 'no-upload' : '']"
            @click="visa.method == 2 ? null : xxActive = 0">
            <img :src="`https://file.sport-syy.com/visa/admin/images/pc/xs_sc${xxActive == 0 ? '1' : '0'}.png`" alt="">
            <div class="upload-content">
              <p>在线上传资料</p>
              <span>快速上传资料,随时修改，高效办理</span>
            </div>
          </div>

          <div class="upload-item upload-right"
            :class="[xxActive == 1 ? 'upload-active' : '', visa.method == 1 ? 'no-upload' : '']"
            @click="visa.method == 1 ? null : xxActive = 1">
            <img :src="`https://file.sport-syy.com/visa/admin/images/pc/xx_sc${xxActive == 1 ? '1' : '0'}.png`" alt="">
            <div class="upload-content">
              <p>线下邮寄资料</p>
              <span>资料邮寄，办理时间长</span>
            </div>
          </div>

          <div class="upload-ab" v-if="visa.method != 2">
            89%同业的选择
          </div>
        </div>
        <el-button class="upload-btn" @click.stop="checkShow = true">下一步</el-button>
      </div>
      <div class="upload-box" v-else>

        <template v-if="!isAdd">

          <span class="box-title">预计出行时间</span>
          <div class="date-input" @click.stop="openCheck" :class="[inputShow ? 'date-input-active' : '']">
            <span class="date-input-text1">选择日期</span>
            <div class="date-flex">
              <span class="date-input-text2"> {{ !dateInfo ? '请选择' : dateInfo.str }}</span>
              <i class="el-icon-caret-bottom"></i>
            </div>

            <dateSelect ref="dateSelect" @setDate="setDate" :dateInfo="dateInfo" v-model="inputShow"
              :countryId="countryId" :processingDay="visa.processingDay"></dateSelect>
          </div>
        </template>
        <div class="user-box">
          <p class="user-box-title">出行人信息 <span>(共{{ applicants.length }}人)</span></p>
          <div class="user-box-btn" @click="addUser">
            + 新增出行人
          </div>
        </div>

        <div class="user-box-list" v-for="(item, index) in applicants"
          :style="{ paddingRight: applicants.length > 1 ? '70px' : '15px' }" :key="index">
          <div class="user-box-item">
            <span class="user-box-item-title">姓名</span>
            <div class="user-box-item-input">
              <el-input v-model="item.applicantName" placeholder="请输入内容"></el-input>
            </div>
          </div>
          <p class="user-box-item-x"></p>
          <div class="user-box-item">
            <span class="user-box-item-title">旅客身份</span>
            <div class="user-box-item-input">
              <el-select v-model="item.identityTypeId" placeholder="请选择">
                <el-option v-for="item in visaDocs" :key="item.identityTypeId" :label="item.name"
                  :value="item.identityTypeId">
                </el-option>
              </el-select>
            </div>

          </div>

          <div class="user-box-del" v-if="applicants.length > 1" @click="delUser(index)">
            <i class="el-icon-delete"></i>
          </div>
        </div>

        <div class="user-start-btn">
          <el-button class="start-btn" @click.stop="toOrder">{{ isAdd ? "确认" : '开始办理' }}</el-button>
        </div>
      </div> -->

      <div class="upload-zl" v-if="!checkShow">
        <div class="upload-list">
          <div class="upload-item upload-left"
            :class="[xxActive == 0 ? 'upload-active' : '', visa.method == 2 ? 'no-upload' : '']"
            @click="visa.method == 2 ? null : xxActive = 0">
            <img :src="`https://file.sport-syy.com/visa/admin/images/pc/xs_sc${xxActive == 0 ? '1' : '0'}.png`" alt="">
            <div class="upload-content">
              <p>在线上传资料</p>
              <span>快速上传资料,随时修改，高效办理</span>
            </div>
          </div>

          <div class="upload-item upload-right"
            :class="[xxActive == 1 ? 'upload-active' : '', visa.method == 1 ? 'no-upload' : '']"
            @click="visa.method == 1 ? null : xxActive = 1">
            <img :src="`https://file.sport-syy.com/visa/admin/images/pc/xx_sc${xxActive == 1 ? '1' : '0'}.png`" alt="">
            <div class="upload-content">
              <p>线下邮寄资料</p>
              <span>资料邮寄，办理时间长</span>
            </div>
          </div>

          <div class="upload-ab" v-if="visa.method != 2">
            89%同业的选择
          </div>
        </div>
        <el-button class="upload-btn" @click.stop="checkShow = true">下一步</el-button>
      </div>
      <div class="upload-box" v-else>
        <div v-show="step == 1">
          <div class="icon-wrap" v-if="checkShow" @click="back">
            <i class="el-icon-arrow-left" style="font-size: 20px;"></i>
          </div>
          <div class="upload-top-box">
            <div class="upload-top-left">
              <img :src="require('@/assets/images/common/hu.png')" alt="">
            </div>
            <div class="upload-top-right">
              <div class="upload-top-right-item">
                <div class="upload-img-info">
                  <i class="el-icon-check"></i>
                </div>
                <span>上传清晰的护照首页</span>
              </div>
              <div class="upload-top-right-item">
                <div class="upload-img-info">
                  <i class="el-icon-check"></i>
                </div>
                <span>彩色扫描件或手机拍照(JPG/PNG/PDF)</span>
              </div>
              <div class="upload-top-right-item">
                <div class="upload-img-info">
                  <i class="el-icon-check"></i>
                </div>
                <span>露出四个边角，拍照不要反光</span>
              </div>
              <div class="upload-top-right-item">
                <div class="upload-img-info">
                  <i class="el-icon-check"></i>
                </div>
                <span>有效期离出发日期应至少还有6个月</span>
              </div>
            </div>
          </div>

          <div class="upload-btn-wrap upload-btn-wrap-flex" style="margin-top: 35px;">
            <el-button class="upload-btn-left" style="margin: 0 30px;" @click.stop="checkUser('left')">手动添加联系人</el-button>
            <el-upload class="upload_button" multiple accept=".jpg,.png,.jpeg" action="" :limit="100" :auto-upload="true"
              :show-file-list="false" :http-request="uploadImages" :on-exceed="onExceed" :file-list="tableData">
              <el-button class="upload-btn" size="mini" type="primary" ref="upImg">批量上传护照</el-button>
            </el-upload>
          </div>
        </div>

        <div v-show="step == 2">
          <div class="upload-box">
            <div class="icon-wrap" @click="() => { step = 1; tableData = [] }">
              <i class="el-icon-arrow-left" style="font-size: 20px;"></i>
            </div>
            <template v-if="!isAdd">
              <span class="box-title">预计出行时间</span>
              <div class="date-input" @click.stop="openCheck" :class="[inputShow ? 'date-input-active' : '']">
                <span class="date-input-text1">选择日期</span>
                <div class="date-flex">
                  <span class="date-input-text2"> {{ !dateInfo ? '请选择' : dateInfo.str }}</span>
                  <i class="el-icon-caret-bottom"></i>
                </div>
                <dateSelect ref="dateSelect" @setDate="setDate" :dateInfo="dateInfo" v-model="inputShow"
                  :countryId="countryId" :processingDay="visa.processingDay"></dateSelect>
              </div>
            </template>
            <div v-if="!zidong">
              <div class="user-box">
                <p class="user-box-title">出行人信息 <span>(共{{ applicants.length }}人)</span></p>
                <div class="user-box-btn" @click="addUser">
                  + 新增出行人
                </div>
              </div>

              <div class="user-box-list" v-for="(item, index) in applicants"
                :style="{ paddingRight: applicants.length == 1 ? '15px' : '70px' }" :key="index">
                <div class="user-box-item">
                  <span class="user-box-item-title">姓名</span>
                  <div class="user-box-item-input">
                    <el-input v-model="item.applicantName" placeholder="请输入内容"></el-input>
                  </div>
                </div>
                <p class="user-box-item-x"></p>
                <div class="user-box-item">
                  <span class="user-box-item-title">旅客身份</span>
                  <div class="user-box-item-input">
                    <el-select v-model="item.identityTypeId" placeholder="请选择">
                      <el-option v-for="item in visaDocs" :key="item.identityTypeId" :label="item.name"
                        :value="item.identityTypeId">
                      </el-option>
                    </el-select>
                  </div>

                </div>

                <div class="user-box-del" v-if="applicants.length > 1" @click="delUser(index)">
                  <i class="el-icon-delete"></i>
                </div>
              </div>
              <div class="user-start-btn">
                <el-button class="start-btn" @click.stop="toOrder">{{ isAdd ? "确认" : '开始办理' }}</el-button>
              </div>
            </div>
            <div v-else>
              <div class="user-box">
                <p class="user-box-title">出行人信息 <span>(共{{ tableData.length }}人)</span></p>
              </div>
              <div class="user-upload-info" v-if="tableData.length > 0">
                <el-table :data="tableData" style="width: 100%" :row-key="new Date().getTime() + ''" ref="singleTable">
                  <el-table-column prop="img" label="护照">
                    <template slot-scope="scope">
                      <div class="demo-image__preview">
                        <el-image style="width: 68px; height: 42px" :src="scope.row.img" fit="fit" v-if="scope.row.img"
                          :preview-src-list="[scope.row.img]"></el-image>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="name" label="姓名">
                    <template slot-scope="scope">
                      <el-input v-model="scope.row.name"></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column prop="identityTypeId" label="旅客身份">
                    <template slot-scope="scope">

                      <el-select v-model="scope.row.identityTypeId" clearable placeholder="旅客身份" @change="getQuery">
                        <el-option v-for="item in visaDocs" :key="item.identityTypeId" :label="item.name"
                          :value="item.identityTypeId" prefix-icon="el-icon-caret-top">
                        </el-option>
                      </el-select>
                    </template>
                  </el-table-column>
                  <el-table-column prop="type" label="状态">
                    <template slot-scope="scope">
                      <template v-if="scope.row.type == '正在上传' || scope.row.type == '正在识别'">
                        <el-button type="text" :loading="true" style="color: #303133;">{{
                          scope.row.type }}</el-button>
                      </template>
                      <template v-else-if="scope.row.type == '识别失败'">
                        <i style="color: #FF5967" class="el-icon-warning"></i>
                        <span style="color: #FF5967;">识别失败</span>
                      </template>
                      <template v-else-if="scope.row.type == '识别成功'">
                        <i style="color: #4BD97F" class="el-icon-success"></i>
                        <span style="color: #4BD97F;">识别成功</span>
                      </template>
                    </template>
                  </el-table-column>
                  <el-table-column prop="type" label="操作">
                    <template slot-scope="scope">
                      <i class="el-icon-delete" @click="del(scope.$index)"></i>
                    </template>
                  </el-table-column>
                </el-table>

              </div>

              <div class="upload-btn-wrap  upload-btn-wrap-flex" style="margin-top: 35px;">
                <el-upload class="upload_button" multiple accept=".jpg,.png,.jpeg" action="" :limit="100"
                  :auto-upload="true" :show-file-list="false" :http-request="uploadImages" :on-exceed="onExceed"
                  :file-list="tableData">
                  <el-button class="upload-btn" size="mini" type="primary" ref="upImg">继续上传</el-button>
                </el-upload>
                <el-button v-preventReClick class="upload-btn" style="margin: 0 30px;" type="primary"
                  @click.stop="toOrder('bath')">{{ isAdd ? '确认' : '开始办理' }}</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import dateSelect from '@/components/date.vue'
import { getdefaultidentity, preorder, passportrecognitionapplicant } from '@/api/product.js';
import alyoss from "@/utils/alyoss";

export default {
  inject: ['pageReload'],
  components: {
    dateSelect
  },
  data() {
    return {
      xxActive: 0,
      isVisible: this.config,
      checkShow: false,
      inputShow: false,
      dateInfo: null,
      isShow: false,
      formInfo: {
        date: '',
      },
      identity: [],
      applicants: [{
        applicantName: '',
        identityTypeId: '',
      }],
      step: 1,
      zidong: false,
      tableData: [],

    }
  },
  props: {
    config: {
      type: Boolean,
      default: false,
    },
    //  国家id
    countryId: {
      type: [String, Number],
    },
    //  签证详情
    visa: {
      type: [Object],
      default: {}
    },
    //  签证详情
    visaDocs: {
      type: [Object, Array],
      default: []
    },
    //  签证城市id
    provinceId: {
      type: [String, Number],
    },
    //  是否仅是添加申请人
    isAdd: {
      type: [Boolean],
      default: false,
    }
  },

  created() {

    if (this.isAdd) {
      this.checkShow = true;
    }

    if (this.visaDocs.length == 1) {
      this.applicants = [
        {
          applicantName: '',
          identityTypeId: this.visaDocs[0].identityTypeId
        }
      ]
    }

    this.getdefaultidentityFn();
  },
  methods: {
    back() {
      this.checkShow = false;
      this.tableData = []
    },
    del(index) {
      this.$confirm('是否要删除该申请人?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.tableData.splice(index, 1);
        if (this.tableData.length == 0) {
          this.step = 1;
        }
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    handleClose() {
      // this.dialogVisible = false;   
      // 清空数据
      // this.ruleForm.checkItem = ''
      // 传递关闭事件给父组件 
      this.$emit('close')
    },

    checkUser(str) {
      if (str == 'left') {
        this.zidong = false
      } else {
        this.zidong = true;
      }
      this.step = 2
    },

    openCheck() {
      this.inputShow = !this.inputShow;
    },
    delUser(index) {
      this.applicants.splice(index, 1)
    },
    getdefaultidentityFn() {
      getdefaultidentity({

      }).then(res => {
        console.log(res);
        this.identity = res.data
      })
    },

    setDate(e) {

      this.dateInfo = e;
      // this.inputShow = false;
      // this.openCheck();
      // this.$refs.dateSelect.style.display = 'none'
    },

    addUser() {

      if (this.visaDocs.length == 1) {
        this.applicants.push({
          applicantName: '',
          identityTypeId: this.visaDocs[0].identityTypeId,
        })
      } else {
        this.applicants.push({
          applicantName: '',
          identityTypeId: '',
        })
      }


      this.$forceUpdate();
    },

    toOrder(type) {
      if (type === 'bath') {
        // 判断订单是否全部识别结束
        if (this.tableData.findIndex(res => { return res.loading == true }) > -1) {
          return this.$message.error('还有申请人正在识别中...')
        }
        this.applicants = this.tableData.map(v => ({ identityTypeId: v.identityTypeId, applicantName: v.name, passportInfo: v.passportInfo }))
      }
      let vm = this;
      if (this.isAdd) {

        let isError = true;
        vm.applicants.forEach(item => {
          if (!item.applicantName) {
            isError = false;
          }

          if (!item.identityTypeId) {
            isError = false;
          }
        })

        if (!isError) {
          this.$message({
            message: '出行人信息未完善',
            type: 'warning'
          });
          return;
        }
        this.$emit('addUserFn', vm.applicants)
      } else {

        if (!vm.dateInfo) {
          this.$message({
            message: '未选择出行时间',
            type: 'warning'
          });

          return;
        }

        let isError = true;
        vm.applicants.forEach(item => {
          if (!item.applicantName) {
            isError = false;
          }

          if (!item.identityTypeId) {
            isError = false;
          }
        })

        if (!isError) {
          this.$message({
            message: '出行人信息未完善',
            type: 'warning'
          });
          return;
        }

        preorder({
          visaId: vm.visa.id,
          method: vm.xxActive + 1,
          provinceId: vm.provinceId,
          departureTime: vm.dateInfo.date,
          applicants: vm.applicants,
          token: vm.$store.state.token
        }).then(res => {
          if (res.code == 1) {
            this.$router.push({ name: 'orderDetail', params: { id: res.data.order.id } })
          }
        })
      }
    },
    // 上传文件
    async uploadImages(file) {
      this.checkUser('right')
      file = file.file

      this.tableData.push({
        uid: file.uid,
        name: '',
        type: '正在上传',
        identityTypeId: this.visaDocs.length == 1 ? this.visaDocs[0].identityTypeId : '',
        img: '',
        url: '',
        loading: true,
        passportInfo: {},
      })

      let alyossData = await alyoss(file);
      let findIndex = this.tableData.findIndex(res => { return res.uid == file.uid })
      if (findIndex > -1) {
        this.tableData[findIndex].loading = true
        this.tableData[findIndex].type = '正在识别'
      }
      passportrecognitionapplicant({ passports: [alyossData] }).then(res => {
        let findIndex = this.tableData.findIndex(res => { return res.uid == file.uid })
        if (res.code == 1) {
          if (findIndex > -1) {
            this.tableData[findIndex].name = res.data[0].customer_FullName
            this.tableData[findIndex].loading = false
            this.tableData[findIndex].type = '识别成功'
            this.tableData[findIndex].passportInfo = res.data[0]
            this.tableData[findIndex].img = res.data[0].passportUrl
            this.tableData[findIndex].url = res.data[0].passportUrl
          }
        } else {
          if (findIndex > -1) {
            this.tableData[findIndex].name = res.data[0].customer_FullName
            this.tableData[findIndex].loading = false
            this.tableData[findIndex].type = '识别失败'
          }
        }
        this.tableData = [...this.tableData]

      }).catch(() => {
        let findIndex = this.tableData.findIndex(res => { return res.uid == file.uid })
        if (findIndex > -1) {
          this.tableData[findIndex].name = ''
          this.tableData[findIndex].loading = false
          this.tableData[findIndex].type = '识别失败'
        }
        this.tableData = [...this.tableData]

      })
    },
    onExceed() {
      var vm = this;
      vm.$message({
        message: "只能上传" + 100 + "张,如需替换请先删除已上传图片",
        type: 'warning'
      });
    },
    getQuery() {

    },


  }
}
</script>


<style lang="scss" scoped>
.upload-zl {
  display: flex;
  flex-direction: column;
  align-items: center;

  .upload-list {
    display: flex;
    align-items: center;
    position: relative;

    .upload-ab {
      position: absolute;
      top: 0px;
      left: 0px;
      color: #fff;
      font-size: 12px;
      line-height: 20px;
      padding-left: 8px;
      width: 120px;
      background: url('https://file.sport-syy.com/visa/admin/images/pc/xx_bg.png') no-repeat;
      background-size: 110px 20px;
      border-radius: 5px 0 0 0;
    }

    .upload-item {
      border-radius: 5px;
      border: 1px solid #dee2e6;
      display: flex;
      align-items: center;
      cursor: pointer;

      img {
        width: 40px;
        height: 55px;
        margin-left: 45px;

      }

      .upload-content {
        display: flex;
        flex-direction: column;
        margin-left: 20px;

        p {
          font-size: 18px;

        }

        span {
          font-size: 14px;
          color: #91999f;
        }
      }

      &.upload-left {
        width: 350px;
        height: 120px;
        margin-right: 15px;

      }

      &.upload-right {
        width: 250px;
        height: 120px;

        img {
          width: 40px;
          height: 40px;
          margin-left: 35px;
        }

        .upload-content {
          margin-left: 10px;
        }
      }


      &.upload-active {
        border: 1px solid #599fff;

        .upload-content {
          p {

            color: #599fff;
          }

          span {
            color: #599fff;
          }
        }
      }

      &.no-upload {
        cursor: not-allowed;
        background-color: #fafbfc;
        border: 1px solid #dee2e6;

        .upload-content {
          p {

            color: #c9cdd1;
          }

          span {
            color: #c9cdd1;
          }
        }

      }



    }
  }

  .upload-btn {
    width: 162px;
    height: 48px;
    background: #599fff;
    border-radius: 4px;
    border: 1px solid #5e9be1;
    color: #FFF;
    margin-top: 32px;
  }
}

.user-start-btn {
  display: flex;
  justify-content: center;
  margin-top: 22px;

  .start-btn {
    width: 240px;
    height: 40px;
    font-size: 16px;
    color: #fff;
    background-color: #599fff;
    border-color: #5e9be1;

  }
}

.upload-box {
  margin-top: -25px;
  position: relative;

  .box-title {
    font-size: 16px;
    font-weight: 500;
  }

  .icon-wrap {
    position: absolute;
    left: 0;
    top: -30px;
    cursor: pointer;
  }



  .user-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 24px 0;

    .user-box-title {
      font-size: 14px;

      span {

        color: #f66878;
      }
    }

    .user-box-btn {
      display: inline-block;
      border: 1px solid #5e9be1;
      border-radius: 3px;
      height: 28px;
      line-height: 26px;
      color: #5e9be1;
      font-size: 14px;
      text-align: center;
      width: 100px;
      vertical-align: middle;
      margin-top: -6px;
      cursor: pointer;
    }
  }

  .user-box-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    display: table;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 0 15px;
    box-sizing: border-box;
    margin-bottom: 15px;
    position: relative;
    position: relative;

    .user-box-del {
      position: absolute;
      right: 0;
      top: 0;
      width: 70px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &::before {
        content: "";
        display: block;
        border-left: 1px solid #e9e9e9;
        height: 50px;
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -25px;
        margin-left: 15px;
      }

      i {
        font-size: 21px;
        color: #e3e3e3;
        margin-left: 12px;
      }
    }

    .user-box-item-x {
      height: 1px;
      width: 100%;
      background: #E9E9E9;
    }

    .user-box-item {
      display: flex;
      align-items: center;

      .user-box-item-title {
        width: 95px;
        display: bold;
      }



      .user-box-item-input {
        flex: 1;

        .el-input__inner {
          border: none;
        }
      }


    }

    .user-box-del {}



  }

  .date-input {
    width: 100%;
    height: 40px;
    border: 1px solid #ddd;
    line-height: 38px;
    font-size: 14px;
    border-radius: 4px;
    position: relative;
    -webkit-transition: all .2s;
    transition: all .2s;
    background-color: #fff;
    cursor: pointer;
    margin-top: 18px;
    padding: 0 17px;
    box-sizing: border-box;
    display: flex;

    &.date-input-active {
      border: 1px solid #5e9be1;
    }

    .date-input-text1 {
      font-size: 14px;
      margin-right: 17px;
    }

    .date-flex {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      .date-input-text2 {
        margin-left: 40px;
      }
    }
  }
}
</style>

<style lang="scss">
// .login-input {
//     .el-input__inner {
//         height: 50px !important;

//     }
// }


.upload-in {

  .el-dialog {
    border-radius: 5px;
  }

  .user-box-item-input {
    .el-input__inner {
      border: none;
    }
  }

  .el-select {
    // width: 408px;
    flex: 1;
    width: 100%;
  }
}

.upload-top-box {
  display: flex;
  align-items: center;

  .upload-top-left {
    width: 270px;
    height: 140px;
    margin: 0 15px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .upload-top-right {
    display: flex;
    flex-direction: column;

    .upload-top-right-item {
      display: flex;
      align-items: center;
      margin-bottom: 18px;

      .upload-img-info {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background: #C0D4FF;

        i {
          color: #FFF;
          margin-left: 2px;
        }
      }

      span {
        margin-left: 10px;
      }
    }
  }
}

.upload-btn {
  width: 162px;
  height: 48px;
  background: #599fff;
  border-radius: 4px;
  border: 1px solid #5e9be1;
  color: #FFF;
  // margin-top: 32px;
  border: none;

  &:hover {
    background: #599fff;
    color: #FFF;
    border: none;
  }
}

.upload-btn-left {
  border: 1px solid #3888E9;
  background: #FFF;
  color: #3888E9;
  width: 162px;
  height: 48px;
}

.upload-btn-wrap-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>