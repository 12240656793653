<template>
  <div class="page" style="background-color: #FFF;">
    <div class="page-top">
      <el-input placeholder="搜索办签国家/地区" v-model="valueInput" @focus="inputShow" @blur="outInput()"
        class="input-with-select" style="height: 40px;width: 973px;">
        <!-- <el-button slot="suffix" ic
          on="el-icon-search" class="btn-style"></el-button> -->
        <i slot="suffix" class="el-icon-search"></i>
      </el-input>
      <div class="srarch-input" v-if="inputShowBom" @mouseleave="inputShowBom = false; valueInput = ''">
        <div class="search-input-item" v-for="(item, index) in searchList" @click="pathRoute(item)" :key="item">
          <span class="input-item-text1">{{ item.zhName }}</span>
          <span class="input-item-text2">{{ item.visaTypes }}</span>
        </div>
      </div>
      <div class="page-search" v-if="isShow" @mouseleave="isShow = false">
        <div class="page-search-menu">
          <div class="page-menu-item" :class="[index == menuActive ? 'menu-active' : '']" @click="menuCheck(index)"
            v-for="(item, index) in list" :key="index">{{ item.name }}</div>
        </div>
        <div class="page-search-content">
          <div class="page-content-item" v-for="(item, index) in contentObj.no" :key="index">
            <span class="page-content-p">{{ item }}</span>
            <div class="page-content-info">
              <span v-for="(item, idx) in contentObj.data[item]" :key="idx" @click="pathRoute(item)">{{ item.zhName
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-content">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane :label="item.label" :name="item.value" v-for="(item, index) in menu" :key="index">
          <template v-if="activeName != -1">

            <div class="pane-warp">
              <div class="pane-content">
                <div class="pane-title">{{ countrysObj.label }}</div>
                <div class="pane-right">
                  <div class="box3-item" v-for="(item, idx) in countrysObj.datas" @click="pathInfo(item)" :key="idx">
                    <span class="box3-text1">{{ item.zhName }}</span>
                    <span class="box3-text2">{{ item.enName }}</span>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="pane-warp" v-for="(item, idx) in countrys.continentCountry" :key="idx">
              <div class="pane-content">
                <div class="pane-title">{{ item.label }}</div>
                <div class="pane-right">
                  <div class="box3-item" v-for="(elem, i) in item.datas" @click="pathInfo(elem)" :key="i">
                    <span class="box3-text1">{{ elem.zhName }}</span>
                    <span class="box3-text2">{{ elem.enName }}</span>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </el-tab-pane>

      </el-tabs>
    </div>
  </div>
</template>

<script>
import { continentselect, getcountryandabbr } from '@/api/product.js'
import { getcountryandvisatype } from '@/api/common.js'


export default {
  name: 'product',
  data() {
    return {
      list: [
        {
          name: 'ABCD',
          no: ['A', 'B', 'C', 'D'],
          data: {
            A: [],
            B: [],
            C: [],
            D: [],
          }
        },
        {
          name: 'EFGHJK',
          no: ['E', 'F', 'G', 'H', 'J', 'K'],
          data: {
            E: [],
            F: [],
            G: [],
            H: [],
            J: [],
            K: [],
          }
        },
        {
          name: 'LMNO',
          no: ['L', 'M', 'N', 'O'],
          data: {
            L: [],
            M: [],
            N: [],
            O: [],
          }
        },
        {
          name: 'PQRST',
          no: ['P', 'Q', 'R', 'S', 'T'],
          data: {
            P: [],
            Q: [],
            R: [],
            S: [],
            T: [],
          }
        },
        {
          name: 'WXYZ',
          no: ['W', 'X', 'Y', 'Z'],
          data: {
            W: [],
            X: [],
            Y: [],
            Z: [],
          }
        },
      ],
      isShow: false,
      inputShowBom: false,
      contentObj: {},
      searchList: [],
      activeName: -1,
      boxList3: [
        {
          name: '越南',
          lable: 'Vietnam'
        },
        {
          name: '越南',
          lable: 'Vietnam'
        },
        {
          name: '越南',
          lable: 'Vietnam'
        },
        {
          name: '新加坡',
          lable: 'Singapore'
        },
        {
          name: '新加坡',
          lable: 'Singapore'
        },
        {
          name: '新加坡',
          lable: 'Singapore'
        },
      ],
      menu: [],
      countrys: {},
      countrysObj: {},
      menuActive: 0,
      valueInput: '',
    }
  },
  created() {
    console.log(this.$store.state, 8888888)
    this.countrys = this.$store.state.product.countrys;
    this.continentselectFn();
    this.getcountryandabbrFn();
  },
  watch: {
    activeName(val) {

      if (val == -1) {


        return;
      }


      this.countrys.continentCountry.forEach(item => {


        if (item.value == val) {

          this.countrysObj = item;
        }
      })

    },
    valueInput(val, oldVal) {

      // if (this.isShow && ) {

      // }
      if (val != oldVal) {

        if (val && this.isShow) {
          this.isShow = false;
          this.getcountrysInput(val)
          this.inputShowBom = true;
        }

        if (!val && !this.isShow) {
          this.isShow = true;
          this.inputShowBom = false;
          // this.getcountrysInput(val)
        }

        if (!val) {
          this.inputShowBom = false;
        }
      }


    }
  },
  methods: {
    handleClick(e) {

    },
    pathRoute(item) {
      console.log(item);
      this.$router.push({ name: 'productDetail', params: { id: item.id + "_" + item.zhName } })
    },

    outInput() {
      console.log(this.inputShowBom);
      if (this.inputShowBom) {
        this.inputShowBom = true;

      } else {
        this.inputShowBom = false;
        this.valueInput = ''
      }
    },

    inputShow() {
      if (!this.valueInput) {
        this.isShow = true;
      }
    },

    async getcountrysInput(val) {
      let res = await getcountryandvisatype({
        key: val
      })
      this.searchList = res.data
      console.log(res, 'CountryName');

    },

    menuCheck(index) {
      this.menuActive = index;
      this.contentObj = JSON.parse(JSON.stringify(this.list[index]));
    },
    async getcountryandabbrFn() {
      let res = await getcountryandabbr({})
      console.log(res);

      let arr = res.data;
      arr.forEach(item => {
        this.list.forEach(elem => {

          // console.log(elem, 111111)

          if (item.abbreviation && elem.name.indexOf(item.abbreviation) != -1) {

            console.log(elem, 11111111)
            elem.data[item.abbreviation].push({
              ...item,
            });
          }
        })

      })

      console.log(this.list[0], 'list');
      this.contentObj = JSON.parse(JSON.stringify(this.list[0]));

    },
    continentselectFn() {
      continentselect({
      }).then(res => {
        console.log(res, 5555);
        this.menu = res.data;
        this.menu.unshift({
          label: '全部',
          value: -1
        })
      })
    },
    pathInfo(item) {
      this.$router.push({ name: 'productDetail', params: { id: item.id + "_" + item.zhName } })

    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/product/index.scss';
</style>

<style lang="scss"></style>