<template>
  <div class="page order-center">
    <div class="page-content">
      <div class="page-menu">
        <div
          class="page-menu-item"
          @click="setActive(index)"
          :class="[formDate.menuActive == index ? 'page-menu-item-active' : '']"
          v-for="(item, index) in menuList"
          :key="index"
        >
          {{ item.name }}({{ item.value }})
        </div>
      </div>

      <div class="order-center-search">
        <div class="box-input">
          <div class="box-input-left" style="margin-bottom: 12px">
            <el-select
              clearable
              v-model="formDate.CountryId"
              style="width: 174px"
              placeholder="国家"
              filterable
              @change="getQueryorderorg"
            >
              <el-option
                v-for="item in countryData"
                :key="item.id"
                :label="item.zhName"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <el-select
              class="box-input-left-z"
              style="width: 200px"
              clearable
              v-model="formDate.VisaTypeId"
              placeholder="签证类型"
              @change="getQueryorderorg"
            >
              <el-option
                v-for="item in visaType"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <el-select
              clearable
              v-model="formDate.OrderStatus"
              style="width: 200px"
              placeholder="订单状态"
              @change="getQueryorderorg"
            >
              <el-option
                v-for="item in orderStatusList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>

            <el-select
              clearable
              v-model="formDate.ApplcantStatus"
              style="width: 200px"
              placeholder="申请人状态"
              @change="getQueryorderorg"
            >
              <el-option
                v-for="item in selectStatus"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <!-- <el-select clearable v-model="formDate.continentId" style="width: 280px" @change="getQuery" placeholder="下单时间">
              <el-option v-for="item in visaType" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select> -->

            <el-date-picker
              v-model="dateInfo"
              type="daterange"
              range-separator="至"
              start-placeholder="下单开始日期"
              end-placeholder="下单结束日期"
              @change="setDate"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>

            <!-- <el-select v-model="productForm.areaId" placeholder="请选择领区">
                    <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select> -->

            <!-- <el-input style="width: 130px;" v-model="formDate.name" placeholder="产品名称"></el-input> -->
          </div>

          <div class="box-input-left">
            <el-select
              class="box-input-left-z"
              style="width: 174px"
              clearable
              v-model="formDate.MerchatId"
              placeholder="商户"
              @change="getQueryorderorg"
            >
              <el-option
                v-for="item in continent"
                :key="item.id"
                :label="item.companyName"
                :value="item.id"
              >
              </el-option>
            </el-select>

            <el-input
              style="width: 526px"
              v-model="formDate.Key"
              placeholder="订单号、申请人状态、护照号、联系人姓名、联系人手机号"
            ></el-input>

            <div class="box-input-btn-wrap" style="margin-left: 10px">
              <el-button
                v-preventReClick
                class="default btn-size"
                @click="getQueryorderorg"
                >搜索</el-button
              >
              <el-button class="box-input-btn-cz btn-size" @click="reset"
                >重置</el-button
              >
              <el-button class="box-input-btn-cz btn-size" @click="openAdd"
                >快速下单</el-button
              >
            </div>
          </div>
        </div>

        <div class="box-table order-center-table">
          <el-table
            :data="orderOrgList"
            :row-key="getRowKeys"
            :expand-row-keys="expands"
            @expand-change="expandSelect"
            @row-click="clickTable"
            :header-cell-style="{
              backgroundColor: '#F2F4F7',
              color: '#333',
              fontWeight: 'bold',
              textAlign: 'center',
              fontSize: '12px',
            }"
            style="width: 100%"
            v-loading="loading"
            :height="$store.state.product.screen ? '920px' : '570px'"
            :cell-style="cellStyleFn"
            :cell-class-name="cellClassName"
            ref="refTable"
          >
            <el-table-column type="expand" width="30" style="height: auto" fit>
              <template slot-scope="outer">
                <div class="props-table-box">
                  <el-table
                    v-loading="loadingOrder"
                    :data="orderUserList"
                    :cell-style="{ 'text-align': 'center' }"
                    :header-cell-style="{
                      backgroundColor: '#F9F9F9',
                      color: '#333',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      fontSize: '12px',
                    }"
                  >
                    <el-table-column label="姓名/拼音">
                      <template slot-scope="props">
                        <span
                          >{{ props.row.applicantName }}
                          {{ props.row.surnameEn }} {{ props.row.nameEn }}</span
                        >
                      </template>
                    </el-table-column>
                    <el-table-column label="性别">
                      <template slot-scope="props">
                        <span>{{ props.row.gender }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="身份类型">
                      <template slot-scope="props">
                        <span>{{ props.row.identityTypeName }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="护照号">
                      <template slot-scope="props">
                        <span>{{ props.row.passportNumber }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="申请状态">
                      <template slot-scope="props">
                        <span :style="setTopClass(props.row.status)">{{
                          props.row.statusName
                        }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="出生日期">
                      <template slot-scope="props">
                        <span>{{
                          props.row.birthday && props.row.birthday.split(" ")[0]
                        }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="护照签发日">
                      <template slot-scope="props">
                        <span>{{
                          props.row.passportIssue &&
                          props.row.passportIssue.split(" ")[0]
                        }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="护照到期日">
                      <template slot-scope="props">
                        <span>{{
                          props.row.passportValidity &&
                          props.row.passportValidity.split(" ")[0]
                        }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="完成率">
                      <template slot-scope="props">
                        <el-progress
                          type="circle"
                          define-back-color="#EAF3FF"
                          :percentage="props.row.taskTable"
                          :width="60"
                          :height="60"
                          :stroke-width="6"
                          :color="colors"
                          style="transform: scale(0.8)"
                        ></el-progress>
                      </template>
                    </el-table-column>
                    <el-table-column label="签证页">
                      <template slot-scope="props">
                        <div
                          v-if="!props.row.visaPage"
                          @click="orderApplicantId = props.row.id"
                        >
                          <el-upload action="" :http-request="uploadVisapage">
                            <i class="iconfont icon-shangchuan visa-icon"
                              >上传</i
                            >
                          </el-upload>
                        </div>
                        <i
                          class="iconfont icon-search visa-icon"
                          v-else
                          @click="openFile(props.row.visaPage)"
                        >
                          查看</i
                        >
                      </template>
                    </el-table-column>
                    <el-table-column label="操作">
                      <template slot-scope="props">
                        <div class="edit-wrap">
                          <div class="edit-item">
                            <span
                              class="lan"
                              @click="openElectron(outer.row, props.row.id)"
                              >查看</span
                            >
                          </div>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                  <div
                    class="table-pagination"
                    style="background-color: #f9f9f9"
                  >
                    <el-pagination
                      @current-change="currentPropsChange($event, outer.row)"
                      :current-page="outer.row.pageIndex"
                      :page-size="outer.row.pageSize"
                      layout="total, prev, pager, next, jumper"
                      :total="outer.row.pageTotal"
                    >
                    </el-pagination>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="createdTime"
              label="下单-支付时间"
              width="110"
            >
              <template slot-scope="scope">
                <div class="flex-center">
                  <img
                    :src="scope.row.paymentTime ? time2 : time1"
                    class="time-line"
                  />
                  <div class="time-text">
                    <p>{{ scope.row.createdTime }}</p>
                    <p>
                      {{ scope.row.paymentTime ? scope.row.paymentTime : "--" }}
                    </p>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="orderNo" label="订单号"> </el-table-column>
            <el-table-column prop="paidAmount" label="订单金额">
              <template slot-scope="scope">
                {{ scope.row.paidAmount }}
                <div v-if="scope.row.couponAmount" style="color: #ff5967">
                  优惠 {{ scope.row.couponAmount }}元
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="visaLegend" label="国家-类型">
              <template slot-scope="scope">
                <p>{{ scope.row.countryName }}</p>
                <p>{{ scope.row.visaTypeName }}</p>
              </template>
            </el-table-column>
            <el-table-column prop="visaTitle" label="产品名称" width="140px">
            </el-table-column>
            <el-table-column
              prop="num"
              label="申请人数"
              width="70"
            ></el-table-column>
            <el-table-column prop="statusName" label="订单状态">
              <template slot-scope="scope">
                <span :style="{ color: setStatus(scope.row.status) }">{{
                  scope.row.statusName
                }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="succeedNum" label="成功/拒签" width="75">
              <template slot-scope="scope">
                {{ scope.row.succeedNum }}-{{ scope.row.failedNum }}
              </template>
            </el-table-column>
            <el-table-column prop="userName" label="创建人" class="kaka">
              <!-- orderSource  0：商户 1：平台 2:用户 3:API接口 -->
              <template slot-scope="scope">
                <span
                  class="source-type"
                  :style="{
                    background: setOrderSourceColor(scope.row.orderSource),
                  }"
                  >{{ setOrderSourceText(scope.row.orderSource) }}</span
                >{{ scope.row.userName }}
              </template>
            </el-table-column>
            <el-table-column prop="contacts" label="联系人信息" width="120px">
              <template slot-scope="scope">
                <span>{{ scope.row.contacts ? scope.row.contacts : "--" }}</span
                ><br />
                <span>{{
                  scope.row.contactPhone ? scope.row.contactPhone : "--"
                }}</span>
              </template>
            </el-table-column>

            <el-table-column
              prop="serviceMarks"
              label="操作备注"
              width="100"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span class="marks-text">{{
                  scope.row.serviceMarks ? scope.row.serviceMarks : "--"
                }}</span>
                <i
                  slot="reference"
                  class="iconfont icon-a-xiugai1"
                  @click.stop="showMarksDialog(scope.row, scope.$index)"
                ></i>
                <span class="marks-icon">
                  <div
                    class="marks-dialog"
                    v-show="marksIndex == scope.$index"
                    @click.stop
                    v-clickOutside="
                      () => {
                        marksIndex = -1;
                      }
                    "
                  >
                    <p>操作备注</p>
                    <el-input
                      v-model="curOrderItem.serviceMarks"
                      type="textarea"
                      :rows="4"
                      resize="none"
                    />
                    <div class="marks-btn">
                      <el-button class="default" @click="handleSaveMarks"
                        >保存</el-button
                      >
                    </div>
                  </div>
                </span>
              </template>
            </el-table-column>
            <el-table-column
              fixed="right"
              label="操作"
              header-align="center"
              width="120"
            >
              <template slot-scope="scope">
                <div class="edit-wrap2">
                  <div class="edit-item" @click.stop>
                    <span class="lan" @click.stop="openElectron(scope.row)"
                      >查看</span
                    >
                    <span
                      class="lan"
                      v-if="scope.row.paymentStatus == 1"
                      @click="handleCommand('a', scope.row)"
                      >退款</span
                    >
                    <el-dropdown
                      v-if="[-1, -2, 0, 1, 2].indexOf(scope.row.status) == -1"
                      trigger="hover"
                      @command="handleCommand($event, scope.row)"
                    >
                      <span class="lan" style="cursor: pointer; font-size: 12px"
                        >展开<i class="el-icon-arrow-down el-icon--right"></i
                      ></span>
                      <el-dropdown-menu slot="dropdown">
                        <!-- <el-dropdown-item command="a"
                          v-if="[3, 4, 7, -3].indexOf(scope.row.status) !== -1">退款</el-dropdown-item> -->
                        <el-dropdown-item
                          command="b"
                          v-if="[4].indexOf(scope.row.status) !== -1"
                          >填写物流</el-dropdown-item
                        >
                        <el-dropdown-item
                          command="c"
                          v-if="[6, 7].indexOf(scope.row.status) !== -1"
                          >查看物流</el-dropdown-item
                        >
                        <el-dropdown-item
                          command="d"
                          v-if="[-4].indexOf(scope.row.status) !== -1"
                          >恢复</el-dropdown-item
                        >

                        <!-- <el-dropdown-item :command="item.value" v-for="(item, index) in setDropdown(scope.row.status).arr" :key="index" >{{item.name}}</el-dropdown-item> -->
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="table-pagination">
          <el-pagination
            @current-change="currentChange"
            :current-page="formDate.pageIndex"
            :page-size="formDate.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="formDate.pageTotal"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <!-- 退款 -->
    <el-dialog
      :visible.sync="outPricedio"
      width="419px"
      class="quan-dialog-out-class"
      :close-on-click-modal="true"
    >
      <div class="dialog-member-title">
        <span class="dialog-member-name">退款操作</span>
        <div class="dialog-close" @click="outPricedio = false">
          <i class="el-icon-close"></i>
        </div>
      </div>
      <div class="quan-num-add" style="margin-top: 20px">
        <div class="quan-num-add-item">
          <span class="quan-num-add-title">退款金额</span>
          <div>
            <span>订单剩余{{ orderItem.reiduePrice }}元</span>
            <span
              class="quan-num-right"
              @click="outPrice.price = orderItem.reiduePrice"
              >全部退款</span
            >
          </div>
        </div>

        <el-input
          type="Number"
          min="0.01"
          :max="orderItem.reiduePrice"
          class="quan-num-input"
          v-model="outPrice.price"
        ></el-input>

        <div class="quan-num-add-item">
          <span class="quan-num-add-title">退款原因</span>
        </div>

        <el-input
          type="textarea"
          :rows="4"
          placeholder="请输入内容"
          v-model="outPrice.text"
          class="quan-num-input"
          style="margin-bottom: 25px"
        >
        </el-input>

        <div class="packge-btn-add">
          <el-button class="pageck-btn" @click="outPricedio = false"
            >取消</el-button
          >
          <el-button
            class="pageck-btn default"
            type="primary"
            @click="outSubmit"
            >确认</el-button
          >
        </div>
      </div>
    </el-dialog>
    <addUserInfoTwo
      :config="newAddUserShow"
      @addUserFn="addUserFn"
      @close="newAddUserShow = false"
      :isAddOrder="true"
      :visaDocs="[]"
      v-if="newAddUserShow"
    >
    </addUserInfoTwo>
  </div>
</template>

<script>
import {
  orderstatusselect,
  orderapplcantstatusselect,
  queryorderapplicants,
  orderrefund,
} from "@/api/order.js";
import { countrylist, visatypelist } from "@/api/personal.js";
import { uploadvisapage } from "@/api/order.js";
import {
  queryorderorg,
  merchatlist,
  queryorderapplicantorg,
  orderremarks,
} from "@/api/orderCenter.js";
import addUserInfoTwo from "@/components/addUserInfoTwo.vue";
import time1 from "@/assets/images/order/time1.png";
import time2 from "@/assets/images/order/time2.png";
import alyoss from "@/utils/alyoss";

export default {
  data() {
    return {
      menuActive: 0,
      tui: {
        total: "",
      },
      menuList: [
        {
          name: "全部",
          label: 0,
          value: 0,
        },
        {
          name: "待处理",
          label: 1,
          value: 0,
        },
        {
          name: "等待出签",
          label: 1,
          value: 0,
        },
        {
          name: "已完成",
          label: 1,
          value: 0,
        },
      ],
      colors: [
        { color: "#E41313", percentage: 50 },
        { color: "#EEBB17", percentage: 99 },
        { color: "#1C9C1C", percentage: 100 },
      ],
      dropdownList: [
        {
          name: "退款",
          value: "a",
        },
        {
          name: "填写物流",
          value: "b",
        },
        {
          name: "查看物流",
          value: "c",
        },
        {
          name: "恢复",
          value: "d",
        },
      ],
      expands: [],
      dateInfo: [],
      loading: false,
      outPricedio: false,
      newAddUserShow: false,
      formDate: {
        CountryId: "",
        VisaTypeId: "",
        OrderStatus: "",
        ApplcantStatus: "",
        Key: "",
        StartTime: "",
        EndTime: "",
        MerchatId: "",
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 0,
        menuActive: 0,
      },
      outPrice: {
        price: "",
        text: "",
      },
      orderStatusList: [],
      selectStatus: [],
      countryData: [],
      visaType: [],
      orderOrgList: [],
      orderUserList: [],
      continent: [],
      orderItem: {},
      loadingOrder: false,
      getRowKeys(row) {
        return row.id || "";
      },
      dateWrap: {
        totalCount: "",
        waitingCount: "",
        FinishiCount: "",
        PendingCount: "",
      },
      time2: time2,
      time1: time1,
      marksIndex: -1,
      orderApplicantId: "",
      curOrderItem: {},
      timer: null,
    };
  },
  components: {
    addUserInfoTwo,
  },
  mounted() {
    this.getParams();
    this.getOrderstatusselect();
    this.getOrderapplcantstatusselect();
    this.getCountrylist();
    this.getVisatypelist();
    this.getQueryorderorg();
    this.getMerchatlist();
  },
  computed: {
    setStatus() {
      return function (status) {
        if (status == -4 || status == -2 || status == -3 || status == 2) {
          return "#E41313";
        } else if (status == 0 || status == 1 || status == 3) {
          return "#EEBB17";
        } else {
          return "#1C9C1C";
        }
      };
    },
    setDropdown() {
      return function (status) {
        let arr = [];
        if ([3, 4, 7].indexOf(status) !== -1) {
          arr.push({
            name: "退款",
            value: "a",
          });
        }

        if ([4].indexOf(status) !== -1) {
          arr.push({
            name: "填写物流",
            value: "b",
          });
        }

        if ([6, 7].indexOf(status) !== -1) {
          arr.push({
            name: "查看物流",
            value: "c",
          });
        }

        if ([6, 7].indexOf(status) !== -1) {
          arr.push({
            name: "恢复",
            value: "d",
          });
        }
        return { arr };
      };
    },
    setOrderSourceText() {
      return function (num) {
        let str = "";
        switch (num) {
          case 0:
            str = "商";
            break;
          case 1:
            str = "平";
            break;
          case 2:
            str = "用";
            break;
          default:
            str = "接";
        }
        return str;
      };
    },
    setOrderSourceColor() {
      return function (num) {
        let str = "";
        switch (num) {
          case 0:
            str = "#4791EA";
            break;
          case 1:
            str = "#49BD49";
            break;
          case 2:
            str = "#F79534";
            break;
          default:
            str = "#EA4747";
        }
        return str;
      };
    },
    setTopClass() {
      return function (num) {
        if ([0, 1, 2, 3, 4].indexOf(num) >= 0) {
          return "color:#eebb17"; //黄色
        } else if ([5, 6, 7, 8].indexOf(num) >= 0) {
          return "color:#1c9c1c"; //绿色
        } else {
          return "color:#e41313"; //红色
        }
      };
    },
  },

  methods: {
    getParams() {
      this.formDate.CountryId = this.$route.params.id;
      if (this.formDate.CountryId) {
        this.getQueryorderorg();
      }
    },
    showMarksDialog(val, index) {
      this.marksIndex = index;
      this.curOrderItem = JSON.parse(JSON.stringify(val));
    },
    cellStyleFn({ row, column, rowIndex, columnIndex }) {
      if (column.label == "创建人") {
        return { "text-align": "left" };
      } else {
        return { "text-align": "center" };
      }
    },
    cellClassName({ row, column, rowIndex, columnIndex }) {
      if (column.label == "操作备注") {
        return "cellMarks";
      }
    },
    handleSaveMarks() {
      orderremarks({
        orderId: this.curOrderItem.id,
        remarks: this.curOrderItem.serviceMarks,
      }).then((res) => {
        this.$message.success("修改成功！");
        this.getQueryorderorg();
        this.marksIndex = -1;
      });
    },
    async uploadVisapage(file) {
      let alyossData = await alyoss(file.file);
      uploadvisapage({
        orderApplicantId: this.orderApplicantId,
        visaPage: alyossData,
      }).then((res) => {
        if (res.code === 1) {
          this.$message({
            message: "上传成功！",
            type: "success",
          });
          this.queryorderapplicantorgFn(
            this.curOrderItem.id,
            this.curOrderItem
          );
        }
      });
    },
    openFile(url) {
      window.open(url);
    },
    getQueryorderorg() {
      let vm = this;

      this.loading = true;
      vm.orderOrgList = [];
      queryorderorg({
        "Page.Size": vm.formDate.pageSize,
        "Page.Index": vm.formDate.pageIndex,
        CountryId: vm.formDate.CountryId,
        VisaTypeId: vm.formDate.VisaTypeId,
        OrderStatus: vm.formDate.OrderStatus,
        ApplcantStatus: vm.formDate.ApplcantStatus,
        Key: vm.formDate.Key,
        StartTime: vm.formDate.StartTime,
        EndTime: vm.formDate.EndTime,
        MerchatId: vm.formDate.MerchatId,
        Status: vm.formDate.menuActive,
      }).then((res) => {
        if (res.data) {
          vm.orderOrgList = res.data.row;

          (this.menuList = [
            {
              name: "全部",
              label: 0,
              value: res.data.total,
            },
            {
              name: "待处理",
              label: 1,
              value: res.data.pendingCount ? res.data.pendingCount : 0,
            },
            {
              name: "等待出签",
              label: 2,
              value: res.data.waitingCount ? res.data.waitingCount : 0,
            },
            {
              name: "已完成",
              label: 3,
              value: res.data.finishiCount ? res.data.finishiCount : 0,
            },
          ]),
            vm.orderOrgList.forEach((item) => {
              item.createdTime = item.createdTime.split(" ")[0];
              item.pageIndex = 1;
              item.pageTotal = 0;
              item.pageSize = 5;
            });
          vm.formDate.pageTotal = res.data.totalCount;
        }
        vm.loading = false;
      });
    },

    async getOrderstatusselect() {
      let vm = this;

      let res = await orderstatusselect({});
      vm.orderStatusList = res.data;
    },
    async getOrderapplcantstatusselect() {
      let res = await orderapplcantstatusselect({});

      this.selectStatus = res.data;
    },

    getCountrylist() {
      countrylist({}).then((res) => {
        this.countryData = res.data;
      });
    },
    getVisatypelist() {
      visatypelist({}).then((res) => {
        this.visaType = res.data;
      });
    },
    // 商户
    getMerchatlist() {
      merchatlist({}).then((res) => {
        this.continent = res.data;
      });
    },
    openElectron(item, userId) {
      console.log(item, userId);
      let vm = this;
      //马来西亚插件
      if (item.systemVisaId === 1) {
        vm.$router.push({
          name: "malaysia",
          query: {
            id: item.id,
            userId,
          },
        });
        return;
      }
      if (item && item.autocommit) {
        vm.$router.push({
          name: "orderCenterElectron",
          params: {
            id: `${item.applicantName}_${item.identityTypeName}_${item.id}_${userId}`,
          },
        });
      } else {
        vm.$router.push({
          name: "informations",
          params: { informations: userId, id: `${item.id}_${userId}` },
        });
      }
    },
    setActive(index) {
      this.formDate.menuActive = index;

      this.reset(index);
    },
    addUserFn(data) {
      this.$message({
        message: "出行人信息未完善",
        type: "success",
      });
      this.newAddUserShow = false;
      this.$set(data.order, "systemVisaId", data.visaDetail.visa.systemVisaId);
      debugger;
      this.openElectron(data.order);
      this.getQueryorderorg();
    },
    queryorderapplicantorgFn(id, row) {
      this.loadingOrder = true;
      queryorderapplicants({
        OrderId: id,
        "Page.Size": row.pageSize,
        "Page.Index": row.pageIndex,
      }).then((res) => {
        this.loadingOrder = false;

        this.orderUserList = res.data.rows;
        this.orderOrgList.forEach((item) => {
          if (item.id == id) {
            item.pageTotal = res.data.total;
          }
        });
        this.$refs.refTable.doLayout();
      });
    },
    currentChange(e) {
      this.formDate.pageIndex = e;
      this.$refs.refTable.bodyWrapper.scrollTop = 0;
      this.getQueryorderorg();
    },
    search() {},
    outSubmit() {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        orderrefund({
          amount: this.outPrice.price,
          refundRemarks: this.outPrice.text,
          orderId: this.orderItem.id,
        }).then((res) => {
          clearTimeout(this.timer)
          if (res.code == 0) {
            this.$message({
              message: res.msg,
              type: "warning",
            });
          } else {
            this.$message({
              message: "退款成功！",
              type: "success",
            });
            this.outPrice = {
              price: "",
              text: "",
            };
            this.outPricedio = false;
            this.getQueryorderorg();
          }
        }).catch(()=>{
          clearTimeout(this.timer)
        });
      }, 200);
    },
    reset(index) {
      this.formDate = {
        CountryId: "",
        VisaTypeId: "",
        OrderStatus: "",
        ApplcantStatus: "",
        Key: "",
        StartTime: "",
        EndTime: "",
        MerchatId: "",
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 0,
        menuActive: this.formDate.menuActive,
      };
      this.getQueryorderorg();
    },
    openAdd() {
      this.newAddUserShow = true;
    },
    handleCommand(e, item) {
      if (e == "a") {
        this.orderItem = item;
        item.reiduePrice = item.paidAmount - item.refundAmount;
        this.outPricedio = true;
      }
    },
    // 点击行展开事件
    clickTable(row, index, e) {
      this.curOrderItem = JSON.parse(JSON.stringify(row));
      this.queryorderapplicantorgFn(row.id, row);
      this.$refs.refTable.toggleRowExpansion(row);
    },
    currentPropsChange(e, row) {
      row.pageIndex = e;
      this.queryorderapplicantorgFn(row.id, row);
    },
    // 折叠面板每次只能展开一行
    expandSelect(row, expandedRows) {
      this.curOrderItem = JSON.parse(JSON.stringify(row));
      var that = this;
      if (expandedRows.length) {
        that.expands = [];
        if (row) {
          that.expands.push(row.id); // 每次push进去的是每行的ID
          that.queryorderapplicantorgFn(row.id, row);
        }
      } else {
        that.expands = []; // 默认不展开
      }
    },
    setDate(e) {
      if (!e) {
        this.formDate.StartTime = "";
        this.formDate.EndTime = "";
      } else {
        this.formDate.StartTime = e[0];
        this.formDate.EndTime = e[1];
      }

      this.getQueryorderorg();
    },
  },
};
</script>

<style lang="scss">
.order-center {
  .quan-dialog-out-class {
    background: none !important;
    border-radius: 12px;

    .el-dialog {
      background: none;
    }

    .el-dialog__header {
      display: none;
    }

    .el-dialog__body {
      width: 419px;

      // height: 285px;
      background: linear-gradient(
        180deg,
        #e7ecfb 0%,
        #f6f8fe 24%,
        #ffffff 100%
      );
      border-radius: 12px;
      box-sizing: border-box;
      overflow: hidden;
    }
  }

  .order-center-search {
    padding: 16px;

    .el-select {
      margin-right: 8px;
    }

    .el-input__inner {
      background: #f2f4f7;
      border: none;
    }

    .el-table__row {
      .cell {
        font-size: 12px;
      }
    }

    .el-range-input {
      background: #f2f4f7;
    }
  }

  .el-pager {
    .active {
      cursor: default;
      background: #1492e6;
      color: #fff !important;
      border-radius: 100%;
      width: 28px;
      height: 28px;
      min-width: 10px;
      box-shadow: 0px 2px 3px 1px rgba(19, 152, 231, 0.26);
    }
  }

  .el-table__header-wrapper {
    border-radius: 8px;
  }

  .props-table-box {
    margin-top: -20px;
  }

  .order-center-table {
    .el-table {
      .el-table__body-wrapper {
        overflow-y: auto;
      }
    }

    .el-table__row {
      box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.09);
      border-radius: 8px 8px 8px 8px;
      margin-bottom: 12px;

      .el-table__cell {
        border-bottom: none;
      }
    }

    .el-table td.el-table__cell,
    .el-table th.el-table__cell.is-leaf {
      height: 40px;
    }
  }

  .el-table {
    width: 100%;
    margin-bottom: 20px;

    &::before {
      display: none;
    }

    .el-table__body {
      //-webkit-border-horizontal-spacing: 13px;  // 水平间距
      -webkit-border-vertical-spacing: 10px; // 垂直间距 设置的是行间距
    }

    //以下代码是实现边框效果
    thead th {
      font-size: 14px;
      color: #575757;

      &:nth-last-child(2) {
        border-right: 1px solid rgba(0, 0, 0, 0.1) !important;
        border-radius: 0 5px 5px 0;
        right: 1px;
      }
    }

    // thead th,
    .el-table__row td {
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      padding: 10px 0;
      // border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
      // border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;

      &:first-child {
        border-left: 1px solid rgba(223, 204, 204, 0.1) !important;
        border-radius: 5px 0 0 5px;
      }
    }

    .el-table__row > td {
      font-size: 12px;
      color: #333333;

      &:last-child {
        border-right: 1px solid rgba(223, 204, 204, 0.1) !important;
        border-radius: 0 5px 5px 0;
        right: 1px;
      }
    }

    // .el-table__body tr:hover>td.el-table__cell {
    //   background-color: rgba(0, 110, 255, 0.05);
    // }

    .el-table__fixed::before {
      display: none;
    }

    .props-table {
    }
  }
}

.el-table__expanded-cell {
  // transform: translateY(-22px) !important;
  // background: #F9F9F9;
}

.props-table-box {
  // background: #F9F9F9;
  width: 100%;
  height: auto;

  .el-table__header-wrapper {
    border-radius: 0px;
  }

  .el-table__cell {
    background: #f9f9f9;
    padding: 0px;
  }

  .el-table__body {
    //-webkit-border-horizontal-spacing: 13px;  // 水平间距
    -webkit-border-vertical-spacing: 0px !important; // 垂直间距 设置的是行间距
  }

  .table-pagination {
    .el-pagination {
      height: 50px;
      margin-top: -30px;
    }
  }
}
</style>

<style lang="scss" scoped>
.quan-num-add {
  .quan-num-input {
    margin: 12px 0;
  }

  .quan-num-add-item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .quan-num-add-title {
      font-size: 16px;
      margin-top: 12px;
    }

    .quan-num-right {
      margin-left: 16px;
      color: #3888e9;
      cursor: pointer;
    }
  }
}

.dialog-member-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -10px;

  .dialog-member-name {
    font-size: 18px;
    font-weight: bold;
  }

  .dialog-close {
    width: 25px;
    height: 25px;
    background: #e0e0e0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .el-icon-close {
      color: #fff;
      font-size: 16px;
    }
  }
}

.packge-btn-add {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;

  .pageck-btn {
    width: 145px;
    margin: 0 16px;
  }
}

.page-content {
  width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
  height: calc(100vh - 100px - 20px);

  .order-center-search {
    height: calc(100% - 50px);

    .box-table {
      height: calc(100% - 150px);

      .el-table {
        height: 100% !important;
      }
    }
  }

  .page-menu {
    display: flex;
    align-items: center;

    .page-menu-item {
      width: 133px;
      height: 50px;
      // background: #FFF;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      color: #777777;
      cursor: pointer;
      border-radius: 8px 8px 0px 0px;

      &.page-menu-item-active {
        background: #fff;
        color: #3888e9;
      }
    }
  }

  .order-center-search {
    background: #fff;
    box-sizing: border-box;
    padding: 24px;

    .box-input-left {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.edit-wrap2 {
  .edit-item {
    // padding-left: 20px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      cursor: pointer;
      display: inline-block;
      margin: 0 2px;

      &.lan {
        color: #3888e9;
      }

      &.copy {
        color: #9ea09e;
      }

      &.del {
        color: #e41313;
      }
    }
  }
}

.box-table {
  margin-top: 20px;

  ::v-deep .el-table__expand-icon {
    width: 18px;
    height: 18px;
    background: #f2f5f9;
    border-radius: 4px;

    .el-icon {
      margin-left: -6px;
    }
  }

  .time-line {
    width: 8px;
    margin-right: 3px;
  }

  .time-text {
    height: 44px;

    p {
      text-align: left;
      margin-top: -5px;
    }

    p + p {
      margin-top: 10px;
    }
  }

  .source-type {
    display: inline-block;
    width: 16px;
    height: 16px;
    text-align: center;
    line-height: 16px;
    background: #4791ea;
    border-radius: 4px;
    color: #fff;
    font-size: 10px;
    margin-right: 5px;
  }

  ::v-deep .cellMarks {
    .cell {
      overflow: visible;
      display: flex;
      align-items: center;
    }
  }

  .marks-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    width: 60px;
  }

  .marks-icon {
    position: relative;

    .marks-dialog {
      position: absolute;
      top: 27px;
      left: -190px;
      z-index: 99;
      width: 282px;
      background: #ffffff;
      box-shadow: 0px 3px 10px 1px rgba(0, 0, 0, 0.16);
      border-radius: 8px;
      padding: 10px;

      p {
        font-size: 14px;
        font-weight: bold;
        text-align: left;
      }

      .el-textarea {
        margin-top: 20px;
      }

      ::v-deep .el-textarea__inner {
        width: 250px;
        height: 128px;
        background: #f2f4f7;
        border-radius: 8px;
        border: none;
      }

      .marks-btn {
        text-align: right;
        margin-top: 20px;

        .default {
          border-radius: 8px;
        }
      }
    }
  }

  .icon-a-xiugai1 {
    color: #3888e9;
    cursor: pointer;
    font-size: 12px;
  }

  .visa-icon {
    color: #0099e9;
    font-size: 12px;
    cursor: pointer;
  }
}

.table-pagination {
  text-align: center;
  margin-top: 16px;
}

.box-input-btn-wrap {
  // width: 130px;
  // height: 46px;
  // border-radius: 8px;
  padding-left: 15px;
  flex: 1;
  display: flex;
  justify-content: space-between;

  .btn-size {
    width: 130px;
    height: 46px;
    border-radius: 8px;
    font-size: 16px;

    &:nth-child(2) {
      background: #eaf3ff;
      color: #3888e9;
      border: none;
    }

    &:nth-child(3) {
      background: #fff !important;
      color: #3888e9;
      border: 1px solid #3888e9;
    }
  }
}

.edit-wrap {
  .edit-item {
    // padding-left: 20px;

    span {
      cursor: pointer;
      display: inline-block;
      margin: 0 2px;

      &.lan {
        color: #3888e9;
      }

      &.copy {
        color: #9ea09e;
      }

      &.del {
        color: #e41313;
      }
    }
  }
}
</style>
