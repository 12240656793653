<template>
    <div class="settins">
        <div class="settins-left">
            <div class="setting-type">
                <p>{{ method == 1 ? '线上办理' : '线下办理' }}</p>
                <span v-if="setting.method == 0" @click="selectMethod"><i class="el-icon-sort"></i> 切换</span>
            </div>

            <div class="identitys-list">
                <div class="identitys-item" :class="identitysActive == index ? 'identitys-item-active' : ''"
                    v-for="(item, index) in visaIdentitys" :key="index" @click="checkIdentity(index)">
                    {{ item.name }}
                </div>
            </div>

            <div class="identitys-select" @click="openDialogIdentitys"  style="white-space: nowrap;">选择身份类型</div>
        </div>
        <div class="settins-right">

            <div class="box-input">

                <div class="box-input-left">
                    <!-- @change="getQuery" -->
                    <el-select clearable v-model="form.isRequire" placeholder="是否必须" @change="getQueryvisadocs">
                        <el-option v-for="item in requires" :key="item.value" :label="item.name" :value="item.value">
                        </el-option>
                    </el-select>

                    <el-input @input="getQueryvisadocs" v-model="form.name" style="width: 340px;margin-right: 22px;" placeholder="请输入资料名称"></el-input>


                    <!-- <el-select clearable v-model="productForm.visaTypeId" @change="getQuery" placeholder="请选择签证大类">
                        <el-option v-for="item in visaType" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
  

                    <el-select clearable v-model="productForm.countryId" @change="getQuery" placeholder="请选择国家">
                        <el-option v-for="item in country" :key="item.id" :label="item.zhName" :value="item.id">
                        </el-option>
                    </el-select> -->
                </div>

                <div class="box-input-btn">
                    <el-button class="box-input-btn-cz" @click="reset">重置</el-button>
                    <el-button class="box-input-btn-add" @click="editData">配置资料</el-button>
                </div>
            </div>

            <div class="box-table">
                <el-table :data="queryVisaList"
                    :header-cell-style="{ backgroundColor: '#F2F4F7', color: '#333', fontWeight: 'bold', textAlign: 'center', fontSize: '12px' }"
                    v-loading="loading" :cell-style="{ 'text-align': 'center' }" :height="$store.state.product.screen ? '820px' : '470px'">

                    <el-table-column prop="categoryName" label="一级大类">
                    </el-table-column>
                    <el-table-column prop="name" label="资料名称">
                    </el-table-column>
                    <el-table-column prop="legend" label="样例">
                        <template slot-scope="scope">
                            <div>
                                <el-image style="width: 88px; height: 55px;border-radius: 8px;" :src="scope.row.legend"
                                    :preview-src-list="[scope.row.legend]">
                                </el-image>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="sort" label="文书模板">
                        <template slot-scope="scope">
                            上传
                        </template>
                    </el-table-column>
                    <el-table-column prop="sort" label="排序">
                    </el-table-column>
                    <el-table-column prop="isNeed" label="是否必须">
                        <template slot-scope="scope">
                            <div>
                                <el-switch v-model="scope.row.isNeed" active-color="#3888E9" inactive-color="#CCCCCC"
                                    @change="switchIsNeedChange(scope.row)">
                                </el-switch>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="isMultiple" label="是否多张">
                        <template slot-scope="scope">
                            <div>
                                <el-switch v-model="scope.row.isMultiple" active-color="#3888E9" inactive-color="#CCCCCC"
                                    @change="switchIsMultipleChange(scope.row)">
                                </el-switch>

                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" header-align="center">
                        <template slot-scope="scope">
                            <div class="edit-wrap">
                                <span style="color: #3888E9;cursor: pointer;" class="del"
                                    @click="delProduct(scope.row.id)">删除</span>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="table-pagination">

                <el-pagination @current-change="currentChange" :current-page="pageIndex" :page-size="10"
                    layout="total, prev, pager, next, jumper" :total="pageTotal">
                </el-pagination>
            </div>
        </div>

        <el-dialog :visible.sync="zlDataShow" width="808px" class="zl-class">
            <div class="dialog-member-wrap" style="margin-bottom: 20px;">
                <div class="dialog-member-title">
                    <span class="dialog-member-name">配置资料</span>
                    <div class="dialog-close" @click="zlDataShow = false">
                        <i class="el-icon-close"></i>
                    </div>
                </div>
            </div>
            <div calss="dialog-zl-wrap">
                <el-table :data="docList"
                    :header-cell-style="{ backgroundColor: '#F2F4F7', color: '#333', fontWeight: 'bold', textAlign: 'center' }"
                    style="width: 100%" height="520px" v-loading="loading" :key="tableKey">
                    <el-table-column prop="name" label="类别" width="140px">
                    </el-table-column>
                    <el-table-column prop="docs" label="项目">
                        <template slot-scope="scope">
                            <el-checkbox-group v-model="checkBoxArr">
                                <el-checkbox :label="item.id" v-for="(item, index) in scope.row.docs" :key="index">{{
                                    item.name }}</el-checkbox>
                            </el-checkbox-group>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="dialog-zl-btn">
                <el-button class="close2 zl-btn" @click="zlDataShow = false">取消</el-button>
                <el-button class="default zl-btn" @click="settingSubmit">确认</el-button>

            </div>
        </el-dialog>

        <!-- 成员管理 添加or编辑 -->
        <el-dialog :visible.sync="dialogIdentitys" width="464px" class="identitys-class" :close-on-click-modal="false">
            <div class="dialog-member-wrap">
                <div class="dialog-member-title">
                    <span class="dialog-member-name">选择身份类型</span>
                    <div class="dialog-close" @click="dialogIdentitys = false">
                        <i class="el-icon-close"></i>
                    </div>
                </div>
            </div>
            <div class="identitys-dialog-list">
                <div class="identitys-dialog-item" @click="selectActive(item)"
                    :class="[item.active ? 'identitys-dialog-item-active' : '']" v-for="(item, index) in identitysList"
                    :key="index">
                    {{ item.name }}

                    <div class="identitys-dialog-item-img">
                        <img :src="`https://file.sport-syy.com/visa/admin/images/pc/identity${index}.png`"
                            v-if="item.active" alt="">
                        <img :src="`https://file.sport-syy.com/visa/admin/images/pc/identityNo${index}.png`" v-else alt="">

                    </div>
                </div>
            </div>
            <div class="identitys-dialog-btn">
                <el-button class="default identitys-btn" @click="identitysCheck">确认</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { getvisaidentitys, getdefaultvisaidentitys, visaidentitys, queryvisadocs, getvisadocs, doclist, modifyvisadoc, modifymultipleorisneed, deletevisadoc } from '@/api/personal.js'
export default {
    data() {
        return {
            id: '',
            visaIdentitys: [],
            docList: [],
            visaDocs: [],
            checkBoxArr: [],
            queryVisaList: [],
            identitysList: '',
            identitysActive: -1,
            method: 0,
            num: 0,
            tableKey: 1,
            dialogIdentitys: false,
            zlDataShow: false,
            loading: false,
            pageIndex: 1,
            pageTotal: 0,
            requires: [
                {
                    name: '是',
                    value: 1
                },
                {
                    name: '否',
                    value: 2
                },
            ],
            form: {
                isRequire: '',
                name: '',
            }
        }
    },
    mounted() {
        this.id = this.setting.id;

        if (this.setting.method == 0) {
            this.method = 1
        } else {

            this.method = this.setting.method
        }
        this.getvisaidentitysFn();
        this.getDoclist();
        setTimeout(()=>{
            if(this.visaIdentitys.length){
                this.checkIdentity(0)
            }
        },500)
    },
    props: {
        setting: {
            type: Object,
            default: {},
        }
    },

    methods: {

        getvisaidentitysFn() {
            let vm = this;

            getvisaidentitys({
                id: vm.id,
                token: vm.$store.state.token
            }).then(res => {
                vm.visaIdentitys = res.data
                vm.getdefaultvisaidentitysFn();
            })
        },
        getdefaultvisaidentitysFn() {
            let vm = this;

            getdefaultvisaidentitys({
                token: vm.$store.state.token
            }).then(res => {

                console.log(vm.visaIdentitys, 6666);
                vm.identitysList = res.data;

                vm.identitysList.forEach(item => {
                    vm.visaIdentitys.forEach(elem => {
                        elem.active = false;

                        if (item.id == elem.identityTypeId) {
                            item.active = true;
                        }
                    })
                })
                vm.getQueryvisadocs();
            })
        },
        getQueryvisadocs() {
            let vm = this;
 
            vm.loading = true;

            let isNeed = ''
            if (!vm.form.isRequire) {
                isNeed = ''
            } else {
                isNeed = vm.form.isRequire == 1 ? 'true' : 'false'
            }


            if (vm.identitysActive == -1) {
                vm.loading = false;
                return;
            }

            queryvisadocs({
                token: vm.$store.state.token,
                VisaId: vm.id,
                IdentityId: vm.visaIdentitys[vm.identitysActive] ? vm.visaIdentitys[vm.identitysActive].identityTypeId : '',
                Method: vm.method,
                'Page.Size': 10,
                'Page.Index': this.pageIndex,
                IsNeed: isNeed,
                Name: vm.form.name,
            }).then(res => {
                console.log(res);
                vm.loading = false;
                this.queryVisaList = res.data.rows;
                this.pageTotal = res.data.total
            })
        },

        reset () {
            let vm = this;
            vm.form = {
                name: '',
                isRequire: '',
            }

            vm.getQueryvisadocs();

        },
        getvisadocsFn() {
            let vm = this;

            this.loading = true

            getvisadocs({
                token: vm.$store.state.token,
                visaIdentityId: vm.visaIdentitys[vm.identitysActive].id,
                Method: vm.method,
            }).then(res => {
                console.log(res);
                vm.visaDocs = res.data
                console.log(vm.docList);
                let arr = []

                vm.docList.forEach(item => {
                    item.docs.forEach(elem => {
                        vm.visaDocs.forEach(el => {
                            if (el.docId == elem.id) {
                                // elem.isActive = true;
                                arr.push(elem.id)
                            }
                        })

                    })
                })

                this.checkBoxArr = arr;

                this.tableKey += 1;
                console.log(vm.docList);
                this.loading = false

            })
        },

        getDoclist() {
            doclist({
            }).then(res => {
                console.log(res);

                this.docList = res.data;

                this.docList.forEach(item => {
                    item.docs.forEach(elem => {
                        elem.isActive = false;
                    })
                })
            })
        },

        checkIdentity(index) {
            this.identitysActive = index
            this.getvisaidentitysFn();
        },
        currentChange (val) {
            this.pageIndex = val;
            this.getQueryvisadocs();
        },
        selectMethod() {
            if (this.method == 1) {
                this.method = 2
            } else {
                this.method = 1
            }
            this.getvisaidentitysFn();
        },
        editData() {
            if(this.identitysActive == -1){
                this.$message({
                    message: '请先选择身份类型！',
                    type: 'warning'
                });
                return 
            }
            this.getvisadocsFn();
            this.zlDataShow = true;

        },
        openDialogIdentitys() {
            this.dialogIdentitys = true;
        },
        settingSubmit() {
            let vm = this;
            modifyvisadoc({
                token: vm.$store.state.token,
                method: vm.method,
                visaIdentityId: vm.visaIdentitys[vm.identitysActive].id,
                docIds: vm.checkBoxArr
            }).then(res => {
                console.log(res);
                vm.$message({
                    message: '配置成功！',
                    type: 'success'
                });

                vm.getvisaidentitysFn();
                vm.zlDataShow = false;
            })
        },
        checkBoxZl(e, index, idx) {
            console.log(e);

            this.docList[index].docs[idx].isActive = e;
            this.$forceUpdate();
            this.tableKey = this.tableKey + 1
        },
        identitysCheck() {


            console.log(this.identitysList)
            let arr = [];

            this.identitysList.forEach(item => {
                if (item.active) {
                    arr.push(item.id)
                }
            })


            visaidentitys({
                visaId: this.id,
                ids: arr,
                 
            }).then(res => {
                console.log(res);

                this.$message({
                    message: '修改成功！',
                    type: 'success'
                });

                this.getvisaidentitysFn();
                this.dialogIdentitys = false;
            })
        },
        selectActive(item) {
            item.active = !item.active;
            this.$forceUpdate();
        },
        delProduct(id) {

            this.$confirm(`是否删除该签证资料`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {

                deletevisadoc({
                    id,
                }).then(res => {
                    
                    this.$message({
                        message: '删除成功！',
                        type: 'success'
                    });
                    this.getQueryvisadocs();

                })
            }).catch(res => {
                    
                })
            
        },  
        switchIsNeedChange(item) {
            console.log(item);
            item.isNeed = !item.isNeed
            this.$confirm(`是否${!item.isNeed ? '开启' : '关闭'}必须该资料`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                modifymultipleorisneed({
                    id: item.id,
                    flag: !item.isNeed,
                    type: 1
                }).then(res => {
                    this.getvisaidentitysFn();
                }).catch(res => {
                    
                })
            }).catch(res => {
                    
                })

        },
        switchIsMultipleChange(item) {
            console.log(item);
            item.isMultiple = !item.isMultiple
            this.$confirm(`是否要${!item.isMultiple ? '开启' : '关闭'}多张图片`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                modifymultipleorisneed({
                    id: item.id,
                    flag: !item.isMultiple,
                    type: 2
                }).then(res => {
                    this.getvisaidentitysFn();
                })
            }).catch(res => {
                    
                })

        },
    }
}
</script>

<style lang="scss">
.settins {
    .el-select {
        margin-right: 8px;
    }

    .el-input__inner {
        background: #F2F4F7;
        border: none;
    }

    .el-table__row {
        .cell {
            font-size: 12px;
        }
    }
}

.zl-class {
    background: none !important;
    border-radius: 12px;

    .el-dialog {
        background: none;
    }

    .el-dialog__header {
        display: none;
        background: #F2F8FF;
    }

    .el-dialog__body {
        width: 808px;
        background: #F1F5FF;
        border-radius: 12px;
        box-sizing: border-box;
        overflow: hidden;
        height: 680px;
    }

    &.address-class {
        .el-dialog__body {}
    }
}

.identitys-class {
    background: none !important;
    border-radius: 12px;

    .el-dialog {
        background: none;
    }

    .el-dialog__header {
        display: none;
    }

    .el-dialog__body {
        width: 464px;
        background: #F1F5FF;
        border-radius: 12px;
        box-sizing: border-box;
        overflow: hidden;
    }

    &.address-class {
        .el-dialog__body {
            height: 519px;

        }
    }
}
</style>

<style lang="scss" scoped>
.table-pagination {
    text-align: center;
    margin-top: 16px;
}

.dialog-member-wrap {
    .dialog-member-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: -10px;

        .dialog-member-name {
            font-size: 18px;
            font-weight: bold;
        }

        .dialog-close {
            width: 25px;
            height: 25px;
            background: #E0E0E0;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            .el-icon-close {
                color: #FFF;
                font-size: 16px;
            }
        }
    }
}

.identitys-dialog-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 30px;

    .identitys-dialog-item {
        width: 194px;
        height: 54px;
        border-radius: 8px 8px 8px 8px;
        background: #FFF;
        margin-bottom: 20px;
        display: flex;
        padding-left: 24px;
        align-items: center;
        box-sizing: border-box;
        cursor: pointer;
        position: relative;

        &.identitys-dialog-item-active {
            color: #3888E9;

        }

        .identitys-dialog-item-img {
            position: absolute;
            right: 0;
            bottom: 0;

            // transform: scale(.5);
            img {
                display: inline-block;
                width: 50%;
                height: 50%;
                float: right;
            }
        }
    }
}

.box-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;

    .box-input-btn {
        text-align: right;

        .box-input-btn-cz {
            background: #EAF3FF;
            color: #3888E9;
            border: none;
        }

        .box-input-btn-add {
            background: #3888E9;
            color: #FFF;
            border: none;
        }
    }
}

.identitys-dialog-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px 0;

    .identitys-btn {
        width: 191px;
        height: 50px;
    }

}

.dialog-zl-btn {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    margin-top: 24px;

    .zl-btn {
        margin: 0 30px;
        width: 145px;
        height: 38px;
        border-radius: 8px 8px 8px 8px;
    }
}

.settins {
    height: 100%;
    overflow: hidden;
    display: flex;
    width: 1005px;
    flex: 1;
    .settins-left {
        width: 199px;
        background: #F9FAFF;
        margin-top: 8px;
        padding: 16px 12px;
        box-sizing: border-box;
        position: relative;

        .identitys-select {
            position: absolute;
            bottom: 30px;
            left: 50%;
            transform: translateX(-50%);
            font-size: 16px;
            color: #3888E9;
            cursor: pointer;
        }

        .setting-type {
            display: flex;
            align-items: center;
            cursor: pointer;

            p {
                font-size: 24px;
                font-weight: bold;
                color: #3888E9;
            }

            span {
                background: #3888E9;
                color: #FFF;
                // height: 18px;
                font-size: 12px;
                padding: 2px 6px;
                border-radius: 2px;
                margin-left: 13px;
            }
        }

        .identitys-list {
            display: flex;
            flex-direction: column;
            margin-top: 23px;

            .identitys-item {
                width: 145px;
                height: 46px;
                border-radius: 8px;
                display: flex;
                padding-left: 22px;
                align-items: center;
                cursor: pointer;

                &.identitys-item-active {
                    background: #F2F6FF;
                    color: #3888E9;
                }
            }


        }
    }

    .settins-right {
        width:800px;
        padding: 16px;
        overflow:auto;
    }
}
</style>