<template>
  <div class="page">
    <div class="banner1">

    </div>
    <div class="box1">
      <div class="box1-title">企业介绍</div>
      <div class="box1-content">
        <div class="box1-top">
          <div class="box-top-item">
            <span class="box-item1">2000+</span>
            <span class="box-item2">合作同业</span>
          </div>
          <div class="box-top-item">
            <span class="box-item1">200000+</span>
            <span class="box-item2">累计出签本数</span>
          </div>
          <div class="box-top-item">
            <span class="box-item1">78+</span>
            <span class="box-item2">国家覆盖</span>
          </div>
          <div class="box-top-item">
            <span class="box-item1">15</span>
            <span class="box-item2">领区覆盖</span>
          </div>
        </div>
        <div class="box1-bom">
          <p>
            快马签证采用技术手段解决传统签证办理痛点，简化资料提交流程，办理签证实时追踪，实现办签业务全面线上化。小程序随时随地云接单，办签资料一键分享，为同业提供一站式办签体验。快马签证（www.kuaimavisa.com）致力于运用技术手段，提升签证行业效率。通过全面实现签证办理流程电子化，智能化来解决传统签证行业办理痛点，为同业旅行社和终端用户都提供了极大的便利。。
          </p>
          <p></p>
          <p>
            团队自主研发的智能办签SaaS平台，主要面向旅游同业客户。致力于运用技术手段，提升签证行业效率。通过全面实现签证办理流程电子化，智能化来解决传统签证行业办理痛点，为同业旅行社和终端用户都提供了极大的便利。
          </p>
          <p></p>
          <p>
            目前快马签证平台支持同业客户在电脑端和微信小程序在线办理签证，大幅降低签证办理难度，提升效率，致力于打造一键式办签体验。
          </p>
        </div>
      </div>
      <div class="box1-title">企业愿景</div>
      <div class="box1-bom-info">
        <div class="box1-bom-info-item">
          <img :src="require('../../assets/images/about/1.png')" alt="">
          <div class="box1-bom-r">
            <span class="box1-bom-text1">使命</span>
            <span class="box1-bom-text2">技术创新，提高签证行业效率</span>
          </div>
        </div>
        <div class="box1-bom-info-item" style="margin: 0 32px;">
          <img :src="require('../../assets/images/about/2.png')" alt="">
          <div class="box1-bom-r">
            <span class="box1-bom-text1">愿景</span>
            <span class="box1-bom-text2">最值得信赖的签证解决方案提供商</span>
          </div>
        </div>
        <div class="box1-bom-info-item">
          <img :src="require('../../assets/images/about/3.png')" alt="">
          <div class="box1-bom-r">
            <span class="box1-bom-text1">价值观</span>
            <span class="box1-bom-text2">客户至上，高效、专业、创新、进取</span>
          </div>
        </div>
      </div>
    </div>
    <div class="box2">
      <div class="box2-title">发展历程</div>
      <div class="box2-list">
        <div class="box2-item">
          <div class="box2-item-left">
            <span class="box2-x-1"></span>
            <span class="box2-x-2"></span>
            <span class="box2-x-3"></span>
          </div>
          <div class="box2-item-right">
            <span>2022.09</span>
            <p>签证公司正式注册成立，创始团队曾就职于阿里巴巴,腾讯等知名企业</p>
          </div>
        </div>
        <div class="box2-item">
          <div class="box2-item-left">
            <span class="box2-x-1"></span>
            <span class="box2-x-2"></span>
            <span class="box2-x-3"></span>
          </div>
          <div class="box2-item-right">
            <span>2023.05</span>
            <p>签证助手小程序上线</p>
          </div>
        </div>
        <div class="box2-item">
          <div class="box2-item-left">
            <span class="box2-x-1"></span>
            <span class="box2-x-2"></span>
            <span class="box2-x-3"></span>
          </div>
          <div class="box2-item-right">
            <span>2023.08</span>
            <p>申根自动预约系统上线</p>
          </div>
        </div>
        <div class="box2-item">
          <div class="box2-item-left">
            <span class="box2-x-1"></span>
            <span class="box2-x-2"></span>
            <span class="box2-x-3"></span>
          </div>
          <div class="box2-item-right">
            <span>2023.11</span>
            <p>马来西亚、新加坡入境卡自动化上线</p>
          </div>
        </div>
        <div class="box2-item">
          <div class="box2-item-left">
            <span class="box2-x-1"></span>
            <span class="box2-x-2"></span>
            <span class="box2-x-3"></span>
          </div>
          <div class="box2-item-right">
            <span>2024.02</span>
            <p>越南、柬埔寨、土耳其、老挝电子签自动化上线</p>
          </div>
        </div>
      </div>
      <div class="box2-x"></div>
    </div>
    <div class="box4">
      <div class="box4-list">
        <div class="box4-item">
          <p class="box4-title">联系我们</p>
          <p class="box4-lable">电话</p>
          <p class="box4-value" style="margin-bottom: 16px;">181 7084 3717</p>
          <p class="box4-lable">邮箱</p>
          <p class="box4-value">1042752863@qq.com</p>
          <p class="box4-lable" style="margin-top: 16px">官网客服</p>
          <div class="box4-dm">
            <el-image :src="require('@/assets/images/common/kefu.jpg')"  style="width: 108px; height: 108px" fit="fit"></el-image>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'about'
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/about.scss';

.banner1 {
  height: 600px;
  width: 100%;
  background: url('@/assets/images/home/banner2.jpg');

  background-size: 100% 600px;
  background-position: center;
  position: relative;


}</style>