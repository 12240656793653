import request from '@/utils/request';

export function queryinsuranceproducts(params) {
    return request('/app_api/v1/insurance/queryinsuranceproducts', {
      method: 'get',
      params: {...params}//get请求使用params,直接拼接在请求url上
    })
  }
  export function getinsuranceproductdetail(params) {
    return request('/app_api/v1/insurance/getinsuranceproductdetail', {
      method: 'get',
      params: {...params}//get请求使用params,直接拼接在请求url上
    })
  }

  export function getinsuranceprice(params) {
    return request('/app_api/v1/insurance/getinsuranceprice', {
      method: 'get',
      params: {...params}//get请求使用params,直接拼接在请求url上
    })
  }
  
  
